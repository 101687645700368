import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IconService} from "../../../services/icon/icon.service";
import {Subscription} from "rxjs";
import {Icon} from "../../../models/icon/icon";

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  private subscription = new Subscription();
  public icon: Icon = null;

  constructor(
    private iconService: IconService
  ) {
  }

  ngOnInit(): void {
    this.getIcon();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getIcon() {
    this.subscription.add(
      this.iconService.getIcon().subscribe((icon: Icon) => {
        if (icon && icon.id  == this.id) this.icon = icon;
      })
    );
  }
}
