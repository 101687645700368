import { Injectable } from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from "rxjs";
import { ITableForm } from '../../interfaces/tables/form/i-table-form';
import {ITableWithoutEdit} from "../../interfaces/tables/without-edit/i-table-without-edit";
import {IRowValue} from "../../interfaces/tables/i-row-value";
import {ICellWithoutEdit} from "../../interfaces/tables/without-edit/i-cell-without-edit";

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public tableWithoutEditValue$: BehaviorSubject<ITableWithoutEdit> = new BehaviorSubject<ITableWithoutEdit>(null);
  public tableValue$: ReplaySubject<ITableForm> = new ReplaySubject<ITableForm>(2, 100);
  public tableNewValue$: BehaviorSubject<ITableForm> = new BehaviorSubject<ITableForm>(null);
  public expand$:BehaviorSubject<string> = new BehaviorSubject<string>('');
  public tableExpandedValue$: BehaviorSubject<ICellWithoutEdit> = new BehaviorSubject<ICellWithoutEdit>(null);
  public tableFormValue$: Subject<{}> = new Subject<{}>();
  public singleValue$: Subject<IRowValue> = new Subject<IRowValue>();
  public disableTableInput$: Subject<boolean> = new Subject<boolean>();
  public lineRemove$: Subject<string> = new Subject<string>();
  public lineEdit$: Subject<string> = new Subject<string>();
  removeAction$: BehaviorSubject<string> = new BehaviorSubject('');
  readonly rowChange$: Subject<any> = new Subject();

  constructor() { }

  setTableWithoutEditValue(value: ITableWithoutEdit) {
    this.tableWithoutEditValue$.next(value);
  }

  setTableValue(value: ITableForm, isNew: boolean = false) {
    this.tableValue$.next(value);
  }

  setExpand(value: string) {
    this.expand$.next(value);
  }

  setTableExpandedValue(value: ICellWithoutEdit) {
    this.tableExpandedValue$.next(value);
  }

  setTableFormValue(value: {}) {
    this.tableFormValue$.next(value);
  }

  setSingleValue(value: IRowValue) {
    this.singleValue$.next(value);
  }

  disableTableInputStatus(status: boolean) {
    this.disableTableInput$.next(status);
  }

  setLineRemoved(value: string) {
    this.lineRemove$.next(value);
  }

  setLineEdited(id: string) {
    this.lineEdit$.next(id);
  }
}
