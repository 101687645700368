import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {Table} from "../../../models/table/table.model";
import {Line} from "../../../models/table/body/row/line";
import { InputValue } from '../../../models/table/validations/input-value';
import {Column} from "../../../models/table/body/column/column";

@Injectable({
  providedIn: 'root'
})
export class TableService {
  private table$: BehaviorSubject<Table> = new BehaviorSubject<Table>(null);
  private expandedId$: Subject<string> = new Subject<string>();
  private expandedLineValues$: Subject<Line[]> = new Subject<Line[]>();
  private action$: Subject<{id: string, action: 'delete' | 'edit' | 'view'}> = new Subject<{ id: string, action: 'delete' | 'edit' | 'view' }>();
  private tableInputValue$: Subject<InputValue> = new Subject<InputValue>();
  private columnValue$: Subject<{i: number, id: string, column: Column, isParent: boolean}> = new Subject<{i: number, id: string, column: Column, isParent: boolean}>();
  private removedLisne$: Subject<{id: string, status: Boolean, isParent: boolean}> = new Subject<{id: string, status: Boolean, isParent: boolean}>();

  constructor() { }

  getTable(): Subject<Table> {
    return this.table$
  }

  setTable(value: Table): void {
    this.table$.next(value)
  }

  getExpandedId(): Subject<String> {
    return this.expandedId$
  }

  setExpandedId(value: string): void {
    this.expandedId$.next(value)
  }

  getExpandedLineValues(): Subject<Line[]> {
    return this.expandedLineValues$;
  }

  setExpandedLineValues(lines: Line[]) {
    this.expandedLineValues$.next(lines)
  }

  getAction(): Subject<{ id: string, action: 'delete' | 'edit' | 'view' }> {
    return  this.action$;
  }

  setAction(value: { id: string, parentId?: string, action: 'delete' | 'edit' | 'view' }): void {
    this.action$.next(value);
  }

  getTableInputValue(): Subject<InputValue> {
    return this.tableInputValue$;
  }

  setTableInputValue(value: InputValue) {
    this.tableInputValue$.next(value);
  }

  getColumnValue(): Subject<{i: number, id: string, column: Column, isParent: boolean}> {
    return this.columnValue$;
  }

  setColumnValue(value: {i: number, id: string, column: Column, isParent: boolean}) {
    this.columnValue$.next(value);
  }

  getRemovedLine(): Subject<{id: string, status: Boolean, isParent: boolean}> {
    return this.removedLisne$;
  }

  setRemovedLine(value: {id: string, status: Boolean, isParent: boolean}) {
    this.removedLisne$.next(value);
  }
}
