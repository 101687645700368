<div class="tab">
  <app-tabs [tabType]="'button'" [emmitConfirmSelectTab]="" [uniqueId]="'customerTabUniqueId'">
    <app-tab [tabTitle]=" 'title tab 1'" [tab-id]="'particular'">
      div 1
    </app-tab>
    <app-tab [tabTitle]="'title tab 2'" [tab-id]="'professionnal'">
      div 2
    </app-tab>
  </app-tabs>
</div>
