import { Subscription } from 'rxjs';
import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MiscService} from "../../../services/miscellaniscious/misc.service";
import {popin} from "../../../../product/models/popin";
import {TranslateService} from "@ngx-translate/core";
import {IImport} from "../../../models/import";
import {ImportService} from "../../../services/import/import.service";

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  // @Output() sendFile: EventEmitter<any> = new EventEmitter();

  @Input()
  uniqueId: string="";

  @Input('buttonText') buttonText!: string;

  @Input()
  importConfig!: IImport;

  importFormGroup!: FormGroup;
  file!: File;
  send: boolean = false;

  popin = popin;
  fileLine: Number = 0;

  subscription: Subscription =new Subscription();

  @ViewChild('popInTemplate') template?: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private miscService: MiscService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private importService: ImportService
  ) {
    this.createForm();
  }

  ngOnInit(): void { }


  createForm() {
    this.importFormGroup = this.formBuilder.group({
      file: ['', Validators.required]
    })
  }

  resetForm(event) {
    event.target.value ='';
    this.importFormGroup.reset();
  }

  onFilechange(event: any) {
    this.importService.validateFile(event.target.files, this.importConfig.validation, this.uniqueId);
    this.importFormGroup.reset();
  }
}
