import { IPageTitleLink } from './../../../models/page-title-link';
import { Component,Input, OnInit } from '@angular/core';
import {MiscService} from "../../../services/miscellaniscious/misc.service";
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input()
  text:string = "";
  @Input()
  textLite:string = "";
  @Input()
  date:string;
  @Input()
  link: IPageTitleLink;

  subscription: Subscription =new Subscription();
  confirmLeavePage: boolean =false;

  constructor(
    private miscService: MiscService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.miscService.confirmLeavePage$.subscribe((confirm)=>{
        this.confirmLeavePage =confirm;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goTo(url: string) {
    if( this.confirmLeavePage ===true ){
      this.miscService.emmitLeavePageEvent(url);
    }else{
      this.miscService.goTo( url );
    }
  }
  
}
