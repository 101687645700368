import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function itemInArrayValidator(source: any[], key: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let index = source?.findIndex(d=>{
      return d[key] == control.value[key];
    });
    return index==-1?{itemNotInSourceArray: {value: control.value}}:null;
  };
}
