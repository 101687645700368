<div class="action__list-item">
  <label (click)="export()">
 
      <!-- <img src="{{exportConfig.icon}}" alt="icone"/> -->
      <i class="icn-csv"></i>
    

    {{exportConfig.label}}
  </label>
</div>
