import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first, of, pairwise, Subscription} from "rxjs";
import {storageKey} from "../../../../../../../config/storage";
import {LocalStorageService} from "../../../../services/local-storage/local-storage.service";
import {debounceTime, filter, startWith, switchMap} from "rxjs/operators";
import {ItemSerializationService} from "../../../../services/item-serialization/item-serialization.service";
import {cancel, save} from "../../../../config/constants";

@Component({
  selector: 'app-per-unit',
  templateUrl: './per-unit.component.html',
  styleUrls: ['./per-unit.component.scss']
})
export class PerUnitComponent implements OnInit, OnDestroy {
  @Input() public productUuid!: string;
  @Input() public serialization!: any;
  @Input() public serializationValue!: any;
  @Input() public id!: number;
  @Input() public autoCompleteField: boolean = false;
  @Output() public serializationInputValue: EventEmitter<any> = new EventEmitter();
  private subscription = new Subscription();
  public serializationFormGroup: FormGroup;
  public serializationInvalid: boolean = true;
  private userData!: any;
  private lastSerialNumber: string = '';
  private allInputSerializations: any = [];

  constructor(
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private itemSerializationService: ItemSerializationService
  ) {
    this.createForm();
    this.getUserDetail();
  }

  ngOnInit(): void {
    this.initializeFormValue();
    this.getSerializations();
    this.getSerialNumberValidity();
    this.canceledSerialization();
    this.getSerializationInputQuantityValidation();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.serializationFormGroup = this.formBuilder.group({
      id: this.id,
      product_uuid: this.productUuid,
      product_serialization_uuid: ['', Validators.required],
      fk_serialization_type_key: ['', Validators.required],
      product_serialization_key: ['', Validators.required],
      serial_number_start: '',
      serial_number_end: '',
      is_serialization_valid: false,
      quantity: 1,
      trigger: false
    });
  }

  getUserDetail() {
    this.userData = JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
  }

  initializeFormValue() {
    this.subscription.add(
      this.itemSerializationService.getSerializationValue().pipe(first()).subscribe(value => {
        this.serializationFormGroup.patchValue({
          id: this.id,
          product_uuid: this.productUuid,
          fk_serialization_type_key: this.serialization?.fk_serialization_type_key,
          product_serialization_key: this.serialization?.product_serialization_key,
          product_serialization_uuid: this.serialization?.product_serialization_uuid,
          serial_number_start: '',
          serial_number_end: ''
        });
        this.serializationInvalid = true;
        if (value[this.id] && value[this.id].serializationData.length > 0) {
          const _value = value[this.id]?.serializationData.filter(x => x.fk_serialization_type_key == this.serialization?.fk_serialization_type_key)[0];
          this.serializationFormGroup.patchValue({
            serial_number_start: _value.serial_number_start,
            serial_number_end: _value.serial_number_start
          });
          this.lastSerialNumber = _value.serial_number_start;

          this.serializationInvalid = (_value.serial_number_start == '' || !_value.is_serialization_valid);
        }

        value.map(x => x.serializationData).map(y => y.map(a => {
          this.allInputSerializations.push(a);
          return a;
        }))
        this.serializationFormGroup.updateValueAndValidity();
      })
    )
  }

  canceledSerialization() {
    this.subscription.add(
      this.itemSerializationService.getSaveSerializationStatus().subscribe(status => {
        if (status == cancel) {
          /*this.serializationFormGroup.patchValue({
            serial_number_start: this.lastSerialNumber,
            serial_number_end: this.lastSerialNumber
          });
          this.serializationFormGroup.updateValueAndValidity();
          this.sendSerialNumber( this.serializationFormGroup.value)*/
        }
      })
    );
  }

  triggerEvent(status: boolean = true) {
    this.serializationFormGroup.patchValue({ trigger: status });
    this.serializationFormGroup.updateValueAndValidity();
  }

  getSerialNumberValidity() {
    this.subscription.add(
      this.serializationFormGroup.valueChanges.pipe(
        debounceTime(1000),
        filter(value => value['trigger'] && !this.autoCompleteField),
        startWith(null), pairwise(),
        switchMap(([prev, next]: [any, any]) => {
          this.serializationInvalid = true;
          const start = next['serial_number_start']
          next['serial_number_end'] = start;
          next['is_serialization_valid'] = true;
          const serialization = this.serializations.filter(x=> x.product_uuid == this.productUuid);
          const starts = serialization.filter(s => s['fk_serialization_type_key'] === next['fk_serialization_type_key']).map(x => x.serial_number_start);
          const isBetween = serialization.filter(s => s['fk_serialization_type_key'] === next['fk_serialization_type_key']).map(x => {
            let status = false
            if (+x.serial_number_start < +x.serial_number_end && +start >= +x.serial_number_start && +start <= +x.serial_number_end) {
              status = true;
            }
            return status
          })
          if (starts.includes(start) || isBetween.includes(true) || start == '') {
            this.serializationInvalid = true;
            next['is_serialization_valid'] = false;
          }
          this.itemSerializationService.setInputSerializations(prev, next)
          if (next['serial_number_start'] != '') return this.itemSerializationService.getSerialNumberInterval(this.productUuid, next)
          else return of({is_exist: true, formValue: next})
        })
      ).subscribe((result: any) => {
        this.triggerEvent(false);
        this.serializationInvalid = (result.is_exist || !result.formValue['is_serialization_valid']);
        if (this.serializationInvalid) {
          result.formValue['is_serialization_valid'] = false;
          result.formValue['quantity'] = 0;
        }
        this.isExist = result.is_exist
        this.sendSerialNumber(result.formValue);
      })
    );
  }

  isExist: boolean = false;
  sendSerialNumber(value: any) {
    this.serializationInputValue.emit(value);
  }

  getSerializationInputQuantityValidation() {
    this.subscription.add(
      this.itemSerializationService.getSerializationInputQuantity().subscribe(value => {
        if (value && this.id == value['id'] && value['productUuid'] == this.productUuid && value['product_serialization_key'] == this.serialization?.product_serialization_key) {
          if ((value['inputQuantity'] > value['quantity'] && !value['isValid']) || !value['isValid'] || this.isExist) {
            this.serializationInvalid = true;
          } else if (value['inputQuantity'] <= value['quantity'] && value['isValid'] && !this.isExist) {
            this.serializationInvalid = false;
          }
        }
      })
    )
  }

  private serializations: any[] = []
  getSerializations() {
    this.itemSerializationService.getInputSerializations().subscribe(value => {
      this.serializations = value.filter(x=> x.product_uuid == this.productUuid && x.id != this.id)
    });
  }
}
