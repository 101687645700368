<div>
  <div class="block-table-user pb-4">
    <div class="block-table-user-top">
      <div class="block-table-user-top-item">
        <!--<span>6 Remise(s) : </span> -->
      </div>
      <div class="block-table-user-top-item">
        <div class="block-table-user-top-item-right">
          <app-icon *ngIf="iconId != ''" [id]="iconId"></app-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="client__list" appHeightScreen spaceHeight="183" *ngIf="id == tables?.id">
    <app-infinite-scroll-table>
      <table class="table" [formGroup]="tableFormGroup">
        <thead>
          <th class="w-md" *ngFor="let header of tables?.header" [attr.colspan]="header?.colspan" scope="col">
            <div class="block-intern-header"
              [ngClass]="{'align-right': header.alignement==='right', 'align-left': header.alignement==='left', 'align-center': header.alignement==='center'}">
              {{ header?.value }}
              <div *ngIf="header?.hasTooltip" class="icone-info" [attr.data-tooltip]="header?.tooltip"
                data-flow="bottom">
                <i class="icn-info-ellipse"></i>
              </div>
            </div>
          </th>
          <th *ngIf="tables?.isEditable || tables?.isDeletable">
            <div class="block-intern-header bold">
              <span>Actions</span>
            </div>
          </th>
          <!-- <th>
          type de remise
        </th> -->
        </thead>
        <tbody formArrayName="field_group">
          <ng-container *ngFor="let fields of fieldGroup().controls; let i = index;">
            <tr [formGroupName]="i">
              <ng-container formArrayName="field_list">
                <td *ngFor="let field of fieldList(i).controls; let j=index;"
                  [ngClass]="{ 'blockRight': field.value.align == 'right' }">
                  <div class="box-relative" [style.background-color]="field?.value?.bg"
                    [ngClass]="{'align-right': field.value.align==='right', 'align-left': field.value.align==='left', 'align-center': field.value.align==='center', 'p-lr': field?.value?.bg != 'transparent' }"
                    [formGroupName]="j">
                    <ng-container *ngIf="field.value.type == 'input' else simple ">
                      <ng-template [ngTemplateOutlet]="
                        field.value.input == 'text' ?
                        text : field.value.input == 'number' ?
                        number : field.value.input == 'checkbox' ?
                        checkbox : field.value.input == 'switch' ?
                        switch:  field.value.input === 'textarea' ?
                        textarea : radio
                      "></ng-template>
                      <ng-template #text>
                        <div class="blockCenter">
                          <input type="text" class="input-valid"
                            [ngClass]="{'input-error': field.get('value').hasError('required') || field.get('value').hasError('pattern')}"
                            [name]="'value'" [formControlName]="'value'" [readOnly]="isDisabled"
                            (change)="triggerValueChanges()" (input)="onValueChange(i, j)" />
                        </div>
                      </ng-template>
                      <ng-template #number>
                        <div class="blockCenter">
                          <input type="text" class="input-valid" numberInput
                            [fieldData]="{fieldName: i+'_'+j, uniqueId: 'table-form'}"
                            [readOnly]="isDisabled"
                            [ngClass]="{'input-error': field.get('value').hasError('required') || field.get('value').hasError('pattern')}"
                            [name]="'value'" [formControlName]="'value'" (input)="onValueChange(i, j)" />
                        </div>
                      </ng-template>
                      <ng-template #checkbox>
                        <div class="cell-table">
                          <label for="table-form-checkbox{{i}}" class="container"><span class="hide">Name </span>
                            <input type="checkbox" id="table-form-checkbox{{i}}" [ngClass]="{'disabled': isDisabled}"
                              [name]="'value'" [formControlName]="'value'" (change)="triggerValueChanges()">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </ng-template>
                      <ng-template #switch>
                        <div class="block-on-off">
                          <div class="block-switch lg-switch">
                            <label class="switch switch-primary">
                              <input type="checkbox" class="switch-input" [name]="'value'" [formControlName]="'value'"
                                [readOnly]="isDisabled" (change)="onCheck(field.value)">
                              <span class="switch-label" data-on="Actif" data-off="Inactif"></span>
                              <span class="switch-handle"></span>
                            </label>
                          </div>
                          <!-- onoff -->
                        </div>
                      </ng-template>
                      <ng-template #radio>
                        <input type="radio" [name]="'value'" [formControlName]="'value'" [readOnly]="isDisabled"
                          (change)="triggerValueChanges()" />
                      </ng-template>
                      <ng-template #textarea>
                        <div class="blockCenter">
                          <textarea class="input-valid" [name]="'value'" [formControlName]="'value'"
                            [readOnly]="isDisabled" (change)="triggerValueChanges()" (input)="onValueChange(i, j)"
                            [maxLength]="field.value.inputMaxLength">
                        </textarea>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-template #simple>
                      <ng-container class="column-flex" *ngIf="+field.value.expand">
                        <div class="plus {{expanded == field.value.id ? 'expanded' : 'notexpanded'}}"
                          (click)="expand(field.value.id)">
                          <span class="arrowRight"></span>
                        </div>
                        <div class="block-col-name">
                          <div class="block-left" *ngIf="field.value.image != ''">
                            <img [src]="field.value.image">
                          </div>
                          <div class="block-right" [attr.data-tooltip]="field.value.value" data-flow="top">
                            <span class="align-end lc-2">
                              {{ field.value.value }}
                              <span
                                *ngIf="tables?.body?.cellValue[i]?.extras?.length > 0"
                                [ngClass]="getTableExtra(i, field.value.key)?.style || ''"
                              >
                                {{getTableExtra(i, field.value.key)?.value || ''}}
                              </span>
                            </span>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container class="column-flex" *ngIf="!+field.value.expand">
                        <ng-container *ngIf="field.value.type == 'button' else simple">
                          <button class="{{ field.value?.class }}" (click)="field.value?.action()">
                            {{ field.value?.label }}
                          </button>
                        </ng-container>
                        <ng-template #simple>
                          <ng-container *ngIf="field.value.type === 'difference' else normal">
                            <div class="block-bold">
                              <div class="extra-bold {{ field.value.class }}">
                                <span [ngClass]="{'icn-icon_right_rrow': field.value.class}"></span>
                                <span>{{ field.value.value }}</span>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #normal>
                            <div class="block-col-name">
                              <div class="block-left" *ngIf="field.value.image != ''">
                                <img [src]="field.value.image">
                              </div>
                              <div class="block-right w-100">
                                <span class="align-end" [attr.data-tooltip]="field.value.value" data-flow="top">
                                  <span
                                    *ngIf="field.value?.badge?.status else notBadge"
                                    class="badge {{field.value?.badge?.bg}}"
                                  >
                                    {{ field.value.value }}
                                  </span>
                                  <ng-template #notBadge>
                                    <ng-container
                                      *ngIf="(typeOfValue(field.value.value) == 'string' || typeOfValue(field.value.value) == 'number') else other"
                                    >
                                      <span class="lc-2">{{ field.value.value }} </span>
                                    </ng-container>
                                    <ng-template #other>
                                      <span *ngFor="let v of field.value.value" class="lc-2">{{ v }}</span>
                                    </ng-template>
                                  </ng-template>
                                  <div
                                    [ngClass]="{'align-right': field.value.align==='right', 'align-left': field.value.align==='left', 'align-center': field.value.align==='center'}"
                                  >
                                    <span
                                      *ngIf="tables?.body?.cellValue[i]?.extras?.length > 0"
                                      class="{{getTableExtra(i, field.value.key)?.style || ''}}"
                                    >
                                      {{getTableExtra(i, field.value.key)?.value || ''}}
                                    </span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ng-container>
                    </ng-template>

                    <div class="flx-align-center block-description-article"
                      *ngIf="field.value?.icon && field.value?.icon['status']">
                      <div class="block-right-description-article">
                        <span [attr.data-tooltip]="field.value?.icon['tooltip']" [attr.data-flow]="'top'">
                          <i class="{{ field.value?.icon['icon'] }}"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td *ngIf="(tables?.isEditable || tables?.isDeletable)" >
                  <div class="blockCenter">
                    <div class="cell-content">
                      <button class="link-edit-profile" [disabled]="isDisabled" *ngIf="tables?.isEditable"
                        (click)="editLine(fields.value.id)" data-tooltip="Modifier" data-flow="top">
                        <span class="icn-edit"></span></button>
                      <button class="link-edit-profile" [disabled]="isDisabled" *ngIf="tables?.isDeletable"
                        (click)="removeLine(i)" data-tooltip="Supprimer" data-flow="top">
                        <span class="icn-delete"></span></button>
                    </div>
                  </div>
                </td>
                <td *ngIf="fields.value['action'].length > 0 || fields.value['action'] != ''">
                  <div class="blockCenter" >
                    <div class="cell-content" >
                      <button class="link-edit-profile" *ngFor="let action of fields?.value['action']"
                        (click)="action.function()" [attr.data-tooltip]="action.tooltip"
                        [ngClass]="{'disabled': !action.actif}" data-flow="top">
                        <span [ngClass]="{'blank' : action.id === 'blank'}" [class]="action.icon"></span>
                      </button>
                    </div>
                  </div>
                </td>
              </ng-container>
            </tr>
            <tr [ngClass]="expanded == fields.value.id ? 'isExpand' : 'isNotExpand'"
              *ngFor="let expandValue of expandedValues?.cellValue; let i = index">
              <td *ngFor="let expand of expandValue?.rowValue">
                <ng-container *ngIf="expand?.image">
                  <div class="block-col-name">
                    <div class="block-left">
                      <img [src]="expand?.image">
                    </div>
                    <div class="block-right">
                      {{ expand.value.value }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!expand?.image">
                  <div class="" *ngIf="!expand?.image"
                    [ngClass]="{'align-right': expand.align==='right', 'align-left': expand.align==='left', 'align-center': expand.align==='center'}">
                    {{ expand.value.value }}
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </app-infinite-scroll-table>
  </div>

</div>
