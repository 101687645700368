import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {JwtHelperService} from "@auth0/angular-jwt";
import {formatNumber} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class HelperService {

  constructor(@Inject(LOCALE_ID) public locale: string) { }
  /**
   * @description Form validation for text input and select
   * @param form
   * @param field
   * @param validation
   * @returns FormControlIsValid: boolean
   */
  formValid(form: FormGroup, field: string, validation?: string) {
    let value: boolean = true;

    if (form.valid) {
      value = true;
    }

    if ((form.get(field)?.dirty || form.get(field)?.touched) || !form.get(field)?.valid) {
      if (validation != null && form.get(field)?.hasError(validation)) {
        value = false;
      }
    }

    return value;
  }

  /**
   * @description Decode JwtToken
   * @param token
   * @returns data: any
   */
  decodeJwtToken(token: string): any{
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  }

  getTimeZoneReference() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  removeSimilarItemInArrayByUniqueId(sourceArray: any[], uniqueId: string, separator: string){
    const reference =uniqueId.split(separator);
    const lastItemArray= reference.splice(reference.length-1, 1);
    return sourceArray.filter(x => {
      let itemIndex: string[] = x.uniqueId.split(separator);
      let itemMatched: boolean = true;
      reference.forEach((d, i) => {
        if (d != itemIndex[i]) {
          itemMatched = false;
        }
      });
      if (itemIndex[itemIndex.length - 1] == '' && lastItemArray[0] != '') {
        itemMatched = false;
      }
      return !itemMatched
    });
  }


  getFormControlStatus(form: FormGroup, field: string){
    return {
      touched: form.get(field)?.touched,
      dirty: form.get(field)?.dirty
    }
  }

  convertDateTime(dateTime: Date, langage: string = 'en') {
    const day = ('0' + dateTime.getDate()).slice(-2);
    const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
    const year = dateTime.getFullYear();
    const hours = ('0' + dateTime.getHours()).slice(-2);
    const minutes = ('0' + dateTime.getMinutes()).slice(-2);

    return (langage === 'en') ? `${day}/${month}/${year} ${hours}:${minutes}` : `${day}-${month}-${year} ${hours}:${minutes}`
  }

  convertDate(date: Date, langage: string = 'en') {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const year = date.getFullYear()
    return (langage === 'en') ? `${day}/${month}/${year}` : `${day}-${month}-${year}`;
  }

  /**
   * Compares two dates at Year-Month-Day-Hour-Minutes level
   * @param dateOne first Date
   * @param dateTwo second Date
   */
  compareDates(dateOne: Date, dateTwo: Date){
    return dateOne?.getMinutes() === dateTwo?.getMinutes();
  }

  formatNumber(value: number, decimal: number = 0) {
    return formatNumber(value, this.locale, `1.${decimal}`);
  }
}
