import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonDefault } from './button-default.model';

@Component({
  selector: 'app-button-default',
  templateUrl: './button-default.component.html',
  styleUrls: ['./button-default.component.scss']
})
export class ButtonDefaultComponent implements OnChanges {
  @Input() label:string; 
  @Input() view:'flat'|'smooth'|'outlined'; // flat - smooth - outlined
  @Input() color:'primary'|'secondary'|'tertiary'|'pointer'; // primary - secondary - tertiary .....
  @Input() size:'xl' | 'lg'|'md'|'sm'; // xl - lg - md - sm
  @Input() icon:string;
  @Input() rightIcon:boolean;
  @Input() tooltip:string;
  @Input() disabled:boolean;
  @Input() data: ButtonDefault;

  @Output() actionButton: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['data']) {        
        this.view = this.data.view;
        this.color = this.data.color;
        this.size = this.data.size;
        this.icon = this.data.icon;
        this.rightIcon = this.data.rightIcon;
        this.tooltip = this.data.tooltip;
        this.disabled = this.data.disabled;
      }
    }
    
  }

  onAction(e:any) {
    this.actionButton.emit(e);
  }

}
