import { Component, Input, OnInit } from '@angular/core';
import { IProductCard } from '../../../../models/product/product-card.model';

@Component({
  selector: 'app-pack-card',
  templateUrl: './pack-card-item.component.html',
  styleUrls: ['./pack-card-item.component.scss']
})
export class PackCardItemComponent implements OnInit {

  @Input() product: IProductCard;
  @Input() selected: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
