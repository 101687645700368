import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-paragraph-intro',
  templateUrl: './paragraph-intro.component.html',
  styleUrls: ['./paragraph-intro.component.scss']
})
export class ParagraphIntroComponent implements OnInit {
  @Input()
  text:string ="";
  constructor( ) {
  }

  ngOnInit(): void {
  }

}
