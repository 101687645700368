
import { map } from 'rxjs';
import { I_FormContract } from './../../../models/formContract.model';
import { genericForm } from './../../../models/generic-form/generic-form.model';
import { FormArray, FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';
import { config } from 'src/app/config/config';
import { GenericFormService } from '../../../services/generic-form/generic-form.service';



@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent implements OnInit {

  @Input()
  uniqueId: string ='';

  @Input()
  formTitle: string ='';

  @Input()
  tableHead: string[] =[];

  @Input()
  cible: string;

  formulaire: FormGroup;

  @Input()
  formSchema: I_FormContract; // = genericForm;

  subscription: Subscription =new Subscription();

  initialFormValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private genericFormService: GenericFormService
    ) {

    this.formulaire =this.formBuilder.group({
      contract_uuid: [ this.formSchema?.contract_uuid ],
      field_list: this.formBuilder.array([])
    });
  }

  fieldList(): FormArray{
    return this.formulaire.get('field_list') as FormArray;
  }


  ngOnInit() {

    this.subscription.add(
      this.genericFormService.genericFormSchema$.subscribe( (data: any)=>{
        if( data?.uniqueId ==this.uniqueId && data?.formSchema !=null ){
          this.formSchema =data.formSchema;
          this.resetForm();
        }
      } )
    );

    this.subscription.add(
      this.formulaire.valueChanges
      .pipe(
        debounceTime( config.formValueChangeDebounceTime ),
        map((data)=>{
          let contract_uuid =data.contract_uuid;

          // TODO : Ajouter et importer deepEqual pour la comparaison des objets JSON
          let formIsDirty: boolean =false;

          let fields =data.field_list.map( (x, i)=>{
            x.form_contract_uuid =contract_uuid;
            delete x.form_field_type;
            formIsDirty =formIsDirty ||this.initialFormValue.field_list[i].is_active!=x.is_active ||this.initialFormValue.field_list[i].is_mandatory!=x.is_mandatory ;
            return x;
          } );
          this.genericFormService.updateFormIsDirty( formIsDirty, this.uniqueId);
          return fields;
        })
      )
      .subscribe( data=>{
        this.genericFormService.updateGenericFormData(data, this.uniqueId)
      } )
    );

    this.subscription.add(
      this.genericFormService.resetContractForm$.subscribe((data)=>{
        if( data?.uniqueId ==this.uniqueId ){
          this.resetForm();
        }
      })
    );

    this.resetForm();
  }

  // disable is_mandatory field if is_anable is unchecked
  checkIfIsMandatory(index: number){
    let ligne =this.fieldList().at(index).value;

    if(!ligne.is_active){
      ligne.is_mandatory =0;
    }
    this.fieldList().at(index).patchValue(ligne);

  }

  resetForm(){

    this.fieldList().clear();
    this.formulaire.patchValue({contract_uuid: this.formSchema?.contract_uuid});

    this.formSchema?.fields?.forEach(d => {

      let fields =this.fieldList();

      // TODO grouper les champs par group key

      let ligne =this.formBuilder.group({
        form_field_id: [d.form_field_id],
        form_field_key: [d.form_field_key],
        form_field_label: [ d.form_field_label],
        is_active: [ d.is_active==1],
        is_mandatory: [ +d.is_mandatory],
        is_editable: [ +d.is_editable],
        is_locked: [ +d.is_locked],
      });

      fields.push(ligne);
    });

    // reset initial form
    this.initialFormValue =this.formulaire.value;
    this.genericFormService.updateFormIsDirty( false, this.uniqueId);

  }

}
