import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appHeightScreen]'
})
export class HeightScreenDirective implements OnInit {
  constructor(private el: ElementRef) { }
  @Input('spaceHeight') spaceHeight;

  ngOnInit(): void {
    const header:HTMLElement = document.querySelector('header');
    const navTabs:HTMLElement = document.querySelector('.nav-tabs');
    const calcSpace:number = Number(header?.offsetHeight) + Number(navTabs?.offsetHeight) + Number(this.spaceHeight) + 3;
    this.el.nativeElement.style.maxHeight = `calc(100vh - ${calcSpace}px)`;
  }

}
