import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ImageService} from "../../../services/image/image.service";
import {IImage} from "../../../models/i-image/i-image";
import {MiscService} from "../../../services/miscellaniscious/misc.service";
import {TranslateService} from "@ngx-translate/core";
import {ImportService} from "../../../services/import/import.service";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() id: string = '';
  @Input() disabled: boolean = false;
  public image: IImage;
  private fileType: string[] = ["image/png", "image/jpeg", "image/jpg"];
  private subsciprion= new Subscription();
  public packageimage:string = this.translateService.instant('packageimage');

  constructor(
    private imageService: ImageService,
    private miscService: MiscService,
    private translateService: TranslateService,
    private importService: ImportService,
  ) { }

  ngOnInit(): void {
    this.getImage();
  }

  ngOnDestroy(): void {
    this.subsciprion.unsubscribe();
  }

  getImage() {
    this.subsciprion.add(
      this.imageService.getImage().subscribe((img => {
        if (img?.id === this.id) {
          this.image = img
        }
      }))
    )
  }

  onImageChange(event: any) {
    const images = event.target.files;
    if (images) {
      if (!this.fileType.includes(images[0].type)) {
        this.showNotification(
          'danger',
          'alert',
          this.translateService.instant('user.update.error.format'),
          this.translateService.instant('notification.error.title'),
        );
      } else {
        if (images.length > 0) {
          let observableFile = this.importService.convertFile(images[0]);

          this.imageService.setImageFile({status: true, file: observableFile});
        }
      }
      event.target.value = ''
    }
  }

  removeImage() {
    this.imageService.setImageFile({status: true, file: ''});
  }

  showNotification(type, icon, description, title) {
    this.miscService.showToast({
      type: type,
      icon: icon,
      desc: description,
      title: title
    })
  }

  imageButtonText(hasImage: boolean) {
    return hasImage ? 'image.edit' : 'image.add';
  }
}
