import {Component, Input, OnInit} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IUrlQueryParams } from '../../../models/i-url-query-params';
import {I_QuickRepportData} from "../../../models/quick-repport.model";

@Component({
  selector: 'app-quick-repport',
  templateUrl: './quick-repport.component.html',
  styleUrls: ['./quick-repport.component.scss']
})
export class QuickRepportComponent implements OnInit {
  @Input()
  quickRepports!: I_QuickRepportData[];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goTo(url: string, params: IUrlQueryParams) {
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    if (url && url !== '') {
      if (params) {
        navigationExtras.queryParams = params;
      }
      this.router.navigate([url], navigationExtras)
    }
  }
}
