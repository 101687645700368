import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MiscService } from '../../../services/miscellaniscious/misc.service';
import {I_ToastData} from "../../../models/toast";
import {I_BreadCrumbData} from "../../../models/breadCrumb";

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit, OnDestroy {

  subscription: Subscription =new Subscription();

  breadCrumbData: I_BreadCrumbData[] = [];

  confirmLeavePage: boolean =false;

  constructor(private miscService: MiscService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.miscService.breadCrumbDataList$.subscribe((breadCrumb) => {
        this.breadCrumbData = breadCrumb;
      })
    );

    this.subscription.add(
      this.miscService.confirmLeavePage$.subscribe((confirm)=>{
        this.confirmLeavePage =confirm;
      })
    );
  }

  goTo(url: string){
    if (this.confirmLeavePage ===true) {
      this.miscService.emmitLeavePageEvent(url);
    } else {
      this.miscService.goTo( url );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
