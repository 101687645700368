import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/services/api-service/api.service';
import {I_ToastData} from "../../../shared/models/toast";
import {MiscService} from "../../../shared/services/miscellaniscious/misc.service";
import {LoaderService} from "../../../shared/services/loader/loader.service";
import {AuthentificationService} from "../../services/authentification/authentification.service";
import {I_ApiResponse} from "../../../shared/models/api-response";

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  public toastDataList: I_ToastData[] = [];
  public loading$ = this.loaderService.loading$;
  subscription: Subscription =new Subscription();

  companyList: any[] =[];

  constructor(
    private apiService: ApiService,
    private miscService: MiscService,
    private loaderService: LoaderService,
    private authentificationService: AuthentificationService
    ) { }

  ngOnInit(): void {
    this.getCompanies()
    this.getNotification()
  }

  getCompanies() {
    this.subscription.add(
      this.authentificationService.getCompany().subscribe((response: I_ApiResponse)=>{
        this.companyList = response?.data?.company||[];
      })
    );
  }

  getNotification() {
    this.subscription.add(
      this.miscService.ToastDataList$.subscribe((data: I_ToastData[]) => {
        if(data!=null && data.length!=0){
          this.toastDataList = data;
        }
      })
    );
  }
}
