<div class="shop-list {{cible}}">
  <div class="shop-title">
    <h2>{{ counter.title |translate  }}</h2>
  </div>
  <div class="shop-list__counter">
    <div class="icon-bg">
      <i class="{{ counter.icon }}"></i>
      <span class="info-state">
        <i class="icn-check"></i>
        <!-- <i class="icn-alert"></i> -->
      </span>
    </div>
    <div class="counter-label">
      <span class="counter-label-desc">{{ counter.description |translate }}</span>
      <span class="shop-number">{{ counter.number | number }}</span>
    </div>
    <div class="icone-info">
      <i class="{{ counter.info }} "></i>
    </div>
  </div>
</div>
