import { Directive,ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTextareaInput]'
})
export class TextareaInputDirective {

  constructor(private elementRef: ElementRef) { }
  updateHeightTextArea() {
    this.elementRef.nativeElement.style.height = 30+"px";
    this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
  }
  @HostListener('input') onInput() {
   this.updateHeightTextArea();
  }
} 
