<app-page-title [text]="'inventory.list.title' | translate"></app-page-title>

<div class="block-column-20">
  <div class="main-content-shop">
    <app-to-do [title]="'todoToDay' | translate">
      <div>
        <div class="block-ico-left-txt-right">
          <div class="block-ico-left">
            <i class="icn-inventory fs-44"></i>
          </div>
          <div class="block-txt-right">
            <span>{{ 'inventory.list.createInventoryLabel' | translate }}</span>
          </div>
        </div>
      </div>
    </app-to-do>
    <app-counter-multi [counters]="inventoryCounter"></app-counter-multi>
  </div>
  <div class="right-cl">
    <div class="block-column-20">
      <app-table-filter-new [id]="tableFilterInventoriesId" [filterParams]="tableFilter"></app-table-filter-new>
      <app-table-without-edit [id]="tableInventoriesId"></app-table-without-edit>
      <div class="block-center" *ngIf="hasNextPage">
        <app-button [config]="loadMoreBtn"></app-button>
      </div>
    </div>
  </div>
</div>
