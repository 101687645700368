<form [formGroup]="textForm">
  <input
    type="text"
    class="input-valid"
    [ngClass]="{
      'input-error': (textForm.get('text').hasError('required') || error)
    }"
    name="text" [formControlName]="'text'" [readOnly]="isDisabled"
    (input)="triggerEvent()"
  />
</form>
