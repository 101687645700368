import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TabService {
  tabId$: Subject<any> = new Subject<any>();
  confirmSelectTab$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  nextTab$:  BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  getTabId(id: string, uniqueId: string) {
    this.tabId$.next({
      tabId: id,
      uniqueId: uniqueId
    });
  }

  emmitConfirmSelectTab(tabId: string,uniqueId: string){
    this.confirmSelectTab$.next({
      tabId: tabId,
      uniqueId: uniqueId

    });
  }

  confirmSelectTab(tabId: string, uniqueId: string){
    this.getTabId(tabId, uniqueId);
  }

  switchToNexTab(){
    let nextTab =this.nextTab$.getValue();

    if( nextTab!=null ){
      this.confirmSelectTab(nextTab.tabId, nextTab.uniqueId);
    }
  }

  setNextTab( nextTab: any ){
    this.nextTab$.next(nextTab);
  }
}
