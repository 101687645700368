  <div class="block-pack-name">
    <div class="block-img-info block-align-vertical">
      <div class="item-align-vertical" [title]=" packageimage  | translate ">
        <div class="block-head-right-pack block-image-pack" *ngIf="title != ''">
          <div class="title-uppercase">{{ title }}</div>
        </div>

        <div class="block-top-info">
          <div class="block-upload">
            <figure class="img-info" *ngIf="image?.hasImage else noImage">
              <img class="description-img" [src]="image?.imageUrl">
            </figure>

            <ng-template #noImage>
              <figure class="img-info block-blank-img">
                <img class="blank-img" src="../../../../../../../assets/img/image_vide.svg" alt="avatar">
              </figure>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="item-align-vertical">
        <form class="block-middle-img-info">
          <input [disabled]="disabled"
                 type="file"
                 name="input-upload-img"
                 id="input-upload-img"
                 class="not-visible"
                 (change)="onImageChange($event)"
          />
          <!-- <p *ngIf="!image?.hasImage">Taille maximum de l'image : 371x371 pixel</p>
          <p *ngIf="!image?.hasImage">Fichiers prise en charge : JPEG, PNG,</p> -->
        </form>
        <div class="block-bottom-img-info">
          <input [disabled]="disabled"
                 *ngIf="image?.hasImage"
                 type="button"
                 [value]="'image.remove' | translate"
                 class="btn btn-default" (click)="removeImage()"
          />
          <label for="input-upload-img" class="btn {{ disabled ? 'btn-default' : 'btn-primary' }}">
            {{ imageButtonText(image?.hasImage) | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
