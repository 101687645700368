import { I_FieldData } from '../../models/field-data';
import { I_ToastData } from '../../models/toast';
import { I_PopInData } from '../../models/popIn';
import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { I_BreadCrumbData } from '../../models/breadCrumb';
import { Router } from '@angular/router';
import { ICheckBoxTreeData, ICheckboxTreeItem, ICheckboxTreeItems } from '../../models/checkbox-tree/checkbox-tree.model';

@Injectable({
  providedIn: 'root',
})
export class MiscService {
  popInData$: BehaviorSubject<I_PopInData | null> =
    new BehaviorSubject<I_PopInData | null>(null);
  toastData$: BehaviorSubject<I_ToastData | null> =
    new BehaviorSubject<I_ToastData | null>(null);
  ToastDataList$: BehaviorSubject<I_ToastData[]> = new BehaviorSubject<
    I_ToastData[]
  >([]);
  pendingToastData$: BehaviorSubject<I_ToastData[]> = new BehaviorSubject<
    I_ToastData[]
  >([]);
  breadCrumbData$: BehaviorSubject<I_BreadCrumbData | null> =
    new BehaviorSubject<I_BreadCrumbData | null>(null);
  breadCrumbDataList$: BehaviorSubject<I_BreadCrumbData[]> =
    new BehaviorSubject<I_BreadCrumbData[]>([]);
  //TODO Unique Id pour valider plusieurs fichiers en meme temps
  fileValid$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  //TODO Unique Id pour lire plusieurs fichiers en meme temps
  csvLineCount$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  //TODO Unique Id pour lire plusieurs fichiers en meme temps
  encodedFile$: BehaviorSubject<string|ArrayBuffer> = new BehaviorSubject<string|ArrayBuffer>('');

  checkBoxTreeItems$: BehaviorSubject<ICheckboxTreeItems|null> =new BehaviorSubject<ICheckboxTreeItems|null>(null);
  checkBoxTreeData$: Subject<ICheckBoxTreeData|null> =new Subject<ICheckBoxTreeData|null>();

  popInVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  toastVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  confirmLeavePage$: Subject<boolean> =new Subject<boolean>();
  leavePageEvent$: Subject<string|null> =new Subject<string|null>();
  currency$: BehaviorSubject<string> =new BehaviorSubject<string>('');


  /////////////// test contract service

  /////////////// xxxxx
  public fieldData$: BehaviorSubject<I_FieldData|null> = new BehaviorSubject<I_FieldData|null>(null);

  constructor(private router: Router) {}

  initPopIn(popInData: I_PopInData, template: ElementRef | undefined) {
    if (popInData != null && template != null) {
      popInData.body = template.nativeElement.innerHTML;
      this.updatePopInData(popInData);
    }
  }

  addBreadCrumb(breadCrumb: I_BreadCrumbData[]) {
    this.breadCrumbDataList$.next(breadCrumb);
  }

  updatePopInData(popin: I_PopInData) {
    this.popInData$.next(popin);
  }

  updatePopInBody(body: string) {
    let popin = this.popInData$.getValue();

    if (popin != null) {
      popin.body = body;
      this.updatePopInData(popin);
    }
    this.popInData$.next(popin);
  }

  showPopIn() {
    this.popInVisible$.next(true);
  }

  hidePopIn() {
    this.popInVisible$.next(false);
  }

  addToPendingToast(toast: I_ToastData) {
    let list = this.pendingToastData$.getValue();
    list.push(toast);
    this.pendingToastData$.next(list);
  }

  processpendingToast() {
    let list = this.pendingToastData$.getValue();
    this.ToastDataList$.next(list);
    this.pendingToastData$.next([]);
  }

  initToast() {
    this.ToastDataList$.next([]);
  }

  showToast(toast: I_ToastData) {
    setTimeout(() => {
      this.ToastDataList$.next([toast]);
    }, 100);

  }

  updateFileValid(fileIsValid: any) {
    this.fileValid$.next(fileIsValid);
  }

  updateCsvLineCount(lineCount: number) {
    this.csvLineCount$.next(lineCount);
  }

  updateEncodedFile(encodedFile: string|ArrayBuffer) {
    this.encodedFile$.next(encodedFile);
  }

  countLineInCsv(file: File) {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      let csvData = reader.result;
      let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
      let lineCount =
        csvRecordsArray.filter((x) => {
          return x != '';
        }).length - 1;

      this.updateCsvLineCount(lineCount);
    };
  }

  checkError(obj: any, exception?: string[]): boolean {
    let noError = true;
    if (obj != undefined) {
      let keys = Object.keys(obj);
      keys.forEach((k) => {
        if (exception.indexOf(k) == -1) {
          noError = noError && obj[k];
        }
      });
    }

    return noError;
  }

  convertFile(file: File) {
    let reader = new FileReader();

    reader.onloadend = () => {

      this.updateEncodedFile(reader.result);

    };
    reader.readAsDataURL(file);
  }

  resetCsvLineCount(){
    this.csvLineCount$.next(-1);
  }

  generateUniqueId(){
    return Math.random().toString(16).slice(2);
  }

  goTo( url: string, skipLocatation = false){
    if (skipLocatation)
      this.router.navigateByUrl(url, { skipLocationChange: true });
    else
      this.router.navigateByUrl(url);
      // this.router.navigateByUrl('/products/pack/list');
  }

  updateConfirmLeavePage(confirm: boolean){
    this.confirmLeavePage$.next(confirm);
  }

  emmitLeavePageEvent(url: string){
    this.leavePageEvent$.next(url);
  }

  updateCurrency( currency: string ){
    if( currency!='' && currency!=null ){
      this.currency$.next( currency );
    }

  }

  /*
  showToast() {
    this.toastVisible$.next(true);
  }

  hideToast() {
    this.toastVisible$.next(false);
  }
  */

  //TODO Changer @args string fileType pour string[] fileType pour valider plusieurs type de fichiers à la fois
  fileTypeMatched(file: File, fileType: string[]) {
    let fileMatched: boolean = false;

    if (fileType.indexOf(file.type) > -1) {
      fileMatched = true
    }

    return fileMatched
  }

  updateFieldData( fieldData: I_FieldData ){
    this.fieldData$.next(fieldData);
  }

  updateCheckBoxTreeData(uniqueId: string, checkBoxTreeData: ICheckboxTreeItem[]){
    this.checkBoxTreeData$.next({
      uniqueId: uniqueId,
      checkboxTreeData: checkBoxTreeData
    })
  }

  updateCheckBoxTreeItems(uniqueId: string, checkBoxTreeItems: ICheckboxTreeItem[]){
    this.checkBoxTreeItems$.next({
      uniqueId: uniqueId,
      checkboxTreeItems: checkBoxTreeItems
    });
  }
}
