import { I_SidebarMenu } from '../main/modules/shared/models/sidebar-menu';
import {url} from "./url";
import {abilities} from "./abilities";

export const sideBarMenu: I_SidebarMenu[] = [
  {
    menuItem: [
      {
        icon: 'dashboard',
        label: 'Accueil',
        url: url.home,
        action: []
      }
    ]
  },
  {
    groupName: 'Accès rapide',
    menuItem: [
      {
        ability_group_key: abilities.shop_management.page.list,
        icon: 'shop',
        label: 'Gestion Shop',
        url: url.shop_management.list,
        action: [
          {
            ability_group_key: abilities.shop_management.page.create,
            label: 'Création shop',
            url: url.shop_management.create,
          }
        ]
      },
      {
        ability_group_key: abilities.cashRegister.page.list,
        icon: 'checkout',
        label: 'Gestion Caisse',
        url: url.cash_register,
      },
      {
        icon: 'item',
        label: 'Gestion Catalogue',
        subMenu: [
          {
            groupName: "Article",
            menuItem: [
              {
                ability_group_key: abilities.item.page,
                label: 'Liste des articles',
                url: url.catalog.product.list,
                action: [
                  {
                    ability_group_key: abilities.item.element.updateProduct,
                    label: "Modification produit",
                    url: url.catalog.product.edit
                  }
                ]
              },
              {
                ability_group_key: abilities.item_category.page,
                label: 'Catégories d\'articles',
                url: url.catalog.category
              }
            ]
          },
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.pack.page.list,
                label: 'Pack d\'article',
                url: url.product.pack.list,
                action: [
                  {
                    ability_group_key: abilities.pack.page.list,
                    label: "Aucun pack disponible",
                    url: url.product.pack.empty
                  },
                  {
                    ability_group_key: abilities.pack.page.create,
                    label: "Création d'un pack",
                    url: url.product.pack.create
                  },
                  {
                    ability_group_key: abilities.pack.page.edit,
                    label: "Modifier un pack",
                    url: url.product.pack.edit
                  },
                  {
                    ability_group_key: abilities.pack.page.edit,
                    label: "Modifier un pack",
                    url: url.product.pack.createWithProduct
                  }
                ]
              },
              {
                ability_group_key: abilities.discount.page.list,
                label: 'Remises d\'article',
                url: url.product.discount.list,
                action: [
                  {
                    ability_group_key: abilities.discount.page.create,
                    label: "Création d'une remise",
                    url: url.product.discount.create
                  },
                  {
                    ability_group_key: abilities.discount.page.edit,
                    label: "Modifier une remise",
                    url: url.product.discount.edit
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        icon: 'stock-manager',
        label: 'Gestion Stock',
        subMenu: [
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.stock.page,
                label: 'Consultation stock',
                url: url.stock_management,
              }
            ]
          },
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.various.page.list.entry,
                label: 'Entrée diverse',
                url: url.various.entry.list,
                action: [
                  {
                    ability_group_key: abilities.various.page.create.entry,
                    label: "Création d'une entrée diverse",
                    url: url.various.entry.create
                  }
                ]
              }
            ]
          },
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.various.page.list.exit,
                label: 'Sortie diverse',
                url: url.various.exit.list,
                action: [
                  {
                    ability_group_key: abilities.stock.page,
                    label: "Création d'une sortie diverse",
                    url: url.various.entry.create
                  }
                ]
              }
            ]
          },
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.inventory.list,
                label: 'Inventaire',
                url: url.inventory.list,
                action: [
                  {
                    ability_group_key: abilities.inventory.create,
                    label: "Création d'un inventaire",
                    url: url.inventory.create,
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        ability_group_key: abilities.shop_management.page.list, // @todo: à changer
        icon: 'user3',
        label: 'Gestion Clients',
        url: url.customer.list,
      },
      {
        icon: 'services-mobile-money',
        label: 'Service Mobile Money',
        subMenu: [
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.mobile_money.operation,
                label: 'Cash-In',
                url: url.mobile_money.cash_in,
              },
              {
                ability_group_key: abilities.mobile_money.operation,
                label: 'Cash-Out',
                url: url.mobile_money.cash_out,
              },
              {
                ability_group_key: 'abilities.mobile_money.dashboard',
                label: 'Dashboard',
                url : url.mobile_money.dashboard,
              },
              {
                ability_group_key: abilities.mobile_money.history,
                label: 'Historique',
                url: url.mobile_money.history,
              }
            ]
          }
        ]
      },

    ]
  },
  {
    groupName: 'Autre',
    menuItem: [
      {
        icon: 'user3',
        label: 'Import de fichier',
        subMenu: [
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.mobile_money.history,
                label: 'Import',
                url: url.import.import
              },
              {
                ability_group_key: abilities.mobile_money.history,
                label: 'Historique',
                url: url.import.history,
              }
            ]
          }
        ]
      }
    ],
  },
  {
    groupName: 'Paramétrages',
    menuItem: [
      {
        ability_group_key: abilities.setting.page,
        icon: 'setting',
        label: 'Préférences',
        url: url.setting,
      },
      {
        ability_group_key: 'modules',
        icon: 'mods',
        label: 'Modules',

        subMenu: [
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.modules.customer.customerCategory.page,
                label: 'Catégories client',
                url: url.module.customer_category
              },
            ]
          },
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.modules.sale.paymentMethod.page,
                label: 'Moyens de paiement',
                url: url.module.payments.payment_method
              }
            ]
          },
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.modules.sale.paymentMethod.page,
                label: "Entrée/Sortie diverse",
                url: url.module.variousEntryExit
              }
            ]
          },
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.mobile_money.setting,
                label: 'Service mobile money',
                url: url.module.payments.mobile_money,
              }
            ]
          }
        ]
      },
      {
        ability_group_key: 'user',
        icon: 'user',
        label: "Utilisateurs",
        subMenu: [
          {
            groupName: null,
            menuItem: [
              {
                ability_group_key: abilities.user.profile.page.list,
                label: "Gestion des profils",
                url: url.user.profile_management.list,
                action: [
                  {
                    ability_group_key: abilities.user.profile.page.detail,
                    label: "Détails de l'utilisateur",
                    url: url.user.profile_management.detail
                  }
                ]
              },
              {
                ability_group_key: abilities.user.user.page.list,
                label: "Gestion des utilisateurs",
                url: url.user.user_management.list,
                action: [
                  {
                    ability_group_key: abilities.user.user.page.detail,
                    label: "Détails de l'utilisateur",
                    url: url.user.user_management.detail
                  },
                  {
                    ability_group_key: abilities.user.user.page.update,
                    label: "Modifier un utilisateur",
                    url: url.user.user_management.update.page
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
