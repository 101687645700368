import { LocalStorageService } from './../local-storage/local-storage.service';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { storageKey } from 'src/app/config/storage';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  totalRequests = 0;
  completedRequests = 0;

  constructor(
    private storageService: LocalStorageService,
    private loaderService: LoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    this.totalRequests++;

    return next.handle(req).pipe(
      finalize( () => {
        this.completedRequests++
        if (this.completedRequests == this.totalRequests) {
          this.loaderService.hide();
          this.completedRequests = 0;
          this.totalRequests = 0;
        }
      })
    );
  }
}
