import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {HelperService} from "../../services/helper/helper.service";
import {LocalStorageService} from "../../services/local-storage/local-storage.service";
import {storageKey} from "../../../../../config/storage";
import { constant } from 'src/app/config/constants';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {

  constructor(
    private helperService: HelperService,
    private localStorageService: LocalStorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = storageKey.session.jwtKey
    let token = this.localStorageService.getLocalStorage(jwt) ? this.localStorageService.getLocalStorage(jwt) : '';
    let headers = new HttpHeaders({
      "Accept": "Application/json",
      "Authorization": "Bearer " + token,
      "client_timezone": this.helperService.getTimeZoneReference(),
      'X-Key': constant.authentication.xKey,
      'Frame-Target': constant.authentication.fragmentTarget,
      "ngrok-skip-browser-warning": "any value",
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    });

    const httpRequest = request.clone({headers})
    return next.handle(httpRequest);
  }
}
