import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {I_Export} from "../../../models/export";
import {Subscription} from "rxjs";
import {ExportService} from "../../../services/export/export.service";
import {FileService} from "../../../services/file/file.service";

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit, OnDestroy {
  @Input() exportConfig!: I_Export;
  private subscription = new Subscription();

  constructor(
    private exportService: ExportService,
    private fileService: FileService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  export() {
    this.subscription.add(
      this.exportService.file$.subscribe( (file: string) => {
        this.fileService.downloadFile(file, this.exportConfig.fileName);
      })
    );
  }
}
