<div class="main-modal" *ngIf="popInVisible"  >
  <div class="cnt-modal" [ngClass]="size == 'lg' ? 'modal-lg' : 'modal-md'">
    <div class="cnt-modal__header cnt-modal__header--large">
      <h3>{{popInData?.title}}</h3>
      <span (click)="closeModal()">+</span>
    </div>
    <div class="cnt-modal__body" [innerHTML]="popInData?.body">
    </div>
    <div class="cnt-modal__footer">
      <div class="btn-group">
        <button *ngFor="let action of popInData?.action" [class]="'btn btn-'+action.class"
          (click)="action.action()">{{action.text}}</button>
      </div>
    </div>
  </div>
</div>
