import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {first, iif, of, pairwise, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {storageKey} from "../../../../../../../config/storage";
import {LocalStorageService} from "../../../../services/local-storage/local-storage.service";
import {ItemSerializationService} from "../../../../services/item-serialization/item-serialization.service";
import {debounceTime, filter, startWith, switchMap} from "rxjs/operators";
import {cancel} from "../../../../config/constants";

@Component({
  selector: 'app-per-batch',
  templateUrl: './per-batch.component.html',
  styleUrls: ['./per-batch.component.scss']
})
export class PerBatchComponent implements OnInit, OnDestroy {
  @Input() public productUuid!: string;
  @Input() public reste!: number;
  @Input() public serialization!: any;
  @Input() public serializationValue!: any;
  @Input() public id!: number;
  @Input() public autoCompleteField: boolean = false;
  @Output() public serializationInputValue: EventEmitter<any> = new EventEmitter();
  private subscription = new Subscription();
  public serializationFormGroup: FormGroup;
  public showStartError: boolean = false;
  public showEndError: boolean = false;
  private userData!: any;
  private lastStartNumber: string = '';
  private lastEndNumber: string = '';
  private quantity: number = 0;
  public serializationInvalid: boolean = true;
  private allInputSerializations: any = [];

  constructor(
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private itemSerializationService: ItemSerializationService
  ) {
    this.createForm();
    this.getUserDetail();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getSerialNumberValidity();
    this.getSerializationInputQuantityValidation();
    this.canceledSerialization();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.serializationFormGroup = this.formBuilder.group({
      id: this.id,
      product_uuid: this.productUuid,
      product_serialization_uuid: ['', Validators.required],
      fk_serialization_type_key: ['', Validators.required],
      product_serialization_key: ['', Validators.required],
      serial_number_start: '',
      serial_number_end: '',
      is_serialization_valid: false,
      quantity: 1,
      trigger: false
    });
  }

  getUserDetail() {
    this.userData = JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
  }

  initializeForm() {
    this.subscription.add(
      this.itemSerializationService.getSerializationValue().pipe(first()).subscribe(value => {
        this.serializationFormGroup.patchValue({
          id: this.id,
          product_uuid: this.productUuid,
          fk_serialization_type_key: this.serialization?.fk_serialization_type_key,
          product_serialization_key: this.serialization?.product_serialization_key,
          product_serialization_uuid: this.serialization?.product_serialization_uuid,
          serial_number_start: '',
          serial_number_end: ''
        });
        this.serializationInvalid = true;
        if (value[this.id] && value[this.id].serializationData.length > 0) {
          const _value = value[this.id]?.serializationData.filter(x => x.fk_serialization_type_key == this.serialization?.fk_serialization_type_key)[0];
          this.serializationFormGroup.patchValue({
            serial_number_start: _value.serial_number_start,
            serial_number_end: _value.serial_number_end
          });
          this.lastStartNumber = _value.serial_number_start;
          this.lastEndNumber = _value.serial_number_end;
          this.serializationInvalid = (_value.serial_number_start == '' || _value.serial_number_end == '' || !_value.is_serialization_valid);
        }
        value.map(x => x.serializationData).map(y => y.map(a => {
          this.allInputSerializations.push(a)
          return a
        }))
        this.serializationFormGroup.updateValueAndValidity();
      })
    )
  }

  canceledSerialization() {
    this.subscription.add(
      this.itemSerializationService.getSaveSerializationStatus().pipe(
        filter(status => status == cancel)
      ).subscribe(status => {
        if (status == cancel) {
          /*this.serializationFormGroup.patchValue({
            serial_number_start: this.lastStartNumber,
            serial_number_end: this.lastEndNumber
          });
          this.serializationFormGroup.updateValueAndValidity();
          this.sendSerialNumber( this.serializationFormGroup.value)*/
        }
      })
    );
  }

  triggerEvent(status: boolean = true) {
    this.serializationFormGroup.patchValue({ trigger: status });
    this.serializationFormGroup.updateValueAndValidity();
  }

  getTotal(end, start) {
    this.showEndError = +end < +start;
    this.quantity = (parseInt(end || 0) - parseInt(start || 0)) + 1;
    return this.quantity;
  }

  getSerialNumberValidity() {
    this.subscription.add(
      this.serializationFormGroup.valueChanges.pipe(
        debounceTime(1000),
        filter(value => value['trigger'] && !this.autoCompleteField),
        switchMap((value) => {
          this.getSerializations()
          return of(value);
        }),
        startWith(null), pairwise(),
        switchMap(([prev, next]: [any, any]) => {
          next['is_serialization_valid'] = true;
          const start = next['serial_number_start']
          const end = next['serial_number_end']
          this.serializationInvalid = true;
          const starts = this.serializations.map(x => x.serial_number_start);
          const ends = this.serializations.map(x => x.serial_number_end);

          const serialization = this.serializations.filter(x=> x.product_uuid == this.productUuid);
          const startIsBetween = serialization.map(x => {
            let status = false
            if (x.serial_number_start < x.serial_number_end &&start >= x.serial_number_start && start <= x.serial_number_end) {
              status = true;
            }
            return status
          })

          const endIsBetween = serialization.map(x => {
            let status = false
            if (+x.serial_number_start < +x.serial_number_end && +end >= +x.serial_number_start && +end <= +x.serial_number_end) {
              status = true;
            }
            return status
          })

          if (
            +next['serial_number_end'] < +next['serial_number_start'] ||
            startIsBetween.includes(true) ||
            endIsBetween.includes(true) ||
            starts.includes(start) || ends.includes(end) ||
            starts.includes(end) || ends.includes(start) ||
            (start == '' || end == '')
          ) {
            this.serializationInvalid = true;
            next['is_serialization_valid'] = false;
          }

          next['quantity'] = (start == '' || end == '') ? 0 : ((+next['serial_number_end']) - (+next['serial_number_start']) + 1);
          const checkDefaultSerialization = {
            "is_exist": true,
            "formValue": {
              "id": next['id'],
              "product_serialization_uuid":  next['product_serialization_uuid'],
              "fk_serialization_type_key":  next['fk_serialization_type_key'],
              "product_serialization_key":  next['product_serialization_key'],
              "serial_number_start": start,
              "serial_number_end": end,
              "is_serialization_valid": false,
              "quantity": next['quantity'],
              "trigger": true
            }
          }

          console.log()
          this.itemSerializationService.setInputSerializations(prev, next);
          if (next['serial_number_start'] != '' && next['serial_number_end'] != '' && ((+end) - (+start))+1 <= this.reste) {
            return this.itemSerializationService.getSerialNumberInterval(this.productUuid, next)
          } else {
            return of(checkDefaultSerialization)
          }
        })
      ).subscribe((result) => {
        console.log(result)
        this.triggerEvent(false);
        this.serializationInvalid = (result.is_exist || !result.formValue['is_serialization_valid']);
        if (this.serializationInvalid) {
          result.formValue['is_serialization_valid'] = false;
          result.formValue['quantity'] = 0;
        }
        this.sendSerialNumber(result.formValue);
      })
    );
  }

  sendSerialNumber(value: any) {
    this.serializationInputValue.emit(value);
  }

  getSerializationInputQuantityValidation() {
    this.subscription.add(
      this.itemSerializationService.getSerializationInputQuantity().subscribe(value => {
        if (value && this.id == value['id'] && value['productUuid'] == this.productUuid && value['fk_serialization_type_key'] == this.serialization?.fk_serialization_type_key) {
          const formValue = this.serializationFormGroup.value;
          if ( value['inputQuantity'] > value['quantity'] && !value['isValid'] ) {
            this.serializationInvalid = true;
          } else if (value['inputQuantity'] <= value['quantity'] && value['isValid'] && (formValue['serial_number_start'] != '' && formValue['serial_number_end'] != '')) {
            this.serializationInvalid = false;
          }
        }
      })
    )
  }

  private serializations: any[] = [];
  getSerializations() {
    this.itemSerializationService.getInputSerializations().subscribe(value => {
      this.serializations = value.filter(x=> x.product_uuid == this.productUuid && x.id != this.id)
    });
  }
}
