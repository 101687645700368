import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {MiscService} from "../../services/miscellaniscious/misc.service";
import {AuthentificationService} from "../../../user/services/authentification/authentification.service";
import {Router} from "@angular/router";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private miscService: MiscService,
    private authentificationService: AuthentificationService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).
    pipe(
      tap(
        ()=>{},
        (error: any) => {
          switch (error.status) {
            case 401:
              this.showNotification(
                '',
                "Vous n'avez pas accès à cette page. Veuillez demander à votre responsable.",
                'alert',
                'orange'
              )
              setTimeout(() => this.router.navigateByUrl('page-not-allowed'), 1000);
              break;
            case 403:
              this.showNotification(
                '',
                'Votre clé d\'authenfication est expiré. Vous allez être rediriger vers la page de connexion',
                'alert',
                'orange'
              )
              setTimeout(() => this.authentificationService.fakeLogOut(), 2000);
              break;
            case 404:
              this.showNotification(
                '',
                'URL de connexion avec le serveur n\'existe pas.',
                'alert',
                'danger'
              )
              break;
            case 500:
              this.showNotification('ERREUR', 'Problème de connexion avec le serveur. Veuillez contacter le responsable si l\'erreur persiste.', 'alert', 'danger');
              break;
          }
        }
      )
    )
  }

  showNotification = (title: any, desc: any, icon: any, type: any) => {
    this.miscService.showToast({
      title: title,
      desc: desc,
      icon: icon,
      type: type
    })
  }
}

