export const citySourceList: any = {
  "city": [
    {
      "id_city": 1,
      "uuid_city": "1",
      "name": "Lomé",
      "fk_id_region": 1,
      "country_key": "Togo"
    },
    {
      "id_city": 7,
      "uuid_city": "7",
      "name": "Tsévié",
      "fk_id_region": 1,
      "country_key": "Togo"
    },
    {
      "id_city": 8,
      "uuid_city": "8",
      "name": "Aného",
      "fk_id_region": 1,
      "country_key": "Togo"
    },
    {
      "id_city": 16,
      "uuid_city": "17",
      "name": "Vogan",
      "fk_id_region": 1,
      "country_key": "Togo"
    },
    {
      "id_city": 19,
      "uuid_city": "21",
      "name": "Tabligbo",
      "fk_id_region": 1,
      "country_key": "Togo"
    },
    {
      "id_city": 2,
      "uuid_city": "2",
      "name": "Sokodé",
      "fk_id_region": 2,
      "country_key": "Togo"
    },
    {
      "id_city": 11,
      "uuid_city": "12",
      "name": "Tchamba",
      "fk_id_region": 2,
      "country_key": "Togo"
    },
    {
      "id_city": 15,
      "uuid_city": "16",
      "name": "Sotouboua",
      "fk_id_region": 2,
      "country_key": "Togo"
    },
    {
      "id_city": 3,
      "uuid_city": "3",
      "name": "Kara",
      "fk_id_region": 3,
      "country_key": "Togo"
    },
    {
      "id_city": 6,
      "uuid_city": "6",
      "name": "Bassar",
      "fk_id_region": 3,
      "country_key": "Togo"
    },
    {
      "id_city": 12,
      "uuid_city": "13",
      "name": "Niamtougou",
      "fk_id_region": 3,
      "country_key": "Togo"
    },
    {
      "id_city": 13,
      "uuid_city": "14",
      "name": "Bafilo",
      "fk_id_region": 3,
      "country_key": "Togo"
    },
    {
      "id_city": 20,
      "uuid_city": "22",
      "name": "Kandé",
      "fk_id_region": 3,
      "country_key": "Togo"
    },
    {
      "id_city": 23,
      "uuid_city": "25",
      "name": "Kpagouda",
      "fk_id_region": 3,
      "country_key": "Togo"
    },
    {
      "id_city": 4,
      "uuid_city": "4",
      "name": "Kpalimé",
      "fk_id_region": 4,
      "country_key": "Togo"
    },
    {
      "id_city": 5,
      "uuid_city": "5",
      "name": "Atakpamé",
      "fk_id_region": 4,
      "country_key": "Togo"
    },
    {
      "id_city": 14,
      "uuid_city": "15",
      "name": "Notsé",
      "fk_id_region": 4,
      "country_key": "Togo"
    },
    {
      "id_city": 17,
      "uuid_city": "18",
      "name": "Badou",
      "fk_id_region": 4,
      "country_key": "Togo"
    },
    {
      "id_city": 21,
      "uuid_city": "23",
      "name": "Amlamé",
      "fk_id_region": 4,
      "country_key": "Togo"
    },
    {
      "id_city": 9,
      "uuid_city": "9",
      "name": "Mango",
      "fk_id_region": 5,
      "country_key": "Togo"
    },
    {
      "id_city": 10,
      "uuid_city": "11",
      "name": "Dapaong",
      "fk_id_region": 5,
      "country_key": "Togo"
    },
    {
      "id_city": 18,
      "uuid_city": "19",
      "name": "Biankouri",
      "fk_id_region": 5,
      "country_key": "Togo"
    },
    {
      "id_city": 22,
      "uuid_city": "24",
      "name": "Galangachi",
      "fk_id_region": 5,
      "country_key": "Togo"
    }
  ]
}
