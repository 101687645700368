import {I_ApiResponse} from '../../models/api-response';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {city, country} from 'src/config/shared/static';
import {user} from 'src/config/user/user';
import {HelperService} from "../helper/helper.service";
import {LocalStorageService} from "../local-storage/local-storage.service";
import {storageKey} from "../../../../../config/storage";
import { map, Observable } from 'rxjs';
import {EnvService} from "../../../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  userDetails: any =null;
  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private localStorageService: LocalStorageService,
    private env: EnvService
  ) {
  }

  doGet(url: string, params?: any, headers?: any): Observable<I_ApiResponse> {
    let header = new HttpHeaders()
    if( headers!=null ) {
      header = new HttpHeaders({"Accept": "Application/json", ...headers});
    }
    return this.http.get<HttpResponse<I_ApiResponse>>(url, {params: params, headers: header, observe: 'response'})
      .pipe(
        map(response => {
          const token = response.headers.get('X-Jwt');
          if (token) this.localStorageService.setLocalStorage('jwt', token)
          return response.body;
        })
      );
  }

  doPost(url: string, data?: any) {
    let header = new HttpHeaders({ Accept: 'Application/json' });
    return this.http.post<I_ApiResponse>(url, data, { headers: header });
  }

  doPut(url: string, data?: any) {
    let header = new HttpHeaders({ Accept: 'Application/json' });
    return this.http.put<I_ApiResponse>(url, data, { headers: header });
  }

  doDelete(url: string) {
    let header = new HttpHeaders({ Accept: 'Application/json' });
    return this.http.delete<I_ApiResponse>(url, { headers: header });
  }

  getCountries() {
    return this.doGet(`${this.env.apiStaticService}/${country.countries}`);
  }

  getCountry(country_key: string) {
    return this.doGet(`${this.env.apiStaticService}/${country.countries}/${country_key}`);
  }

  getCity(country_key: string) {
    return this.doGet(`${this.env.apiStaticService}/${country.countries}/${country_key}/${city.city}`);
  }

  getUserByCategory(category?: string[], _params?: any) {
    this.userDetails =JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
    const companyUuid =this.userDetails.company_uuid;
    return this.doGet(`${this.env.apiUserService}/${user.get}`, {'category[]': category, 'company': companyUuid, ..._params})
  }

  getAllUserByCategory(category?: string[]) {
    return this.doGet(`${this.env.apiUserService}/${user.get}`, {'category[]': category});
  }

  setHeaders(headers?: any) {
    const jwt = storageKey.session.jwtKey
    let token = this.localStorageService.getLocalStorage(jwt) ? this.localStorageService.getLocalStorage(jwt) : '';
    let header = new HttpHeaders({
      "Accept": "Application/json",
      "Authorization": "Bearer " + token,
      "client_timezone": this.helperService.getTimeZoneReference()
    });

    if (headers != null) {
      header = {...header, ...headers};
    }

    return header;
  }
}
