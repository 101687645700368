import { sideBarClass } from '../../../config/sidebar-config';
import { I_SidebarMenu_Item } from '../../../../modules/shared/models/sidebar-menu';
import { Subscription } from 'rxjs';
import { I_SidebarMenu } from 'src/app/main/modules/shared/models/sidebar-menu';
import { SidebarService } from 'src/app/main/modules/shared/services/sidebar/sidebar.service';
import { url } from '../../../../../config/url';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { MiscService } from 'src/app/main/modules/shared/services/miscellaniscious/misc.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public submenuStatus: Boolean = false;
  public subMenu: I_SidebarMenu[] =[];

  public subMenuLabel: string;

  private currentMenuIndex: number[] =[-1, -1];
  private currentSubMenuIndex: number[] =[-1, -1];

  sidebarMenu: I_SidebarMenu[] | null = null;
  subscription: Subscription = new Subscription();

  public url: any = url;
  constructor(
    private miscService: MiscService,
    private sidebarService: SidebarService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      let classList =(e.target as HTMLElement).classList;
      let keepSubMenuStatus =false
      classList.forEach( d=>{
        if( sideBarClass.indexOf(d) > -1 ){
          keepSubMenuStatus =true;
        }
      });

      if(!keepSubMenuStatus){
        this.submenuStatus =false;
      }
    });
  }

  ngOnInit(): void {

    this.subscription.add(
      this.sidebarService.sidebarMenu$.subscribe(
        (sidebarMenu: I_SidebarMenu[] | null) => {
          this.sidebarMenu = sidebarMenu;
          let menuIndex =this.getMenuIndexFromUrl();
          this.setActiveMenu(menuIndex);
        }
      )
    );
  }

  updateSubmenuItemStatus( subMenuGroupIndex: number, subMenuIndex: number ){

    let subMenu =null;

    if( this.currentMenuIndex[0]>-1 && this.currentMenuIndex[1]>-1  ){
      let currentMenu =this.sidebarMenu;

      currentMenu =currentMenu.map((x: I_SidebarMenu, i: number )=>{
        x.menuItem =x.menuItem.map((y: I_SidebarMenu_Item, j:number)=>{
          y.subMenu =y.subMenu?.map((z: I_SidebarMenu, k:number)=>{
            z.menuItem =z.menuItem.map((aa: I_SidebarMenu_Item, l: number)=>{
              aa.active =false;
              return aa;
            } );
            return z;
          });
          // y.active=false;
          return y;
        });
        return x;
      });
      currentMenu[this.currentMenuIndex[0]].menuItem[this.currentMenuIndex[1]].subMenu[subMenuGroupIndex].menuItem[subMenuIndex].active =true;
      this.sidebarMenu =currentMenu;
      subMenu =currentMenu[this.currentMenuIndex[0]].menuItem[this.currentMenuIndex[1]].subMenu[subMenuGroupIndex].menuItem[subMenuIndex];
    }

    if(subMenu?.url!=null){
      this.miscService.goTo(subMenu?.url);
      this.currentSubMenuIndex =[ subMenuGroupIndex, subMenuIndex ];
      if (this.submenuStatus) {
        this.submenuStatus = false;
      }
    }

  }

  updateMenuItemStatus(event:Event, menu: I_SidebarMenu_Item) {

    this.submenuStatus =false;
    this.subMenu =null;
    let displaySubmenu =true;

    event.preventDefault();
    event.stopPropagation();
    let currentMenu =this.sidebarMenu;
    let menuIsAlreadySelected :boolean =false;

    currentMenu =currentMenu.map( (x: I_SidebarMenu, i: number )=>{
      x.menuItem =x.menuItem.map((y: I_SidebarMenu_Item, j:number)=>{

        if(y.icon ==menu.icon && y.label ==menu.label && y.url ==menu.url){
          // y.active =true;
          // si on a changé de menu
          if( !y.active ){
            // on retire le status active du sous-menu
            let menuItem =currentMenu[this.currentMenuIndex[0]]?.menuItem[this.currentMenuIndex[1]] //?.subMenu[this.currentSubMenuIndex[0]];

            // ?.subMenuItem[this.currentSubMenuIndex[1]];
            if( menuItem?.subMenu ){
              let currentSubMenuItem =menuItem?.subMenu[this.currentSubMenuIndex[0]]?.menuItem[this.currentSubMenuIndex[1]];

              if( currentSubMenuItem!=undefined ){
                currentMenu[this.currentMenuIndex[0]].menuItem[this.currentMenuIndex[1]].subMenu[this.currentSubMenuIndex[0]].menuItem[this.currentSubMenuIndex[1]].active =false;
              }
            }
          }

          y.active =true;
          if( i == this.currentMenuIndex[0] && j ==this.currentMenuIndex[1]){
            menuIsAlreadySelected =true;
          }

          this.currentMenuIndex =[i, j];
        }else{
          y.active =false;
        }

        return y;
      });
      return x;
    });

    this.sidebarMenu =currentMenu;

    if( menu.subMenu!=null && displaySubmenu ){
      if( menuIsAlreadySelected ){
        this.submenuStatus = false;
        this.currentMenuIndex =[-1, -1];
      }else{
        this.showSubmenu(menu);
      }

    }else if(menu.url!=null ){
      this.miscService.goTo(menu.url);
    }

  }

  showSubmenu(menu: I_SidebarMenu_Item){

    this.subMenuLabel =menu.label;

    this.subMenu =menu.subMenu;

    this.submenuStatus = true ; // !this.submenuStatus;

  }

  getMenuIndexFromUrl(): number[]{
    let currentUrl: string =this.router.url;
    let menuIndex:number[] =[-1,-1];
    let menuItemFound: boolean =false;
    this.sidebarMenu.forEach( (x: I_SidebarMenu, i: number )=>{
      x.menuItem.forEach((y: I_SidebarMenu_Item, j:number)=>{
        if( !menuItemFound && (currentUrl==`/${y.url}`||currentUrl==`${y.url}`)){
          menuIndex =[i,j];
          menuItemFound =true;
        }

        y.subMenu?.forEach((z:I_SidebarMenu, k: number)=>{
          z.menuItem.forEach((aa:I_SidebarMenu_Item, l: number)  => {
            if( !menuItemFound && (currentUrl==`/${aa.url}`||currentUrl==`${aa.url}`) ){
              menuIndex =[i, j, k, l];
              menuItemFound =true;
            }
          });
        })

      });

    });

    return menuIndex;

  }

  setActiveMenu(menuIndex: number[]){
    if(this.sidebarMenu[menuIndex[0]]!=undefined && this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]]!=undefined){
      this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]].active =true;
      if(
        this.sidebarMenu[menuIndex[0]]!=undefined &&
        this.sidebarMenu[menuIndex[0]].menuItem!=undefined &&
        this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]]!=undefined &&
        this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]].subMenu!=undefined &&
        this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]].subMenu[menuIndex[2]]!=undefined &&
        this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]].subMenu[menuIndex[2]].menuItem!=undefined &&
        this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]].subMenu[menuIndex[2]].menuItem[menuIndex[3]]!=undefined
      ){
        this.sidebarMenu[menuIndex[0]].menuItem[menuIndex[1]].subMenu[menuIndex[2]].menuItem[menuIndex[3]].active =true;
      }
    }

  }

  cntsub(e:Event) {
    e.stopPropagation();
  }
}
