import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ChangeFilterValue, ITableFilterNew, ITableFilterNewField} from "../../interfaces/i-table-filter-new.interface";

@Injectable({
  providedIn: 'root'
})
export class TableFilterService {
  public formValue$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public formValueDisctinct$: BehaviorSubject<{id: string, value: string[]}> = new BehaviorSubject<{id: string, value: string[]}>(null);
  public filterNew$:BehaviorSubject<ITableFilterNew> = new BehaviorSubject<ITableFilterNew>(null);
  private changeFilterValue$: Subject<ChangeFilterValue> = new Subject<ChangeFilterValue>();
  private filterColumn$: Subject<{ id: string, filter: ITableFilterNewField }> = new Subject<{ id: string, filter: ITableFilterNewField }>();

  constructor() { }

  setFormValue(value: any) {
    this.formValue$.next(value);
  }

  setFormValueDisticnt(value: {id: string, value: any[]}) {
    this.formValueDisctinct$.next(value);
  }

  setFilterNew(value: ITableFilterNew) {
    this.filterNew$.next(value);
  }

  setChangeFilterValue(value: ChangeFilterValue) {
    this.changeFilterValue$.next(value);
  }

  getChangeFilterValue(): Observable<ChangeFilterValue> {
    return this.changeFilterValue$;
  }

  setFilterColumn(value: { id: string, filter: ITableFilterNewField }) {
    this.filterColumn$.next(value);
  }

  getFilterColumn(): Observable<{ id: string, filter: ITableFilterNewField }> {
    return this.filterColumn$;
  }
}
