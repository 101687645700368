<div>
  <div class="block-table-user pb-4">
    <div class="block-table-user-top">
      <div class="block-table-user-top-item">
        <!--<span>6 Remise(s) : </span> -->
      </div>
      <div class="block-table-user-top-item">
        <div class="block-table-user-top-item-right ">
          <app-icon *ngIf="iconId !== ''" [id]="iconId"></app-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <table class="fl-table" *ngIf="table">
      <thead *ngIf="table.header && table.header.length > 0">
      <tr>
        <th *ngFor="let head of table.header" [attr.colspan]="head?.colspan" [attr.rowspan]="head?.rowspan"
            scope="col">
          <div class="contentHeaderTable" [ngClass]="head.style.align">
            <span>{{ head?.value | translate }}</span>
            <div
              class="icon"
              *ngIf="head.sort.isSorted" [attr.data-tooltip]="head.sort.tooltip?.text"
              [attr.data-flow]="head.sort.tooltip?.flow"
            >
              <i class="icn-info-ellipse"></i>
            </div>
          </div>
        </th>
        <th *ngIf="table.action && (table.action.parent || table.action.child)">
          Action
        </th>
      </tr>
      </thead>
      <tbody *ngIf="table.body && table.body.line.length > 0">
      <ng-container *ngFor="let _line of table.body.line; let i = index">
        <tr [class.active]="expandedId === _line.lineId">
          <td *ngFor="let _column of _line.column; let j = index">
            <div
              [style.background-color]="_column.style.background"
              [ngClass]="_column.style.align"
            >
              <!-- ON BOUCLE LE CONTENU DU COLONNE QUI EST UN TABLEAU -->
              <div class="column-content"
                   *ngFor="let _content of _column['content']; let k = index"
              >
                <ng-container *ngIf="_content.type === 'simple'">
                  <div class="contentColumn">
                    <!-- S'IL Y A UN DROPDOWN OU COLLAPSE -->
                    <div
                      *ngIf="_content.expandable"
                      class="plus {{ expandedId === _line.lineId ? 'expanded' : 'notexpanded' }}"
                      (click)="setExpendLineId(_line.lineId)"
                    >
                      <span class="arrow-col"></span>
                    </div>

                    <!-- S' IL Y UNE IMAGE -->
                    <div class="block-col-name">
                      <div class="block-left">
                        <img *ngIf="_content.image" [src]="_content.image">
                      </div>
                      <div class="block-right">
                        <!--<div class="block-right"
                             data-tooltip=" dfn, em, label, legend"
                             data-flow="top">-->
                        <span class="lc-2" [class.bold]="_line.column[0].content[0]['expandable']">
                          {{ _content.value }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <!-- SI BADGE -->
                <ng-container *ngIf="_content.type === 'badge'">
                  <div class="contentColumnBadge" [attr.data-tooltip]="_content.tooltip?.text" [attr.data-flow]="_content.tooltip?.flow">
                    <span class="badge {{_content.badge?.bg}}">
                      {{ _content.value }}
                    </span>
                  </div>
                </ng-container>

                <!-- SI INPUT -->
                <ng-container *ngIf="_content.type === 'input'">
                  <div class="contentColumnInput">
                    <app-input-number
                      *ngIf="_content.input === 'number'"
                      [id]="i+'-'+j+'-'+k"
                      [value]="_content.value.toString()"
                    >
                    </app-input-number>
                    <app-input-switch
                      *ngIf="_content.input === 'switch'"
                      [id]="i+'-'+j+'-'+k"
                      [value]="_content.value"
                      [isDisabled]="_content.disabled"
                      [tooltip]="_content.tooltip"
                      (switchValue)="getInputValue($event)"
                    >
                    </app-input-switch>
                  </div>
                </ng-container>

                <!-- SI BUTTON -->
                <ng-container *ngIf="_content.type === 'button'">
                  <div class="contentColumnButton">
                    <button [class]="_content.icon" (click)="_content.function()" [attr.data-tooltip]="_content?.tooltip.text"
                            [attr.data-flow]="_content?.tooltip.flow">
                      <span [class]="_content.icon">{{ _content.value }}</span>
                    </button>
                  </div>
                </ng-container>

                <!-- SI ICON -->
                <ng-container *ngIf="_content.type === 'icon'">
                  <div class="contentColumnIcon">
                    <span [attr.data-tooltip]="_content.tooltip?.text" [attr.data-flow]="_content.tooltip?.flow">
                      <i class="{{ _content.icon }}"></i>
                    </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </td>
          <td *ngIf="table.action && table.action.parent">
            <div class="block-center">
              <div class="cell-content">
                <a *ngIf="table.action.view" (click)="action(_line.lineId, 'view')" data-tooltip="Voir" data-flow="top">
                  <span class="icn-see-detail"></span>
                </a>
                <a *ngIf="table.action.edit" (click)="action(_line.lineId, 'edit')" data-tooltip="Modifier" data-flow="top">
                  <span class="icn-edit"></span>
                </a>
                <a *ngIf="table.action.delete" data-tooltip="Supprimer" data-flow="top">
                  <span class="icn-delete"></span>
                </a>
              </div>
            </div>
          </td>
        </tr>
        <ng-container *ngIf="expandedId === _line.lineId">
          <tr *ngFor="let expandedLine of expandedLines; let i = index">
            <td *ngFor="let _column of expandedLine.column; let j = index">
              <div
                [style.background-color]="_column.style.background"
                [ngClass]="_column.style.align"
              >
                <!-- ON BOUCLE LE CONTENU DU COLONNE QUI EST UN TABLEAU -->
                <div class="column-content"
                     *ngFor="let _content of _column['content']; let k = index"
                >
                  <ng-container *ngIf="_content.type === 'simple'">
                    <div class="contentColumn">
                      <!-- S'IL Y A UN DROPDOWN OU COLLAPSE -->
                      <div
                        *ngIf="_content.expandable"
                        class="plus {{ expandedId === _line.lineId ? 'expanded' : 'notexpanded' }}"
                        (click)="setExpendLineId(_line.lineId)"
                      >
                        <span class="arrow-col"></span>
                      </div>

                      <!-- S' IL Y UNE IMAGE -->
                      <div class="block-col-name">
                        <div class="block-left">
                          <img *ngIf="_content.image" [src]="_content.image">
                        </div>
                        <div class="block-right">
                          <!--<div class="block-right"
                               data-tooltip=" dfn, em, label, legend"
                               data-flow="top">-->
                          <span class="lc-2">
                          {{ _content.value }}
                        </span>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- SI BADGE -->
                  <ng-container *ngIf="_content.type === 'badge'">
                    <div class="contentColumnBadge">
                      <span class="badge {{_content.badge?.bg}}" [attr.data-tooltip]="_content.tooltip?.text" [attr.data-flow]="_content.tooltip?.flow">
                        {{ _content.value }}
                      </span>
                    </div>
                  </ng-container>

                  <!-- SI INPUT -->
                  <ng-container *ngIf="_content.type === 'input'">
                    <div class="contentColumnInput">
                      <app-input-number
                        *ngIf="_content.input === 'number'"
                        [id]="i+'-'+j+'-'+k"
                        [value]="_content.value"
                      >
                      </app-input-number>
                      <app-input-switch
                        *ngIf="_content.input === 'switch'"
                        [id]="i+'-'+j+'-'+k"
                        [value]="_content.value"
                        [tooltip]="_content.tooltip"
                        [isDisabled]="_content.disabled"
                        (switchValue)="getInputValue($event, true)"
                      >
                      </app-input-switch>
                    </div>
                  </ng-container>

                  <!-- SI BUTTON -->
                  <ng-container *ngIf="_content.type === 'button'">
                    <div class="contentColumnButton">
                      <button [class]="_content.icon" (click)="_content.function()">
                        <span>{{ _content.value }}</span>
                      </button>
                      <!-- <button [class]="_content.icon" (click)="_content.function()" data-tooltip="Bonjour"
                              data-flow="top">
                        <span [class]="_content.icon">{{ _content.value }}</span>
                      </button>
                      <button [class]="_content.icon" (click)="_content.function()" data-tooltip="Bonjour"
                              data-flow="top">
                        <span [class]="_content.icon">{{ _content.value }}</span>
                      </button> -->
                    </div>
                  </ng-container>

                  <!-- SI ICON -->
                  <ng-container *ngIf="_content.type === 'icon'">
                    <div class="contentColumnIcon">
                    <span [attr.data-tooltip]="_content.tooltip?.text" [attr.data-flow]="_content.tooltip?.flow">
                      <i class="{{ _content.icon }}"></i>
                    </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </td>
            <td *ngIf="table.action && table.action.child">
            <div class="block-center">
              <div class="cell-content">
                <a *ngIf="table.action.view" data-tooltip="Voir" data-flow="top">
                  <span class="icn-eyes"></span>
                </a>
                <a *ngIf="table.action.edit" (click)="action(expandedLine.lineId, 'edit')" data-tooltip="Modifier" data-flow="top">
                  <span class="icn-edit"></span>
                </a>
                <a *ngIf="table.action.delete" (click)="action(expandedLine.lineId, 'delete', true, _line.lineId)" data-tooltip="Supprimer" data-flow="top">
                  <span class="icn-delete"></span>
                </a>
              </div>
            </div>
          </td>
          </tr>
        </ng-container>
      </ng-container>
      <tbody>
    </table>
  </div>
</div>


