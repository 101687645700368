<div class="block-table-filtre block-table-filtre--{{size}}" [formGroup]="filterFormGroup">
  <h3 class="title-table-filtre">{{ filterParams?.title | translate }}</h3>
  <div class="block-filtre">
    <div class="block-content-filtre" formArrayName="field_list">

      <div class="item-content-filtre" *ngFor="let field of fieldGroup().controls; let i = index;">
        <!-- <div class="title-content-filtre">Rechercher par :</div> -->
        <div class="block-flex" [formGroupName]="i">
          <label>{{ field.value.label | translate }} :</label>
          <input
            type="text"
            name="form_field_value"
            formControlName="form_field_value"
            placeholder="{{field.value.placeholder | translate}}"
            *ngIf="field.value.type === tableFilter.tableFilterFieldType.input"
            (input)="triggerValueChanges()"
          >
          <ng-container *ngIf="field.value.type === tableFilter.tableFilterFieldType.select">
            <div class="box-select">
              <ng-select
                [items]="selectValue[i]"
                [bindLabel]="'label' | translate"
                bindValue="value"
                formControlName="form_field_value"
                (change)="triggerValueChanges()"
              >
              </ng-select>
              <span class="arrow"></span>
            </div>
          </ng-container>
          <ng-container *ngIf="field.value.type === tableFilter.tableFilterFieldType.swicth">
            <!-- checkBox siwth vert si checker et gris si non -->
            <div class="block-on-off">
              <div class="block-switch lg-switch">
                <label class="switch switch-primary">
                  <input
                    type="checkbox"
                    class="switch-input"
                    name="form_field_value"
                    formControlName="form_field_value"
                    (change)="triggerValueChanges()"
                  >
                  <span class="switch-label" [attr.data-on]="'Actif'" [attr.data-off]="'Inactif'"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
              <!-- onoff -->
            </div>
          </ng-container>

          <ng-container *ngIf="field.value.type === tableFilter.tableFilterFieldType.range" >
              <div class="block-daterangepicker">
                <mat-form-field appearance="fill" (click)="picker.open()">
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="form_field_start" (input)="triggerValueChanges()" (dateChange)="triggerValueChanges()" placeholder="00/00/0000">
                    <input matEndDate formControlName="form_field_end" (input)="triggerValueChanges()" (dateChange)="triggerValueChanges()" placeholder="00/00/0000">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <div class="result-filtre" *ngIf="chips.length > 0  && !allChipsAreAll()">
    <div class="result-filtre-titre">Critères d'affichage :</div>
    <div class="block-word">
      <div class="item-word" *ngFor="let chip of this.fieldGroup().value; let i = index;" >
        <div class="item-content-word" *ngIf="chip.form_field_value && chip.form_field_value !== 'all'">
          <span *ngIf="chip.type === tableFilter.tableFilterFieldType.input">
            {{ (chip.label | translate) + ': '}} {{ chip.form_field_value.toString() }}
          </span>
          <ng-container *ngIf="chip.type === tableFilter.tableFilterFieldType.swicth">
            <span *ngIf="chip.form_field_value === false else actif">Inactif</span>
            <ng-template #actif>
              <span>Actif</span>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="chip.type === tableFilter.tableFilterFieldType.select">
            <span>{{ (chip.label | translate) + ': '}} {{ getValueOfSelect(chip.key, chip.form_field_value, i) }}</span>
          </ng-container>
          <span class="icon-close" (click)="removeFilter(chip.key, chip.form_field_value, i)">+</span>
        </div>
        <div class="item-content-word" *ngIf="chip.form_field_start">
          <span >
            {{ (chip.label | translate) + ': '}} {{ chip.form_field_start | date }} - {{ chip.form_field_end | date }}
          </span>
          <span class="icon-close" (click)="removePeriodFilter(chip.form_field_start, i)">+</span>
        </div>
      </div>
    </div>
    <div class="block-delete-all">
      <div class="block-delete-all-content" (click)="resetFilter()">
        <span class="icn-refersh"></span><span>Supprimer les critères</span>
      </div>
    </div>
  </div>
</div>


