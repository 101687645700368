import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ApiService} from "../api-service/api.service";
import {I_ApiResponse} from "../../models/api-response";
import {EnvService} from "../../../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class ItemSerializationCartService {
  private productSerialization$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private products$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private stockService: string = this.env.apiStockService;

  constructor(
    private apiService: ApiService,
    private env: EnvService
  ) { }

  setProductSerialization(product: any) {
    this.productSerialization$.next(product);
  }

  getProductSerialization() {
    return this.productSerialization$
  }

  setProducts(product: any) {
    this.products$.next(product);
  }

  getProducts() {
    return this.products$
  }

  getSerialNumberByProduct(shopUuid: string, productUuid: string, search: string = '', quantity?: number): Observable<I_ApiResponse> {
    let param = {
      search: search,
      quantity: quantity??1
    }
    const url =`${this.stockService}/serial/stock-location/${shopUuid}/product/${productUuid}`;
    return this.apiService.doGet(url, param);
  }

  getEndSerialNumber(shopUuid: string, cartUuid: string, productUuid: string, key: string, start: string, param: Object) {
    const url =`${this.stockService}/serial/stock-location/${shopUuid}/${cartUuid}/product/${productUuid}/${key}/${start}`;
    return this.apiService.doGet(url, param);
  }

  updateSerialiNumberAvailable(cart: string, shop: string, product: string, currentValue: any, previousValue: any): Observable<I_ApiResponse> {
    let url = `${this.env.apiCartService}/various/outgoing/${cart}/product/${product}/add-serial-number`
    let values = {
      previous_serial_number : previousValue,
      requested_serial_number : currentValue,
    };
    return this.apiService.doPost(url, values);
  }


  getSerialNumberBatcheable(shopUuid: string, productUuid: string, search: string = '', quantity?: number): Observable<I_ApiResponse> {
    let param = {
      search: search,
      quantity: quantity??1
    }
    return this.apiService.doGet(`${this.stockService}/serial/stock-location/${shopUuid}/product/${productUuid}`, param);
  }
}
