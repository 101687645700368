import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ItemSerializationCartService} from "../../../services/item-serialization-cart/item-serialization-cart.service";
import {IButton} from "../../../models/i-button";
import {I_ApiResponse} from "../../../models/api-response";
import {storageKey} from "../../../../../../config/storage";
import {LocalStorageService} from "../../../services/local-storage/local-storage.service";

@Component({
  selector: 'app-item-serialization-cart',
  templateUrl: './item-serialization-cart.component.html',
  styleUrls: ['./item-serialization-cart.component.scss']
})
export class ItemSerializationCartComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() cartUuid!: string;
  @Input() shopUuid!: string;
  private subscription = new Subscription();
  public product: any;
  public inputQuantity: number = 0;
  public perBatch: IButton = {
    buttonText: 'Par lot',
    buttonIcon: [],
    variant: '',
    state: '',
  };
  public perUnit: IButton = {
    buttonText: 'Par unité',
    buttonIcon: [],
    variant: '',
    state: '',
  };
  private userData!: any;

  constructor(
    private localStorageService: LocalStorageService,
    private itemSerializationCartService: ItemSerializationCartService
  ) { }

  ngOnInit(): void {
    this.getProductSerialization();
    this.getUserDetail();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getUserDetail() {
    this.userData = JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
  }

  getProductSerialization() {
    this.subscription.add(
      this.itemSerializationCartService.getProductSerialization().subscribe(product => {
        if (product) {
          this.product = product;
          if (product?.serialization.is_batcheable) {
            this.inputs = [];
            this.perBatch.action = this.addBatchInput;
            this.perUnit.action = this.addUnitInput;
            if (product?.serialization.quantity_selected > 0)
            this.addDefaultInput(product?.serialization.serializations);
          }
        }
      })
    );
  }

  getProductInput(event: any) {
    this.product = event;
    this.inputs = [];
    this.addDefaultInput(event?.serialization.serializations);
  }

  public inputs: any[] = [];
  addBatchInput = () => {
    const serializations =  this.product?.serialization.serializations[0].serializationData[0]
    /*this.product?.serialization.serializations.map(x => {
      x['isBatch'] = true;
      return x
    });*/
    this.addInput(serializations, true);
  }

  addUnitInput = () =>{
    const serializations =  this.product?.serialization.serializations[0].serializationData[0]
    /*this.product?.serialization.serializations.map(x => {
      x['isBatch'] = false;
      return x
    });*/
    this.addInput(serializations, false);
  }

  addInput(serializations: any, isBatch: boolean) {
    const length = this.inputs.length;
    if (
      length > 0 &&
      this.product?.serialization.serializations[length -1]['serial_number_start'] != '' &&
      this.product?.serialization.serializations[length -1]['serial_number_end'] != ''
    ) {
      const newSerializations = {
        is_mandatory: 1,
        is_serialization_valid: false,
        product_serialization_key: serializations['product_serialization_key'],
        quantity : isBatch ? this.product?.serialization.quantity_remaining : 1,
        serial_number_end : "",
        serial_number_start: ""
      }
      this.inputs.push({
        totaItems: this.product?.serialization.quantity_remaining,
        serializationData: [newSerializations],
        isBatch: isBatch
      })
    } else {
      this.product.serialization.serializations[0]['isBatch'] = isBatch
      this.inputs.push(this.product?.serialization.serializations[0]);
    }
  }

  addDefaultInput(serializations: any[]) {
    serializations.forEach(serialization => {
      if (serialization.totaItems > 1) {
        serialization['isBatch'] = true;
        this.inputs.push(serialization);
      } else {
        serialization['isBatch'] = false;
        this.inputs.push(serialization);
      }
    })
  }

  remove(i: number) {
    if (!this.product.serialization.serializations[i]?.serializationData[0].is_serialization_valid) {
      this.inputs.splice(i, 1);
      return;
    }
    // this.inputs[i].serializationData = this.product.serialization.serializations[i].serializationData
    const serialization = this.inputs[i].serializationData[0];
    const prev = {
      product_serialization_key: serialization.product_serialization_key,
      serial_number_start: serialization.serial_number_start,
      serial_number_end: serialization.serial_number_end,
      id: i
    }
    this.enableSeriallNumber(i, prev);
  }

  enableSeriallNumber(i: number, previousValue: any) {
    this.subscription.add(
      this.itemSerializationCartService.updateSerialiNumberAvailable(
        this.cartUuid,
        this.shopUuid,
        this.product.uuid_product,
        null,
        previousValue
      ).subscribe((response: I_ApiResponse) => {
        const products = response.data.cart.items
        this.product = products.filter(x => x.uuid_product == this.product.uuid_product)[0];
        this.inputs.splice(i, 1);
        this.itemSerializationCartService.setProducts(products);
      })
    )
  }
}
