import {Component, Input, OnInit} from '@angular/core';
import {I_CounterData} from "../../../models/counter";

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  // TODO: Configurer l'affichage en counter -> counter-item
  @Input()
  counter!: I_CounterData;

  @Input()
  cible: string=''; // actif | inactif

  constructor() { }

  ngOnInit(): void {
  }

}
