<header>
  <div class="header-container">
    <app-bread-crumb class="cont-breadcrumb"></app-bread-crumb>
    <div class="logo">
      <a href="#">
        <img src="assets/img/logo.svg" alt="logo" />
      </a>
      <div class="current-caisse" *ngIf="logoText != ''">
        {{ logoText }}
      </div>
    </div>
    <div class="cnt-space">
      <div class="avatar" (click)="onAvatarClicked()" #avatar>
        <figure class="avatar-box">
          <!-- <img src="assets/img/avatar.png" alt="avatar" data-avatar /> -->
          <i class="icn-profil-man" data-avatar></i>
        </figure>
        <div class="content-disconnect" [ngClass]="{'show': isSettingDisplayed}">
          <div class="block-disconnect">
            <div class="item-disconnect" (click)="signOut()">
              <div class="int-disconnect-inline">
                <span><i class="icn-ico_deconnexion"></i></span>
                <span>{{ 'home.logOut' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="notification">
    <app-toast-notification *ngFor="let toast of toastDataList" [icon]="toast.icon" [type]="toast.type"
      [title]="toast.title" [desc]="toast.desc"></app-toast-notification>
  </div>
</header>
