import { I_ScrollPagination } from './../../../models/infinite-scroll/scrollPagination';
import { InfiniteScrollService } from './../../../services/infinite-scroll/infinite-scroll.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-infinite-scroll-table',
  templateUrl: './infinite-scroll-table.component.html',
  styleUrls: ['./infinite-scroll-table.component.scss']
})
export class InfiniteScrollTableComponent implements OnInit, AfterViewInit {

  @ViewChild('scrollButton')
  scrollButton: ElementRef;

  @Input()
  uniqueId: string;

  scrollPagination: I_ScrollPagination;
  constructor(private infiniteScrollService: InfiniteScrollService) { }

  ngOnInit(): void {
    this.scrollPagination ={
      uniqueId: this.uniqueId,
      page: 1
    }as I_ScrollPagination
  }

  ngAfterViewInit(): void {

    let options = {
      // root: this.scrollButton.nativeElement,
      rootMargin: '0px',
      threshold: 1
    }

    let observer = new IntersectionObserver((entries)=>{
      let ratioList =entries.map(x=>x.intersectionRatio);

      if( ratioList.indexOf( options.threshold ) > -1 ){
        this.scrollPagination.page =this.scrollPagination.page +1;
        this.infiniteScrollService.loadMore(this.scrollPagination);
      }
    }, options);

    observer.observe(this.scrollButton.nativeElement);
  }

}
