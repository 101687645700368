import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { I_ApiResponse } from 'src/app/main/modules/shared/models/api-response';
import { ApiService } from 'src/app/main/modules/shared/services/api-service/api.service';
import { LocalStorageService } from 'src/app/main/modules/shared/services/local-storage/local-storage.service';
import {I_SidebarMenu_Action, I_SidebarMenu_Item} from "../../../../modules/shared/models/sidebar-menu";
import {Url} from "../../../../modules/shared/models/url/url";
import {storageKey} from "../../../../../config/storage";
import * as CryptoJS from 'crypto-js';
import {EnvService} from "../../../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  userAbilities$: BehaviorSubject<string[]>=new BehaviorSubject<string[]>([]);

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private env: EnvService
  ) {
  }

  /**
   * @description: API pour récupérer la liste des habilitations pour plusieurs roles JWT
   * @todo: à changer par l'API
   */
  getUserAbilities(): Observable<I_ApiResponse> {
    return this.apiService.doGet('./assets/data/abilities-reponse.json');
  }

  storeAbilitiesUrl(urls: Url[] = []) {
    let encryptValue = CryptoJS.AES.encrypt(JSON.stringify(urls), storageKey.ability.url).toString()
    this.localStorageService.setLocalStorage(storageKey.ability.url, encryptValue)
  }

  getSubMenuItem(
    subMenuItem: I_SidebarMenu_Item[],
    sidebarMenuItemSubMenus: I_SidebarMenu_Item[],
    userAbilities: string[],
    urls: Url[]
  ) {
    subMenuItem.forEach((itemSubMenu) => {
      if (userAbilities.includes(itemSubMenu.ability_group_key)) {
        let url: Url = {
          ability_group_key: itemSubMenu.ability_group_key,
          url: itemSubMenu.url
        }
        this.setUrl(urls, url)
        if (itemSubMenu.action) this.getMenuAction(itemSubMenu.action, userAbilities, urls);
        sidebarMenuItemSubMenus.push(itemSubMenu)
      }
    });
  }

  getMenuAction(actions: I_SidebarMenu_Action[], userAbilities: string[], urls) {
    actions.forEach(action => {
      let url: Url = {
        ability_group_key: action.ability_group_key,
        url: action.url
      }
      if (userAbilities.includes(action.ability_group_key)) this.setUrl(urls, url);
    });
  }

  setUrl(urls: Url[], url: Url) {
    urls.push(url);
    this.storeAbilitiesUrl(urls);
  }

  updateUserAbilities(abilities: string[]){
    this.userAbilities$.next(abilities);
  }
}
