import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IButton } from '../../../models/i-button';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {

  @Input() pageTitle: string="";
  @Input() pageWelcomeText: string="";
  @Input() pageSubTitle: string="";
  @Input() buttonLabel: string ='';
  @Input() buttonUrl: string ='';
  @Input() imageUrl: string ='';

  public buttonCreate: IButton;
  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
    this.buttonCreate = {
      buttonText: this.buttonLabel,
      buttonIcon: [],
      variant: 'yellow',
    };

    this.buttonCreate.action = this.goToCreate;
  }

  goToCreate = () => {
    this.route.navigateByUrl(this.buttonUrl)
  }
}
