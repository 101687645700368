import { I_CanLoadMore } from './../../models/can-load-more';
import { I_ScrollPagination } from './../../models/infinite-scroll/scrollPagination';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfiniteScrollService {

  scrollStatus$: BehaviorSubject<string> =new BehaviorSubject<string>('idle');
  scrollPagination$: BehaviorSubject<I_ScrollPagination|null> =new BehaviorSubject<I_ScrollPagination|null>(null);
  canLoadMore$: BehaviorSubject<I_CanLoadMore|null>=new BehaviorSubject<I_CanLoadMore|null>(null);

  constructor() { }

  updateScrollStatus(status: string){
    this.scrollStatus$.next(status);
  }

  updateCanLoadMore( canLoadMore: I_CanLoadMore ){
    this.canLoadMore$.next(canLoadMore);
  }

  loadMore(pagination: I_ScrollPagination){
    this.scrollPagination$.next(pagination);
  }
}
