import { config } from '../../../../../../config/config';
import { Component, OnInit, Input, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
  @Input()
  icon: string = '';

  @Input()
  type: string = '';

  @Input()
  title: string = '';

  @Input()
  desc: string = '';

  subscription: Subscription = new Subscription();

  fadeOut: string = '';

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.fadeOut = 'fadeOut';
    }, config.notificationFadeDelay);

    setTimeout(() => {
      // remove component permanently
      this.host.nativeElement.remove();
    }, config.notificationDisplayTime);
  }
  closeNotif(){
    this.host.nativeElement.remove();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
