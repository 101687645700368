// TODO Créer une interface pour rendre le modèle générique

import { I_FormContract } from '../formContract.model';

// TODO Créer un modèle pour contract compliant form
export const genericForm: I_FormContract = {
  contract_uuid: '1111-1111-1111-1111',
  fields: [
    {
      form_field_id: 1,
      form_field_key: 'first_name',
      form_field_label: 'Nom',
      is_mandatory: 1,
      is_active: 1,
      is_editable: 0,
      is_locked: 1,
      form_field_group_key: 'Civilité',
      form_field_type: {
        form_field_type_id: 1,
        form_field_type_key: 'TEXT',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 1,
          fk_form_field_id: 1,
          fk_form_field_regex_id: 4,
          form_field_regex: {
            form_field_regex_id: 4,
            form_field_regex_key: 'ALPHA_64',
            form_field_regex_value: '^.{1,64}$',
          },
        },
      ],
    },
    {
      form_field_id: 2,
      form_field_key: 'last_name',
      form_field_label: 'Prénom',
      is_mandatory: 1,
      is_active: 1,
      is_editable: 0,
      is_locked: 1,
      form_field_group_key: 'Civilité',
      form_field_type: {
        form_field_type_id: 1,
        form_field_type_key: 'TEXT',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 2,
          fk_form_field_id: 2,
          fk_form_field_regex_id: 4,
          form_field_regex: {
            form_field_regex_id: 4,
            form_field_regex_key: 'ALPHA_64',
            form_field_regex_value: '^.{1,64}$',
          },
        },
      ],
    },
    {
      form_field_id: 3,
      form_field_key: 'date_of_birth',
      form_field_label: 'Date de naissance',
      is_mandatory: 0,
      is_active: 1,
      is_editable: 1,
      is_locked: 0,
      form_field_group_key: 'Civilité',
      form_field_type: {
        form_field_type_id: 1,
        form_field_type_key: 'DATE',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 3,
          fk_form_field_id: 3,
          fk_form_field_regex_id: 1,
          form_field_regex: {
            form_field_regex_id: 1,
            form_field_regex_key: 'DATE',
            form_field_regex_value:
              '^(?:(?:31(\\/)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$',
          },
        },
      ],
    },
    {
      form_field_id: 4,
      form_field_key: 'cin',
      form_field_label: 'Numéro CIN',
      is_mandatory: 0,
      is_active: 1,
      is_editable: 1,
      is_locked: 0,
      form_field_group_key: 'Civilité',
      form_field_type: {
        form_field_type_id: 1,
        form_field_type_key: 'TEXT',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 4,
          fk_form_field_id: 4,
          fk_form_field_regex_id: 4,
          form_field_regex: {
            form_field_regex_id: 4,
            form_field_regex_key: 'ALPHA_64',
            form_field_regex_value: '^.{1,64}$',
          },
        },
      ],
    },
    {
      form_field_id: 5,
      form_field_key: 'country_key',
      form_field_label: 'Pays',
      is_mandatory: 0,
      is_active: 1,
      is_editable: 1,
      is_locked: 0,
      form_field_group_key: 'Adresse',
      form_field_type: {
        form_field_type_id: 3,
        form_field_type_key: 'SELECT',
      },
      form_field_source: {
        form_field_source_id: 1,
        source_url: 'http://10.163.13.80:303/country',
        source_key: 'country_key',
        source_value_key: 'name',
      },
      form_field_regexes: [],
    },
    {
      form_field_id: 6,
      form_field_key: 'city_key',
      form_field_label: 'Ville',
      is_mandatory: 0,
      is_active: 1,
      is_editable: 1,
      is_locked: 0,
      form_field_group_key: 'Adresse',
      form_field_type: {
        form_field_type_id: 1,
        form_field_type_key: 'TEXT',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 5,
          fk_form_field_id: 6,
          fk_form_field_regex_id: 5,
          form_field_regex: {
            form_field_regex_id: 5,
            form_field_regex_key: 'ALPHA_128',
            form_field_regex_value: '^.{1,128}$',
          },
        },
      ],
    },
    {
      form_field_id: 7,
      form_field_key: 'address',
      form_field_label: 'Adresse',
      is_mandatory: 0,
      is_active: 1,
      is_editable: 1,
      is_locked: 0,
      form_field_group_key: 'Adresse',
      form_field_type: {
        form_field_type_id: 6,
        form_field_type_key: 'TEXT_AREA',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 6,
          fk_form_field_id: 7,
          fk_form_field_regex_id: 6,
          form_field_regex: {
            form_field_regex_id: 6,
            form_field_regex_key: 'ALPHA_256',
            form_field_regex_value: '^(.|\n*){1,256}$',
          },
        },
      ],
    },
    {
      form_field_id: 8,
      form_field_key: 'phone',
      form_field_label: 'Téléphone',
      is_mandatory: 0,
      is_active: 1,
      is_editable: 1,
      is_locked: 0,
      form_field_group_key: 'Contact',
      form_field_type: {
        form_field_type_id: 5,
        form_field_type_key: 'PHONE',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 7,
          fk_form_field_id: 8,
          fk_form_field_regex_id: 8,
          form_field_regex: {
            form_field_regex_id: 8,
            form_field_regex_key: 'NUMERIC_32',
            form_field_regex_value: '^[0-9]{1,4}$',
          },
        },
      ],
    },
    {
      form_field_id: 9,
      form_field_key: 'email',
      form_field_label: 'E-mail',
      is_mandatory: 0,
      is_active: 1,
      is_editable: 1,
      is_locked: 0,
      form_field_group_key: 'Contact',
      form_field_type: {
        form_field_type_id: 1,
        form_field_type_key: 'TEXT',
      },
      form_field_source: null,
      form_field_regexes: [
        {
          form_field_regex_relationship_id: 8,
          fk_form_field_id: 9,
          fk_form_field_regex_id: 2,
          form_field_regex: {
            form_field_regex_id: 2,
            form_field_regex_key: 'EMAIL',
            form_field_regex_value: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          },
        },
        {
          form_field_regex_relationship_id: 9,
          fk_form_field_id: 9,
          fk_form_field_regex_id: 5,
          form_field_regex: {
            form_field_regex_id: 5,
            form_field_regex_key: 'ALPHA_128',
            form_field_regex_value: '^.{1,128}$',
          },
        },
      ],
    },
  ],
};
