import { fileConstants } from './../../../../../../config/constants';
import { IImportRule } from './../../models/import';
import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IImportResult } from '../../models/import';
import { MiscService } from '../miscellaniscious/misc.service';
@Injectable({
  providedIn: 'root',
})
export class ImportService {
  import$: BehaviorSubject<IImportResult | null> =
    new BehaviorSubject<IImportResult | null>(null);

  constructor(
    private apiService: ApiService,
    private miscService: MiscService
  ) {}

  importResponseData(importResult: IImportResult) {
    this.import$.next(importResult);
  }

  postFile(url: string, file: File) {
    let data = {
      file: file,
    };
    return this.apiService.doPost(url, data);
  }

  // Validate file -> emmit file valid

  /**
   * @description Check if file is valid according to given rules
   * @param file
   * @param rules
   * @author Mavalson
   * @todo gestion input file multiple avec Unique ID
   */
  validateFile(files: File[], rules: IImportRule, uniqueId?: string) {
    Array.from(files).forEach((file: File) => {
      let reader = new FileReader();
      let fileValidation: any ={};
      // async on load end
      reader.onloadend = (e) => {
        // test sur taille de fichier
        if (rules.maxSize != null) {

          fileValidation.size =file.size<=(rules.maxSize *1024);
        }

        // test sur encodage
        if (rules.encoding != null) {
          let allowdEncoding=rules.encoding;
          //@ts-ignore
          const uint = new Uint8Array(e.target?.result);

          let bytes: string[] = [];
          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          });
          const header = bytes.join('').toUpperCase();
          let fileSignatureKey =Object.keys(fileConstants.fileSignature);
          let fileSignature =fileConstants.fileSignature;

          fileValidation.encodage =false;

          allowdEncoding.forEach((encoding)=>{

            if(fileSignatureKey.indexOf(encoding) > -1){
              if( header.includes(fileSignature[encoding]) ){

                fileValidation.encodage =true;

              }
            }
          });

          if( allowdEncoding.indexOf(header) > -1 ){
            fileValidation.encodage =true;
          }
        }
        fileValidation.fileContent =file;
        fileValidation.uniqueId =uniqueId;
        this.miscService.updateFileValid(fileValidation);

      };
      reader.readAsArrayBuffer(file.slice(0, 4));
    });
  }
    convertFile(file: File) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    return Observable.create((observer: any) => {
      reader.onloadend = () => {
        observer.next(reader.result);
        observer.complete();
      };
    });
  }
    fileDataCount(file: File) {
    let reader = new FileReader();
    reader.readAsText(file);
    return Observable.create((observer: any) => {
      reader.onloadend = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        observer.next(csvRecordsArray.length - 1);
        observer.complete();
      };
    });
  }
}
