<div class="main-modal" [style.visibility]="dialogStatus?.visible?'visible':'hidden'"> <!-- *ngIf="dialogStatus?.visible" -->
  <div class="cnt-modal"
    [ngClass]="{
      'cnt-modal__header--large': size === 'large',
      'cnt-modal__header--small' : size === 'small',
      'cnt-modal__header--new-client' : size ==='medium',
      'cnt-modal__header--serialisation' : size ==='serialisation',
      'cnt-modal__header--payment' : size ==='payment',
      'cnt-modal__body--generate' : size === 'document',
      'cnt-modal__body--serialisation' : size ==='serialisation',
      'cnt-modal__header--interlocutor' : size === 'interlocutor',
      'cnt-modal__body--full-width' : size === 'full'
    }"
    [style.width]="dialogWidth()"
  >
    <div class="cnt-modal__header " >
      <h3><ng-content select="[head]"></ng-content></h3>
      <span (click)="closeModal()">+</span>
    </div>
    <div class="cnt-modal__body" [ngClass]="cible" >
      <div class="cnt-modal__body-intern">
        <div class="modal-desc">
          <div class="intro">
            <ng-content select="[intro]"></ng-content>
          </div>
          <div class="modal-content">
            <ng-content select="[main]"></ng-content>
          </div>
      </div>
      </div>
    </div>
    <div class="cnt-modal__footer">
      <div class="btn-group">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
