<div class="">
  <div class="modal-article-content__item" *ngIf="selectProduct">
    <h3 class="modal-article-content__title">
    </h3>
    <div class="scoll-block-modal">
      <p class="modal-article-content__subtitle">{{ 'serialization-item-selling.item-field.sub-title' | translate }}</p>
    </div>
  </div>
  <div class="modal-article-content__item">
    <div class="container-header">
      <div class="container-header__item">
        <h3 class="container-header__title">
          {{ itemSerialization?.product?.label}}
        </h3>
      </div>
      <div class="container-header__item">
        <div class="container-header__content">
          <div class="container-header__stat">
            {{ 'serialization-item-selling.serial-field.serial-number-information' | translate }} :
            <span class="container-header__stat&#45;&#45;number">{{itemSerialization?.inputQuantity || 0 }}
              / {{ itemSerialization?.quantity }}</span>
          </div>
          <div class="container-header__stat">
            {{ 'serialization-item-selling.serial-field.serial-number-remaining' | translate }} :
            <span
              class="container-header__stat&#45;&#45;number">{{ itemSerialization?.quantity - (itemSerialization?.inputQuantity || 0) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-content">
      <div class="article-content">
        <ng-container *ngIf="!itemSerialization?.product?.is_batcheable else batcheable">
          <ng-container *ngFor="let inputSerialization of getSerializationInputList(itemSerialization); let i = index;">
            <app-per-unit
              *ngFor="let serialization of inputSerialization"
              [serialization]="serialization"
              [serializationValue]="serializationValue[i]"
              [productUuid]="itemSerialization.productUuid"
              [id]="i"
              [autoCompleteField]="autoCompleteField"
              (serializationInputValue)="getSerializationValue($event)"
            >
            </app-per-unit>
          </ng-container>
        </ng-container>
        <ng-template #batcheable>
          <ng-container *ngFor="let batchInput of batchInputs; let i = index">
            <div class="block-inline-flx">
              <div class="item-inline-flx">
                <div class="block-int-item-inline-flx">
                  <app-per-batch
                    *ngIf="batchInput['isBatch'] else unit"
                    [serialization]="batchInput['serialization']"
                    [serializationValue]="serializationValue[i]"
                    [reste]="itemSerialization?.quantity-(itemSerialization?.inputQuantity || 0)"
                    [productUuid]="itemSerialization.productUuid"
                    [id]="i"
                    [autoCompleteField]="autoCompleteField"
                    (serializationInputValue)="getSerializationValue($event)"
                  >
                  </app-per-batch>
                  <ng-template #unit>
                    <app-per-unit
                      [serialization]="batchInput['serialization']"
                      [serializationValue]="serializationValue[i]"
                      [productUuid]="itemSerialization.productUuid"
                      [id]="i"
                      [autoCompleteField]="autoCompleteField"
                      (serializationInputValue)="getSerializationValue($event)"
                    >
                    </app-per-unit>
                  </ng-template>
                </div>
              </div>
              <div class="item-inline-flx">
                <div class="article-lot__item">
                  <div class="article-lot__trash" (click)="removeInput(i)" data-tooltip="Supprimer" data-flow="left">
                    <i class="icn-delete"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="article-total inline-flx-right">
              <p class="article-total__text">Total:
                <span class="article-total__stat">
                  {{ itemSerialization.serializationValue[i]?.totalItems}}
                </span>
              </p>
            </div>
          </ng-container>
          <div class="wrapper" *ngIf="batchInputs.length == 0 || (isValid && itemSerialization?.quantity != itemSerialization?.inputQuantity)">
            <div class="batchable">
              <div
                class="batchable__item"
                *ngIf="(itemSerialization?.quantity - itemSerialization?.inputQuantity) > 1"
              >
                <app-button [config]="perBatch"></app-button>
              </div>
              <div class="batchable__item">
                <app-button [config]="perUnit"></app-button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
