import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PosLayoutComponent } from '../../layouts/pos-layout/pos.component';
import { CreateInventoryComponent } from './pages/create/create-inventory.component';
import { ListInventoryComponent } from './pages/list/list-inventory.component';

const routes: Routes = [{
  path: '',
  component: PosLayoutComponent,
  children: [
    {
      path: 'inventory/create/:uuid',
      component: CreateInventoryComponent,
    },
    {
      path: 'inventory',
      component: ListInventoryComponent
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryRoutingModule { }
