export const url = {
  test_page: "/test",
  product:{
    baseUrl: "/products",
    categories:{ url:"/products/categories", ability: "category_list"},
    pack:{
      list: "/products/pack/list",
      create: "/products/pack/create",
      createWithProduct: "/products/pack/create/:uuid",
      edit: "/products/pack/edit/:uuid",
      empty: "/products/pack/empty",
    },
    discount: {
      list: "/products/discount/list",
      search: "/discounts/search",
      create: "/products/discount/create",
      edit: "/products/discount/edit/:uuid",
    }
  },
  authGuard:{
    defaultUrl: 'page-not-allowed'
  },
  home: "/",
  shop_management: {
    list: "/liste-shop",
    create: "/create-shop",
  },
  stock_management: "/stock/reception",
  inventory: {
    list: "/inventory",
    create: "inventory/create/:uuid"
  },
  catalog: {
    product: {
      list: "/products",
      edit: "/products/edit/:uuid"
    },
    category: "/products/categories",
    pack: "/products/pack"
  },
  setting: "/settings",
  module: {
    customer_category: "/customer/category",
    payments: {
      payment_method: "/payment/payment-method",
      mobile_money: "payment/mobile-money",
    },
    variousEntryExit: "/stock-configuration/various-entry-exit",
  },
  user: {
    profile_management: {
      list: "/user/profile",
      detail: "/user/profile/:uuid"
    },
    user_management: {
      list:"/user",
      detail: "/user/detail/:uuid",
      update: {
        page: "/user/update/:uuid",
        information: "/user/update/:uuid/information",
        access: "/user/update/:uuid/access",
        attached_shop: "/user/update/:uuid/attached-shop",
      }
    },
    login:{
      authentication: 'login',
      success: '/login/success',
      authenticationLDAP: 'login-ldap'
    }
  },
  cash_register: '/cash-register',
  various: {
    entry: {
      list: 'various/entry',
      create: 'various/entry/create'
    },
    exit: {
      list: 'various/exit',
      create: 'various/exit/create'
    }
  },
  customer: {
    list: '/customer'
  },
  mobile_money: {
    cash_in: '/mobile-money/cash-in',
    cash_out: '/mobile-money/cash-out',
    dashboard: '/mobile-money/dashboard',
    history: '/mobile-money/history',

  },
  import: {
    import: '/import',
    history: '/import/history',
    import_list: {
      shop: '/import/shop-import',
      client: '/import/client-import',
      stock: '/import/stock-import',
      article: '/import/article-import',
      categorie_article: 'import/categorie-article-import'
    }
  }
};
