<footer>
    <div class="footer-content">
        <div class="signature">
            Designed & developed with <i class="icn-heart"></i> by <span>PULSE.</span>
        </div>
        <div class="logo-footer">
            <a href="#">
                <img src="assets/img/togocom.svg" alt="logo togo">
            </a>
        </div>
    </div>
</footer>