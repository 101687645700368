<ng-container [formGroup]="formulaire">
  <div formArrayName="field_group" class="{{cible}}">
    <!-- pour chaque item de field group -->
    <div *ngFor="let group of fieldGroup().controls; let i=index;" class="{{cible}}__item">
      <div *ngIf="formTitle!==null" class="form-title">
        {{formTitle}}
      </div>
      <ng-container [formGroupName]="i">
        <ng-container formArrayName="field_list">
          <div *ngFor="let field of fieldList(i).controls; let j=index;" class="form-group">
            <ng-container [formGroupName]="j">
              <label for="form_field_value">{{field.value.label}} <span *ngIf="field.value.is_mandatory" class="text-danger">
                  *</span>: </label>

              <input *ngIf="field.value.type===fieldTypText" type="text" formControlName="form_field_value"
                name="form_field_value"
                [ngClass]="{'input-error': !field.get('form_field_value').valid && displayError}">

              <input *ngIf="field.value.type===fieldTypNumber" numericInput [fieldData]="{uniqueId: formContract.contract_uuid, fieldName: i+'_'+j}" type="text" formControlName="form_field_value"
                name="form_field_value"
                [ngClass]="{'input-error': !field.get('form_field_value').valid && displayError}" autocomplete="off">

              <input *ngIf="field.value.type===fieldTypDateTime||field.value.type===fieldTypDate" type="date" placeholder="JJ / MM / AAAA"
                formControlName="form_field_value" name="form_field_value">

              <ng-container *ngIf="field.value.type===fieldTypPhone">
                <div class="phone-input"  [ngClass]="{'input-error': !field.get('form_field_value').valid && displayError}">
                  <span class="prefixe">+228</span>
                  <input type="text" numericInput [fieldData]="{uniqueId: formContract.contract_uuid, fieldName: i+'_'+j}"  id="phone" formControlName="form_field_value" name="form_field_value"  autocomplete="off">
                </div>
              </ng-container>

              <ng-container *ngIf="field.value.type===fieldTypSelect">
                <div class="box-select">
                  <ng-select [items]="selectSourceData[field.value.form_field_key]"
                    [bindLabel]="field.value?.source_value_key" [bindValue]="field.value?.source_key"
                    formControlName="form_field_value" name="form_field_value">
                  </ng-select>
                  <span class="arrow"></span>
                </div>
              </ng-container>

              <textarea *ngIf="field.value.type===fieldTypTextArea" appTextareaInput type="text" formControlName="form_field_value"
                name="form_field_value"
                [ngClass]="{'input-error': !field.get('form_field_value').valid && displayError}"></textarea>

              <div class="error"
                *ngIf="field.get('form_field_value').hasError('required')
                && displayError">
                {{ "fieldControl.required" | translate }}
              </div>
              <div class="error"
                *ngIf="field.get('form_field_value').hasError('pattern')
                && displayError">
                {{ "fieldControl.format" | translate }}
              </div>

            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
