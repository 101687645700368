import { Injectable } from '@angular/core';
import {SidebarService as SharedSidebarService} from "../sidebar/sidebar.service";
import {LocalStorageService} from "../local-storage/local-storage.service";
import {storageKey} from "../../../../../config/storage";
import * as CryptoJS from 'crypto-js';
import {HelperService} from "../helper/helper.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HabilitationsService {

  constructor(
    private sharedSidebarService: SharedSidebarService,
    private localStorageService: LocalStorageService,
    private helperService: HelperService,
    private router: Router
  ) { }

  getUrlHabilitations(currentUrl: string) {
    const urlStocked = localStorage.getItem(storageKey.ability.url)

    // Retoruner à la page d'accueil s'il n'y pas l'url dans le localStorage
    if (!urlStocked) this.goToLogin();

    let decrypted: CryptoJS = CryptoJS.AES.decrypt(urlStocked, storageKey.ability.url);
    let decryptedUrl: any = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

    let status: boolean = false;
    for (const element of decryptedUrl) {
      let url = element.url.includes('/:uuid') ? element.url.replace("/:uuid", "") : element.url;
      let newCurrentUrl: string = currentUrl;
      if (element.url.includes(':uuid')) {
        let urlLengthBeforeUuid = element.url.indexOf(':uuid');
        url = element.url.substring(0, urlLengthBeforeUuid);
        newCurrentUrl = currentUrl.substring(0, urlLengthBeforeUuid);
      }

      if (newCurrentUrl.includes('?')) {
        newCurrentUrl = newCurrentUrl.substring(0, newCurrentUrl.indexOf('?'));
      }

      if (url === newCurrentUrl) {
        status = true;
        break
      }
    }
    return status;
  }

  getKeyHabilitations(key: string) {
    const jwt = localStorage.getItem(storageKey.session.jwtKey)
    // Retoruner à la page d'accueil s'il n'y pas le jwt dans le localStorage
    if (!jwt) this.goToLogin();
    const token = this.helperService.decodeJwtToken(jwt);
    let userAbilities = token.sub['abilities'];
    return userAbilities.includes(key);
  }

  /**
   * @todo Ajouter la fonction de déconnextion
   */
  goToLogin() {
    this.localStorageService.clearLocalStorage();
    return this.router.navigate(['login-ldap']);
  }
}
