import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router'
import { SsoRedirectionComponent } from './main/modules/user/pages/ssoRedirection/ssoRedirection.component';
import { PageNotAllowedComponent } from './main/modules/shared/pages/page-not-allowed/page-not-allowed.component';
import { AuthenticationComponent } from './main/modules/user/pages/authentication/authentication.component';
import { url } from './config/url';
import { AuthenticationGuard } from "./main/modules/shared/guards/authentication/authentication.guard";
import {UserNotShopComponent} from "./main/modules/shared/pages/user-not-shop/user-not-shop.component";
import { PageTestComponent } from './main/modules/shared/pages/page-test/page-test.component';
import {PageTestHeightComponent} from "./main/modules/shared/pages/page-test-height/page-test-height.component";
import {IsLoggedGuard} from "./main/modules/shared/guards/is-logged/is-logged.guard";

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./main/modules/pos/pos.module').then((m) => m.PosModule),
      canActivate: [AuthenticationGuard]
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./main/modules/setting/setting.module').then(
        (m) => m.SettingModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./main/modules/product/product.module').then(
        (m) => m.ProductModule
      ),
  },
  {
    path: 'stock',
    loadChildren: () =>
      import('./main/modules/stock/stock.module').then(
        (m) => m.StockModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'sale-management',
    loadChildren: () =>
      import('./main/modules/sale-management/sale-management.module').then(
        (m) => m.SaleManagementModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./main/modules/user/user.module').then((m) => m.UserModule),
      canActivate: [AuthenticationGuard]
  },
  {
    path: 'login-ldap',
    component: AuthenticationComponent,
    canActivate: [IsLoggedGuard]
  },
  {
    path: 'login/success/:token',
    component: SsoRedirectionComponent,
    canActivate: [IsLoggedGuard]
  },
  {
    path: url.authGuard.defaultUrl,
    component: PageNotAllowedComponent,
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./main/modules/customer/customer.module').then(
        (m) => m.CustomerModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./main/modules/paiment-management/payment-management.module').then(m => m.ModulesModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'various',
    loadChildren: () => import('./main/modules/various/various.module').then(v => v.VariousModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'stock-configuration',
    loadChildren: () => import('./main/modules/stock-confiugration/stock-confiugration.module').then(m => m.StockConfigurationModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'mobile-money',
    loadChildren: () =>import('./main/modules/mobile-money/mobile-money.module').then(m => m.MobileMoneyModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'error/user-no-shop',
    component: UserNotShopComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'design-system',
    loadChildren: () => import('./main/modules/design-system/design-system.module').then(m => m.DesignSystemModule)
  },
  {
    path: 'de-sy',
    component: PageTestComponent
  },
  {
    path: 'height',
    component: PageTestHeightComponent
  },
  {
    path: 'import',
    loadChildren: () => import('./main/modules/import/import.module').then(m => m.ImportModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
