import {Component, OnInit} from '@angular/core';
import {Table} from "../../models/table/table.model";
import {TableService} from "../../services/table/dynamic/table.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-page-test-height',
  templateUrl: './page-test-height.component.html',
  styleUrls: ['./page-test-height.component.scss']
})
export class PageTestHeightComponent implements OnInit {

  public table: Table = {
    id: 'test-table',
    header: [
      {
        headId: 'expand',
        value: 'Expand',
        style: {
          align: "align-left"
        },
        sort: {
          isSorted: false,
          tooltip: {
            hasTooltip: false
          }
        },
        colspan: 1,
        rowspan: 1
      },
      {
        headId: 'text',
        value: 'text',
        style: {
          align: "align-left"
        },
        sort: {
          isSorted: true,
          icon: 'icn-info-ellipse',
          tooltip: {
            hasTooltip: true,
            text: 'Trier',
            flow: 'right'
          }
        },
        colspan: 1,
        rowspan: 1
      },
      {
        headId: 'BADGE',
        value: 'BADGE',
        style: {
          align: "align-center"
        },
        sort: {
          isSorted: false,
          icon: 'icn-info-ellipse',
          tooltip: {
            hasTooltip: false,
          }
        },
        colspan: 1,
        rowspan: 1
      },
      {
        headId: 'input',
        value: 'INPUT',
        style: {
          align: "align-center"
        },
        sort: {
          isSorted: false,
          icon: 'icn-info-ellipse',
          tooltip: {
            hasTooltip: false,
          }
        },
        colspan: 1,
        rowspan: 1
      },
      {
        headId: 'input',
        value: 'Switch',
        style: {
          align: "align-center"
        },
        sort: {
          isSorted: false,
          icon: 'icn-info-ellipse',
          tooltip: {
            hasTooltip: false,
          }
        },
        colspan: 1,
        rowspan: 1
      },
      {
        headId: 'button',
        value: 'BUTTON',
        style: {
          align: "align-center"
        },
        sort: {
          isSorted: false,
          icon: 'icn-info-ellipse',
          tooltip: {
            hasTooltip: false,
          }
        },
        colspan: 1,
        rowspan: 1
      }
    ],
    body: {
      bodyId: 'test-table-body',
      line: [
        {
          lineId: '1',
          column: [
            {
              content: [
                {
                  type: "simple",
                  key: "string",
                  value: 'expand',
                  expandable: true,
                  image: 'favicon.ico',
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-left',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "simple",
                  key: "string",
                  value: 'text',
                  expandable: false,
                  tooltip: {
                    hasTooltip: false
                  }
                },
                {
                  type: 'icon',
                  key: "string",
                  icon: 'icn-check',
                  tooltip: {
                    hasTooltip: true,
                    text: 'Valide',
                    flow: 'top'
                  }
                }
              ],
              style: {
                align: 'align-left',
                flex: "inline"
              }
            },
            {
              content: [{
                type: "badge",
                key: "string",
                value: 'IT001',
                badge: {
                  bg: "bg-success"
                },
                tooltip: {
                  hasTooltip: false
                }
              }],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "input",
                  key: "string",
                  input: 'number',
                  disabled: false,
                  value: '',
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "input",
                  key: "string",
                  input: 'switch',
                  disabled: false,
                  value: '',
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "button",
                  key: "string",
                  disabled: false,
                  value: 'button',
                  function: () => { alert('Button') },
                  icon: '',
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            }
          ]
        },
        {
          lineId: '2',
          column: [
            {
              content: [
                {
                  type: "simple",
                  key: "string",
                  value: 'expand',
                  expandable: true,
                  tooltip: {
                    hasTooltip: false
                  }
                },

              ],
              style: {
                align: 'align-left',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "simple",
                  key: "string",
                  value: 'text',
                  expandable: false,
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-left',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "badge",
                  value: 'IT002',
                  key: "string",
                  badge: {
                    bg: "bg-danger"
                  },
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "input",
                  key: "string",
                  input: 'number',
                  disabled: false,
                  value: '',
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "input",
                  key: "string",
                  input: 'switch',
                  disabled: false,
                  value: '',
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            },
            {
              content: [
                {
                  type: "button",
                  key: "string",
                  disabled: false,
                  value: '',
                  function: () => { alert('Button') },
                  icon: 'icn-icn-export',
                  tooltip: {
                    hasTooltip: false
                  }
                }
              ],
              style: {
                align: 'align-center',
                flex: "inline"
              }
            }
          ]
        }
      ]
    }
  }

  constructor(
    private tableService: TableService
  ) {
  }

  ngOnInit(): void {
    this.tableService.setTable(this.table);
  }

  getInputValue() {

  }
}
