<ng-container [formGroup]="form">
  <div class="title-sale-point block-inline-flex has-child open">
    <div class="cell-table black-check">
      <label class="container"><span class="sub-title-sale-point semi_bold">Tous les points de vente ({{ total }})</span>
        <input type="checkbox" name="all_is_checked" formControlName="all_is_checked" (change)="selectAllPos()">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
  <ul class="list-sale-point" formArrayName="fields">
    <li class="item-sale-point" *ngFor="let field of fieldsGroups().controls; let i = index;">
      <ng-container [formGroupName]="i">
        <div class="block-inline-flex has-child">
          <div class="cell-table black-check">
            <label class="container">
              <span class="semi_bold h-18">{{ field.value.name }}</span>
              <input type="checkbox" name="is_checked" formControlName="is_checked" (change)="selectNetWork(i)">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <ul class="list-children-sale-point" *ngIf="hasChiflField" formArrayName="subFields">
          <ng-container *ngFor="let subField of subFieldsGroups(i)?.controls; let j = index;">
            <li class="item-children-sale-point" [formGroupName]="j">
              <div class="block-inline-flex">
                <div class="cell-table black-check">
                  <label class="container">
                    <span>{{subField.value.name}}</span>
                    <input type="checkbox" name="is_checked" formControlName="is_checked" (change)="selectShop(i, j)">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </li>
  </ul>
</ng-container>
