<div class="breadcrumb">
  <a (click)="goTo('/')" class="breadcrumb__item" *ngIf="breadCrumbData.length === 0 else notHomePage">Accueil</a>
  <ng-template #notHomePage>
    <a (click)="goTo('/')" class="breadcrumb__item"><i (click)="goTo('/')" class="icn-home"></i></a>
    <ng-container *ngFor="let breadCrumb of breadCrumbData;let i =index;">
      <a
        class="breadcrumb__item-not-url"
        *ngIf="breadCrumb.url == '' else haveUrl"
      >
        {{breadCrumb.label}}
      </a>
      <ng-template #haveUrl>
        <a
          (click)="goTo(breadCrumb.url)"
          class="breadcrumb__item"
          [ngClass]="{'disable-clic': i===breadCrumbData.length-1}"
        >
        {{breadCrumb.label}}
      </a>
      </ng-template>
    </ng-container>
  </ng-template>
</div>
