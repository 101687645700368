<div class="block-article-unit block-unit">
  <div class="article-unit" [formGroup]="serializationFormGroup">
    <div class="article-unit__item">
      <div class="article-unit__text">
        {{ serializationFormGroup.value['product_serialization_key'] }}
      </div>
    </div>
    <div class="article-unit__item">
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <input
            type="text"
            formControlName="serial_number_start"
            matInput
            [matAutocomplete]="auto"
            (input)="triggerChange(true)"
          >
          <button class="grey-icon" *ngIf="serializationFormGroup.value['serial_number_start']" matSuffix mat-icon-button aria-label="Clear" (click)="clearSerialNumber()">
            <i  class="icn-cross"></i>
          </button>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectValue($event)">
            <mat-option *ngFor="let serial of serialNumberPerUnit | async" [value]="serial">
              {{serial.product_serialization_key}} {{serial.serial_number_start}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="article-unit__item">
      <div class="article-unit-flex">
        <i class="icon" [ngClass]="serializationFormGroup.value['is_serialization_valid'] ? 'icn-check' : 'icn-alert'"></i>
      </div>
    </div>
  </div>
</div>
