<div class="block-list-select-deselect">
  <div class="block-sub-list-select-deselect">
      <div class="item-sub-list-select-deselect">
          <div class="block-int-first">
            <div class="block-list-popin-top">
              <app-table-filter-new [size]="'column-filter'" [filterParams]="itemsFilter" #filter></app-table-filter-new>
            </div>
            <div class="block-list-popin-bottom">
              <div class="block-scroll-list" >
                <ng-container *ngIf="isProduct; else pack">
                  <app-product-card *ngFor="let item of items; index as i"
                                    [product]="item"
                                    [selected]="item.isSelected"
                                    (click)="onSelectAddItem(i)"></app-product-card>
                </ng-container>
                <ng-template #pack>
                  <app-pack-card *ngFor="let pack of items index as i"
                                [product]="pack"
                                [selected]="pack.isSelected"
                                (click)="onSelectAddItem(i)"></app-pack-card>
                </ng-template>
              </div>
          </div>
          </div>
      </div>
      <div class="item-sub-list-select-deselect">
          <div class="block-int-middle">

              <button class="btn btn-add" (click)="onAddButtonClick()">
                   AJOUTER <em _ngcontent-gxr-c81="" class="arrow right"></em>
              </button>
              <button class="btn btn-unadd" (click)="onRemoveButtonClick()">
                  <em _ngcontent-gxr-c81="" class="arrow right"></em>RETIRER
              </button>
          </div>
      </div>
      <div class="item-sub-list-select-deselect">
          <div class="block-int-last">
            <h3 class="title-selected"><span>{{addedItems.length}} </span>{{ addedItemsTitle }}</h3>
            <div class="block-scroll-list">
              <ng-container *ngIf="isProduct; else rightPack">
                <app-product-card *ngFor="let addItem of addedItems; index as j"
                                  [product]="addItem"
                                  [selected]="addItem.isSelected"
                                  (click)="onSelectRemoveItem(j)"></app-product-card>
              </ng-container>
              <ng-template #rightPack>
                <app-pack-card *ngFor="let addItem of addedItems; index as j"
                                [product]="addItem"
                                [selected]="addItem.isSelected"
                                (click)="onSelectRemoveItem(j)"></app-pack-card>
              </ng-template>
            </div>
          </div>
      </div>
  </div>
</div>
