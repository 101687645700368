import { ICheckboxTreeItem } from '../../models/checkbox-tree/checkbox-tree.model';
import { HelperService } from 'src/app/main/modules/shared/services/helper/helper.service';
import { I_FieldData } from '../../models/field-data';
import { IImport, IValidImport } from '../../models/import';
import { imgUrl } from 'src/environments/environment';
import { ApiService } from '../../services/api-service/api.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { I_LeftMenu } from '../../models/left-menu';

import { SidebarService } from '../../services/sidebar/sidebar.service';
import { MiscService } from '../../services/miscellaniscious/misc.service';
import { I_PopInData } from '../../models/popIn';
import { HttpClient } from '@angular/common/http';
import { I_CardData } from '../../models/card';
import {I_BreadCrumbData} from "../../models/breadCrumb";
import {ImportService} from "../../services/import/import.service";
import { importConfig } from '../../models/import-test/import-config';
import { itemInArrayValidator } from '../../validators/item-in-array/item-in-array.validator';;
import {counter, counterMulti} from "../../models/counter-test/counter";
import {HabilitationsService} from "../../services/habilitations/habilitations.service";
import {I_CounterData} from "../../models/counter";
import {I_CounterMulti} from "../../models/counter-multi";
import {ITableFilter} from "../../interfaces/table-filter";
import {quickReportId, tableFilter} from "../../config/constants";
import {quickRepportTest} from "../../models/quick-repport-test/quick-repport.model";
import {TabService} from "../../services/tab/tab.service";
import {BehaviorSubject, concatMap, Observable, Subscription} from "rxjs";
import {FormBuilder, FormGroup} from "@angular/forms";
import { citySourceList } from '../../models/mock.data/city';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { storageKey } from 'src/app/config/storage';
import { abilities } from 'src/app/config/abilities';
import { DialogService } from '../../services/dialog/dialog.service';
import { IProductCard } from '../../models/product/product-card.model';
import {EnvService} from "../../../../../env.service";
import { IButton } from '../../models/i-button';
import { ButtonDefault } from '../../components/miscellaneous/button-default/button-default.model';
@Component({
  selector: 'app-page-test',
  templateUrl: './page-test.component.html',
  styleUrls: ['./page-test.component.scss'],
})
export class PageTestComponent implements OnInit {
  @ViewChild('popInTemplate') template?: ElementRef;
  @ViewChild('improtCategories') improtCategories?: ElementRef;

  productCard: IProductCard = {
    uuid_product: 'ljdfoziejlf',
    code: 'XXXXXX',
    image: '',
    label: 'Product test',
    ttc_price: 1000000,
    product_packs: [{
      uuid_product: 'lkjdfooaejeodiz',
      code: 'XXXXXX',
      image: '',
      label: 'Product test',
      ttc_price: 1000000,
    }]
  };

  appbutton = "<app-button-default label=\"flat\" view=\"flat\" [disabled]=\"true\"></app-button-default>";

  dataButton: ButtonDefault = {
    view: 'outlined',
    size: 'lg',
  }

  actionB() {
    console.log("click..........!");

  }

  testAction() {
    console.log("tst miet")
  }

  checkBoxTreeUniqueId: ICheckboxTreeItem[]=[
    {
      uuid: '123-123-1',
      label: 'Réseau 1',
      isChecked: false,
      items: [
        {
          uuid: '123-123-1',
          label: 'Shop 1',
          isChecked: true,
        },
        {
          uuid: '123-123-1',
          label: 'Shop 2',
          isChecked: false,
        }
      ]
    }
  ];

  card: I_CardData={
    icon: 'icn-shop',
    description: 'Shop existants',
    data: 'data card'
  }

  fieldData ={
    fieldName: 'xxx',
    fieldValue:'-1',
    uniqueId:'xxx'
  } as I_FieldData;
  importConfig: IImport =importConfig;
  counter: I_CounterData = counter;

  imgUrl = imgUrl + 'shoplist.svg';

  upload_file_1: string="";
  upload_file_2: string="";

  redirect: boolean =false;
  nextUrl: string;
  heroForm: FormGroup;

  categoryForm: FormGroup;
  subscribion = new Subscription();

  tabSwitchToConfirm: any;

  formulaire: FormGroup;

  tabsUniqueId ="tabsUniqueId";
  confirmTabSwtichId ="confirmTabSwtichId";

  cityList: any =citySourceList;

  public abilities: any = abilities;

  public multiCounter: I_CounterMulti = counterMulti

  button1: IButton = {
    buttonText: 'Bouton',
    variant: 'yellow',
  };
  button2: IButton = {
    buttonText: 'Bouton',
    tooltip: 'Tooltip exemple',
    buttonIcon: ['icn-add plus-icon'],
    variant: 'yellow',
  };
  button3: IButton = {
    variant: 'yellow',
    buttonIcon: ['icn-add plus-icon'],
  };
  buttonDesabled: IButton = {
    buttonText: 'Bouton',
    variant: 'yellow button-disabled',
  };
  buttonDefault1: IButton = {
    buttonText: 'Bouton',
  };
  buttonDefault2: IButton = {
    buttonText: 'Bouton',
    tooltip: 'Tooltip exemple',
    buttonIcon: ['icn-add plus-icon'],
  };
  buttonDefault3: IButton = {
    buttonIcon: ['icn-add plus-icon'],
  };
  buttonLarge: IButton = {
    buttonText: 'Bouton',
    variant: 'yellow button-disabled',
  };
  buttonSmall: IButton = {
    buttonText: 'Bouton',
    tooltip: 'Tooltip exemple',
    buttonIcon: ['icn-add plus-icon'],
  };
  buttonLite: IButton = {
    buttonIcon: ['icn-add plus-icon'],
  };
  constructor(
    private sidebarService: SidebarService,
    private apiService: ApiService,
    private miscService: MiscService,
    private http: HttpClient,
    private importService: ImportService,
    private dialogService: DialogService,
    private tabService: TabService,
    private fb: FormBuilder,
    public habilitationsService: HabilitationsService,
    private helperService: HelperService,
    private localStorageService: LocalStorageService,
    private env: EnvService
  ) {
    this.formulaire =this.fb.group({
      'country': ['', [itemInArrayValidator(this.cityList.city, 'id_city')]] //
    });
  }

  shop: any = {
    nom: 'Nom du shop lorem ipsum dolore',
  };
  popInData: I_PopInData = {
    title: 'pop in titre',
    desc: 'Confirmez-vous les informations suivantes pour la création de votre shop?',
    icon: 'assets/img/popup.svg',
    action: [
      {
        text: 'Annuler',
        class: 'lite',
        action: () => {
          this.hidePopIn();
          console.log('cancel');
        },
      },
      {
        text: 'Confirmer',
        class: 'yellow',
        action: () => {
          this.confirm();
          this.hidePopIn();
          console.log('confirm');
        },
      },
    ],
  };

  breadCrumb: I_BreadCrumbData[] = [];

  ngOnInit(): void {

    this.miscService.updateCheckBoxTreeItems('checkboxTreeUniqueId',[
      {
        label: 'Réseau 1',
        uuid: '000-000-000',
        isChecked: false,
        items: [
          {
            label: 'Shop 1',
            uuid: '111-111-111',
            isChecked: true,
          },
          {
            label: 'Shop 2',
            uuid: '2222-2222-2222',
            isChecked: false,
          }
        ]
      }
    ]);

    this.updateBC()
/*    this.action.innerHTML = "<label for='file'>Choose File</label> <input type='file' id='file'>"
    console.log(this.action);*/
    // X-Key: company key
    // Frame-Target: "in"
    // accept: "json"
    // this.apiService.doGet('http://10.163.13.80:301/get/companies-user', {}, {'X-Key':'---', b:'***'}).subscribe(resp=>{console.log(resp)});

    this.miscService.checkBoxTreeData$.subscribe(x=>console.log('checkBoxTreeData', x))
    let user_data =this.helperService.decodeJwtToken(this.localStorageService.getLocalStorage(storageKey.session.jwtKey));
    this.subscribion.add(
      this.formulaire.statusChanges.subscribe((data=>{
        console.log( data );
      }))
    );

    this.subscribion.add(
      this.tabService.confirmSelectTab$.subscribe((data: any)=>{
        if( data?.uniqueId === this.tabsUniqueId ){
          this.tabSwitchToConfirm =data;
          this.dialogService.showDialog(this.confirmTabSwtichId);
        }
      })
    );

    this.miscService.showToast({
      desc: 'Import Non Valide',
      icon: 'alert',
      title: 'Fichier Not valid',
      type: 'danger'
    });

    this.heroForm = this.fb.group({
      heroId: 'particulier',
      agree: null
    });

    this.updateBC()

    this.miscService.leavePageEvent$.subscribe((url)=>{
      if( url!=null ){
        this.nextUrl=url;
        this.redirect =true;
        this.showDialog('test');
      }

    });

    this.quickRepport();

    this.upload_file_1 =this.miscService.generateUniqueId();
    this.upload_file_2 =this.miscService.generateUniqueId();
    this.subscribion.add(
      this.miscService.fileValid$.subscribe((importValid: IValidImport)=>{

        if(importValid!=null){

          let noError =this.miscService.checkError(importValid, ['fileContent']);

          if(noError){
            if( importValid.uniqueId ==this.upload_file_1 ){
              this.miscService.countLineInCsv(importValid.fileContent);
            }

          }else{
            this.miscService.showToast({
              desc: 'Import Non Valide',
              icon: 'alert',
              title: 'Fichier Not valid',
              type: 'danger'
            });
          }
        }
      })
    );

    this.subscribion.add(
      this.miscService.csvLineCount$.subscribe( (lineCount)=>{
        if( lineCount >-1 ){

          this.dialogService.showDialog('test');

        }
      } )
    );


    this.subscribion.add(
      this.miscService.encodedFile$.subscribe((content)=>{
      })
    );

    let menu: I_LeftMenu = {
      menu_item: [
        {
          label: 'menu 1',
          url: '/url',
          active: true,
        },
      ],
    };
    this.sidebarService.updateLeftMenu(menu);

    this.miscService.processpendingToast();
    this.sidebarService.updateLeftMenu({
      menu_item: [
        {
          label: 'test',
          url: 'test',
          active: false,
        },
      ],
    });
  }

  goTo(url:string){
    this.miscService.goTo(url);
  }

  ngAfterViewInit() {
    this.miscService.initPopIn(this.popInData, this.template);
  }

  showPopIn() {
    this.miscService.showPopIn();
  }

  hidePopIn() {
    this.miscService.hidePopIn();
  }

  confirm() {
    console.log('confirm');
  }

  fetchMultipleData() {
    this.http
      .get('https://api.github.com/users/thisiszoaib')
      .subscribe((res) => {
      });

    this.http
      .get('https://api.github.com/users/thisiszoaib')
      .pipe(concatMap(() => this.http.get('https://api.github.com/users')))
      .subscribe((res) => {
      });
  }
  manaoZavatra(){
    // this.dialogService.switchDialogCible( 'test', this.miscService.generateUniqueId() );
    this.dialogService.switchDialogSize( 'test', this.miscService.generateUniqueId() );
  }
  showToast() {
    // this.miscService.showToast({
    //   type: 'danger',
    //   icon: 'alert',
    //   desc: `Shop Nom du <span>Shop lorem ipsum dolor 0001</span> créé le 27/11/2021
    //   par <span>Kouadjo Lawson</span>`,
    //   title: 'titre 1',
    // });
    this.miscService.addToPendingToast({
      type: 'success',
      icon: 'check',
      desc: `Shop Nom du <span>Shop lorem ipsum dolor 0001</span> créé le 27/11/2021
      par <span>Kouadjo Lawson</span>`,
      title: 'titre 1',
    });
    this.miscService.addToPendingToast({
      type: 'danger',
      icon: 'alert',
      desc: `Shop Nom du <span>Shop lorem ipsum dolor 0001</span> créé le 27/11/2021
      par <span>Kouadjo Lawson</span>`,
      title: 'titre 1',
    });
    this.miscService.addToPendingToast({
      type: 'danger',
      icon: 'alert',
      desc: `Shop Nom du <span>Shop lorem ipsum dolor 0001</span> créé le 27/11/2021
      par <span>Kouadjo Lawson</span>`,
      title: 'titre 1',
    });
    this.miscService.processpendingToast();
  }

  updateBC() {
    this.breadCrumb = [
      {
        url: 'setting',
        label: 'setting'
      },
      {
        url: '',
        label: 'Test A',
      },
      {
        url: 'test',
        label: 'Test',
      },
    ];

    this.miscService.addBreadCrumb(this.breadCrumb);
    this.miscService.updateConfirmLeavePage(true);
  }

  getNotification(file: File) {
    let url = ''
    this.subscribion.add(
      this.importService.postFile(url, file).subscribe( (responses) => {
        this.importService.importResponseData(responses.data.categories);
      })
    )
  }

  showDialog(id: string){
    this.dialogService.showDialog(id);
  }

  quickRepports = quickRepportTest;
  quickRepport() {
    let quickRepport = this.quickRepports.find( (qr) => qr.id == quickReportId.stock);
    quickRepport.value = 1600
  }

  closeAndRedirect(){
    if( this.redirect ===true ){
      this.miscService.goTo(this.nextUrl);
    }
  }
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items$: Observable<any> = this.obsArray.asObservable();
  currentPage: number = 0;
  pageSize: number = 10;


  cancelSwtichTab(){
    this.dialogService.hideDialog(this.confirmTabSwtichId);
  }

  confirmSwitchTab(){
    this.tabService.switchToNexTab();
    this.dialogService.hideDialog(this.confirmTabSwtichId);
  }

  public tableFilter: ITableFilter = {
    id: 'search-user',
    title: "Liste des utilisateurs",
    fields: [
      {
        key: tableFilter.key.keyword,
        label: "Mot clé",
        type: 'input',
      },
      {
        key: tableFilter.key.roles,
        label: 'PROFIL',
        type: "select",
        source: {
          form_field_source_id: 1,
          source_url: './assets/data/roles.json',
          source_key: 'role_key',
          source_value_key: 'role_name',
        }
      },
      {
        key: tableFilter.key.shop,
        label: "SHOP DE RATTACHEMENT",
        type: 'input',
      }
    ]
  }

  cities = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
    {id: 3, name: 'Pavilnys', disabled: true},
    {id: 4, name: 'Pabradė'},
    {id: 5, name: 'Klaipėda'}
  ];selectedCity: any;

}
