import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-test-font-icon',
  templateUrl: './page-test-font-icon.component.html',
  styleUrls: ['./page-test-font-icon.component.scss']
})
export class PageTestFontIconComponent implements OnInit {

  listeIcones: any =[];
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get('http://localhost:4200/assets/data/icones.json')
    .subscribe(data => {
      this.listeIcones =data;
    });
  }

}
