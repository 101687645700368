
<div class="user-detail-block-top">
  <div class="page-title">
    <h1>{{text}} <span *ngIf="textLite">{{textLite}}</span></h1>
  </div>
  
  <ng-container>
    <div *ngIf="date" class="return-link date-day">
      <span class="return-text"> Date du jour : {{date}}</span>
    </div>
  </ng-container >

  <ng-container *ngIf="link!==undefined">
    <a *ngIf="link?.confirm else leave" (click)="goTo(link?.url)" class="return-link">
      <span class="link--intern">
        <span class="icn-return"></span>
        <span class="return-text"> {{ link.label | translate }}</span>
      </span>
    </a>
    <ng-template #leave>
      <a *ngIf="link!==undefined" href="{{link?.url}}" class="return-link">
        <span class="link--intern">
          <span class="icn-return"></span>
          <span class="return-text"> {{ link.label | translate }}</span>
        </span>
      </a>
    </ng-template>
  </ng-container >

</div>
