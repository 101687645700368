import { Component } from '@angular/core';

@Component({
  selector: 'app-required-indicator',
  templateUrl: './required-indicator.component.html',
  styleUrls: ['./required-indicator.component.scss']
})
export class RequiredIndicatorComponent {

}
