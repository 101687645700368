import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ICheckbox} from "../../models/i-checkbox/i-checkbox";

@Injectable({
  providedIn: 'root'
})
export class CheckboxService {
  public boxArray$: BehaviorSubject<{ id: string, value: ICheckbox[] }> = new BehaviorSubject<{ id: string, value: ICheckbox[] }>(null);

  constructor() { }

  updateBoxArray(box: { id: string, value: ICheckbox[] }) {
    this.boxArray$.next(box);
  }
}
