
import { InfiniteScrollService } from './../../services/infinite-scroll/infinite-scroll.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-infinite-scroll-test',
  templateUrl: './page-infinite-scroll-test.component.html',
  styleUrls: ['./page-infinite-scroll-test.component.scss']
})
export class PageInfiniteScrollTestComponent implements OnInit {

  tableData: string[] =[];
  scrollStatus: string;

  susbscription: Subscription =new Subscription();

  constructor(private infiniteScrollService: InfiniteScrollService) { }

  ngOnInit(): void {
    for( let i=0;i<10;i++ ){
      this.tableData.push(`x-lignei-${i}`);
    }

    this.susbscription.add(
      this.infiniteScrollService.scrollStatus$
        .subscribe((status)=>{
          console.log(status);
          this.scrollStatus =status;
        })
    );

    this.infiniteScrollService.scrollPagination$
    .subscribe((data)=>{
      if(data!=null && data?.uniqueId =='xxx'){
        this.loadMore();
      }
    })
  }

  loadMore(): void{
    let length =this.tableData.length;

    for( let i=length;i<length+10;i++ ){
      this.tableData.push(`x-lignei-${i}`);
    }
  }

}
