import {I_QuickRepportData} from "../quick-repport.model";
import {quickReportId} from "../../config/constants";

export const quickRepportTest: I_QuickRepportData[] = [
  {
    id: quickReportId.stock,
    box: '',
    value: 0,
    label: 'Mes artciles en stock'
  },
  {
    id: quickReportId.restock,
    box: '',
    value: 0,
    label: 'Articles en réassort'
  }
];
