<p>
  page-test-formulaire-generique works!
</p>
test parametrage contract formIsDirty: {{ formIsDirty?'dirty':'prestine' }}
<app-generic-form uniqueId="xxx" formTitle="Paramétrage Client" [tableHead]="['label', 'actif', 'obligatoire']" ></app-generic-form>
<button (click)="resetContractForm()">Réinitialiser</button>

test form contract
<app-contract-compliant-form [uniqueId]="uniqueId" cible="xeform-list" formTitle="xixi"></app-contract-compliant-form>
<button (click)="validateForm()">Valider</button>

<button (click)="resetContractCompliantForm()">Réinitialiser</button>
