<form [formGroup]="formulaire">
  <div class="form" formArrayName="field_list" [ngClass]="{'form--pro': cible == 'pro'}">
    <div class="form__intro">
      {{ formTitle }}
    </div>
    <div class="form__content">
      <div class="table-content">
        <table>
          <thead>
            <tr>
              <th *ngFor="let head of tableHead">{{head}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let field of fieldList().controls; let i=index;">
              <ng-container [formGroupName]="i">
                <td>{{ field.value.form_field_label }}</td>
                <td *ngIf="field.value.is_editable ===0">
                  <div class="form-input_check--generic">
                    <input type="checkbox"  class="is_active_{{i}}" checked disabled >
                    <label for="is_active_{{i}}"></label>
                  </div>
                </td>
                <td *ngIf="field.value.is_editable ===1">
                  <div class="form-input_check--generic">
                    <input type="checkbox"  class="is_active_{{i}}" name="is_active" formControlName="is_active" (change)="checkIfIsMandatory(i)">
                    <label for="is_active_{{i}}"></label>
                  </div>
                </td>
                <td>
                  
                  <div class="form-input_check--generic">
                    <input
                      type="checkbox" name="is_mandatory"
                      formControlName="is_mandatory" class="is_mandatory_{{i}}"
                      [ngClass]="{'disabled': field.value.is_active===false||field.value.is_active===0||field.value.is_locked===true||field.value.is_locked===1 }">
                    <label for="is_mandatory_{{i}}"></label>
                  </div>
                  </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
