import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Language} from "../../../../modules/shared/models/language";
import {
  InternationalizationService
} from "../../../../modules/shared/services/internationalization/internationalization.service";
import {defaultLanguage} from "../../../../../../environments/environment";
import {LocalStorageService} from "../../../../modules/shared/services/local-storage/local-storage.service";
import { I_ToastData } from 'src/app/main/modules/shared/models/toast';
import { Subscription } from 'rxjs';
import { MiscService } from 'src/app/main/modules/shared/services/miscellaniscious/misc.service';
import {cashRegisterOpened} from "../../../../modules/shared/config/constants";
import {AuthentificationService} from "../../../../modules/user/services/authentification/authentification.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public toastDataList: I_ToastData[] = [];
  @Input()
  logoText = '';
  currentLanguage = defaultLanguage;
  languages!: Language[];
  subscription: Subscription =new Subscription();
  isSettingDisplayed = false;

  constructor(
    private internationalization: InternationalizationService,
    private localStorageService: LocalStorageService,
    private miscService: MiscService,
    private authentificationService: AuthentificationService,
    ) { }

  ngOnInit(): void {
    this.getlanguages();
    this.getCurrentLanguage();
    this.getNotification();
  }

  onAvatarClicked(): void {
    this.isSettingDisplayed = !this.isSettingDisplayed;
  }

  getCurrentLanguage() {
    this.currentLanguage = this.localStorageService.getLocalStorage('language')
  }

  getlanguages() {
    this.languages = this.internationalization.getlanguages();
  }

  updatelanguage(code: any) {
    let language = code.target.value;
    this.internationalization.switchLanguage(language)
  }

  getNotification() {
    this.subscription.add(
      this.miscService.ToastDataList$.subscribe((data: I_ToastData[]) => {
        if(data!=null && data.length!=0){
          this.toastDataList = data;
        }
      })
    );
  }

  @ViewChild('avatar') avatar : ElementRef
  @HostListener('window:click', ['$event.target'])
  clickOut(el){
    if (this.avatar.nativeElement.querySelector('[data-avatar]')!=el) {
      this.isSettingDisplayed = false;
    }
  }

  //TODO: A effacer lors de l'implémentation de l'authentification
  signOut() {
    if (this.localStorageService.getLocalStorage(cashRegisterOpened)) {
      this.showToast('warning', 'alert', 'Veuillez d\'abord fermer votre caisse.', 'Déconnexion');
    } else {
      this.authentificationService.fakeLogOut();
    }
  }
  showToast(type?, icon?, description?, titre?) {
    this.miscService.showToast({
      type: type,
      icon: icon,
      desc: description,
      title: titre,
    });
  }

}
