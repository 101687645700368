import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductService } from 'src/app/main/modules/product/services/product/product.service';
import { tableFilter } from '../../../config/constants';
import { ITableFilterNew } from '../../../interfaces/i-table-filter-new.interface';
import { IProductCard } from '../../../models/product/product-card.model';
import { TableFilterService } from '../../../services/table-filter/table-filter.service';
import { TableFilterNewComponent } from '../table-filter-new/table-filter-new.component';

@Component({
  selector: 'app-add-remove-items',
  templateUrl: './add-remove-items.component.html',
  styleUrls: ['./add-remove-items.component.scss']
})
export class AddRemoveItemsComponent implements OnInit {

  @Input() addedItemsTitle: string;
  @Output() selectedData = new EventEmitter<IProductCard[]>();
  @ViewChild('filter') filter: TableFilterNewComponent;

  items: IProductCard[] = [];
  isProduct: boolean;
  selectedAddItems:IProductCard[] = [];
  selectedRemoveItem: IProductCard[]= [];
  addedItems: IProductCard[] = []
  itemsFilter: ITableFilterNew = {
    id: '',
    title: '',
    fields: [{
      label: 'MOT CLÉ',
      placeholder:'Nom de l\'article',
      type: 'input',
      key: tableFilter.key.keyword
    }]
  };

  subscription: Subscription = new Subscription();

  constructor(
    private tableFilterService: TableFilterService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.productService.isProductDisplay$.subscribe(isProduct => {
      this.isProduct = isProduct;
      this.filter?.resetFilter();
    }));
    this.subscription.add(this.productService.seachResult$.subscribe(data => {
      this.items = data
    }));

    this.subscription.add(this.productService.addedProduct$.subscribe(data => {
      this.addedItems = data;
    }));

    this.tableFilterService.filterNew$.next(this.itemsFilter);

  }

  onAddButtonClick(): void {
    const added = [...this.addedItems, ...this.selectedAddItems];
    added.forEach(item => {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
      item.isSelected = false;
    });

    this.selectedAddItems = [];
    this.productService.updateSelectedProduct(added);
  }

  onRemoveButtonClick(): void {
    this.selectedRemoveItem.forEach(elem => {
      elem.isSelected = false;
      const index = this.addedItems.indexOf(elem);
      this.addedItems.splice(index, 1);
      this.items.push(elem);
    });
    this.selectedRemoveItem = [];
    this.productService.updateSelectedProduct(this.addedItems);
  }

  onSelectAddItem(index: number): void {
    const product = this.items[index];
    if (!product.isAvailable) {
      return;
    }
    this.items[index].isSelected = !this.items[index].isSelected;
    if (this.items[index].isSelected) {
      this.selectedAddItems.push(this.items[index]);
    } else {
      const indexElemToRemove = this.selectedAddItems.indexOf(this.items[index]);
      this.selectedAddItems.splice(indexElemToRemove, 1);
    }
  }

  onSelectRemoveItem(index: number): void {
    this.addedItems[index].isSelected = !this.addedItems[index].isSelected;
    if (this.addedItems[index].isSelected) {
      this.selectedRemoveItem.push(this.addedItems[index]);
    } else {
      const indexElemToRemove = this.selectedRemoveItem.indexOf(this.addedItems[index]);
      this.selectedRemoveItem.splice(indexElemToRemove, 1);
    }
  }

}

