<div>Infinite scroll <span>Scroll Status: <u>{{scrollStatus}}</u></span></div>
<app-infinite-scroll-table uniqueId="xxx">
  <table>
    <tbody>
      <tr *ngFor="let ligne of tableData">
        <td>{{ligne}}</td>
      </tr>
    </tbody>
  </table>
</app-infinite-scroll-table>

<div>

</div>
