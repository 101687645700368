import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiCompanyService = '';
  public apiShopService = '';
  public apiStaticService = '';
  public apiUserService = '';
  public apiProductService = '';
  public apiStockService = '';
  public apiCatalogService = '';
  public apiContractService = '';
  public apiPaymentService = '';
  public apiCartService = '';
  public apiOrderService = '';
  public apiBillingService = '';
  public apiRightService = '';
  public apiRemoteService = '';
  public apiDocumentService = '';
  public apiAuthService = "";
  public defaultLanguage = 'fr';
  public imgUrl = 'assets/img/';

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {
  }
}
