import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IButton} from "../../models/i-button";

@Injectable({
  providedIn: 'root'
})
export class ButtonService {
  public button$: BehaviorSubject<IButton> = new BehaviorSubject<IButton>(null);

  constructor() { }

  setButtonValue = (value: IButton) => {
    this.button$.next(value);
  }
}
