import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-demo-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent {
  @Input()copyStatus: string;

  copyToClipboard(content: string): void {
   // Création textarea dans document HTML, (zone invisible temporaire permettant la copie du texte)
   const el = document.createElement('textarea');

   // Ici la valeur est égale au parametre content
   el.value = content;

   // Ajouter textarea dans document HTML
   document.body.appendChild(el);

   // Selectionner le contenu du textarea, permettant la copie dans le presse-papier
   el.select();

   // Copier la valeur selectionner dans le presse-papier
   document.execCommand('copy');

   // Le retirer depuis document HTML après copie terminer
   document.body.removeChild(el);

  this.copyStatus = 'Copié dans le presse-papiers';

  // Affiche la boîte de message
  const messageBox = document.querySelector('.message-box') as HTMLElement;
  messageBox.style.display = 'block';

  // Réinitialisez copyStatus après 1 seconde et cachez la boîte de message
  setTimeout(() => {
    messageBox.style.display = 'none';
  }, 1000);
  }
}
