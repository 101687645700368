import { LocalStorageService } from '../../modules/shared/services/local-storage/local-storage.service';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, ElementRef, Injector } from '@angular/core';
import { InternationalizationService } from '../../modules/shared/services/internationalization/internationalization.service';
import { TranslateService } from '@ngx-translate/core';
import { defaultLanguage } from '../../../../environments/environment';
import { LoaderService } from '../../modules/shared/services/loader/loader.service';
import { MiscService } from '../../modules/shared/services/miscellaniscious/misc.service';
import { Subscription } from 'rxjs';
import { I_ToastData } from '../../modules/shared/models/toast';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SidebarService } from '../../modules/shared/services/sidebar/sidebar.service';
import { sideBarMenu } from 'src/app/config/sidebar-menu';
import { SidebarService as LayoutSidebarService } from './services/sidebar/sidebar.service';
import {I_SidebarMenu, I_SidebarMenu_Item} from '../../modules/shared/models/sidebar-menu';
import {Url} from "../../modules/shared/models/url/url";
import {HelperService} from "../../modules/shared/services/helper/helper.service";
import * as pos from './services/sidebar/sidebar.service';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss'],
})
export class PosLayoutComponent implements OnInit, OnDestroy {
  /**
   * @description dependence
   * @private
   */
  private internationalization: InternationalizationService;
  private translate: TranslateService;
  private localStorageService: LocalStorageService;
  private layoutSidebarService: LayoutSidebarService;
  private helperService: HelperService;

  public loading$ = this.loaderService.loading$;
  private subscription: Subscription = new Subscription();
  public toastDataList: I_ToastData[] = [];
  private userSidebar: I_SidebarMenu[] = []
  public urls: Url[] = [];

  @ViewChild('popInTemplateErrorFile') templateErrorFile?: ElementRef;

  constructor(
    private injector: Injector,
    public loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private miscService: MiscService,
    router: Router,
    private sidebarService: SidebarService,
    private posLayoutSideBarService: pos.SidebarService
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        // Init Code
      }

      if (e instanceof NavigationEnd) {
        // Exit Code
        this.miscService.processpendingToast();
      }
    });
    this.setConstructor();
  }

  ngOnInit(): void {
    this.getDefaultLanguage();
    this.subscription.add(
      this.miscService.ToastDataList$.subscribe((data: I_ToastData[]) => {
        if(data!=null && data.length!=0){
          this.toastDataList = data;
        }
      })
    );

    this.miscService.processpendingToast();
    this.sidebarService.updateSidebarMenu(sideBarMenu);
    this.getUserAbilities();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  setConstructor() {
    this.internationalization = this.injector.get<InternationalizationService>(InternationalizationService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.localStorageService = this.injector.get<LocalStorageService>(LocalStorageService);
    this.changeDetector = this.injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this.miscService = this.injector.get<MiscService>(MiscService);
    this.sidebarService = this.injector.get<SidebarService>(SidebarService);
    this.layoutSidebarService = this.injector.get<LayoutSidebarService>(LayoutSidebarService);
    this.helperService = this.injector.get<HelperService>(HelperService);
  }

  getDefaultLanguage() {
    if (this.localStorageService.getLocalStorage('language')) {
      let language = this.localStorageService.getLocalStorage('language');
      this.internationalization.switchLanguage(language);
    } else {
      this.localStorageService.setLocalStorage('language', defaultLanguage);
      this.translate.use(defaultLanguage);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getUserAbilities() {
    const jwt = localStorage.getItem('jwt')
    const token = this.helperService.decodeJwtToken(jwt);
    let userAbilities = token.sub['abilities'];

    this.userSidebar.push(sideBarMenu[0]);
    this.showUserMenu(sideBarMenu, userAbilities);
  }

  showUserMenu(sideBarMenu: I_SidebarMenu[], userAbilities: string[]) {
    sideBarMenu.forEach((sidebar, index) => {
      if (index !=0) {
        let sidebarMenus: I_SidebarMenu = {};
        let sidebarMenuItem: I_SidebarMenu_Item[] = [];
        sidebar.menuItem.forEach((values) => {
          if (values.action) {
            this.posLayoutSideBarService.getMenuAction(values.action, userAbilities, this.urls);
          }
          if (userAbilities.includes(values.ability_group_key) && values.subMenu == undefined) {
            let url: Url = {
              ability_group_key: values.ability_group_key,
              url: values.url
            }
            this.layoutSidebarService.setUrl(this.urls, url);
            sidebarMenuItem.push(values);
          }
          this.getAbility(userAbilities, values, sidebarMenuItem);
        });

        if (sidebarMenuItem.length > 0) {
          sidebarMenus.groupName = sidebar.groupName;
          sidebarMenus.menuItem = sidebarMenuItem;
          this.userSidebar.push(sidebarMenus);
        }
      }
    });
    this.sidebarService.updateSidebarMenu(this.userSidebar);
  }

  getAbility(userAbilities: string[], values: I_SidebarMenu_Item, sidebarMenuItem: I_SidebarMenu_Item[]) {
    if (values.subMenu) {
      let menuItemSubMenuGroup: I_SidebarMenu[] = [];
      values.subMenu.forEach((subMenuGroup) => {
        let sidebarMenuItemSubMenus: I_SidebarMenu_Item[] = [];
        this.layoutSidebarService.getSubMenuItem(subMenuGroup.menuItem, sidebarMenuItemSubMenus, userAbilities, this.urls);
        if (sidebarMenuItemSubMenus.length > 0) {
          menuItemSubMenuGroup.push({
            groupName: subMenuGroup.groupName,
            menuItem: sidebarMenuItemSubMenus
          });
        }
      });

      if (menuItemSubMenuGroup.length > 0) {
        sidebarMenuItem.push({
          ability_group_key: values.ability_group_key,
          icon: values.icon,
          label: values.label,
          subMenu: menuItemSubMenuGroup
        });
      }
    }
  }
}
