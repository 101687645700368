import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRow } from '../../shared/interfaces/tables/i-row';
import { I_ApiResponse } from '../../shared/models/api-response';
import { ApiService } from '../../shared/services/api-service/api.service';
import { Inventory } from '../models/inventory.model';
import { InventoryProductDto } from '../models/inventoryProductDto.model';
import {EnvService} from "../../../../env.service";
import {HelperService} from "../../shared/services/helper/helper.service";
import { TableService } from '../../shared/services/table/table.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private stockService: string = this.env.apiStockService
  constructor(
    private apiService: ApiService,
    private env: EnvService,
    private helperService: HelperService,
    private tableService: TableService,
  ) { }

  createInventory(): Observable<I_ApiResponse> {
    return this.apiService.doPost(`${this.stockService}/inventory/create`);
  }

  getInventoryStatistics(): Observable<I_ApiResponse> {
    const url = `${this.stockService}/inventory/statistics`;
    return this.apiService.doGet(url);
  }

  getInventories(paginate?: number, page?: number, shop?: string, type?: string, status?: string, query?: string): Observable<I_ApiResponse> {
    const url = `${this.stockService}/inventories`;
    const params = {};
    if (paginate) {
      params['paginate'] = paginate;
      params['page'] = page;
    }
    if (status) {
      params['status'] = status;
    }
    if (shop) {
      params['shop'] = shop;
    }
    if (type) {
      params['type'] = type;
    }
    if (query) {
      params['q'] = query;
    }
    return this.apiService.doGet(url, params);
  }

  getInventory(inventoryUuid: string, page: number = 1, query?: string, category_uuid?: string, show_today_movement?: number): Observable<I_ApiResponse>  {
    let url = `${this.stockService}/inventory/${inventoryUuid}`;
    const params = {
      paginate: 1,
      page
    };

    if (query) {
      params['q'] = query;
    }
    if (category_uuid && category_uuid !== 'all') {
      params['category_uuid'] = category_uuid
    }
    if (show_today_movement) {
      params['show_today_movement'] = show_today_movement;
    }
    return this.apiService.doGet(url, params);
  }

  saveInventory(inventoryUuid: string, products: InventoryProductDto[]): Observable<I_ApiResponse> {
    const url = `${this.stockService}/inventory/${inventoryUuid}/save`;
    return this.apiService.doPut(url, { products });
  }

  submitInventory(inventoryUuid: string, products: InventoryProductDto[]): Observable<I_ApiResponse> {
    const url = `${this.stockService}/inventory/${inventoryUuid}/submit`;
    return this.apiService.doPut(url, { products });
  }

  getInventoryStatus(): Observable<I_ApiResponse> {
    const url = `${this.stockService}/inventory/status`;
    return this.apiService.doGet(url);
  }

  getInventoryType(): Observable<I_ApiResponse> {
    const url = `${this.stockService}/inventory/type`;
    return this.apiService.doGet(url);
  }

  createInventoryEditableRows(inventory: Inventory): IRow[] {
    const rows: IRow[] = [];
    this.tableService.disableTableInputStatus(inventory.inventory_status.inventory_status_key === 'FINISHED')
    let typeClass: string;
    inventory.products.data.forEach(e => {
      if (+e.expected_quantity === +e.product_quantity) {
        typeClass = 'success';
      } else if (+e.expected_quantity > +e.product_quantity) {
        typeClass = 'danger down';
      } else {
        typeClass = 'danger up'
      }
      rows.push({
        id: e.uuid_product,
        is_expandable: false,
        rowValue: [
          {
            id: e.uuid_product,
            expand: false,
            key: 'article',
            type: 'simple',
            value: {
              value: e.label
            },
          },
          {
            id: e.uuid_product,
            expand: false,
            key: 'categorie',
            type: 'simple',
            value: {
              value: e.category.label
            },
          },
          {
            id: e.uuid_product,
            expand: false,
            key: 'code_article',
            type: 'simple',
            value: {
              value: e.code
            },
          },
          {
            id: e.uuid_product,
            expand: false,
            key: 'snapshot_quantity',
            type: 'simple',
            value: {
              value: this.helperService.formatNumber(e.expected_quantity)
            },
          },
          {
            id: e.uuid_product,
            expand: false,
            key: 'counted_quantity',
            type: 'input',
            input: 'number',
            value: {
              value: e.product_quantity
            },
          },
          {
            id: e.uuid_product,
            expand: false,
            key: 'gap',
            type: 'difference',
            value: {
              value: this.helperService.formatNumber(Math.abs(e.quantity_difference))
            },
            class: typeClass
          },{
            id: e.uuid_product,
            expand: false,
            key: 'comment',
            type: 'input',
            input: 'textarea',
            inputMaxLength: 512,
            value: {
              value: e.comment
            },
          },
        ]
      })
    });

    return rows;
  }

  exportDocument(uuid: string) {
    const url = `${this.stockService}/inventory/${uuid}/generate-document`;
    return this.apiService.doGet(url);
  }
}
