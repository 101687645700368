export const quickReportId = {
  stock:'id_stock',
  restock: 'id_restock',
  stockOut: 'id_stock_out'
};

export const responseStatus = {
  success:200,
  empty:204,
  error:500
};

export const cashRegisterOpened = 'cashRegisterOpened';

export const keepableForNumericInput =["ArrowRight", "ArrowDown", "ArrowUp", "ArrowLeft", "Backspace", "Delete", "Home", "End"];

export const regexValidation ={
  allowDigitOnly: '^[0-9]$',
  allowNumberOnly: '^[0-9]+$',
  allowNumberOrPoint: '^[0-9]|\\.$',
  allowNumberWithTwoFloatingPoint: '^[0-9]+(\\.[0-9]{0,2}){0,1}$',
  allowFormattedHour: '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$'
};

export const fileType = {
  'csv': "text/csv",
};

// constantes utilisés par contract services

export const contractFormFieldType ={
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  DATETIME: 'DATETIME',
  DATE: 'DATE',
  PHONE: "PHONE",
  TEXT_AREA: "TEXT_AREA",
  NUMBER: "NUMBER"
}

export const companyUuid = '548b2a5b5034ac0392e7aa33d076cad690faf776629a14b74005ac5e72a52b7cf34a548b2a5b5034ac0392e7'


export const interlocutorContractParams ={
  // company: '548b2a5b5034ac0392e7aa33d076cad690faf776629a14b74005ac5e72a52b7cf34a548b2a5b5034ac0392e7',
  type: "interlocutor",
  category: "client"
}

export const particularContractParams ={
  // company: '548b2a5b5034ac0392e7aa33d076cad690faf776629a14b74005ac5e72a52b7cf34a548b2a5b5034ac0392e7',
  type: "particular",
  category: "client"
}

export const companyContractParams ={
  // company: '548b2a5b5034ac0392e7aa33d076cad690faf776629a14b74005ac5e72a52b7cf34a548b2a5b5034ac0392e7',
  type: "client_society",
  category: "client"
}

export const tableFilter ={
  tableFilterFieldType:{
    select: 'select',
    input: 'input',
    checkbox: 'checkbox',
    swicth: 'switch',
    range: 'range',
  },
  debounceTime: 700,
  key: {
    keyword: 'search',
    roles: 'roles',
    shop: 'shop',
    product_category: 'category',
    product_serialization: 'serialization',
    product_stock: 'stock',
    network: 'network',
    seller: 'seller',
    city: 'city',
    shopManager: 'shopManager',
    reason: 'reason',
    period: 'period',
    movement: 'movement',
    inventoryStatus: 'inventoryStatus',
    inventoryType: 'inventoryType',
    isTvaExempted: 'isTvaExempted',
    type: 'type',
    document: 'document'
  }
}

export const roles = {
  admin : "TOGOCOM-POS-GROUP-ADMIN",
  saleAdmin: "TOGOCOM-POS-GROUP-SALES_ADMINISTRATOR",
  seller: "TOGOCOM-POS-GROUP-SELLER"
}

export const save: string = 'SAVE';
export const cancel: string = 'CANCEL';
