<div class="tab__item">
  <ng-container
    *ngIf="(currentStep === stepNumber) || (currentStep > stepNumber && isLast) || (currentStep < stepNumber && isFirst)"
  >
    <div class="content">
      <ng-content select="[step-content]"></ng-content>
      <ng-content select="[step-action]"></ng-content>
    </div>
  </ng-container>
</div>
