import { Component, Input, OnInit } from '@angular/core';
import {I_CardData} from "../../../models/card";

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  // TODO: Configurer l'affichage en counter -> counter-item
  @Input()
  card!: I_CardData;

  @Input()
  cible: string=''; // actif | inactif

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    if (this.card.action != null) {
      this.card.action();
    }
  }
}
