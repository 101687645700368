<div class="item-sale-point">
  <div class="block-right-sale-point">
    <div class="all-selected">
      <div class="block-network-selected">
        <div class="top-title-network-selected">
          <div class="title-network-selected"><span></span> {{shops.length}} Point(s) de vente sélectionné(s)</div>
          <!--<div class="result-filtre">
            <div class="block-delete-all">
              <div class="block-delete-all-content">
                <span class="icn-refersh"></span><span>Supprimer les critères</span>
              </div>
            </div>
          </div>-->
        </div>
        <div class="bottom-list-network-selected" *ngIf="shops != null && networks.length > 0">
          <div class="point-sale">
            <div class="title-point-sale">Réseaux ({{ networks.length }}) : </div>
            <div class="list-point-sale result-filtre">
              <div _ngcontent-wtu-c110="" class="block-word">
                <div _ngcontent-wtu-c110="" class="item-word" *ngFor="let network of networks">
                  <div _ngcontent-wtu-c110="" class="item-content-word">
                    <span _ngcontent-wtu-c110="">{{ network?.network_name }}</span>
                    <!--<span _ngcontent-wtu-c110="" class="icon-close">+</span>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block-shop-selected" *ngIf="shops != null && shops.length > 0">
        <div class="content-shop-selected">
          <div class="point-sale">
            <div class="title-point-sale">Shop ({{ shops.length }})</div>
            <div class="list-point-sale result-filtre">
              <div _ngcontent-wtu-c110="" class="block-word">
                <div _ngcontent-wtu-c110="" class="item-word" *ngFor="let shop of shops">
                  <div _ngcontent-wtu-c110="" class="item-content-word">
                    <span _ngcontent-wtu-c110="">{{ shop.shop_name }}</span>
                    <!--<span _ngcontent-wtu-c110="" class="icon-close">+</span>-->
                  </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
