import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { TokenInterceptorService } from './main/modules/shared/services/interceptors/token-interceptor.service';
import {LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PosLayoutComponent } from './main/layouts/pos-layout/pos.component';
import { FooterComponent } from './main/layouts/pos-layout/components/footer/footer.component';
import { SharedModule } from './main/modules/shared/shared.module';
import { SidebarComponent } from './main/layouts/pos-layout/components/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionDirective } from './main/layouts/pos-layout/components/sidebar/accordion.directive';
import { AuthenticationComponent } from './main/modules/user/pages/authentication/authentication.component';
import { AuthenticationFormComponent } from './main/modules/user/components/authentication/authentication-form/authentication-form.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LoginComponent } from './main/modules/user/pages/login/login.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AddHeaderInterceptor } from './main/modules/shared/interceptors/add-header/add-header.interceptor';
import { HttpErrorInterceptor } from './main/modules/shared/interceptors/http-error/http-error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InventoryModule } from './main/modules/inventory/inventory.module';
import {EnvServiceProvider} from "./en-service-provider";
import {TokenInterceptor} from "./main/modules/shared/interceptors/token-interceptor/token.interceptor";
registerLocaleData(localeFr);

export function tokenGetter() {
  return localStorage.getItem("access_token");
}
@NgModule({
  declarations: [
    AppComponent,
    PosLayoutComponent,
    FooterComponent,
    SidebarComponent,
    AccordionDirective,
    AuthenticationComponent,
    AuthenticationFormComponent,

    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    InventoryModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    { provide: Window, useValue: window },
    EnvServiceProvider
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
