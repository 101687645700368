import {Subscription} from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {MiscService} from '../../../services/miscellaniscious/misc.service';
import {I_PopInData} from '../../../models/popIn';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss'],
})
export class DialogConfirmationComponent implements OnInit, OnDestroy {
  @Input() size: string = "";
  subscription: Subscription = new Subscription();

  popInVisible: boolean = false;
  popInData: I_PopInData | null = null;

  constructor(private miscService: MiscService) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.miscService.popInVisible$.subscribe((visible: boolean) => {
        this.popInVisible = visible;
      })
    );

    this.subscription.add(
      this.miscService.popInData$.subscribe((data: I_PopInData | null) => {
        if (data != null) {
          this.popInData = data;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeModal() {
    this.miscService.hidePopIn();
  }
}
