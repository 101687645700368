import {I_CounterData} from "../counter";
import {config} from "../../../../../config/config";
import {I_CounterMulti, I_CounterMultiData} from "../counter-multi";

export const counter: I_CounterData = {
  title: 'Mes shops',
  customIcon: '<span class="icn-actif"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>',
  description: 'Shop existants',
  number: 2,
  info: 'icn-info-ellipse'
}

export const counterMulti: I_CounterMulti = {
  title: 'Mes Recapitulatifs',
  data: [
    {
      icon: 'icon-shop',
      description: 'Nombre total d\'utilisateur',
      number: 15,
      info: 'icn-info-ellipse',
    },
    {
      customIcon: [
        'icn-user3',
        'icn-icon_right_rrow'
      ],
      description: 'Actif',
      number: 2,
      info: 'icn-info-ellipse',
    }
  ]
};

