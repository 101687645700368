<div class="item-sale-point" [formGroup]="formGroup">
  <div class="block-left-sale-point">
    <div class="all-sale-point">

      <div class="title-sale-point block-inline-flex open">
        <div class="cell-table black-check">
          <label class="container"><span class="sub-title-sale-point semi_bold">{{checkAllLabel}}</span>
            <input type="checkbox" name="checkAll" formControlName="checkAll" (change)="checkAll()">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <label [for]="'check-all'+idCheck" class="check-all">
        <span class="has-child" [ngClass]="{'open': isOpen}" (click)="onClickCollapse(isOpen)"></span>
      </label>
      <input type="checkbox" name="check-all" [id]="'check-all'+idCheck">

      <ul class="list-sale-point" formArrayName="items">
        <li class="item-sale-point" *ngFor="let field of formGroupItems().controls; let i = index;">
          <ng-container [formGroupName]="i">
            <div class="block-inline-flex" [ngSwitch]="field.value.label">
              <!-- show particulier and professionnel even if its doesn't have any items -->
              <div class="cell-table black-check sub-items" *ngSwitchCase="'Particulier'">
                <label class="container">
                  <span class="semi_bold h-18">{{ field.value.label }}</span>
                  <input type="checkbox" name="isChecked" formControlName="isChecked" (change)="chekAllSubItems(i)">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="cell-table black-check sub-items" *ngSwitchCase="'Professionnel'">
                <label class="container">
                  <span class="semi_bold h-18">{{ field.value.label }}</span>
                  <input type="checkbox" name="isChecked" formControlName="isChecked" (change)="chekAllSubItems(i)">
                  <span class="checkmark"></span>
                </label>
              </div>
              <!-- the network and shop checkbox tree, no value when no subItems -->
              <div class="cell-table black-check" *ngIf="field.value.subItems.length > 0">
                <label class="container">
                  <span class="semi_bold h-18">{{ field.value.label }}</span>
                  <input type="checkbox" name="isChecked" formControlName="isChecked" (change)="chekAllSubItems(i)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            <ng-container *ngIf="formGroupItemForm(i).controls.length > 0">
              <label for="checkSubInput-{{i}}">
                <span class="has-child open" (click)="onClickCollapseItem('#idCheckSubLabel-'+i)"
                  [id]="'idCheckSubLabel-'+i"></span>
              </label>
              <input type="checkbox" name="checkSub" id="checkSubInput-{{i}}">
            </ng-container>

            <ul class="list-children-sale-point" formArrayName="subItems">
              <ng-container *ngFor="let shop of formGroupItemForm(i).controls; let j = index;">
                <li class="item-children-sale-point" [formGroupName]="j">
                  <div class="block-inline-flex">
                    <div class="cell-table black-check sub-items">
                      <label class="container">
                        <span>{{shop.value.label}}</span>
                        <input type="checkbox" name="isChecked" formControlName="isChecked"
                          (change)="chekSubItem(i, j)">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>