export const abilities = {
  sale_management: {
    page: {
      make_sale: "PAGE_MAKE-A-SALE",
      history: "PAGE_ELEMENT_CAN_ACCESS_SALES_HISTORY"
    },
    element: {
      cancel_bill: "PAGE_ELEMENT_CANCEL_SELL",
      cancel_sale: "PAGE_ELEMENT_CAN_CANCEL_SALES_ANYTIME"
    }
  },
  shop_management: {
    page: {
      list: "PAGE_SHOP-MANAGEMENT",
      create: "PAGE_CREATE_SHOP",
      import: "PAGE_ELEMENT_CAN_IMPORT_SHOP"
    },
    access: "PAGE_ELEMENT_CAN_ACCESS_ALL_SHOP_LIST"
  },
  cashRegister: {
    page: {
      list: "PAGE_ACCES-CASH-LIST"
    },
    element: {
      listAll: "PAGE_ELEMENT-ACCES-ALL-CASH-SHOP",
      listShopOnly: "PAGE_ELEMENT-ACCES-ALL-CASH-SHOP",
      openCashRegister: "PAGE_ELEMENT_CAN_OPEN_CASH_REGISTER"
    }
  },
  item: {
    page: "PAGE_ARTICLE",
    element: {
      import: "PAGE_ELEMENT_IMPORT-ARTICLE",
      updateProduct: "PAGE_UPDATE-PRODUCT",
      updateProductRestockingThreshold: "PAGE_ELEMENT-UPDATE-RESTOCKING-THRESHOLD-PRODUCT",
      updateProductPriceAndPhoto: "PAGE_ELEMENT-UPDATE-PRICE-AND-PHOTO-PRODUCT",
    }
  },
  item_category: {
    page: "PAGE_ARTICLE-CATEGORY",
    element: {
      import: "PAGE_ELEMENT_IMPORT-ARTICLE-CATEGORIES"
    }
  },
  stock: {
    page: "PAGE_INVENTORY-MANAGEMENT",
    element: {
      importStock: "PAGE_ELEMENT_IMPORT-INVENTORY",
      allStockMovement: "PAGE_ELEMENT-ACCES-ALL-MOVEMENT-STOCK-SHOP",
      shopOnlyStockMovement: "PAGE_ELEMENT-ACCES-SELF-SHOP-ONLY",
      allStock: "PAGE_ELEMENT-ACCES-ALL-STOCK-SHOP-MANAGEMENT"
    },
  },
  setting: {
    page: "PAGE_SETTINGS"
  },
  test: {
    page_test: "PAGE_TEST",
    page_test_generic_form: "PAGE_TEST_GENERIC_FORM"
  },
  modules:{
    customer:{
      customerCategory:{
        page:"MODULES__CUSTOMER__CUSTOMER_CATEGORY",
        element:{
          editCustomerCategory: "MODULES__CUSTOMER__CUSTOMER_CATEGORY__EDIT_CUSTOMER_CATEORY"
        }
      }
    },
    sale: {
      paymentMethod:{
        page:"MODULES__VENTE__PAYMENT_METHOD",
        element:{
          editCustomerCategory: "MODULES__VENTE__PAYMENT_METHOD__EDIT_PAYMENT_METHOD"
        }
      }
    }
  },
  user: {
    profile: {
      page: {
        list: "PAGE_PROFILE-MANAGEMENT",
        detail: "PAGE_VIEW-PROFILE"
      }
    },
    user: {
      page: {
        list: "PAGE_USER-MANAGEMENT",
        detail: "PAGE_VIEW-USER",
        update: "PAGA_UPDATE-USER"
      }
    }
  },
  pack: {
    page: {
      list: "PAGE_PACK-LIST",
      create: "PAGE_CREATE-PACK",
      edit: "PAGE_UPDATE-PACK"
    },
    element: {
      edit: "PAGE_ELEMENT_UPDATE-PACK",
      delete: "PAGE_ELEMENT_UPDATE-PACK",
    }
  },
  discount: {
    page: {
      list: "PAGE_DISCOUNT-LIST",
      create: "PAGE_CREATE-DISCOUNT",
      edit: "PAGE_UPDATE-DISCOUNT"
    },
    element: {
      edit: "PAGE_ELEMENT_UPDATE-PACK",
      delete: "PAGE_ELEMENT_UPDATE-PACK",
    }
  },
  various: {
    page: {
      list: {
        entry: "PAGE_ACCES_VARIOUS_INCOMING",
        exit:  "PAGE_ACCES_VARIOUS_OUTGOING",
      },
      create: {
        entry: "PAGE_CREATE_VARIOUS_INCOMING",
        exit: "PAGE_CREATE_VARIOUS_OUTGOING",
      }
    },
    element: {
      all: {
        entry: 'PAGE_ELEMENT_CAN_ACCESS_VARIOUS_INCOMING_ALL_SHOP',
        exit: 'PAGE_ELEMENT_CAN_ACCESS_VARIOUS_OUTGOING_ALL_SHOP',
      }
    }
  },
  vault: {
    open: "PAGE_ELEMENT_OPEN_VAULT",
    close: "PAGE_ELEMENT_CLOSE_VAULT",
  },
  inventory: {
    list: "PAGE_ACCES_INVENTORY_LIST",
    create: "PAGE_MAKE_INVENTORY",
    access: "PAGE_ELEMENT_CAN_ACCESS_ACCES_INVENTORY_LIST_ALL_SHOP"
  },
  user_access_not_shop: 'PAGE_ELEMENT_CAN_NOT_NEED_SHOP',
  mobile_money: {
    access: 'PAGE_SERVICE_MOBILE_MONEY_ACCESS',
    operation: 'PAGE_ELEMENT_MAKE_CASH_IN_CASH_OUT',
    setting: "PAGE_CAN_ACCESS_MODULES_SERVICE_MOBILE_MONEY",
    dashboard: 'PAGE_CAN_ACCESS_MOBILE_MONEY_TRANSACTION_DASHBOARD',
    dashboard_all_shop: 'PAGE_ELEMENT_CAN_ACCESS_MOBILE_MONEY_TRANSACTION_DASHBOARD_ALL_SHOP',
    history: 'PAGE_CAN_ACCESS_CASH_IN_CASH_OUT_LIST',
    history_all_shop: 'PAGE_ELEMENT_CAN_ACCESS_ALL_SHOP_CASH_IN_CASH_OUT_LIST',
    number: 'PAGE_ELEMENT_CAN_ACCESS_MOBILE_MONEY_NUMBER_LIST',
    create: 'PAGE_ELEMENT_CAN_CREATE_MOBILE_MONEY_NUMBER'
  },

}
