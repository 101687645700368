import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, Subscription } from 'rxjs';
import { storageKey } from 'src/app/config/storage';
import { Shop } from '../../../pos/models/shop/shop.model';
import { tableFilter } from '../../../shared/config/constants';
import { ITableFilterFieldNewValue, ITableFilterNew, ITableFilterNewField } from '../../../shared/interfaces/i-table-filter-new.interface';
import { IRowWithoutEdit } from '../../../shared/interfaces/tables/without-edit/i-row-without-edit';
import { ITableWithoutEdit } from '../../../shared/interfaces/tables/without-edit/i-table-without-edit';
import { I_CounterMulti } from '../../../shared/models/counter-multi';
import { IButton } from '../../../shared/models/i-button';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { MiscService } from '../../../shared/services/miscellaniscious/misc.service';
import { TableFilterService } from '../../../shared/services/table-filter/table-filter.service';
import { TableService } from '../../../shared/services/table/table.service';
import { Inventory, InventoryStatus, InventoryType } from '../../models/inventory.model';
import { InventoryService } from '../../services/inventory.service';
import {I_ApiResponse} from "../../../shared/models/api-response";
import { I_BreadCrumbData } from '../../../shared/models/breadCrumb';
import {StockService} from "../../../stock/services/stock.service";
import { HabilitationsService } from '../../../shared/services/habilitations/habilitations.service';
import { abilities } from 'src/app/config/abilities';
import { HelperService } from '../../../shared/services/helper/helper.service';

@Component({
  selector: 'app-list-inventory',
  templateUrl: './list-inventory.component.html',
  styleUrls: ['./list-inventory.component.scss']
})
export class ListInventoryComponent implements OnInit, OnDestroy {

  readonly subscription = new Subscription();
  readonly tableFilterInventoriesId = 'inventories-filter';
  readonly tableFilter: ITableFilterNew = {
    id: this.tableFilterInventoriesId,
    title: 'Liste des inventaires',
    fields: [],
  };

  readonly tableInventoriesId = 'inventories';
  readonly tableData: ITableWithoutEdit = {
    id: this.tableInventoriesId,
    header: [
      {
        value: this.translateService.instant('inventory.list.headers.reference'),
      },
      {
        value: this.translateService.instant('inventory.list.headers.shop'),
      },
      {
        value: this.translateService.instant('inventory.list.headers.users'),
      },
      {
        value: this.translateService.instant('inventory.list.headers.modification'),
      },
      {
        value: this.translateService.instant('inventory.list.headers.type'),
      },
      {
        value: this.translateService.instant('inventory.list.headers.status'),
      },
      {
        value: this.translateService.instant('inventory.list.headers.actions'),
        colspan: 3
      },
    ],
    body: null,
  };

  hasNextPage = false;
  nextPage = 1;
  inventories: Inventory[] = [];
  inventoryStatusFilter: string;
  shopUuidFilter: string;
  inventoryTypeFilter: string;
  query: string;
  loadMoreBtn: IButton = {
    buttonText: 'Afficher plus',
  };

  inventoryCounter: I_CounterMulti = {
    title: 'Récapitulatif des inventaires',
    data: [],
  }

  constructor(
    private tableFilterService: TableFilterService,
    private translateService: TranslateService,
    private tableService: TableService,
    private inventoryService: InventoryService,
    private miscService: MiscService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private stockService: StockService,
    private habilitationService: HabilitationsService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.updateBreadCrumb();
    this.initFilter();
    this.fillTable();
    this.getStatistics();
    this.tableService.setTableWithoutEditValue(this.tableData);
    this.loadMoreBtn.action = this.onClickLoadMoreBtn;
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  private updateBreadCrumb(): void {
    const breadCrumb: I_BreadCrumbData[] = [
      {
        url: '',
        label: 'Gestion stock',
      },
      {
        url: '',
        label: 'Liste des inventaires'
      }
    ];

    this.miscService.addBreadCrumb(breadCrumb);
  }

  private getStatistics(): void {
    this.subscription.add(this.inventoryService.getInventoryStatistics().subscribe(response => {
      if (response.status < 300) {
        this.inventoryCounter.data = [
          {
            description: 'Validé',
            number: response.data.inventory_status.FINISHED,
            customIcon: ['icn-discount', 'icn-check'],
            info: 'icn-info-fill',
            action: () => {
              this.filterByKpi(tableFilter.key.inventoryStatus, 'FINISHED')
            }
          },
          {
            description: 'En cours',
            number: response.data.inventory_status.ONGOING,
            customIcon: ['icn-discount', 'icn-alert'],
            info: 'icn-info-fill',
            action: () => {
              this.filterByKpi(tableFilter.key.inventoryStatus, 'ONGOING')
            }
          },
        ];
        return;
      }
      this.miscService.showToast({
        type: 'danger',
        icon: 'alert',
        title: 'Erreur',
        desc: response.errors[0].message,
      });
    }));
  }

  checkInventory(key: string) {
    return this.habilitationService.getKeyHabilitations(key)
  }

  filterByKpi(key: string, value: string){
    this.tableFilterService.setChangeFilterValue({key: key, value: value})
  }

  canSeeAllInventory(): boolean {
    return this.habilitationService.getKeyHabilitations(abilities.inventory.access);
  }

  private getInventoryType(): Observable<InventoryType[]> {
    return this.inventoryService.getInventoryType().pipe(
      map(response => {
        if (response.status < 300) {
          return <InventoryType[]> response.data.inventory_type
        }
        this.miscService.showToast({
          type: 'danger',
          icon: 'alert',
          title: 'Erreur',
          desc: response.errors[0].message
        });
        return [];
      })
    );
  }

  private getShop(): Observable<Shop[]> {
    return this.stockService.getShop(this.userDetail.company_uuid).pipe(
      map(response => {
        if (response.status < 300) {
          return <Shop[]> response.data.shops
        }
        this.miscService.showToast({
          type: 'danger',
          icon: 'alert',
          title: 'Erreur',
          desc: response.errors[0].message
        });
        return [];
      })
    );
  }

  private getInventoryStatus(): Observable<InventoryStatus[]> {
    return this.inventoryService.getInventoryStatus().pipe(
      map(response => {
        if (response.status < 300) {
          return <InventoryStatus[]> response.data.inventory_status;
        }
        this.miscService.showToast({
          type: 'danger',
          icon: 'alert',
          title: 'Erreur',
          desc: response.errors[0].message
        });
        return [];
      })
    );
  }

  get userDetail() {
    return JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
  }

  private initFilter(): void {
    this.initTableFilter();
    this.subscription.add(this.tableFilterService.formValue$.subscribe(data => {
      if (data != null) {
        this.query = data['search'] ? data['search'] : null;
        this.shopUuidFilter = data['shop'] !== 'all' ? data['shop'] : '';
        this.inventoryTypeFilter = data['inventoryType'] !== 'all' ? data['inventoryType'] : '';
        this.inventoryStatusFilter = data['inventoryStatus'] !== 'all' ? data['inventoryStatus'] : '';
        this.fillTable();
      }
    }));
  }

  private initTableFilter(): void {
    const filterData$ = forkJoin([
      this.getShop(),
      this.getInventoryType(),
      this.getInventoryStatus(),
    ])
    this.subscription.add(filterData$.subscribe(([shops, inventoryTypes, inventoryStatus]) => {
      const allStatus: ITableFilterFieldNewValue = {
        key: tableFilter.key.inventoryStatus,
        label: 'Tous',
        value: 'all',
        default: true,
      };
      const allTypes: ITableFilterFieldNewValue = {
        key: tableFilter.key.inventoryType,
        label: 'Tous',
        value: 'all',
        default: true,
      };
      const allShops: ITableFilterFieldNewValue = {
        key: tableFilter.key.shop,
        label: 'Tous',
        value: 'all',
        default: true,
      };

      const shopsFilterData: ITableFilterFieldNewValue[] = [allShops];
      const inventoryTypesFilterData: ITableFilterFieldNewValue[] = [allTypes];
      const inventoryStatusFilterData: ITableFilterFieldNewValue[] = [allStatus];

      shops.forEach(shop => {
        shopsFilterData.push({
          key: tableFilter.key.shop,
          label: shop.name,
          value: shop.shop_uuid,
        });
      })

      inventoryTypes.forEach(type => {
        inventoryTypesFilterData.push({
          key: tableFilter.key.inventoryType,
          label: type.inventory_type_label,
          value: type.inventory_type_key,
        });
      });

      inventoryStatus.forEach(status => {
        inventoryStatusFilterData.push({
          key: tableFilter.key.inventoryStatus,
          label: status.inventory_status_label,
          value: status.inventory_status_key,
        });
      });

      this.tableFilter.fields = [
        {
          key: tableFilter.key.keyword,
          label: 'Mot clé',
          placeholder: 'Référence',
          type: 'input',
        },
        {
          key: tableFilter.key.shop,
          label: 'Site',
          type: 'select',
          value: shopsFilterData,
        },
        {
          key: tableFilter.key.inventoryType,
          label: 'Type',
          type: 'select',
          value: inventoryTypesFilterData,
        },
        {
          key: tableFilter.key.inventoryStatus,
          label: 'Status',
          type: 'select',
          value: inventoryStatusFilterData,
        },
      ];
      if(!this.habilitationService.getKeyHabilitations(abilities.inventory.access)){
        this.tableFilter.fields = this.tableFilter.fields.filter((field: ITableFilterNewField) => field.key !== tableFilter.key.shop)
      }
      this.tableFilterService.filterNew$.next(this.tableFilter);
    }));
  }

  private getInventories(page: number, _params: Object = null): Observable<Inventory[]> {
    if(!this.habilitationService.getKeyHabilitations(abilities.inventory.access)){
      this.shopUuidFilter = this.userDetail.fk_shop_uuid
    }
    return this.inventoryService.getInventories(
      1,
      page,
      this.shopUuidFilter,
      this.inventoryTypeFilter,
      this.inventoryStatusFilter,
      this.query
    ).pipe(
      map(response => {
        if (response.status < 300) {
          this.hasNextPage = response.data.inventories.last_page > response.data.inventories.current_page;
          this.nextPage = response.data.inventories.current_page ? response.data.inventories.current_page + 1 : 1;
          return <Inventory[]> response.data.inventories.data ?? [];
        }
        this.miscService.showToast({
          type: 'danger',
          icon: 'alert',
          title: 'Erreur',
          desc: response.errors[0].message
        });
        return [];
      })
    );
  }

  private fillTable(): void {
    this.subscription.add(this.getInventories(1).subscribe(inventories => {
      this.inventories = inventories;
      this.tableData.body = {
        cellValue: this.createInventoriesRows(this.inventories)
      };
    }));
  }

  private appendTableData(): void {
    this.subscription.add(this.getInventories(this.nextPage).subscribe(inventories => {
      this.inventories = [...this.inventories, ...inventories];
      this.tableData.body = {
        cellValue: this.createInventoriesRows(this.inventories)
      };
    }));
  }

  onClickLoadMoreBtn = (): void => this.appendTableData();

  private createInventoriesRows(inventories: Inventory[]): IRowWithoutEdit[] {
    const badgeColor = new Map();
    badgeColor.set('FINISHED', 'bg-success');
    badgeColor.set('ONGOING', 'bg-warning');
    const rows: IRowWithoutEdit[] = [];
    inventories.forEach(inventory => {
      rows.push({
        id: inventory.inventory_uuid,
        is_expandable: false,
        rowValue: [
          {
            id: inventory.inventory_uuid,
            key: 'name',
            expand: false,
            type: 'simple',
            value: {
              value: inventory.inventory_reference,
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'site',
            expand: false,
            type: 'simple',
            value: {
              value: inventory.shop.name,
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'user',
            expand: false,
            type: 'simple',
            value: {
              value: inventory.user.first_name +' '+ inventory.user.last_name,
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'update',
            expand: false,
            type: 'simple',
            value: {
              value: this.helperService.convertDate(new Date(inventory.update_date_utc)),
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'type',
            expand: false,
            type: 'simple',
            value: {
              value: inventory.inventory_type.inventory_type_label,
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'status',
            expand: false,
            type: 'simple',
            badge: {
              bg: badgeColor.get(inventory.inventory_status.inventory_status_key),
              status: true
            },
            value: {
              value: inventory.inventory_status.inventory_status_label,
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'action',
            expand: false,
            type: 'button',
            class: 'icn-icn-export',
            value: 'Exporter',
            action: () => {
              this.exportDocument(inventory.inventory_uuid)
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'action',
            expand: false,
            type: 'button',
            class: 'icn-see-detail',
            value: 'Voir détails',
            action: () => {
              this.router.navigate([`inventory/create/${inventory.inventory_uuid}`]);
            },
          },
          {
            id: inventory.inventory_uuid,
            key: 'action',
            expand: false,
            type: 'button',
            value: 'Modifier',
            class: inventory.inventory_status.inventory_status_key === 'FINISHED' ? 'icn-edit hide' : 'icn-edit',
            action: () => {
              this.router.navigate([`inventory/create/${inventory.inventory_uuid}`]);
            },
          },
        ],
      });
    });
    return rows;
  }

  onClickCreateInventoryBtn(): void {
    this.subscription.add(this.inventoryService.createInventory().subscribe(response => {
      if (response.status < 300) {
        this.router.navigate([`/inventory/create/${response.data.inventory_uuid}`]);
      }
      this.miscService.showToast({
        type: 'danger',
        icon: 'alert',
        title: 'Erreur',
        desc: response.errors[0].message
      });
    }));
  }

  exportDocument(uuid: string) {
    this.subscription.add(
      this.inventoryService.exportDocument(uuid).subscribe((response: I_ApiResponse) => {
        const url = response.data.data.document
        window.open(url, '_blank');
      })
    )
  }
}
