import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Product} from "../../../product/models/product/product.model";
import {IItemSerialization} from "../../models/i-item-serialization/i-item-serialization";
import {ApiService} from "../api-service/api.service";
import {environment} from "../../../../../../environments/environment";
import {map} from "rxjs/operators";
import {responseStatus} from "../../config/constants";
import {Reason} from "../../../stock-confiugration/models/reason.model";
import {Router} from "@angular/router";
import {EnvService} from "../../../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class ItemSerializationService {
  private serialization$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private serializationValue$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private product$: Subject<IItemSerialization> = new Subject<IItemSerialization>();
  private saveSerializationStatus$: Subject<string> = new Subject<string>();
  private serializationInputQuantity$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private allSerializationInput$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public cancelSerialization$: Subject<boolean> = new Subject<boolean>();
  private stockService: string = this.env.apiStockService

  constructor(
    private apiService: ApiService,
    private env: EnvService
  ) { }

  resetAll() {
    this.serialization$.next(false);
    this.serializationValue$.next([]);
    this.saveSerializationStatus$.next('');
    this.serializationInputQuantity$.next(null);
    this.allSerializationInput$.next(null);
  }

  setSerialization(value: boolean) {
    this.serialization$.next(value);
  }

  getSerialization() {
    return this.serialization$;
  }

  setSerializationValue(value: any) {
    this.serializationValue$.next(value);
  }

  getSerializationValue() {
    return this.serializationValue$;
  }

  setProduct(value: IItemSerialization) {
    this.product$.next(value);
  }

  getProduct() {
    return this.product$;
  }

  setSaveSerializationStatus(value: string) {
    this.saveSerializationStatus$.next(value);
  }

  getSaveSerializationStatus() {
    return this.saveSerializationStatus$;
  }

  getSerialNumberValidity(shopUuid: string, productUuid: string, param: {}, value: any) {
    const url = `${this.stockService}/serial/stock-location/${shopUuid}/product/${productUuid}`;
    return this.apiService.doGet(url, param).pipe(
      map(response => {
        return {
          status: (response.status == responseStatus.success && response.data.length > 0 &&  value['is_serialization_valid']),
          value: value
        };
      })
    );
  }

  getSerialNumberInterval(productUuid: string, formValue: {}) {
    const start = formValue['serial_number_start']
    const end = formValue['serial_number_end']
    const key = formValue['fk_serialization_type_key']
    const url = `${this.stockService}/stock/product/${productUuid}/serialization/start/${start}/end/${end}/key/${key}/check-serialization-availability`;
    return this.apiService.doGet(url).pipe(
      map(response => {
        return response.data = {...response.data, formValue: formValue}
      })
    );
  }

  setSerializationInputQuantity(value: any) {
    this.serializationInputQuantity$.next(value);
  }

  getSerializationInputQuantity() {
    return this.serializationInputQuantity$
  }

  setAllSerializationInput(value: any) {
    this.allSerializationInput$.next(value);
  }

  getAllSerializationInput() {
    return this.allSerializationInput$
  }

  public inputSerializations$: BehaviorSubject<ISerialization[]> = new BehaviorSubject<ISerialization[]>([]);
  private inputSerializations: ISerialization[] = []
  public resetInputSerialization() {
    this.inputSerializations = []
  }

  public setInputSerializations(prev: ISerialization = null, current: ISerialization = null, productToRemove = '', serializations: ISerialization[] = []) {
    if (productToRemove != '') {
      this.inputSerializations = this.inputSerializations.filter(x => x.product_uuid != productToRemove);
      if (serializations.length > 0) this.inputSerializations = this.inputSerializations.concat(serializations)
    } else {
      let isExit = false;
      this.inputSerializations.map(x => {
        if (
          x['id'] == current['id'] &&
          x.product_uuid == current['product_uuid'] &&
          x.product_serialization_key == current['product_serialization_key']
        ) {
          x.serial_number_start = current['serial_number_start'];
          x.serial_number_end = current['serial_number_end'];
          isExit = true
        }
        return x;
      })
      if (!isExit) this.inputSerializations.push(current)
    }
    this.inputSerializations$.next(this.inputSerializations)
  }
  public getInputSerializations() {
    return this.inputSerializations$
  }

}

export interface ISerialization {
  id: number; // line
  product_uuid: string,
  product_serialization_uuid?: string,
  product_serialization_key: string,
  serial_number_start: string,
  serial_number_end: string,
}
