import { I_DialogStatus } from '../../models/dialog';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {I_PopInData} from "../../models/popIn";
import {I_DialogData} from "../../models/dialog";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogData$: BehaviorSubject<I_DialogData | null> = new BehaviorSubject<I_DialogData | null>(null);
  dialogVisible$: Subject<I_DialogStatus|null> = new Subject<I_DialogStatus|null>();

  dialogCible$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dialogSize$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dialogIsClosed$: Subject<boolean> = new Subject<boolean>();

  constructor() { }



  initDialog(dialogData: I_PopInData) {
    if (dialogData != null) {
      this.updatePopInData(dialogData);
    }
  }

  updatePopInData(dialogData: I_DialogData) {
    this.dialogData$.next(dialogData);
  }

  showDialog(id: string) {
    this.setDialogIsClosed(false);
    this.dialogVisible$.next({dialogId: id, visible: true} as I_DialogStatus);
  }

  hideDialog(id: string) {
    this.dialogVisible$.next({dialogId: id, visible: false} as I_DialogStatus);
  }

  switchDialogCible( dialogId: string, cible: string ){
    this.dialogCible$.next({
      dialogId: dialogId,
      cible: cible
    });
  }

  switchDialogSize( dialogId: string, size: string ){
    this.dialogSize$.next({
      dialogId: dialogId,
      size: size
    });
  }

  setDialogIsClosed(status: boolean) {
    this.dialogIsClosed$.next(status);
  }
}
