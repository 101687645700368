import {Component, Input, OnInit, EventEmitter, Output, OnDestroy} from '@angular/core';
import {IButton} from "../../../models/i-button";
import {TranslateService} from "@ngx-translate/core";
import {ButtonService} from "../../../services/button/button.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() public config: IButton
  public style!: String;
  private subscription = new Subscription();

  constructor(private buttonService: ButtonService) { }

  ngOnInit(): void {
    this.style = 'button ';
    this.style = ` ${this.config?.variant}`;

    this.getButtonValue();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  getButtonValue = () => {
    this.subscription.add(
      this.buttonService.button$.subscribe(value => {
        this.style = 'button ';
        this.style = ` ${value?.variant}`;
        if (value?.state == 'error') {
          this.style += ' dangered';
        }
        if (value?.state == 'success') {
          this.style += ' successed';
        }
      })
    );
  }

  click = () => {
    if (this.config.action) this.config?.action();
  }
}
