<div class="block-tabs tabs-{{tabType}}">
  <ul class="nav-tabs">
    <li
      *ngFor="let tab of tabs"
      (click)="selectTab(tab)"
      [ngClass]="tab.active ? 'active' : ''"
    >
      {{ tab.title | translate }}
    </li>
  </ul>
  <ng-content></ng-content>
</div>
