import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ICheckbox} from "../../../models/i-checkbox/i-checkbox";
import {CheckboxService} from "../../../services/checkbox/checkbox.service";
import {Subscription} from "rxjs";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  private subscription = new Subscription();
  public boxArray: ICheckbox[] = [];
  public form!: FormGroup;
  public total: number = 0;
  constructor(
    private checkboxService: CheckboxService,
    private formBuilder: FormBuilder,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getBox()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.form = this.formBuilder.group({
      all_is_checked: false,
      fields: this.formBuilder.array([])
    })
  }

  fieldsGroups(): FormArray {
    return this.form.get('fields') as FormArray
  }


  subFieldsGroups(index: number): FormArray {
    return this.fieldsGroups().at(index).get('subFields') as FormArray
  }

  field(i: number, j: number) {
    return this.subFieldsGroups(i).at(j)
  }

  public hasChiflField: boolean = false;
  getBox() {
    this.subscription.add(
      this.checkboxService.boxArray$.subscribe(checkboxData => {
        if (checkboxData && checkboxData['id'] == this.id && checkboxData['value'].length > 0) {
          this.boxArray = checkboxData['value'];
          this.boxArray.forEach((value, index) => {
            let fieldGroup = this.formBuilder.group({
              id: value.id,
              name: value.name,
              is_checked: value.is_checked
            })

            this.total ++;
            if (value.is_child) {
              fieldGroup.addControl('subFields', this.formBuilder.array([]))
              this.hasChiflField = true;
              let group = fieldGroup.get('subFields') as FormArray;
              value.is_child.forEach(subValue => {
                let subFieldGroup = this.formBuilder.group({
                  id: subValue.id,
                  name: subValue.name,
                  is_checked: subValue.is_checked
                })
                group.push(subFieldGroup);
              })
            }
            this.fieldsGroups().push(fieldGroup);
          })
        }
      })
    )
  }

  resetForm() {
    this.form.reset();
    this.fieldsGroups().clear();
    this.fieldsGroups().reset();
  }


  selectAllPos() {
    if (this.form.get('all_is_checked').value == true) {
      this.fieldsGroups().controls.forEach((control, i) => {
        control.patchValue({is_checked: true})
        this.selectNetWork(i);
      })
      this.form.updateValueAndValidity()
      this.formValues();
    } else {
      this.fieldsGroups().controls.forEach((control, i) => {
        control.patchValue({is_checked: false})
        this.selectNetWork(i);
      })
      this.form.get('all_is_checked').setValue(false)
      this.form.updateValueAndValidity()
      this.formValues();
    }
  }

  selectNetWork(i: number) {
    const network_is_checked = this.fieldsGroups().at(i).get('is_checked').value
    if (network_is_checked && this.hasChiflField) {
      this.subFieldsGroups(i).controls.forEach(control => {
        control.patchValue({is_checked: true})
      });
      this.form.updateValueAndValidity()
      this.formValues();
    } else if (!network_is_checked && this.hasChiflField) {
      this.subFieldsGroups(i).controls.forEach(control => {
        control.patchValue({is_checked: false})
      });
      this.form.get('all_is_checked').setValue(false)
      this.form.updateValueAndValidity()
      this.formValues();
    }
  }

  selectShop(i: number, j: number) {
    const is_checked = this.field(i, j);
    if (!is_checked.value.is_checked) this.form.get('all_is_checked').setValue(false);
    this.form.updateValueAndValidity()
    this.formValues();
  }

  formValues() {
    this.subscription.add(
      this.form.valueChanges.subscribe(values => {
        /*if (values.shops.length > 0 && JSON.stringify(this.selectedShop) !=JSON.stringify(values)) {
          this.selectedShop = values;
          this.addRemoveShopService.setSelectedShops(this.selectedShop)
        }*/
      })
    );
  }
}
