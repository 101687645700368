import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../../../../../product/models/product/product.model";

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent implements OnInit {
  @Input() showPrice: boolean = true;
  @Input() product: Product;
  @Input() selected: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
