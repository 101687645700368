<div class="block-packs-articles" [ngClass]="{'rockerState': selected, 'pack-disabled': !product.isAvailable}">
  <div class="item-packs-article">
    <!-- start Accordion -->
    <div class="tabs">
      <div class="tab pack-chip-article">
        <input type="checkbox" id="chck-{{product.uuid_product}}" [ngClass]="{'rockerState': selected}">
        <label class="tab-label">
          <span class="tab-title tab-flex">
            <span class="title-accordion"> <em class="arrowLeft"></em>
              <span class="label-product">{{ product.label }}</span><br>
              <span class="description-product">{{ product.product_packs?.length }}</span>
            </span>
            <span class="numbr-article">
              <span class="bold">{{ product.ttc_pack_price }}</span> XOF <br>
              <!-- <div class="chip-colored" *ngIf="!product.isAvailable">iMPOSSIBLE D'AJOUTER CE PACK</div> -->
              <span class="chip-article" *ngIf="!product.isAvailable && product.discounts">
                <div  class="tooltip-wrapper_rounded-color" >
                  <span [title]="'Article appartenant déjà à la remise \'' + product.discounts[0].discount_name + '\'' ">
                    <span class="link-colored text-chip-article">INDISPONIBLE</span>
                  </span>
                </div>
            </span>
            </span>
          </span>
        </label>
        <div class="tab-content">
          <app-product-card *ngFor="let _product of product.product_packs" [product]="_product"></app-product-card>
        </div>
      </div>
    </div>
    <!-- end Accordion -->
  </div>
</div>
