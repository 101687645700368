import {Injectable} from '@angular/core';
import {ApiService} from "../../../shared/services/api-service/api.service";
import {BehaviorSubject, map, Observable, Subject} from "rxjs";
import {I_ApiResponse} from "../../../shared/models/api-response";
import {apiUrl} from "../../config/apiUrl";
import {I_ImportProductResult} from "../../models/product";
import {IProductCard} from '../../../shared/models/product/product-card.model';
import {IHeader} from "../../../shared/interfaces/tables/i-header";
import {I_Product} from "../../../sale-management/models/product/product.model";
import {IRow} from "../../../shared/interfaces/tables/i-row";
import {ITableFilterFieldNewValue, ITableFilterNew} from "../../../shared/interfaces/i-table-filter-new.interface";
import {tableFilter} from "../../../shared/config/constants";
import {Product} from "../../models/product/product.model";
import {HelperService} from "../../../shared/services/helper/helper.service";
import {EnvService} from "../../../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl: string =this.env.apiCatalogService;
  isProductDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  seachResult$: BehaviorSubject<any> = new BehaviorSubject([]);
  selectedProduct$: Subject<IProductCard[]> = new Subject();
  addedProduct$: Subject<IProductCard[]> = new Subject();
  product$: BehaviorSubject<Product> = new BehaviorSubject<Product>(null)
  productFormValue$: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
  constructor(
    private apiService:ApiService,
    private helperService: HelperService,
    private env: EnvService
  ) { }

  importProduct(company_uuid: string, data: string): Observable<I_ImportProductResult>{
    let url = `${this.baseUrl}/${apiUrl.importProduct}/company/${company_uuid}`
    return this.apiService.doPost(url, { file: data })
      .pipe(
        map( (response: I_ApiResponse)=> response.data.products )
      );
  }

  getCsvModel(): Observable<I_ApiResponse> {
    let url = `${this.baseUrl}/model/product`;
    return this.apiService.doGet(url);
  }

  getSatistics(company_uuid: string) {
    let url = `${this.baseUrl}/${apiUrl.statisticsProduct}/company/${company_uuid}`
    return this.apiService.doGet(url);
  }

  updateIsProductDisplay(isProduct: boolean): void {
    this.isProductDisplay$.next(isProduct);
  }

  getProduct(companyUuid: string, isPack: 0 | 1, search: string, body: any): Observable<IProductCard[]> {
    const url = `${this.baseUrl}/discount/product/search?company=${companyUuid}&search=${search}&is_pack=${isPack}`;
    return this.apiService.doPost(url, body).pipe(
      map(response => {
        if (response.status !== 200) {
          return [];
        }
        return response.data[0]?.products.data.map((item: any) => { return this.dataToProductCard(item); });
      })
    );
  }

  updateSearchresult(data: any): void {
    this.seachResult$.next(data);
  }

  updateSelectedProduct(data: IProductCard[]): void {
    this.selectedProduct$.next(data);
  }

  checkProductDisponibility(companyUuid: string, productUuid: string[], body: any): Observable<IProductCard[]> {
    let productParams: string = '';
    productUuid.forEach(item => {
      productParams += `products[]=${item}&`;
    });
    productParams = productParams.slice(0, productParams.length - 1);
    const url = `${this.baseUrl}/company/${companyUuid}/discounts/products/check?${productParams}`;
    return this.apiService.doPost(url, body).pipe(
      map(response => {
        if (response.status !== 200) {
          return [];
        }
        return response.data?.products.map((item: any) => { return this.dataToProductCard(item); });
      })
    );
  }

  dataToProductCard(item: any): IProductCard {
    const productCard: IProductCard = {
      uuid_product: item.uuid_product,
      code: item.code,
      label: item.label,
      ttc_price: +item.ttc_price,
      image: 'assets/img/no-img.svg',
      ttc_pack_price: +item.ttc_pack_price,
      isAvailable: item.discounts?.length === 0,
      is_active: item.is_active,
      isSelected: false,
      discounts: item.discounts,
      category: {
        id_category: item.category?.id_category,
        uuid_category:  item.category?.id_category,
        label: item.category?.label
      }
    };

    productCard.product_packs = item.product_packs?.map ((e: any) => e.product);
    productCard.product_packs?.forEach(e => {
      e.isAvailable = true;
    });
    return productCard;
  }

  updateAddedProduct(data: IProductCard[]): void {
    this.addedProduct$.next(data);
  }

  getCategories(company_uuid: string) {
    let url = `${this.env.apiCatalogService}/categories/company/${company_uuid}`;
    return this.apiService.doGet(url);
  }

  getTableHeader(currency: string): IHeader[] {
    return [
      {
        value: 'Article'
      },
      {
        value: 'Catégorie'
      },
      {
        value: 'Code Article'
      },
      {
        value: `Prix HT ${currency}`,
        alignement: "right"
      },
      {
        value: `TVA`,
        alignement: "center"
      },
      {
        value: `Prix TTC ${currency}`,
        alignement: "right"
      },
      {
        value: 'Seuil réapprovisionnement',
        alignement: "right"
      }
    ]
  }

  getProducts(companyUuid: string, _params: Object = {}): Observable<I_ApiResponse> {
    let params = {
      company: companyUuid,
      is_pack: 0
    };
    if (_params['page'] && _params['page'] != 0) params['page'] = _params['page'];
    if (_params['search'] && _params['search'] != '') params['search'] = _params['search'];
    if (_params['category'] && _params['category'] != 'all') params['category'] = _params['category'];
    if (_params['serialization'] == 'is-serializable') {
      params['is_serializable'] = 1;
    } else if (_params['serialization'] == 'no-serializable') {
      params['is_serializable'] = 0;
    }
    const url = `${this.env.apiCatalogService}/search`;
    return this.apiService.doGet(url, params);
  }

  generateProductTable(product: I_Product): IRow {
    return {
      id: product.uuid_product,
      is_expandable: false,
      rowValue: [
        {
          id: product.uuid_product,
          key: 'label',
          type: 'simple',
          expand: false,
          image: product.image_url ? product.image_url : 'assets/img/no-img.svg',
          value: {
            value: product.label
          }
        },
        {
          id: product.uuid_product,
          key: 'category',
          type: 'simple',
          expand: false,
          value: {
            value: product.category.label
          }
        },
        {
          id: product.uuid_product,
          key: 'code',
          type: 'simple',
          expand: false,
          value: {
            value: product.code
          }
        },
        {
          id: product.uuid_product,
          key: 'htPrice',
          type: 'simple',
          expand: false,
          value: {
            value: this.helperService.formatNumber(product.ht_price,2)
          },
          align: 'right'
        },
        {
          id: product.uuid_product,
          key: 'tva',
          type: 'simple',
          expand: false,
          value: {
            value: this.helperService.formatNumber(product.tva, 2) + '%'
          },
          align: 'center'
        },
        {
          id: product.uuid_product,
          key: 'ttcPrice',
          type: 'simple',
          expand: false,
          value: {
            value: this.helperService.formatNumber(product.ttc_price, 2)
          },
          align: 'right'
        },
        {
          id: product.uuid_product,
          key: 'approv',
          type: 'simple',
          expand: false,
          value: {
            value: product['restocking_threshold']
          },
          align: 'right'
        }
      ]
    }
  }

  addFilterValue(categories: ITableFilterFieldNewValue[] = [], serializations: ITableFilterFieldNewValue[] = []): ITableFilterNew {
    return {
      id: 'article',
      title: "Liste de vos articles",
      fields: [
        {
          key: tableFilter.key.keyword,
          label: "Mot clé",
          type: 'input',
          placeholder: 'Code / Nom article'
        },
        {
          key: tableFilter.key.product_category,
          label: 'Catégorie',
          type: "select",
          value: categories
        },
        {
          key: tableFilter.key.product_serialization,
          label: "Sérialisé",
          type: "select",
          value: serializations
        }
      ]
    }
  }

  getProductDetail(uuid: string): Observable<I_ApiResponse> {
    const url = `${this.env.apiCatalogService}/${uuid}`;
    return this.apiService.doGet(url)
  }

  setProductFormValue(value: Object) {
    this.productFormValue$.next(value);
  }

  updateProduct(product_uuid: string, value: Object): Observable<I_ApiResponse> {
    let url = `${this.env.apiCatalogService}/${product_uuid}/set`;
    return this.apiService.doPut(url, value);
  }
}
