import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { I_LeftMenu } from '../../../models/left-menu';
import { MiscService } from '../../../services/miscellaniscious/misc.service';
import { SidebarService } from '../../../services/sidebar/sidebar.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  @Input() uniqueId?: string = '';
  @Input() skipLocatation: boolean = false;
  @Input() disabled: boolean = false;
  subscription: Subscription = new Subscription();
  menu: I_LeftMenu | null = null;

  constructor(
    private sidebarService: SidebarService,
    private miscService: MiscService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.sidebarService.leftMenu$.subscribe((menu: I_LeftMenu | null) => {
        if (menu && menu.id === this.uniqueId) this.menu = menu;
        else if(this.uniqueId === '' && menu && !menu.id) this.menu = menu;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goTo(url: string) {
    let hasAction = false;
    let i = 0;
    this.menu.menu_item.forEach((item, index) => {
      item.active = item.url === url;
      if (item.url === url) {
        hasAction = !!item.action;
        i = index;
      }
    });
    if (hasAction) {
      this.menu.menu_item[i].action();
    }
    this.miscService.goTo(url, this.skipLocatation);

    // ------------------------------------------------------- Custom
    // set to onload page
    let tab = url.split('/').pop();

    if (tab==='access-management' || tab==="information" || tab==='attached-shop')  {
      this.sidebarService.activeClass$.next(true);
    } else {
      this.sidebarService.activeClass$.next(false);
    }
  }

}
