export const fileConstants = {
  csv: {
    maxSize: 8000000,
    type: 'text/csv',
  },
  fileSignature: {
    'utf8-without-BOM': 'EFBBBF',
    'utf8': '5B464952'
  }
};
