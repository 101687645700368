<div class="message-box">
    {{ copyStatus }}
</div>  
<div class="clearfix mhl ptl">
    <h1 class="mvm mtn fgc1">Grid Size: Unknown</h1>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-see-detail\'></i>')" title="Copier">
            <span class="icn-see-detail"></span>
            <span class="mls" > icn-see-detail</span>
        </div>  
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e922" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe922;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-import-item\'></i>')" title="Copier">
            <span class="icn-import-item"></span>
            <span class="mls"> icn-import-item</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e92e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe92e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-import-shop\'></i>')" title="Copier">
            <span class="icn-import-shop"></span>
            <span class="mls"> icn-import-shop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e92b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe92b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bad\'></i>')" title="Copier">
            <span class="icn-bad"></span>
            <span class="mls"> icn-bad</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e932" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe932;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-good\'></i>')" title="Copier">
            <span class="icn-good"></span>
            <span class="mls"> icn-good</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e979" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe979;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-item-sold\'></i>')" title="Copier">
            <span class="icn-item-sold"></span>
            <span class="mls"> icn-item-sold</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e97f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe97f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-last-connection\'></i>')" title="Copier">
            <span class="icn-last-connection"></span>
            <span class="mls"> icn-last-connection</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e980" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe980;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-profil-man\'></i>')" title="Copier">
            <span class="icn-profil-man"></span>
            <span class="mls"> icn-profil-man</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e981" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe981;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-profil-man-outline\'></i>')" title="Copier">
            <span class="icn-profil-man-outline"></span>
            <span class="mls"> icn-profil-man-outline</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e982" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe982;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-profil-woman\'></i>')" title="Copier">
            <span class="icn-profil-woman"></span>
            <span class="mls"> icn-profil-woman</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e983" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe983;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-profil-woman-outline\'></i>')" title="Copier">
            <span class="icn-profil-woman-outline"></span>
            <span class="mls"> icn-profil-woman-outline</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e984" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe984;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-turnover\'></i>')" title="Copier">
            <span class="icn-turnover"></span>
            <span class="mls"> icn-turnover</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e994" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe994;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-quick-access\'></i>')" title="Copier">
            <span class="icn-quick-access"></span>
            <span class="mls"> icn-quick-access</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e99f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe99f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-export-category\'></i>')" title="Copier">
            <span class="icn-export-category"></span>
            <span class="mls"> icn-export-category</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a0" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a0;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-miscellaneous-output\'></i>')" title="Copier">
            <span class="icn-miscellaneous-output"></span>
            <span class="mls"> icn-miscellaneous-output</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a9" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a9;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-delete-shop\'></i>')" title="Copier">
            <span class="icn-delete-shop"></span>
            <span class="mls"> icn-delete-shop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9bc" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9bc;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-change-shop\'></i>')" title="Copier">
            <span class="icn-change-shop"></span>
            <span class="mls"> icn-change-shop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c9" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c9;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icon-icn-export-shop\'></i>')" title="Copier">
            <span class="icn-export-shop"></span>
            <span class="mls"> icn-export-shop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9cc" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9cc;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-change-item\'></i>')" title="Copier">
            <span class="icn-change-item"></span>
            <span class="mls"> icn-change-item</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d1" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d1;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-delete-category-customer\'></i>')" title="Copier">
            <span class="icn-delete-category-customer"></span>
            <span class="mls"> icn-delete-category-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d3" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d3;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-change-category-customer\'></i>')" title="Copier">
            <span class="icn-change-category-customer"></span>
            <span class="mls"> icn-change-category-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d5" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d5;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-category-customer\'></i>')" title="Copier">
            <span class="icn-add-category-customer"></span>
            <span class="mls"> icn-add-category-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d6" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d6;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-import-category-customer\'></i>')" title="Copier">
            <span class="icn-import-category-customer"></span>
            <span class="mls"> icn-import-category-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d7" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d7;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-export-category-customer\'></i>')" title="Copier">
            <span class="icn-export-category-customer"></span>
            <span class="mls"> icn-export-category-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d8" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d8;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-import-customer\'></i>')" title="Copier">
            <span class="icn-import-customer"></span>
            <span class="mls"> icn-import-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d9" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d9;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-customer\'></i>')" title="Copier">
            <span class="icn-add-customer"></span>
            <span class="mls"> icn-add-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9db" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9db;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icon-icn-export-customer\'></i>')" title="Copier">
            <span class="icn-export-customer"></span>
            <span class="mls"> icn-export-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9dc" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9dc;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-change-customer\'></i>')" title="Copier">
            <span class="icn-change-customer"></span>
            <span class="mls"> icn-change-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9dd" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9dd;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-delete-customer\'></i>')" title="Copier">
            <span class="icn-delete-customer"></span>
            <span class="mls"> icn-delete-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9de" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9de;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-create-item-package\'></i>')" title="Copier">
            <span class="icn-create-item-package"></span>
            <span class="mls"> icn-create-item-package</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9df" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9df;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-import-category\'></i>')" title="Copier">
            <span class="icn-import-category"></span>
            <span class="mls"> icn-import-category</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9e0" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9e0;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-delete-item\'></i>')" title="Copier">
            <span class="icn-delete-item"></span>
            <span class="mls"> icn-delete-item</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9e1" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9e1;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icon-icn-export-item\'></i>')" title="Copier">
            <span class="icn-export-item"></span>
            <span class="mls"> icn-export-item</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9e2" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9e2;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-item\'></i>')" title="Copier">
            <span class="icn-add-item"></span>
            <span class="mls"> icn-add-item</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9e4" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9e4;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-miscellaneous-input\'></i>')" title="Copier">
            <span class="icn-miscellaneous-input"></span>
            <span class="mls"> icn-miscellaneous-input</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9e5" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9e5;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-empty-page\'></i>')" title="Copier">
            <span class="icn-empty-page"></span>
            <span class="mls"> icn-empty-page</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9e8" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9e8;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-profile--employee\'></i>')" title="Copier">
            <span class="icn-profile--employee"></span>
            <span class="mls"> icn-profile--employee</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9eb" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9eb;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-increase\'></i>')" title="Copier">
            <span class="icn-increase"></span>
            <span class="mls"> icn-increase</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ec" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ec;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-decrease\'></i>')" title="Copier">
            <span class="icn-decrease"></span>
            <span class="mls"> icn-decrease</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ed" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ed;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-checkout\'></i>')" title="Copier">
            <span class="icn-add-checkout"></span>
            <span class="mls"> icn-add-checkout</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ef" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ef;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-change-category\'></i>')" title="Copier">
            <span class="icn-change-category"></span>
            <span class="mls"> icn-change-category</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9f0" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9f0;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-category\'></i>')" title="Copier">
            <span class="icn-add-category"></span>
            <span class="mls"> icn-add-category</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9f1" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9f1;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-delete-category\'></i>')" title="Copier">
            <span class="icn-delete-category"></span>
            <span class="mls"> icn-delete-category</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9f2" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9f2;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-shop\'></i>')" title="Copier">
            <span class="icn-add-shop"></span>
            <span class="mls"> icn-add-shop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9f3" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9f3;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-discount\'></i>')" title="Copier">
            <span class="icn-discount"></span>
            <span class="mls"> icn-discount</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9f4" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9f4;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-safe\'></i>')" title="Copier">
            <span class="icn-safe"></span>
            <span class="mls"> icn-safe</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="eaa0" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xeaa0;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-write\'></i>')" title="Copier">
            <span class="icn-write"></span>
            <span class="mls"> icn-write</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e903" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe903;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-ellipse\'></i>')" title="Copier">
            <span class="icn-add-ellipse"></span>
            <span class="mls"> icn-add-ellipse</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e905" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe905;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add-square\'></i>')" title="Copier">
            <span class="icn-add-square"></span>
            <span class="mls"> icn-add-square</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e942" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe942;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-anchor-link\'></i>')" title="Copier">
            <span class="icn-anchor-link"></span>
            <span class="mls"> icn-anchor-link</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e943" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe943;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-archive\'></i>')" title="Copier">
            <span class="icn-archive"></span>
            <span class="mls"> icn-archive</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e944" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe944;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-decrease\'></i>')" title="Copier">
            <span class="icn-arrow-decrease"></span>
            <span class="mls"> icn-arrow-decrease</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e945" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe945;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-diagonal\'></i>')" title="Copier">
            <span class="icn-arrow-diagonal"></span>
            <span class="mls"> icn-arrow-diagonal</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e946" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe946;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-diagonal-double\'></i>')" title="Copier">
            <span class="icn-arrow-diagonal-double"></span>
            <span class="mls"> icn-arrow-diagonal-double</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e947" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe947;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-diagonal-double-in\'></i>')" title="Copier">
            <span class="icn-arrow-diagonal-double-in"></span>
            <span class="mls"> icn-arrow-diagonal-double-in</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e948" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe948;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-diagonal-double-opp\'></i>')" title="Copier">
            <span class="icn-arrow-diagonal-double-opp"></span>
            <span class="mls"> icn-arrow-diagonal-double-opp</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e949" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe949;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-diagonal-opp\'></i>')" title="Copier">
            <span class="icn-arrow-diagonal-opp"></span>
            <span class="mls"> icn-arrow-diagonal-opp</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e94a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe94a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-double-horizontal\'></i>')" title="Copier">
            <span class="icn-arrow-double-horizontal"></span>
            <span class="mls"> icn-arrow-double-horizontal</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e94b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe94b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-double-horizontal-in\'></i>')" title="Copier">
            <span class="icn-arrow-double-horizontal-in"></span>
            <span class="mls"> icn-arrow-double-horizontal-in</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e94c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe94c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-double-vertical\'></i>')" title="Copier">
            <span class="icn-arrow-double-vertical"></span>
            <span class="mls"> icn-arrow-double-vertical</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e94d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe94d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-double-vertical-in\'></i>')" title="Copier">
            <span class="icn-arrow-double-vertical-in"></span>
            <span class="mls"> icn-arrow-double-vertical-in</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e94e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe94e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-down\'></i>')" title="Copier">
            <span class="icn-arrow-down"></span>
            <span class="mls"> icn-arrow-down</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e94f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe94f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-down-1\'></i>')" title="Copier">
            <span class="icn-arrow-down-1"></span>
            <span class="mls"> icn-arrow-down-1</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e950" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe950;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-down-up\'></i>')" title="Copier">
            <span class="icn-arrow-down-up"></span>
            <span class="mls"> icn-arrow-down-up</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e951" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe951;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-down-up-in\'></i>')" title="Copier">
            <span class="icn-arrow-down-up-in"></span>
            <span class="mls"> icn-arrow-down-up-in</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e952" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe952;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-dual-horizontal\'></i>')" title="Copier">
            <span class="icn-arrow-dual-horizontal"></span>
            <span class="mls"> icn-arrow-dual-horizontal</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e953" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe953;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-dual-vertical\'></i>')" title="Copier">
            <span class="icn-arrow-dual-vertical"></span>
            <span class="mls"> icn-arrow-dual-vertical</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e954" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe954;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-income\'></i>')" title="Copier">
            <span class="icn-arrow-income"></span>
            <span class="mls"> icn-arrow-income</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e955" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe955;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-left\'></i>')" title="Copier">
            <span class="icn-arrow-left"></span>
            <span class="mls"> icn-arrow-left</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e957" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe957;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-left-right\'></i>')" title="Copier">
            <span class="icn-arrow-left-right"></span>
            <span class="mls"> icn-arrow-left-right</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e958" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe958;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-left-right-in\'></i>')" title="Copier">
            <span class="icn-arrow-left-right-in"></span>
            <span class="mls"> icn-arrow-left-right-in</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e959" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe959;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-next\'></i>')" title="Copier">
            <span class="icn-arrow-next"></span>
            <span class="mls"> icn-arrow-next</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e95a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe95a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-next-small\'></i>')" title="Copier">
            <span class="icn-arrow-next-small"></span>
            <span class="mls"> icn-arrow-next-small</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e95b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe95b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-outcome\'></i>')" title="Copier">
            <span class="icn-arrow-outcome"></span>
            <span class="mls"> icn-arrow-outcome</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e95c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe95c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-prev\'></i>')" title="Copier">
            <span class="icn-arrow-prev"></span>
            <span class="mls"> icn-arrow-prev</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e95d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe95d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-prev-small\'></i>')" title="Copier">
            <span class="icn-arrow-prev-small"></span>
            <span class="mls"> icn-arrow-prev-small</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e95e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe95e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-resize\'></i>')" title="Copier">
            <span class="icn-arrow-resize"></span>
            <span class="mls"> icn-arrow-resize</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e95f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe95f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-right\'></i>')" title="Copier">
            <span class="icn-arrow-right"></span>
            <span class="mls"> icn-arrow-right</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e960" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe960;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-up\'></i>')" title="Copier">
            <span class="icn-arrow-up"></span>
            <span class="mls"> icn-arrow-up</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e961" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe961;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-arrow-up-1\'></i>')" title="Copier">
            <span class="icn-arrow-up-1"></span>
            <span class="mls"> icn-arrow-up-1</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e962" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe962;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-at\'></i>')" title="Copier">
            <span class="icn-at"></span>
            <span class="mls"> icn-at</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e964" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe964;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-attach\'></i>')" title="Copier">
            <span class="icn-attach"></span>
            <span class="mls"> icn-attach</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e965" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe965;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-other-nav\'></i>')" title="Copier">
            <span class="icn-other-nav"></span>
            <span class="mls"> icn-other-nav</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e967" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe967;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-backspace\'></i>')" title="Copier">
            <span class="icn-backspace"></span>
            <span class="mls"> icn-backspace</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e968" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe968;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-trash-2\'></i>')" title="Copier">
            <span class="icn-trash-2"></span>
            <span class="mls"> icn-trash-2</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e969" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe969;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bell\'></i>')" title="Copier">
            <span class="icn-bell"></span>
            <span class="mls"> icn-bell</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e96a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe96a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bell-off\'></i>')" title="Copier">
            <span class="icn-bell-off"></span>
            <span class="mls"> icn-bell-off</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e96b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe96b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bell-ringing\'></i>')" title="Copier">
            <span class="icn-bell-ringing"></span>
            <span class="mls"> icn-bell-ringing</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e96c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe96c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-goal\'></i>')" title="Copier">
            <span class="icn-goal"></span>
            <span class="mls"> icn-goal</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e96d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe96d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bluetooth\'></i>')" title="Copier">
            <span class="icn-bluetooth"></span>
            <span class="mls"> icn-bluetooth</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e96e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe96e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bluetooth-off\'></i>')" title="Copier">
            <span class="icn-bluetooth-off"></span>
            <span class="mls"> icn-bluetooth-off</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e96f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe96f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bookmark\'></i>')" title="Copier">
            <span class="icn-bookmark"></span>
            <span class="mls"> icn-bookmark</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e970" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe970;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-box\'></i>')" title="Copier">
            <span class="icn-box"></span>
            <span class="mls"> icn-box</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e971" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe971;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-break-link\'></i>')" title="Copier">
            <span class="icn-break-link"></span>
            <span class="mls"> icn-break-link</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e972" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe972;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-brightness\'></i>')" title="Copier">
            <span class="icn-brightness"></span>
            <span class="mls"> icn-brightness</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e973" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe973;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bug\'></i>')" title="Copier">
            <span class="icn-bug"></span>
            <span class="mls"> icn-bug</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e974" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe974;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bulb\'></i>')" title="Copier">
            <span class="icn-bulb"></span>
            <span class="mls"> icn-bulb</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e975" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe975;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-bullhorn\'></i>')" title="Copier">
            <span class="icn-bullhorn"></span>
            <span class="mls"> icn-bullhorn</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e976" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe976;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-call-cancel\'></i>')" title="Copier">
            <span class="icn-call-cancel"></span>
            <span class="mls"> icn-call-cancel</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e978" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe978;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-call-miss\'></i>')" title="Copier">
            <span class="icn-call-miss"></span>
            <span class="mls"> icn-call-miss</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e97a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe97a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-call-out\'></i>')" title="Copier">
            <span class="icn-call-out"></span>
            <span class="mls"> icn-call-out</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e97b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe97b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-call-pause\'></i>')" title="Copier">
            <span class="icn-call-pause"></span>
            <span class="mls"> icn-call-pause</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e97c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe97c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-call-receive\'></i>')" title="Copier">
            <span class="icn-call-receive"></span>
            <span class="mls"> icn-call-receive</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e97d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe97d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-category\'></i>')" title="Copier">
            <span class="icn-category"></span>
            <span class="mls"> icn-category</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e986" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe986;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat\'></i>')" title="Copier">
            <span class="icn-chat"></span>
            <span class="mls"> icn-chat</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e987" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe987;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat-block\'></i>')" title="Copier">
            <span class="icn-chat-block"></span>
            <span class="mls"> icn-chat-block</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e988" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe988;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat-new\'></i>')" title="Copier">
            <span class="icn-chat-new"></span>
            <span class="mls"> icn-chat-new</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e989" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe989;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat-pending\'></i>')" title="Copier">
            <span class="icn-chat-pending"></span>
            <span class="mls"> icn-chat-pending</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e98a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe98a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat-remove\'></i>')" title="Copier">
            <span class="icn-chat-remove"></span>
            <span class="mls"> icn-chat-remove</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e98b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe98b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat-success\'></i>')" title="Copier">
            <span class="icn-chat-success"></span>
            <span class="mls"> icn-chat-success</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e98c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe98c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat-unread\'></i>')" title="Copier">
            <span class="icn-chat-unread"></span>
            <span class="mls"> icn-chat-unread</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e98d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe98d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-chat-writing\'></i>')" title="Copier">
            <span class="icn-chat-writing"></span>
            <span class="mls"> icn-chat-writing</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e98e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe98e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-checkmark\'></i>')" title="Copier">
            <span class="icn-checkmark"></span>
            <span class="mls"> icn-checkmark</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e98f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe98f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-checkmark-small\'></i>')" title="Copier">
            <span class="icn-checkmark-small"></span>
            <span class="mls"> icn-checkmark-small</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e990" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe990;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-circle-checkmark\'></i>')" title="Copier">
            <span class="icn-circle-checkmark"></span>
            <span class="mls"> icn-circle-checkmark</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e991" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe991;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-close\'></i>')" title="Copier">
            <span class="icn-close"></span>
            <span class="mls"> icn-close</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e992" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe992;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-close-ellipse\'></i>')" title="Copier">
            <span class="icn-close-ellipse"></span>
            <span class="mls"> icn-close-ellipse</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e993" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe993;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cloud\'></i>')" title="Copier">
            <span class="icn-cloud"></span>
            <span class="mls"> icn-cloud</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e995" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe995;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cloud-disconnect\'></i>')" title="Copier">
            <span class="icn-cloud-disconnect"></span>
            <span class="mls"> icn-cloud-disconnect</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e996" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe996;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cloud-success\'></i>')" title="Copier">
            <span class="icn-cloud-success"></span>
            <span class="mls"> icn-cloud-success</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e997" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe997;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-component\'></i>')" title="Copier">
            <span class="icn-component"></span>
            <span class="mls"> icn-component</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e998" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe998;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-connect\'></i>')" title="Copier">
            <span class="icn-connect"></span>
            <span class="mls"> icn-connect</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e999" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe999;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-contacts\'></i>')" title="Copier">
            <span class="icn-contacts"></span>
            <span class="mls"> icn-contacts</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e99a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe99a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-copy\'></i>')" title="Copier">
            <span class="icn-copy"></span>
            <span class="mls"> icn-copy</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e99b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe99b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cpu\'></i>')" title="Copier">
            <span class="icn-cpu"></span>
            <span class="mls"> icn-cpu</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e99c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe99c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-create-link\'></i>')" title="Copier">
            <span class="icn-create-link"></span>
            <span class="mls"> icn-create-link</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e99e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe99e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cross\'></i>')" title="Copier">
            <span class="icn-cross"></span>
            <span class="mls"> icn-cross</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a1" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a1;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-csv\'></i>')" title="Copier">
            <span class="icn-csv"></span>
            <span class="mls"> icn-csv</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a2" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a2;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cube\'></i>')" title="Copier">
            <span class="icn-cube"></span>
            <span class="mls"> icn-cube</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a3" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a3;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cup\'></i>')" title="Copier">
            <span class="icn-cup"></span>
            <span class="mls"> icn-cup</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a4" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a4;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cursor\'></i>')" title="Copier">
            <span class="icn-cursor"></span>
            <span class="mls"> icn-cursor</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a5" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a5;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-dialpad\'></i>')" title="Copier">
            <span class="icn-dialpad"></span>
            <span class="mls"> icn-dialpad</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a6" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a6;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-diamond\'></i>')" title="Copier">
            <span class="icn-diamond"></span>
            <span class="mls"> icn-diamond</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a7" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a7;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-dice\'></i>')" title="Copier">
            <span class="icn-dice"></span>
            <span class="mls"> icn-dice</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9a8" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9a8;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-discover-compass\'></i>')" title="Copier">
            <span class="icn-discover-compass"></span>
            <span class="mls"> icn-discover-compass</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9aa" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9aa;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-dislike\'></i>')" title="Copier">
            <span class="icn-dislike"></span>
            <span class="mls"> icn-dislike</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ab" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ab;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-display\'></i>')" title="Copier">
            <span class="icn-display"></span>
            <span class="mls"> icn-display</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ac" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ac;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-document\'></i>')" title="Copier">
            <span class="icn-document"></span>
            <span class="mls"> icn-document</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ad" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ad;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-dots-horizontal\'></i>')" title="Copier">
            <span class="icn-dots-horizontal"></span>
            <span class="mls"> icn-dots-horizontal</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ae" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ae;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-dots-vertical\'></i>')" title="Copier">
            <span class="icn-dots-vertical"></span>
            <span class="mls"> icn-dots-vertical</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9af" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9af;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-download\'></i>')" title="Copier">
            <span class="icn-download"></span>
            <span class="mls"> icn-download</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b0" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b0;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-drag\'></i>')" title="Copier">
            <span class="icn-drag"></span>
            <span class="mls"> icn-drag</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b1" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b1;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-drop\'></i>')" title="Copier">
            <span class="icn-drop"></span>
            <span class="mls"> icn-drop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b2" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b2;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-duplicate\'></i>')" title="Copier">
            <span class="icn-duplicate"></span>
            <span class="mls"> icn-duplicate</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b3" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b3;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-earth\'></i>')" title="Copier">
            <span class="icn-earth"></span>
            <span class="mls"> icn-earth</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b4" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b4;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-edit\'></i>')" title="Copier">
            <span class="icn-edit"></span>
            <span class="mls"> icn-edit</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b5" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b5;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-eye-off\'></i>')" title="Copier">
            <span class="icn-eye-off"></span>
            <span class="mls"> icn-eye-off</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b6" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b6;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-eye-show\'></i>')" title="Copier">
            <span class="icn-eye-show"></span>
            <span class="mls"> icn-eye-show</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b7" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b7;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-feedback\'></i>')" title="Copier">
            <span class="icn-feedback"></span>
            <span class="mls"> icn-feedback</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b8" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b8;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-female\'></i>')" title="Copier">
            <span class="icn-female"></span>
            <span class="mls"> icn-female</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9b9" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9b9;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-filter\'></i>')" title="Copier">
            <span class="icn-filter"></span>
            <span class="mls"> icn-filter</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9ba" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9ba;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-fingerprint\'></i>')" title="Copier">
            <span class="icn-fingerprint"></span>
            <span class="mls"> icn-fingerprint</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9bb" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9bb;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-flag\'></i>')" title="Copier">
            <span class="icn-flag"></span>
            <span class="mls"> icn-flag</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9bd" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9bd;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-flag-triangle\'></i>')" title="Copier">
            <span class="icn-flag-triangle"></span>
            <span class="mls"> icn-flag-triangle</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9be" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9be;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-flashlight\'></i>')" title="Copier">
            <span class="icn-flashlight"></span>
            <span class="mls"> icn-flashlight</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9bf" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9bf;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-folder-add\'></i>')" title="Copier">
            <span class="icn-folder-add"></span>
            <span class="mls"> icn-folder-add</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c1" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c1;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-folder-block\'></i>')" title="Copier">
            <span class="icn-folder-block"></span>
            <span class="mls"> icn-folder-block</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c2" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c2;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-folder-lock\'></i>')" title="Copier">
            <span class="icn-folder-lock"></span>
            <span class="mls"> icn-folder-lock</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c3" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c3;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-folder-move\'></i>')" title="Copier">
            <span class="icn-folder-move"></span>
            <span class="mls"> icn-folder-move</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c4" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c4;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-folder-remove\'></i>')" title="Copier">
            <span class="icn-folder-remove"></span>
            <span class="mls"> icn-folder-remove</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c5" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c5;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-folder-remove-1\'></i>')" title="Copier">
            <span class="icn-folder-remove-1"></span>
            <span class="mls"> icn-folder-remove-1</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c6" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c6;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-folder-share\'></i>')" title="Copier">
            <span class="icn-folder-share"></span>
            <span class="mls"> icn-folder-share</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c7" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c7;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-forward\'></i>')" title="Copier">
            <span class="icn-forward"></span>
            <span class="mls"> icn-forward</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9c8" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9c8;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-newspapper\'></i>')" title="Copier">
            <span class="icn-newspapper"></span>
            <span class="mls"> icn-newspapper</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9cb" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9cb;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user-2\'></i>')" title="Copier">
            <span class="icn-user-2"></span>
            <span class="mls"> icn-user-2</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d0" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d0;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-info-ellipse\'></i>')" title="Copier">
            <span class="icn-info-ellipse"></span>
            <span class="mls"> icn-info-ellipse</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e9d4" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe9d4;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-hashtag-2\'></i>')" title="Copier">
            <span class="icn-hashtag-2"></span>
            <span class="mls"> icn-hashtag-2</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea1e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea1e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-game-controller\'></i>')" title="Copier">
            <span class="icn-game-controller"></span>
            <span class="mls"> icn-game-controller</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea1f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea1f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-hand\'></i>')" title="Copier">
            <span class="icn-hand"></span>
            <span class="mls"> icn-hand</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea21" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea21;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-hashtag\'></i>')" title="Copier">
            <span class="icn-hashtag"></span>
            <span class="mls"> icn-hashtag</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea22" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea22;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-hdd\'></i>')" title="Copier">
            <span class="icn-hdd"></span>
            <span class="mls"> icn-hdd</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea23" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea23;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-heart\'></i>')" title="Copier">
            <span class="icn-heart"></span>
            <span class="mls"> icn-heart</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea24" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea24;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-history\'></i>')" title="Copier">
            <span class="icn-history"></span>
            <span class="mls"> icn-history</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea26" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea26;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-home\'></i>')" title="Copier">
            <span class="icn-home"></span>
            <span class="mls"> icn-home</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea27" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea27;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-hotspot\'></i>')" title="Copier">
            <span class="icn-hotspot"></span>
            <span class="mls"> icn-hotspot</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea28" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea28;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-sale-bold\'></i>')" title="Copier">
            <span class="icn-sale-bold"></span>
            <span class="mls"> icn-sale-bold</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea2a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea2a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-image\'></i>')" title="Copier">
            <span class="icn-image"></span>
            <span class="mls"> icn-image</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea2b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea2b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-inbox\'></i>')" title="Copier">
            <span class="icn-inbox"></span>
            <span class="mls"> icn-inbox</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea2c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea2c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-info\'></i>')" title="Copier">
            <span class="icn-info-ellipse"></span>
            <span class="mls"> icn-info</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea2d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea2d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-joystick\'></i>')" title="Copier">
            <span class="icn-joystick"></span>
            <span class="mls"> icn-joystick</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea2e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea2e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-key\'></i>')" title="Copier">
            <span class="icn-key"></span>
            <span class="mls"> icn-key</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea2f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea2f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-keyboard\'></i>')" title="Copier">
            <span class="icn-keyboard"></span>
            <span class="mls"> icn-keyboard</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea30" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea30;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-keyboard-hide\'></i>')" title="Copier">
            <span class="icn-keyboard-hide"></span>
            <span class="mls"> icn-keyboard-hide</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea31" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea31;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-keyboard-show\'></i>')" title="Copier">
            <span class="icn-keyboard-show"></span>
            <span class="mls"> icn-keyboard-show</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea32" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea32;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-laptop\'></i>')" title="Copier">
            <span class="icn-laptop"></span>
            <span class="mls"> icn-laptop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea33" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea33;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-layers\'></i>')" title="Copier">
            <span class="icn-layers"></span>
            <span class="mls"> icn-layers</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea34" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea34;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-levels\'></i>')" title="Copier">
            <span class="icn-levels"></span>
            <span class="mls"> icn-levels</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea35" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea35;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-like\'></i>')" title="Copier">
            <span class="icn-like"></span>
            <span class="mls"> icn-like</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea36" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea36;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-location\'></i>')" title="Copier">
            <span class="icn-location"></span>
            <span class="mls"> icn-location</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea37" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea37;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-location-define\'></i>')" title="Copier">
            <span class="icn-location-define"></span>
            <span class="mls"> icn-location-define</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea38" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea38;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-location-straight\'></i>')" title="Copier">
            <span class="icn-location-straight"></span>
            <span class="mls"> icn-location-straight</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea39" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea39;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-lock\'></i>')" title="Copier">
            <span class="icn-lock"></span>
            <span class="mls"> icn-lock</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea3a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea3a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-log-in\'></i>')" title="Copier">
            <span class="icn-log-in"></span>
            <span class="mls"> icn-log-in</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea3b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea3b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-log-out\'></i>')" title="Copier">
            <span class="icn-log-out"></span>
            <span class="mls"> icn-log-out</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea3c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea3c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-mail\'></i>')" title="Copier">
            <span class="icn-mail"></span>
            <span class="mls"> icn-mail</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea3d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea3d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-mail-open\'></i>')" title="Copier">
            <span class="icn-mail-open"></span>
            <span class="mls"> icn-mail-open</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea3e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea3e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-male\'></i>')" title="Copier">
            <span class="icn-male"></span>
            <span class="mls"> icn-male</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea3f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea3f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-map\'></i>')" title="Copier">
            <span class="icn-map"></span>
            <span class="mls"> icn-map</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea40" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea40;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-map-pin\'></i>')" title="Copier">
            <span class="icn-map-pin"></span>
            <span class="mls"> icn-map-pin</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea41" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea41;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-map-tag\'></i>')" title="Copier">
            <span class="icn-map-tag"></span>
            <span class="mls"> icn-map-tag</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea42" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea42;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-map-tag-add\'></i>')" title="Copier">
            <span class="icn-map-tag-add"></span>
            <span class="mls"> icn-map-tag-add</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea43" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea43;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-map-tag-minus\'></i>')" title="Copier">
            <span class="icn-map-tag-minus"></span>
            <span class="mls"> icn-map-tag-minus</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea44" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea44;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-map-tag-remove\'></i>')" title="Copier">
            <span class="icn-map-tag-remove"></span>
            <span class="mls"> icn-map-tag-remove</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea45" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea45;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-map-tag-user\'></i>')" title="Copier">
            <span class="icn-map-tag-user"></span>
            <span class="mls"> icn-map-tag-user</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea46" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea46;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-maximize\'></i>')" title="Copier">
            <span class="icn-maximize"></span>
            <span class="mls"> icn-maximize</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea47" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea47;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-best-seller\'></i>')" title="Copier">
            <span class="icn-best-seller"></span>
            <span class="mls"> icn-best-seller</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea48" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea48;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-navigation\'></i>')" title="Copier">
            <span class="icn-navigation"></span>
            <span class="mls"> icn-navigation</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea49" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea49;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-minimize\'></i>')" title="Copier">
            <span class="icn-minimize"></span>
            <span class="mls"> icn-minimize</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea4a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea4a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-minus\'></i>')" title="Copier">
            <span class="icn-minus"></span>
            <span class="mls"> icn-minus</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea4b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea4b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-monoblock\'></i>')" title="Copier">
            <span class="icn-monoblock"></span>
            <span class="mls"> icn-monoblock</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea4d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea4d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-moon\'></i>')" title="Copier">
            <span class="icn-moon"></span>
            <span class="mls"> icn-moon</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea4e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea4e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-mouse\'></i>')" title="Copier">
            <span class="icn-mouse"></span>
            <span class="mls"> icn-mouse</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea4f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea4f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-move\'></i>')" title="Copier">
            <span class="icn-move"></span>
            <span class="mls"> icn-move</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea50" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea50;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-newspaper\'></i>')" title="Copier">
            <span class="icn-newspaper"></span>
            <span class="mls"> icn-newspaper</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea51" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea51;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-new-tab\'></i>')" title="Copier">
            <span class="icn-new-tab"></span>
            <span class="mls"> icn-new-tab</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea52" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea52;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-open-checkout\'></i>')" title="Copier">
            <span class="icn-open-checkout"></span>
            <span class="mls"> icn-open-checkout</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea53" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea53;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-credit-card\'></i>')" title="Copier">
            <span class="icn-credit-card"></span>
            <span class="mls"> icn-credit-card</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea54" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea54;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-payment-by-check\'></i>')" title="Copier">
            <span class="icn-payment-by-check"></span>
            <span class="mls"> icn-payment-by-check</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea55" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea55;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-paste\'></i>')" title="Copier">
            <span class="icn-paste"></span>
            <span class="mls"> icn-paste</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea59" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea59;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-pin\'></i>')" title="Copier">
            <span class="icn-pin"></span>
            <span class="mls"> icn-pin</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea5b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea5b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-add\'></i>')" title="Copier">
            <span class="icn-add"></span>
            <span class="mls"> icn-add</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea5c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea5c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-power-button\'></i>')" title="Copier">
            <span class="icn-power-button"></span>
            <span class="mls"> icn-power-button</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea5d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea5d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-printer\'></i>')" title="Copier">
            <span class="icn-printer"></span>
            <span class="mls"> icn-printer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea5f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea5f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-rate\'></i>')" title="Copier">
            <span class="icn-rate"></span>
            <span class="mls"> icn-rate</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea60" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea60;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-re-load\'></i>')" title="Copier">
            <span class="icn-re-load"></span>
            <span class="mls"> icn-re-load</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea64" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea64;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-remove-ellipse\'></i>')" title="Copier">
            <span class="icn-remove-ellipse"></span>
            <span class="mls"> icn-remove-ellipse</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea65" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea65;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-remove-square\'></i>')" title="Copier">
            <span class="icn-remove-square"></span>
            <span class="mls"> icn-remove-square</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea66" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea66;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-reply\'></i>')" title="Copier">
            <span class="icn-reply"></span>
            <span class="mls"> icn-reply</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea67" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea67;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-report\'></i>')" title="Copier">
            <span class="icn-report"></span>
            <span class="mls"> icn-report</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea68" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea68;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-restrict\'></i>')" title="Copier">
            <span class="icn-restrict"></span>
            <span class="mls"> icn-restrict</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea6a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea6a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-route\'></i>')" title="Copier">
            <span class="icn-route"></span>
            <span class="mls"> icn-route</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea6b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea6b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-route-arrow\'></i>')" title="Copier">
            <span class="icn-route-arrow"></span>
            <span class="mls"> icn-route-arrow</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea6c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea6c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-router\'></i>')" title="Copier">
            <span class="icn-router"></span>
            <span class="mls"> icn-router</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea6d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea6d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-rss\'></i>')" title="Copier">
            <span class="icn-rss"></span>
            <span class="mls"> icn-rss</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea6e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea6e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-scales\'></i>')" title="Copier">
            <span class="icn-scales"></span>
            <span class="mls"> icn-scales</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea70" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea70;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-scaner\'></i>')" title="Copier">
            <span class="icn-scaner"></span>
            <span class="mls"> icn-scaner</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea71" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea71;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-search\'></i>')" title="Copier">
            <span class="icn-search"></span>
            <span class="mls"> icn-search</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea72" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea72;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-send\'></i>')" title="Copier">
            <span class="icn-send"></span>
            <span class="mls"> icn-send</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea73" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea73;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-server\'></i>')" title="Copier">
            <span class="icn-server"></span>
            <span class="mls"> icn-server</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea74" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea74;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-services-mobile-money\'></i>')" title="Copier">
            <span class="icn-services-mobile-money"></span>
            <span class="mls"> icn-services-mobile-money</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea75" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea75;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-settings-2\'></i>')" title="Copier">
            <span class="icn-settings-2"></span>
            <span class="mls"> icn-settings-2</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea76" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea76;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-share\'></i>')" title="Copier">
            <span class="icn-share"></span>
            <span class="mls"> icn-share</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea77" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea77;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-share-1\'></i>')" title="Copier">
            <span class="icn-share-1"></span>
            <span class="mls"> icn-share-1</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea78" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea78;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-share-2\'></i>')" title="Copier">
            <span class="icn-share-2"></span>
            <span class="mls"> icn-share-2</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea79" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea79;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-shield\'></i>')" title="Copier">
            <span class="icn-shield"></span>
            <span class="mls"> icn-shield</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea7a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea7a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-shield-attention\'></i>')" title="Copier">
            <span class="icn-shield-attention"></span>
            <span class="mls"> icn-shield-attention</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea7b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea7b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-shield-checkmark\'></i>')" title="Copier">
            <span class="icn-shield-checkmark"></span>
            <span class="mls"> icn-shield-checkmark</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea7c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea7c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-signal\'></i>')" title="Copier">
            <span class="icn-signal"></span>
            <span class="mls"> icn-signal</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea7e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea7e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-smartphone\'></i>')" title="Copier">
            <span class="icn-smartphone"></span>
            <span class="mls"> icn-smartphone</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea7f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea7f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-smartwatch\'></i>')" title="Copier">
            <span class="icn-smartwatch"></span>
            <span class="mls"> icn-smartwatch</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea80" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea80;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-smartwatch-1\'></i>')" title="Copier">
            <span class="icn-smartwatch-1"></span>
            <span class="mls"> icn-smartwatch-1</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea81" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea81;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-sort\'></i>')" title="Copier">
            <span class="icn-sort"></span>
            <span class="mls"> icn-sort</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea82" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea82;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-square-checkmark\'></i>')" title="Copier">
            <span class="icn-square-checkmark"></span>
            <span class="mls"> icn-square-checkmark</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea83" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea83;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-star\'></i>')" title="Copier">
            <span class="icn-star"></span>
            <span class="mls"> icn-star</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea84" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea84;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-sun\'></i>')" title="Copier">
            <span class="icn-sun"></span>
            <span class="mls"> icn-sun</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea86" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea86;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-switch\'></i>')" title="Copier">
            <span class="icn-switch"></span>
            <span class="mls"> icn-switch</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea88" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea88;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-tablet\'></i>')" title="Copier">
            <span class="icn-tablet"></span>
            <span class="mls"> icn-tablet</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea89" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea89;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-text\'></i>')" title="Copier">
            <span class="icn-text"></span>
            <span class="mls"> icn-text</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea8a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea8a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-trash\'></i>')" title="Copier">
            <span class="icn-trash"></span>
            <span class="mls"> icn-trash</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea8b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea8b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-umbrella\'></i>')" title="Copier">
            <span class="icn-umbrella"></span>
            <span class="mls"> icn-umbrella</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea8c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea8c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-unlock\'></i>')" title="Copier">
            <span class="icn-unlock"></span>
            <span class="mls"> icn-unlock</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea8d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea8d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-unpin\'></i>')" title="Copier">
            <span class="icn-unpin"></span>
            <span class="mls"> icn-unpin</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea8e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea8e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-upload\'></i>')" title="Copier">
            <span class="icn-upload"></span>
            <span class="mls"> icn-upload</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea8f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea8f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-usb\'></i>')" title="Copier">
            <span class="icn-usb"></span>
            <span class="mls"> icn-usb</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea90" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea90;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user\'></i>')" title="Copier">
            <span class="icn-user"></span>
            <span class="mls"> icn-user</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea91" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea91;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user-add\'></i>')" title="Copier">
            <span class="icn-user-add"></span>
            <span class="mls"> icn-user-add</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea92" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea92;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user-block\'></i>')" title="Copier">
            <span class="icn-user-block"></span>
            <span class="mls"> icn-user-block</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea93" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea93;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user-remove\'></i>')" title="Copier">
            <span class="icn-user-remove"></span>
            <span class="mls"> icn-user-remove</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea94" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea94;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user-success\'></i>')" title="Copier">
            <span class="icn-user-success"></span>
            <span class="mls"> icn-user-success</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea95" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea95;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user-wait\'></i>')" title="Copier">
            <span class="icn-user-wait"></span>
            <span class="mls"> icn-user-wait</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea96" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea96;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-view-columns\'></i>')" title="Copier">
            <span class="icn-view-columns"></span>
            <span class="mls"> icn-view-columns</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea98" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea98;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-view-grid\'></i>')" title="Copier">
            <span class="icn-view-grid"></span>
            <span class="mls"> icn-view-grid</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea99" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea99;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-view-grid-many\'></i>')" title="Copier">
            <span class="icn-view-grid-many"></span>
            <span class="mls"> icn-view-grid-many</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea9a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea9a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-view-list\'></i>')" title="Copier">
            <span class="icn-view-list"></span>
            <span class="mls"> icn-view-list</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea9b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea9b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-view-rows\'></i>')" title="Copier">
            <span class="icn-view-rows"></span>
            <span class="mls"> icn-view-rows</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea9c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea9c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-webcam\'></i>')" title="Copier">
            <span class="icn-webcam"></span>
            <span class="mls"> icn-webcam</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea9d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea9d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-wi-fi\'></i>')" title="Copier">
            <span class="icn-wi-fi"></span>
            <span class="mls"> icn-wi-fi</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea9e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea9e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-wi-fi-off\'></i>')" title="Copier">
            <span class="icn-wi-fi-off"></span>
            <span class="mls"> icn-wi-fi-off</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="ea9f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xea9f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-save\'></i>')" title="Copier">
            <span class="icn-save"></span>
            <span class="mls"> icn-save</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e940" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe940;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-payment-check\'></i>')" title="Copier">
            <span class="icn-payment-check"></span>
            <span class="mls"> icn-payment-check</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e937" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe937;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-money-transfer\'></i>')" title="Copier">
            <span class="icn-money-transfer"></span>
            <span class="mls"> icn-money-transfer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e938" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe938;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cash\'></i>')" title="Copier">
            <span class="icn-cash"></span>
            <span class="mls"> icn-cash</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e935" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe935;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-ume\'></i>')" title="Copier">
            <span class="icn-ume"></span>
            <span class="mls"> icn-ume</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e936" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe936;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-sim-card-icon\'></i>')" title="Copier">
            <span class="icn-sim-card-icon"></span>
            <span class="mls"> icn-sim-card-icon</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e934" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe934;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-sim-card-1\'></i>')" title="Copier">
            <span class="icn-sim-card-1"></span>
            <span class="mls"> icn-sim-card-1</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e933" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe933;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-combination\'></i>')" title="Copier">
            <span class="icn-combination"></span>
            <span class="mls"> icn-combination</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e931" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe931;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-category-customer\'></i>')" title="Copier">
            <span class="icn-category-customer"></span>
            <span class="mls"> icn-category-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e93f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe93f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-customer\'></i>')" title="Copier">
            <span class="icn-customer"></span>
            <span class="mls"> icn-customer</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e93e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe93e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-inventory\'></i>')" title="Copier">
            <span class="icn-inventory"></span>
            <span class="mls"> icn-inventory</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e93c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe93c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-logout\'></i>')" title="Copier">
            <span class="icn-logout"></span>
            <span class="mls"> icn-logout</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e93a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe93a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-ico-profile\'></i>')" title="Copier">
            <span class="icn-ico-profile"></span>
            <span class="mls"> icn-ico-profile</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e93b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe93b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-serial-number\'></i>')" title="Copier">
            <span class="icn-serial-number"></span>
            <span class="mls"> icn-serial-number</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e921" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe921;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cash-payment\'></i>')" title="Copier">
            <span class="icn-cash-payment"></span>
            <span class="mls"> icn-cash-payment</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e92a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe92a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-mobile-money\'></i>')" title="Copier">
            <span class="icn-mobile-money"></span>
            <span class="mls"> icn-mobile-money</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e92d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe92d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-help\'></i>')" title="Copier">
            <span class="icn-help"></span>
            <span class="mls"> icn-help</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e929" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe929;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-camera\'></i>')" title="Copier">
            <span class="icn-camera"></span>
            <span class="mls"> icn-camera</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e928" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe928;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-return\'></i>')" title="Copier">
            <span class="icn-return"></span>
            <span class="mls"> icn-return</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e927" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe927;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-icon-right-rrow\'></i>')" title="Copier">
            <span class="icn-icon-right-rrow"></span>
            <span class="mls"> icn-icon-right-rrow</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e92c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe92c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-icn-grid\'></i>')" title="Copier">
            <span class="icn-icn-grid"></span>
            <span class="mls"> icn-icn-grid</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e924" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe924;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-icn-list\'></i>')" title="Copier">
            <span class="icn-icn-list"></span>
            <span class="mls"> icn-icn-list</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e925" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe925;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-icon-icn-export\'></i>')" title="Copier">
            <span class="icn-icn-export"></span>
            <span class="mls"> icn-icn-export</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e926" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe926;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-etc\'></i>')" title="Copier">
            <span class="icn-etc"></span>
            <span class="mls"> icn-etc</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e920" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe920;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-delete\'></i>')" title="Copier">
            <span class="icn-delete"></span>
            <span class="mls"> icn-delete</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e923" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe923;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-item-out-of-stock\'></i>')" title="Copier">
            <span class="icn-item-out-of-stock"></span>
            <span class="mls"> icn-item-out-of-stock</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e90c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe90c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-heart2\'></i>')" title="Copier">
            <span class="icn-heart2"></span>
            <span class="mls"> icn-heart2</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e90a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe90a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-notification\'></i>')" title="Copier">
            <span class="icn-notification"></span>
            <span class="mls"> icn-notification</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e900" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe900;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-message\'></i>')" title="Copier">
            <span class="icn-message"></span>
            <span class="mls"> icn-message</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e901" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe901;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-check\'></i>')" title="Copier">
            <span class="icn-check"></span>
            <span class="mls"> icn-check</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e904" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe904;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-stock-manager\'></i>')" title="Copier">
            <span class="icn-stock-manager"></span>
            <span class="mls"> icn-stock-manager</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e906" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe906;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-item-in-stock\'></i>')" title="Copier">
            <span class="icn-item-in-stock"></span>
            <span class="mls"> icn-item-in-stock</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e907" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe907;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-dashboard\'></i>')" title="Copier">
            <span class="icn-dashboard"></span>
            <span class="mls"> icn-dashboard</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e908" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe908;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-sale\'></i>')" title="Copier">
            <span class="icn-sale"></span>
            <span class="mls"> icn-sale</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e909" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe909;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-item\'></i>')" title="Copier">
            <span class="icn-item"></span>
            <span class="mls"> icn-item</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e90b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe90b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-paper\'></i>')" title="Copier">
            <span class="icn-paper"></span>
            <span class="mls"> icn-paper</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e90e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe90e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-info-fill\'></i>')" title="Copier">
            <span class="icn-info-fill"></span>
            <span class="mls"> icn-info-fill</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e915" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe915;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-shop\'></i>')" title="Copier">
            <span class="icn-shop"></span>
            <span class="mls"> icn-shop</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e916" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe916;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-user3\'></i>')" title="Copier">
            <span class="icn-user3"></span>
            <span class="mls"> icn-user3</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e918" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe918;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-mods\'></i>')" title="Copier">
            <span class="icn-mods"></span>
            <span class="mls"> icn-mods</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e919" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe919;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-setting\'></i>')" title="Copier">
            <span class="icn-setting"></span>
            <span class="mls"> icn-setting</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e91a" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe91a;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-checkout\'></i>')" title="Copier">
            <span class="icn-checkout"></span>
            <span class="mls"> icn-checkout</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e91b" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe91b;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-supervising\'></i>')" title="Copier">
            <span class="icn-supervising"></span>
            <span class="mls"> icn-supervising</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e91d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe91d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-alert\'></i>')" title="Copier">
            <span class="icn-alert"></span>
            <span class="mls"> icn-alert</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e90d" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe90d;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-vente-slim\'></i>')" title="Copier">
            <span class="icn-vente-slim"></span>
            <span class="mls"> icn-vente-slim</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e90f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe90f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-dash-categorie\'></i>')" title="Copier">
            <span class="icn-dash-categorie"></span>
            <span class="mls"> icn-dash-categorie</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e910" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe910;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-tab-catalogue\'></i>')" title="Copier">
            <span class="icn-tab-catalogue"></span>
            <span class="mls"> icn-tab-catalogue</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e911" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe911;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-leave\'></i>')" title="Copier">
            <span class="icn-leave"></span>
            <span class="mls"> icn-leave</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e912" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe912;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-cart\'></i>')" title="Copier">
            <span class="icn-cart"></span>
            <span class="mls"> icn-cart</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e913" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe913;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-edit2\'></i>')" title="Copier">
            <span class="icn-edit2"></span>
            <span class="mls"> icn-edit2</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e914" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe914;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-profil\'></i>')" title="Copier">
            <span class="icn-profil"></span>
            <span class="mls"> icn-profil</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e917" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe917;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-clock\'></i>')" title="Copier">
            <span class="icn-clock"></span>
            <span class="mls"> icn-clock</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e91c" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe91c;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-put-on-hold\'></i>')" title="Copier">
            <span class="icn-put-on-hold"></span>
            <span class="mls"> icn-put-on-hold</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e91e" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe91e;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-refersh\'></i>')" title="Copier">
            <span class="icn-refersh"></span>
            <span class="mls"> icn-refersh</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e91f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe91f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 can-copy clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-close-square\'></i>')" title="Copier">
            <span class="icn-close-square"></span>
            <span class="mls"> icn-close-square</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e92f" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe92f;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div> 
    <div class="glyph fs1">
        <div class="clearfix bshadow0 can-copy pbs" (click)="copyToClipboard('<i class=\'icn-warning\'></i>')" title="Copier">
            <span class="icn-warning"></span>
            <span class="mls"> icn-warning</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e930" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe930;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
        </div>
    </div>
    <div class="glyph fs1">
        <div class="clearfix bshadow0 pbs" (click)="copyToClipboard('<i class=\'icn-last_connection\'></i>')" title="Copier">
            <span class="icn-last_connection"></span>
            <span class="mls"> icn-last_connection</span>
        </div>
        <fieldset class="fs0 size1of1 clearfix hidden-false">
            <input type="text" readonly value="e939" class="unit size1of2" />
            <input type="text" maxlength="1" readonly value="&#xe939;" class="unitRight size1of2 talign-right" />
        </fieldset>
        <div class="fs0 bshadow0 clearfix hidden-true">
            <span class="unit pvs fgc1">liga: </span>
            <input type="text" readonly value="" class="liga unitRight" />
    </div>
</div>
</div>