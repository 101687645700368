<div class="kpi">
  <div class="state">
    <div class="state__title">
      <ng-content></ng-content>
    </div>
    <div class="state__content">
      <div class="state__item" *ngFor="let quickRepport of quickRepports">
        <div class="state__box {{quickRepport.box}}" [ngClass]="{'cursor-pointer' : quickRepport?.action}" (click)="quickRepport?.action()">
          <i class="{{ quickRepport.icon }}"></i>
          <div class="state__value">{{ quickRepport.value | number }}</div>
          <div class="state__label lc-2">{{ quickRepport.label | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
