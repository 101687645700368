<ng-container *ngIf="id && icon && icon.id">
  <div class="item-right" *ngIf="id == icon.id">
    <div class="item-sub-content-right">
      <span
        *ngFor="let _icon of icon.icons"
        class="{{ _icon.class }}"
        [attr.data-tooltip]="_icon.tooltip"
        data-flow="top"
      >
        <i class="{{ _icon.image }}" (click)="_icon.action()"></i>
      </span>
    </div>
  </div>
</ng-container>
