<ng-container *ngIf="icon||label||data">
  <button 
  [disabled]="disabled" 
  (click)="onAction($event)" 
  class="cntBtn" 
  [ngClass]="[
  view==='outlined'?'cntBtn--outlined':'',
  color?'cntBtn--'+color:'', 
  size?'cntBtn--'+size:'', 
  rightIcon?'cntBtn--rightIcon':'',
  view==='flat'?'cntBtn--'+view:'']" 
  [attr.data-tooltip]="tooltip">
    <ng-container *ngIf="icon">
      <i class="{{icon}}"></i>
    </ng-container>
    <ng-container *ngIf="label">
      <span>{{label | translate}}</span>
    </ng-container>
  </button>
</ng-container>

