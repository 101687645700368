import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {InputService} from "../../../services/input/input.service";
import {filter} from "rxjs/operators";
import {Tooltip} from "../../../models/table/body/column/column";

@Component({
  selector: 'app-input-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
  @Input() id!: string;
  @Input() value!: boolean;
  @Input() isDisabled: boolean = false;
  @Input() tooltip: Tooltip
  @Output() switchValue: EventEmitter<any> = new EventEmitter();
  public formGroup: FormGroup;
  private subscription = new Subscription()

  constructor(
    private formBuilder: FormBuilder,
    private inputService: InputService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getFormValue();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      id: [this.id, Validators.required],
      value: [this.value, Validators.required],
      trigger: false
    })
  }

  triggerValue() {
    this.formGroup.get('trigger').setValue(true);
    this.formGroup.updateValueAndValidity();
  }

  getFormValue() {
    this.subscription.add(
      this.formGroup.valueChanges.pipe(
        filter((value: any) => value['trigger'])
      ).subscribe((value: any) => {
        this.formGroup.get('trigger').setValue(false);
        this.switchValue.emit(value)
      })
    )
  }
}
