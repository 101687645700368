import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, of, Subscription, switchMap} from 'rxjs';
import { ITableForm } from '../../../interfaces/tables/form/i-table-form';
import {IAction, IRow} from '../../../interfaces/tables/i-row';
import { IRowValue } from '../../../interfaces/tables/i-row-value';
import { TableService } from '../../../services/table/table.service';
import { filter, tap } from "rxjs/operators";
import {ICell} from "../../../interfaces/tables/i-cell";
import {MiscService} from "../../../services/miscellaniscious/misc.service";
import {I_FieldData} from "../../../models/field-data";
import {ICellWithoutEdit} from "../../../interfaces/tables/without-edit/i-cell-without-edit";

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent implements OnInit, OnDestroy {
  @Input() public id: string = '';
  @Input() public timer: number = 1500;
  @Input() public iconId: string = '';
  private subscription = new Subscription();
  public tables: ITableForm = {
    header: null,
    body: null
  };
  public tableFormGroup: FormGroup;
  public isDisabled: boolean = false;
  public expanded: string = '';
  public expandedValues: ICell = null;

  constructor(
    private tableService: TableService,
    private formBuilder: FormBuilder,
    private miscService: MiscService
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.getTable();
    this.getNumberValidity();
    this.getInputDisabledStatus();
    this.formValueChange();
    this.getExpandedValue();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.tableFormGroup = this.formBuilder.group({
      field_group: this.formBuilder.array([]),
      form_id: [this.id],
      triggerValueChange: [false]
    });
  }

  fieldGroup(): FormArray {
    return this.tableFormGroup.get('field_group') as FormArray;
  }

  fieldList(index: number): FormArray {
    return this.fieldGroup()?.at(index)?.get('field_list') as FormArray;
  }

  getTable() {
    this.subscription.add(
      this.tableService.tableValue$.pipe(
        filter(value => {
          return value?.id == this.id
        })
      ).subscribe((value: ITableForm) => {
        this.getTableValue(value);
      })
    );
  }

  getTableValue(value: ITableForm) {
    if (value && value.id == this.id) {
      this.fieldGroup().clear()
      this.tables = value;
      value?.body?.cellValue.forEach((value: IRow) => {
        let fieldGroup = this.formBuilder.group({
          field_list: this.formBuilder.array([]),
          id: value.id,
          action: this.formBuilder.array([]),
        })
        if (value.action) this.addActionButtonToForm(value.action, fieldGroup.get('action') as FormArray)
        let group = fieldGroup.get('field_list') as FormArray;
        value.rowValue.forEach((row: IRowValue) => {
          let childForm = this.formBuilder.group({
            'id': [row.id],
            'key': [row.key],
            'label': [row.type == 'simple' ? '' : row.label],
            'type': [row.type],
            'input': [row?.input ? row.input : ''],
            'expand': [row?.expand ? row.expand : false],
            'image': [row?.image ? row.image : ''],
            'value': [row.value.value, [Validators.required]],
            'align': [row?.align ? row?.align : 'left'],
            'bg': row?.bg ? row?.bg : 'transparent',
            'action': row?.action ? row?.action : null,
            'class': row?.class ? row?.class : '',
            'icon': row?.icon,
            'badge': row?.badge,
            'inputMaxLength': row?.inputMaxLength
          })

          if (row.value.validator) {
            childForm.get('value').addValidators(Validators.pattern(row.value.validator))
          }
          group.push(childForm);
        })
        this.fieldGroup().push(fieldGroup);
      })
    }
  }

  addActionButtonToForm(value: IAction[], formArray: FormArray) {
    value.forEach(action => {
      let form = this.formBuilder.group({
        id: action.id,
        lineId: action.lineId,
        actif: action.actif,
        tooltip: action.tooltip,
        icon: action.icon,
        confirm: action.confirm,
        function: action.function,
      });
      formArray.push(form)
    })
    return formArray
  }

  typeOfValue(value: any) {
    return typeof value;
  }

  resetForm() {
    this.fieldGroup().clear();
    this.fieldGroup().reset();
    this.tableFormGroup.patchValue({
      form_id: this.id
    })
  }

  getNumberValidity() {
    this.subscription.add(
      this.miscService.fieldData$.subscribe((data: I_FieldData) => {
        if (data && data?.fieldName != '' && data.uniqueId == 'table-form') {
          const index = data.fieldName.split('_')
          this.fieldList(+index[0])?.at(+index[1])?.patchValue({'value': data.fieldValue});
          this.triggerValueChanges();
        }
      })
    )
  }

  formValueChange() {
    this.subscription.add(
      this.tableFormGroup.valueChanges.pipe(
        filter(data =>  data.form_id == this.id && data.triggerValueChange),
        debounceTime(this.timer),
        switchMap((value) => {
          this.tableFormGroup.patchValue({triggerValueChange: false});
          if (value) {
            let data = {
              id: this.id,
              data: value['field_group']
            }
            this.tableService.setTableFormValue(data);
            return this.tableService.tableNewValue$;
          } else {
            return [];
          }
        })
      ).subscribe((value: ITableForm) => {
        if (value && value.id == this.id) {
          this.tables = value;
        }
      })
    )
  }

  onValueChange(i, j) {
    this.fieldList(i).at(j).valueChanges.pipe(
      debounceTime(this.timer),
      switchMap(value => of(value))
    ).subscribe(value => {
      this.tableFormGroup.patchValue({triggerValueChange: false});
      this.tableService.rowChange$.next(value);
    })
  }

  getTableExtra(i: number, key: string) {
    return this.tables?.body?.cellValue[i]?.extras?.filter(extra => extra.key === key)[0]
  }

  getInputDisabledStatus() {
    this.subscription.add(
      this.tableService.disableTableInput$.subscribe((status: boolean) => {
        if(this.isDisabled != status && status) {
          this.isDisabled = status
          this.tableFormGroup.controls['field_group'].disable();
        } else if (this.isDisabled != status && !status) {
          this.isDisabled = status
          this.tableFormGroup.controls['field_group'].enable();
        }
      })
    )
  }

  removeLine(i: number) {
    if (!this.tables.actionConfirm) {
      this.tableService.setLineRemoved(this.fieldList(i).value[0].id);
      this.fieldGroup().removeAt(i)
    } else {
      this.tableService.setLineRemoved(this.fieldList(i).value[0].id);
    }
  }

  expand(id: string) {
    this.expandedValues = null;
    if (this.expanded != id)  {
      this.expanded = id;
      this.tableService.setExpand(this.expanded);
    } else {
      this.expanded = '';
    }
  }

  getExpandedValue() {
    this.subscription.add(
      this.tableService.tableExpandedValue$.subscribe((expandValue: ICellWithoutEdit) => {
        this.expandedValues = null;
        if (expandValue && expandValue.cellValue.length > 0) {
          this.expandedValues = expandValue;
        }
      })
    );
  }

  onCheck(fields: any) {
    let newValue = fields;
    newValue.value = {
      value: fields.value
    }
    this.tableService.setSingleValue(newValue);
    this.triggerValueChanges();
  }

  editLine(id: string) {
    this.tableService.setLineEdited(id);
  }

  triggerValueChanges(): void{
    this.tableFormGroup.patchValue({triggerValueChange: true});
    this.tableFormGroup.updateValueAndValidity();
  }

  isNumber(value): boolean {
    return !isNaN(value);
  }

  typeOf(value) {
    return typeof(value)
  }
}
