import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {Product} from "../../../product/models/product/product.model";

@Injectable({
  providedIn: 'root'
})
export class ItemSelectionService {
  private allPoducts$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  private addedProduct$: Subject<Product[]> = new Subject<Product[]>();
  private productIn$: BehaviorSubject<{ id: string, product: Product[] }> = new BehaviorSubject<{ id: string, product: Product[] }>(null);
  private productIsAdded: Subject<boolean> = new Subject<boolean>();
  private removeAll: BehaviorSubject<{ id: string, status: boolean }> = new BehaviorSubject<{ id: string, status: boolean  }>(null);

  constructor() { }

  resetAll() {
    this.allPoducts$.next([])
    this.addedProduct$.next([])
    this.productIn$.next(null)
    this.removeAll.next(null)
  }

  getAllProducts(): BehaviorSubject<Product[]> {
    return this.allPoducts$;
  }

  setAllProducts(value: Product[]) {
    this.allPoducts$.next(value);
  }

  getAddedProducts() {
    return this.addedProduct$;
  }

  setAddedProducts(value: Product[]) {
    this.addedProduct$.next(value);
  }

  getProductIn() {
    return this.productIn$;
  }

  setProductIn(value: { id: string, product: Product[] }) {
    this.productIn$.next(value);
  }

  getProductIsAdded() {
    return this.productIsAdded;
  }

  setProductIsAdded(value: boolean) {
    this.productIsAdded.next(value);
  }

  getRemoveAll() {
    return this.removeAll;
  }

  setRemoveAll(value: { id: string, status: boolean } ) {
    this.removeAll.next(value);
  }
}
