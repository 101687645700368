import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileService {
  public base64$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor() { }

  convertBase64ToBlob(file: string) {
    const byteCharacters = window.atob(file.replace(/^data:text\/(csv);base64,/,''));
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'text/csv' });
  }

  downloadFile(base64: string, fileName: string) {
    const blob = this.convertBase64ToBlob(base64);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  convertFileToBase64(files) {
    const file = files.length;
    for(let i=0;i<file;i++)
    {
      var reader = new FileReader();
      reader.onloadend = (event:any) =>
      {
        this.base64$.next(event.target.result)
      }
      reader.readAsDataURL(files[i]);
    }
  }
}
