import {Component, OnDestroy, OnInit} from '@angular/core';
import {AddRemoveShop, AddRemoveShopList, AddRemoveShopNetwork} from "../../../../models/add-remove-shop/add-remove-shop.model";
import {from, Observable, of, Subscription} from "rxjs";
import {AddRemoveShopService} from "../../../../services/add-remove-shop/add-remove-shop.service";
import {debounceTime, switchMap} from "rxjs/operators";
import {shop} from "../../../../../../../../config/pos/shop";

@Component({
  selector: 'app-list-selected-shop',
  templateUrl: './list-selected-shop.component.html',
  styleUrls: ['./list-selected-shop.component.scss']
})
export class ListSelectedShopComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  constructor(
    private addRemoveShopService: AddRemoveShopService
  ) { }

  ngOnInit(): void {
    this.getPointOfSale()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getPointOfSale() {
    this.subscription.add(
      this.addRemoveShopService.selectedShops$.pipe(
        debounceTime(500),
        switchMap((shops: AddRemoveShop) => {
          if (shops && shops.shops.length > 0) {
            return of(shops)
          }
          else return []
        })
      ).subscribe((shops: AddRemoveShop) => {
        this.networks = [];
        this.shops = [];
        this.formatData(shops);
      })
    )
  }

  public networks: AddRemoveShopNetwork[] = []
  public shops: AddRemoveShopList[] = []
  formatData(shops: AddRemoveShop) {
    shops.shops.forEach((network: AddRemoveShopNetwork) => {
      if (network.network_is_checked) {
        const networkIsChecked = {
          network_uuid: network.network_uuid,
          network_name: network.network_name,
          network_is_checked: network.network_is_checked
        }
        this.networks.push(networkIsChecked)
      }
      if (network?.shop_list && network.shop_list.length > 0) {
        network.shop_list.forEach((shop: AddRemoveShopList) => {
          if (shop.shop_is_checked) {
            const shopIsChecked = {
              shop_uuid: shop.shop_uuid,
              shop_name: shop.shop_name,
              shop_is_checked: shop.shop_is_checked
            }
            this.shops.push(shopIsChecked)
          }
        })
      }
    })
    this.addRemoveShopService.getSaveSelectedShop({network: this.networks, shop: this.shops})
  }
}
