import { config } from './../../../../../config/config';
import { IImport, IImportRule } from './../import';
export const importConfig: IImport ={
  icon: `${config.imgUrl}/shoplist.svg`,
  label: "Importer catégories",
  url: '',
  accept: ".csv",
  validation: {
    encoding: ['utf8', 'utf8-without-BOM'],
    maxSize: 1024 *8,
  } as IImportRule
}
