<div class="toast toast--{{type}} {{fadeOut}}">
  <div class="toast__icon toast__icon--{{type}}">
    <i class="icn-{{icon}}"></i>
  </div>
  <div class="toast__title">
    <h3>{{title}}</h3>
    <p [innerHTML]="desc"></p>
  </div>
  <div class="toast__close" (click)="closeNotif()">
    <span> + </span>
  </div>
</div>
