import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthentificationService} from "../../services/authentification/authentification.service";
import {LocalStorageService} from "../../../shared/services/local-storage/local-storage.service";
import {Router} from "@angular/router";
import {localStorageUserId} from "../../config/constants";
import {ApiService} from "../../../shared/services/api-service/api.service";
import {Subscription} from "rxjs";
import { imgUrl } from 'src/environments/environment';
import { User } from '../../model/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginFormGroup: FormGroup;
  public users: User[] = [];
  public logo: string = `${imgUrl}/logo.svg`;
  public loginError: boolean = false;
  public errorMessage: boolean = false;
  private subscription = new Subscription();
  constructor(
    private formBuilder: FormBuilder,
    private authentificationService: AuthentificationService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private apiService: ApiService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getUsers();
    // if (this.localStorageService.getLocalStorage(localStorageUserId)) {
    //   this.router.navigate(['/']);
    // }
  }

  createForm() {
    this.loginFormGroup = this.formBuilder.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required]
    })
  }

  getUsers() {
    this.subscription.add(
      this.apiService.getAllUserByCategory(['0c8a3e4c-7bdc-44d8-a6ad-1449605a8eed']).subscribe( (response) => {
        if(response.status == 200) {
          this.users = response.data.users;
        }
      })
    )
  }


  login() {
    if (this.loginFormGroup.valid) {
      this.loginError = false;
      let username = this.loginFormGroup.get('username').value
      let is_logged = this.authentificationService.login(username, this.users)
      if(is_logged) {
        this.errorMessage = false
        this.router.navigate(['/']);
      } else {
       this.errorMessage = true;
      }
    } else {
      this.loginError = true
    }
  }
}
