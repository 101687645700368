import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IImage, IImageFile} from "../../models/i-image/i-image";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private image$: BehaviorSubject<IImage> = new BehaviorSubject<IImage>(null)
  private imageFile$: BehaviorSubject<IImageFile> = new BehaviorSubject<IImageFile>({status: false, file: ''})
  constructor() { }

  getImage(): BehaviorSubject<IImage> {
    return this.image$;
  }

  setImage(image: IImage) {
    this.image$.next(image)
  }

  getImageFile() {
    return this.imageFile$;
  }

  setImageFile(file: IImageFile) {
    this.imageFile$.next(file)
  }
}
