<div style="margin: 2em;">
<!--  <div class="nav-tabs" style="height: 20px"></div>

  <div style="display: grid; grid-template-columns: 10% 10% 20%;width: 100%;">
    <div style="height: 'calc(100vh - 290px)'; background-color: red" class="sidebar-sale" appHeightScreen spaceHeight="175">1</div>
    <div style="height: 'calc(100vh - 326px)'; background-color: greenyellow" class="product__list" appHeightScreen spaceHeight="195">2</div>
    <div style="height: 100vh; background-color: blue" class="client__list" appHeightScreen spaceHeight="183">3</div>
  </div>-->
  <app-table [id]="'test-table'"></app-table>
</div>
