import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {I_ApiResponse} from '../../../shared/models/api-response';
import {IProductCard} from '../../../shared/models/product/product-card.model';
import {ApiService} from "../../../shared/services/api-service/api.service";
import {ICheckboxTreeItem} from "../../../shared/models/checkbox-tree/checkbox-tree.model";
import {IDiscount} from "../../models/discount/i-discount";
import {Product} from "../../models/product/product.model";
import {HelperService} from "../../../shared/services/helper/helper.service";
import {IRowWithoutEdit} from "../../../shared/interfaces/tables/without-edit/i-row-without-edit";
import {EnvService} from "../../../../../env.service";
import {HabilitationsService} from '../../../shared/services/habilitations/habilitations.service';
import {abilities} from 'src/app/config/abilities';
import {IRowValueWithoutEdit} from '../../../shared/interfaces/tables/without-edit/i-row-value-without-edit';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
    private env: EnvService,
    private habilitationsService: HabilitationsService,
  ) { }

  getCustomerCategory(){
    let url: string = `${this.env.apiUserService}/category/get`;
    return this.apiService.doGet(url).pipe(
      map(value => {
        value.data.category = value.data.category.filter((category: any) => category['category_key'] == 'PARTICULAR' || category['category_key'] == 'PROFESSIONAL')
        return value;
      })
    );
  }

  getDiscountFormValue(create: boolean = true, value: Object, user: Object[], shops: Object, discountUuid: string, products: Object[] = [], isActive: boolean = true){
    const startDate =value['discountStartDate'];
    let startTime =value['discountStartTime']||'00:00';
    let formattedStartDate = '';
    if (startDate) {
      formattedStartDate = `${this.helperService.convertDate(startDate, '')} ${startTime}`;
    }

    const dateTimeStart =formattedStartDate;

    let dateTimeEnd = '';
    if (value['hasEndDate']) {
      const endDate =value['discountEndDate']||new Date();
      const endTime =value['discountEndTime']||'00:00';
      dateTimeEnd =`${this.helperService.convertDate(endDate, '')} ${endTime}`;
    }

    if (create) {
      return {
        point_of_sales: shops,
        user_categories: user,
        discount_name: value['discountName'],
        discount_amount: value['discountValue'],
        is_percent: value['isPercent'],
        start_date: dateTimeStart,
        end_date: dateTimeEnd,
        products: products,
        is_active: isActive ? 1 : 0,
        discount: discountUuid
      };
    } else {
      return {
        point_of_sales: shops,
        user_categories: user,
        start_date: dateTimeStart,
        end_date: dateTimeEnd,
        discount: discountUuid
      };
    }
  }

  createDiscount(companyUuid: string, discountUuid: string, data: any): Observable<I_ApiResponse> {
    if (discountUuid == '') {
      const url = `${this.env.apiCatalogService}/company/${companyUuid}/discount`;
      return this.apiService.doPost(url, data);
    } else {
      const url = `${this.env.apiCatalogService}/discount/${discountUuid}/set`;
      return this.apiService.doPut(url, data);
    }
  }

  getProductPack(packUuid: string): Observable<IProductCard[]> {
    const url = `${this.env.apiCatalogService}/pack/${packUuid}`;
    return this.apiService.doGet(url).pipe(
      map(response => {
        if (response.status !== 200) {
          return [];
        }

        return response.data?.pack.products_in_pack.map((e: any) => {
          const {product} = e;
          const productCard: IProductCard = {
            code: product.code,
            label: product.label,
            image: product.image,
            ttc_price: +product.ttc_price,
            uuid_product: product.uuid_product,
            quantity: +e.quantity
          };
          return productCard;
        });
      })
    );
  }

  removeSelectedNetwork(selectedShops: ICheckboxTreeItem[], networks: ICheckboxTreeItem[], network: ICheckboxTreeItem) {
      selectedShops = selectedShops.filter(x => x.uuid != network.uuid);
      networks = networks.filter(x => x.uuid != network.uuid);
      return { selectedShops, networks };
  }

  discountStatistics(company_uuid: string): Observable<I_ApiResponse> {
    let url = `${this.env.apiCatalogService}/statistics/company/${company_uuid}`;
    return this.apiService.doGet(url)
  }

  getDiscounts(comanyUuid: string, _params: Object = {}): Observable<I_ApiResponse> {
    const params = {
      company: comanyUuid,
      paginate: 1
    }
    if (_params['page'] && _params['page'] != 0) params['page'] = _params['page'];
    if (_params['search'] && _params['search'] != '') params['search'] = _params['search'];
    if (_params['is_active'] && _params['is_active'] == 'enable') params['is_active'] = 1;
    if (_params['is_active'] && _params['is_active'] == 'disable') params['is_active'] = 0;
    if (_params['is_percent'] && _params['is_percent'] == '1') params['is_percent'] = 1;
    if (_params['is_percent'] && _params['is_percent'] == '0') params['is_percent'] = 0;
    const url = `${this.env.apiCatalogService}/discounts/search`;
    return this.apiService.doGet(url, params);
  }

  getDiscount(uuid: string): Observable<I_ApiResponse> {
    const url = `${this.env.apiCatalogService}/discount/${uuid}`;
    return this.apiService.doGet(url);
  }

  updateDiscountStatus(uuid: string, status: boolean): Observable<I_ApiResponse> {
    const params = {
      is_active: status
    }
    const url = `${this.env.apiCatalogService}/discount/${uuid}/set?fields[]=is_active`
    return this.apiService.doPut(url, params)
  }

  deleteDiscount(uuid: string): Observable<I_ApiResponse> {
    const url = `${this.env.apiCatalogService}/discount/${uuid}/delete`;
    return this.apiService.doDelete(url)
  }

  generateDiscountList(discount: IDiscount = null, product: Product = null, currency: string, isParent: boolean = true, discountedPrice: number = 0): IRowWithoutEdit {
    let startDate = this.helperService.convertDateTime(new Date(discount.start_date_utc));
    let endDate = discount.end_date_utc ? ` au ${this.helperService.convertDateTime(new Date(discount.end_date_utc))}` : '';
    let statusColumn: IRowValueWithoutEdit;
    if (this.habilitationsService.getKeyHabilitations(abilities.discount.element.edit)) {
      statusColumn = {
        id: discount.discount_uuid,
        key: 'discount_status',
        type: 'input',
        input: 'switch',
        expand: false,
        value: {
          value: isParent ? discount.is_active : null
        }
      };
    } else {
      statusColumn = {
        id: discount.discount_uuid,
        key: 'discount_status',
        type: 'simple',
        badge: {
          bg: discount.is_active ? 'bg-success' : 'bg-danger',
          status: true,
        },
        expand: false,
        value: {
          value: isParent && discount.is_active ? 'Actif' : 'Inactif',
        }
      };
    }
    return {
      id: discount.discount_uuid,
      is_expandable: true,
      rowValue: [
        {
          id: discount.discount_uuid,
          key: 'dicount_name',
          type: 'simple',
          expand: true,
          image: !isParent ? (product.has_image ? product.image_url : 'assets/img/no-img.svg'): '',
          value: {
            value: isParent ? discount.discount_name: product.label
          }
        },
        {
          id: discount.discount_uuid,
          key: 'start_date_utc_end_date_utc',
          type: 'simple',
          expand: false,
          value: {
            value: isParent ? startDate + endDate : ""
          }
        },
        {
          id: discount.discount_uuid,
          key: 'discount_amount',
          type: 'simple',
          expand: false,
          value: {
            value: isParent ? discount.is_percent ? `${this.helperService.formatNumber(+discount.discount_amount, 2)} %` : `${this.helperService.formatNumber(+discount.discount_amount, 2)} ${currency}` : ''
          },
          align: 'center'
        },
        {
          id: discount.discount_uuid,
          key: 'reference_price',
          type: 'simple',
          expand: false,
          value: {
            value: isParent ? '' : this.helperService.formatNumber(product.ttc_price, 2)
          },
          align: 'right'
        },
        {
          id: discount.discount_uuid,
          key: 'discounted_price',
          type: 'simple',
          expand: false,
          value: {
            value: isParent ? '' : this.helperService.formatNumber(discountedPrice, 2)
          },
          align: 'right'
        },
        statusColumn,
      ]
    }
  }

  showNetworkInEdit(networks: any[] = []): ICheckboxTreeItem[] {
    return networks.map(network => {
      return {
        label: network.network_name,
        uuid: network.network_uuid,
        isChecked: true
      }
    });
  }

  showShopsInEdit(shops: any[] = []): ICheckboxTreeItem[] {
    return shops.map(shop => {
      return {
        label: shop.name,
        uuid: shop.shop_uuid,
        isChecked: true
      }
    });
  }

  showUserCategorieInEdit(categories: any[] = []): ICheckboxTreeItem[] {
    return categories.map(category => {
      return {
        label: category.category_name,
        uuid: category.category_uuid,
        isChecked: true
      }
    })
  }
}
