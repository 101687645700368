import { MiscService } from 'src/app/main/modules/shared/services/miscellaniscious/misc.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericFormService {

  genericFormData$: BehaviorSubject<any> =new BehaviorSubject<any>(null);
  genericFormSchema$: BehaviorSubject<any> =new BehaviorSubject<any>(null);
  contractCompliantForm$: BehaviorSubject<any> =new BehaviorSubject<any>(null);
  contractCompliantFormData$: BehaviorSubject<any> =new BehaviorSubject<any>(null);

  displayError$: BehaviorSubject<any> =new BehaviorSubject<any>(false);

  resetContractCompliantForm$: BehaviorSubject<any> =new BehaviorSubject<any>(null);
  resetContractForm$: BehaviorSubject<any> =new BehaviorSubject<any>(null);

  genericFormIsDirty$:BehaviorSubject<any> =new BehaviorSubject<any>(null);

  constructor(
    private miscService: MiscService
  ) { }

  updateGenericFormData( formData: any, uniqueId: string ){
    this.genericFormData$.next({
      formData: formData,
      uniqueId: uniqueId
    });
  }

  updateContractCompliantFormData( formData: any, uniqueId: string ){
    this.contractCompliantFormData$.next({
      formData: formData,
      uniqueId: uniqueId
    });
  }

  updateGenericFormSchema( formSchema: any, uniqueId: string ){
    this.genericFormSchema$.next({
      formSchema: formSchema,
      uniqueId: uniqueId
    });
  }

  updateDisplayError( displayError: boolean, uniqueId: string ){
    this.displayError$.next({
      displayError: displayError,
      uniqueId: uniqueId
    });
  }

  updateFormIsDirty(formIsDirty: boolean, uniqueId: string){
    this.genericFormIsDirty$.next({
      formIsDirty: formIsDirty,
      uniqueId: uniqueId
    });
  }

  showFormError( uniqueId: string ){
    this.updateDisplayError(true, uniqueId );
  }

  hideFormError( uniqueId: string ){
    this.updateDisplayError(false, uniqueId );
  }

  resetContractCompliantForm( uniqueId: string ){
    this.resetContractCompliantForm$.next({
      uniqueId: uniqueId,
      resetId: this.miscService.generateUniqueId()
    });
  }

  resetContractForm( uniqueId: string ){
    this.resetContractForm$.next({
      uniqueId: uniqueId,
      resetId: this.miscService.generateUniqueId()
    });
  }
}
