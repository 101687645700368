
<div class="user-action">
  <div class="action">
      <div class="action__title">
        <h2>{{ 'todoToDay' | translate }}</h2>
      </div>
      <div class="action__list">
        <ng-content></ng-content>
      </div>
    </div>
</div>
