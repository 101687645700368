<div class="cntNotAllowed">
  <h3 class="cntNotAllowed_title">Aucun shop rattaché</h3>
  <div class="cntNotAllowed_img">
    <svg xmlns="http://www.w3.org/2000/svg" width="206.332" height="222.087" viewBox="0 0 206.332 222.087">
      <g id="accès_non_autorisé" data-name="accès non autorisé" transform="translate(-2.958)">
        <path id="Tracé_18010" data-name="Tracé 18010" d="M106.228,0A103.063,103.063,0,1,1,3.165,103.063,103.063,103.063,0,0,1,106.228,0" transform="translate(-0.207)" fill="var(--neutral-100)"/>
        <circle id="Ellipse_1437" data-name="Ellipse 1437" cx="102.563" cy="102.563" r="102.563" transform="translate(3.664 0.5)" fill="none" stroke="var(--neutral-100)" stroke-width="1"/>
        <g id="_5394935" data-name="5394935" transform="translate(6.23 47.161)">
          <path id="Tracé_18123" data-name="Tracé 18123" d="M168.483,157.111H43.474a9.57,9.57,0,0,1-9.574-9.574V42.974A9.571,9.571,0,0,1,43.474,33.4H168.45a9.571,9.571,0,0,1,9.574,9.574v104.6A9.557,9.557,0,0,1,168.483,157.111Z" transform="translate(-6.014 -5.7)" fill="var(--white)"/>
          <circle id="Ellipse_1461" data-name="Ellipse 1461" cx="41.735" cy="41.735" r="41.735" transform="translate(58.23 89.36)" fill="var(--white)"/>
          <path id="Tracé_18124" data-name="Tracé 18124" d="M174.444,35.521l-.032-.065a14.322,14.322,0,0,0-9.087-9.7l-.065-.032c-2.174-.811-4.641-.779-7.269-.746H39.537A14.344,14.344,0,0,0,30,28.381l-.032.032A14.254,14.254,0,0,0,25,39.253V139.565A14.263,14.263,0,0,0,35.48,153.617h.065a21.424,21.424,0,0,0,6.231.487h1.168a2.314,2.314,0,0,0,2.174-1.915v-.13a2.346,2.346,0,0,0-1.688-2.434,13.836,13.836,0,0,0-2.369-.065,13.518,13.518,0,0,1-5.29-.649,9.662,9.662,0,0,1-6.2-9.087V60.672H170.42v79.186a9.753,9.753,0,0,1-5.906,8.99,12.914,12.914,0,0,1-5.582.779,12.278,12.278,0,0,0-2.5.1,2.218,2.218,0,0,0-1.266,1.006,2.053,2.053,0,0,0-.292,1.59l.032.1a2.362,2.362,0,0,0,1.98,1.72c3.083.1,6.523.162,9.346-1.071l.1-.032A14.333,14.333,0,0,0,175,139.922v-98.4A25.19,25.19,0,0,0,174.444,35.521ZM29.541,56.128V39.285A9.68,9.68,0,0,1,35.707,30.2a11.569,11.569,0,0,1,4.738-.649H159.419a12.379,12.379,0,0,1,4.771.649,9.756,9.756,0,0,1,6.2,9.087V56.128Zm20.77-13.371a2,2,0,0,1-.617,1.59,2.209,2.209,0,0,1-1.558.746h-.162a2.219,2.219,0,0,1-1.59-.746,2.249,2.249,0,0,1-.617-1.59V42.6a2.272,2.272,0,0,1,1.817-2.012l.13-.032a2.248,2.248,0,0,1,1.688.454,2.316,2.316,0,0,1,.909,1.558Zm15.577-.032v.162a2.1,2.1,0,0,1-.779,1.623,2.208,2.208,0,0,1-1.688.584l-.162-.032a2.152,2.152,0,0,1-1.493-.974,2.021,2.021,0,0,1-.389-1.655l.032-.1a2.26,2.26,0,0,1,1.785-1.72l.13-.032a1.1,1.1,0,0,1,.325-.032,2.174,2.174,0,0,1,1.4.552A2.078,2.078,0,0,1,65.889,42.725Zm15.577,0v.162a2.1,2.1,0,0,1-.779,1.623A2.208,2.208,0,0,1,79,45.094l-.162-.032a2.281,2.281,0,0,1-1.525-.974,2.146,2.146,0,0,1-.389-1.655l.032-.13a2.306,2.306,0,0,1,1.785-1.72l.13-.032a1.1,1.1,0,0,1,.325-.032,2.174,2.174,0,0,1,1.4.552A2.127,2.127,0,0,1,81.466,42.725Z" fill="#a0a0a0"/>
          <path id="Tracé_18125" data-name="Tracé 18125" d="M204.834,243.027A43.876,43.876,0,0,0,164.722,217a36.225,36.225,0,0,0-4.608.26h0a43.854,43.854,0,1,0,44.72,25.768Zm-16.551,49.2a39.282,39.282,0,0,1-23.107,7.854h-.584a39.224,39.224,0,0,1-31.22-62.764l2.012-2.661,2.369,2.369,25.216,25.216,25.573,25.573,2.369,2.369Zm7.951-7.919-2.012,2.661-2.369-2.369-19.342-19.342c-10.288-10.32-20.965-20.965-31.447-31.415l-2.369-2.369,2.661-2.012a39.17,39.17,0,0,1,62.8,30.344A39.4,39.4,0,0,1,196.234,284.308Z" transform="translate(-64.855 -129.717)" fill="var(--primary)"/>
        </g>
      </g>
    </svg>
  </div>
  <p class="cntNotAllowed_descr">
    Veuillez contacter votre responsable afin de vous rattacher à un shop
  </p>
  <button class="btn btn-yellow btn__back-home" (click)="signOut()">Se déconnectER</button>
</div>
