import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {LocalStorageService} from "../../services/local-storage/local-storage.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse && event.headers.get('Refreshed-Token')) {
          this.localStorageService.setLocalStorage('jwt', event.headers.get('Refreshed-Token'));
        }
        return event;
      })
    );
  }
}
