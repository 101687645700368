import { Subscription } from 'rxjs';
import { ApiService } from './../../services/api-service/api.service';
import { GenericFormService } from './../../services/generic-form/generic-form.service';
import { Component, OnInit } from '@angular/core';
import { I_FormContract } from '../../models/formContract.model';
import { genericForm } from '../../models/generic-form/generic-form.model';
import { HasDirtyForm } from '../../interfaces/hasDirtyForm';

@Component({
  selector: 'app-page-test-formulaire-generique',
  templateUrl: './page-test-formulaire-generique.component.html',
  styleUrls: ['./page-test-formulaire-generique.component.scss']
})
export class PageTestFormulaireGeneriqueComponent implements OnInit, HasDirtyForm {

  // formContract: I_FormContract =genericForm;
  uniqueId: string ='xxx';
  formIsDirty: boolean;

  subscription: Subscription =new Subscription();
  constructor( private genericFormService: GenericFormService ) { }

  ngOnInit() {
    this.genericFormService.updateGenericFormSchema(genericForm, this.uniqueId);

    this.subscription.add(
      this.genericFormService.genericFormIsDirty$.subscribe((formIsDirty: any)=>{

        if( formIsDirty?.uniqueId ==this.uniqueId ){
          this.formIsDirty =formIsDirty.formIsDirty;
        }
      })
    );
  }

  validateForm(){
    this.genericFormService.showFormError(this.uniqueId);
  }

  resetContractCompliantForm(){
    this.genericFormService.resetContractCompliantForm(this.uniqueId);
  }

  resetContractForm(){
    this.genericFormService.resetContractForm(this.uniqueId);
  }

  hasDirtyForm(): boolean {
    return this.formIsDirty;
  }

  dirtyFormCallBack(): void {
    console.log( 'xa' );
  }
}
