
<div class="nav-tabs">
  <ul class="nav">
    <ng-content select="[stepper-title]"></ng-content>
  </ul>
</div>

<div class="tab">
  <ng-content select="[stepper-content]"></ng-content>
</div>

