<div class="item-sale-point" [formGroup]="shopForm">
  <div class="block-left-sale-point">
    <div class="all-sale-point">
      <app-table-filter [filterParams]="tabFilterParams"></app-table-filter>
      <div class="title-sale-point block-inline-flex has-child open">
        <div class="cell-table black-check">
          <label class="container"><span class="sub-title-sale-point semi_bold">Tous les points de vente ({{ shopTotal }})</span>
            <input type="checkbox" name="all_is_checked" formControlName="all_is_checked" (change)="selectAllPos()">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <ul class="list-sale-point" formArrayName="shops">
        <li class="item-sale-point" *ngFor="let field of shopGroups().controls; let i = index;">
          <ng-container [formGroupName]="i">
            <div class="block-inline-flex has-child">
              <div class="cell-table black-check">
                <label class="container">
                  <span class="semi_bold h-18">{{ field.value.network_name }}</span>
                  <input type="checkbox" name="network_is_checked" formControlName="network_is_checked" (change)="selectNetWork(i)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <ul class="list-children-sale-point" formArrayName="shop_list">
              <ng-container *ngFor="let shop of shopList(i).controls; let j = index;">
                <li class="item-children-sale-point" [formGroupName]="j">
                  <div class="block-inline-flex">
                    <div class="cell-table black-check">
                      <label class="container">
                        <span>{{shop.value.shop_name}}</span>
                        <input type="checkbox" name="shop_is_checked" formControlName="shop_is_checked" (change)="selectShop(i, j)">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>
