import { Subscription } from 'rxjs';
import { Component, OnInit ,  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
  OnDestroy} from '@angular/core';
import { TabComponent } from "../tab/tab.component";
import {TabService} from "../../../services/tab/tab.service";
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit, OnInit, OnDestroy {

  @Input()
  tabType: string ='default';

  @Input()
  uniqueId: string;

  @Input()
  emmitConfirmSelectTab: boolean

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  subscription: Subscription =new Subscription();

  currentTabId: string;

  tabSwitchFirstCall:boolean = true;
  nextTab: any;

  constructor(
    private tabService: TabService
  ) { }

  ngAfterContentInit() {
    let activeTabs = this.tabs.filter(tab => tab.active);
    if (activeTabs.length === 0) {

      this.selectTab(this.tabs.first);
      this.tabSwitchFirstCall =false;
    }
  }

  ngOnInit(){
    this.subscription.add(
      this.tabService.tabId$.subscribe((data)=>{
        if( data?.uniqueId ){
          this.tabs?.toArray().forEach((tab) => {
            tab.active = false;
            if( tab.tabId ==data?.tabId ){
              tab.active = true;
              this.currentTabId =tab.tabId;
            }
          });

          // if (tab.active) this.tabService.getTabId(tab.tabId, this.uniqueId);
        }
      })
    );


  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  selectTab(tab: any) {

    // si emmitConfirm, on ne change pas tout de suite d'onglet, on emmit une confirmation

    if( this.emmitConfirmSelectTab && tab.tabId !=this.currentTabId && !this.tabSwitchFirstCall){
      // /!\ Attention: s'assurer qu'au moins un composant a subscribe à l'emmitConfirm

      this.tabService.emmitConfirmSelectTab(tab.tabId, this.uniqueId);
      this.nextTab ={
        tabId: tab.tabId,
        uniqueId: this.uniqueId
      }

      this.tabService.setNextTab(this.nextTab);
    }else{
      // sinon on change tout de suite de tab

      this.tabs?.toArray().forEach((tab) => {
        tab.active = false;
      });
      tab.active = true;
      this.currentTabId =tab.tabId;
      if (tab.active) this.tabService.getTabId(tab.tabId, this.uniqueId);
    }
  }

}
