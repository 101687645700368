import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, Subscription } from 'rxjs';
import { storageKey } from 'src/app/config/storage';
import { Category } from '../../../product/models/category/category.model';
import { ProductService } from '../../../product/services/product/product.service';
import { tableFilter } from '../../../shared/config/constants';
import { ITableFilterFieldNewValue, ITableFilterNew } from '../../../shared/interfaces/i-table-filter-new.interface';
import { ITableForm } from '../../../shared/interfaces/tables/form/i-table-form';
import { IButton } from '../../../shared/models/i-button';
import { I_QuickRepportData } from '../../../shared/models/quick-repport.model';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { MiscService } from '../../../shared/services/miscellaniscious/misc.service';
import { TableFilterService } from '../../../shared/services/table-filter/table-filter.service';
import { TableService } from '../../../shared/services/table/table.service';
import { Inventory, InventoryStatus } from '../../models/inventory.model';
import { InventoryProductDto } from '../../models/inventoryProductDto.model';
import { InventoryService } from '../../services/inventory.service';
import {I_ApiResponse} from "../../../shared/models/api-response";
import { I_BreadCrumbData } from '../../../shared/models/breadCrumb';
import { url } from 'src/app/config/url';
import {IconService} from "../../../shared/services/icon/icon.service";

@Component({
  selector: 'app-create-inventory',
  templateUrl: './create-inventory.component.html',
  styleUrls: ['./create-inventory.component.scss']
})
export class CreateInventoryComponent implements OnInit, OnDestroy {

  private readonly subscription = new Subscription();
  pageTitle = "Fiche d'inventaire ";

  readonly tableFilterArticleListId = 'table-filter-article-list-id';
  readonly tableFilter: ITableFilterNew = {
    id: this.tableFilterArticleListId,
    title: 'Liste des articles',
    fields: []
  };

  readonly quickReportDatas: I_QuickRepportData[] = [{
    id: '',
    label: 'Nombre total d\'article',
    value: 0,
  }];

  readonly tableFormId = 'product-inventory';
  readonly tableData: ITableForm = {
    id: this.tableFormId,
    header: [
      {
        value: 'Article',
      },
      {
        value: 'Catégorie',
      },
      {
        value: 'Code Article',
      },
      {
        value: 'Stock théorique',
      },
      {
        value: 'Qté inventorié',
      },
      {
        value: 'Écarts',
      },
      {
        value: 'Commentaires',
      },
    ],
    body: {
      cellValue: []
    },
  };

  inventoryUuid: string;
  inventory: Inventory;
  modifiedProducts: InventoryProductDto[] = [];
  hasNextPage = true;
  nextPage: number;
  query: string;
  categoryUuid: string;
  showTodayMovement: number;

  readonly saveButton: IButton = {
    buttonText: 'ENREGISTRER',
    variant: 'yellow'
  };
  readonly finishButton: IButton = {
    buttonText: 'TERMINER L\'INVENTAIRE'
  };
  readonly showMoreButton: IButton = {
    buttonText: 'Afficher plus',
  }

  constructor(
    private tableFilterService: TableFilterService,
    private tableService: TableService,
    private productService: ProductService,
    private localStorageService: LocalStorageService,
    private miscService: MiscService,
    private inventoryService: InventoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconService: IconService
  ) {}

  ngOnInit(): void {
    this.updateBreadCrumb();
    this.initFilter();
    this.setInventoryInfo();
    this.tableFilterService.setFilterNew(this.tableFilter);
    this.tableService.setTableValue(this.tableData);
    this.getTableFormValue();
    this.saveButton.action = this.onSaveInventoryBtnclick;
    this.finishButton.action = this.onSubmitInventoryBtnClick;
    this.showMoreButton.action = this.onShowMoreButtonClick;
    this.getIconAction();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get userDetail() {
    return JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
  }

  private initFilter(): void {
    this.initTableFilter();
    this.subscription.add(this.tableFilterService.formValue$.subscribe(data => {
      if (data != null) {
        this.query = data['search'] ?  data['search'] : null;
        this.categoryUuid = data['category'] ? data['category'] : null;
        this.showTodayMovement = data['movement'] ? 1 : 0;
        this.setFilterdInventory();
      }
    }));
  }

  private updateBreadCrumb(): void {
    const breadCrumb: I_BreadCrumbData[] = [
      {
        url: '',
        label: 'Gestion stock',
      },
      {
        url: url.inventory.list,
        label: 'Liste des inventaires'
      },
      {
        url: '',
        label: 'Faire inventaire',
      },
    ];

    this.miscService.addBreadCrumb(breadCrumb);
  }


  private initTableFilter(): void {
    this.subscription.add(this.getProductCategories().subscribe(categories => {
      const productCategories: ITableFilterFieldNewValue[] = [{
        key: tableFilter.key.product_category,
        label: 'tous',
        value: 'all',
        default: true,
      }];

      categories.forEach(category => {
        productCategories.push({
          key: tableFilter.key.product_category,
          label: category.label,
          value: category.uuid_category,
          default: false,
        });
      });

      this.tableFilter.fields = [
        {
          key: tableFilter.key.keyword,
          label: 'Mot clé',
          placeholder: "Nom article / Code article",
          type: 'input',
        },
        {
          key: tableFilter.key.product_category,
          label: 'Catégorie',
          type:'select',
          value: productCategories,
        },
        {
          key: tableFilter.key.movement,
          label: 'Mouvement le',
          type: 'switch',
        },
      ];

      this.tableFilterService.filterNew$.next(this.tableFilter);
    }));
  }

  private getProductCategories(): Observable<Category[]> {
    return this.productService.getCategories(this.userDetail.company_uuid).pipe(
      map(response => {
        if (response.status < 300) {
          return <Category[]> response.data.categories;
        }
        this.miscService.showToast({
          icon: 'alert',
          type: 'danger',
          title: 'Erreur',
          desc: response.errors[0].message,
        });
        return [];
      })
    );
  }

  private setInventoryInfo(): void {
    this.subscription.add(this.activatedRoute.params.subscribe(params => {
      this.inventoryUuid = params['uuid'];
      this.initInventory();
    }));
  }

  private getInventory(page: number): Observable<Inventory> {
    return this.inventoryService.getInventory(this.inventoryUuid, page, this.query, this.categoryUuid, this.showTodayMovement).pipe(
      map(response => {
        if (response.status < 300) {
          return <Inventory> response.data?.inventory;
        }
        this.miscService.showToast({
          type: 'danger',
          icon: 'alert',
          title: 'Erreur',
          desc: response.errors[0].message,
        });
        return null;
      })
    );
  }

  private initInventory(): void {
    this.subscription.add(this.getInventory(1).subscribe(inventory => {
      this.inventory = inventory;
      this.hasNextPage = this.inventory.products.current_page < this.inventory.products.last_page;
      this.nextPage = this.inventory.products.current_page + 1;
      this.quickReportDatas[0].value = this.inventory.products.total;
      this.tableData.body.cellValue = this.inventoryService.createInventoryEditableRows(this.inventory);
      const movementFilter = this.tableFilter.fields.find(e => e.key === tableFilter.key.movement);
      const dateMovement = new Date(this.inventory.create_date_utc).toLocaleDateString();
      movementFilter.label = `Mouvement le ${dateMovement}`;
      this.tableFilterService.filterNew$.next(this.tableFilter);
      this.tableService.setTableValue(this.tableData);
    }));
  }

  get inventoryStatus(): InventoryStatus {
    return this.inventory?.inventory_status;
  }

  private setFilterdInventory(): void {
    this.subscription.add(this.getInventory(1).subscribe(inventory => {
      this.inventory = inventory;
      this.hasNextPage = this.inventory.products.current_page < this.inventory.products.last_page;
      this.nextPage = this.inventory.products.current_page + 1;
      this.tableData.body.cellValue = this.inventoryService.createInventoryEditableRows(this.inventory);
      this.tableService.setTableValue(this.tableData);
    }));
  }

  private appendInventoryProduct(): void {
    this.subscription.add(this.getInventory(this.nextPage).subscribe(inventory => {
      this.hasNextPage = inventory.products.current_page < inventory.products.last_page;
      this.nextPage = this.inventory.products.current_page + 1;
      this.inventory.products.data = [...this.inventory.products.data, ...inventory.products.data];
      this.tableData.body.cellValue = this.inventoryService.createInventoryEditableRows(this.inventory);
      this.tableService.setTableValue(this.tableData);
    }));
  }

  private getTableFormValue(): void {
    this.subscription.add(this.tableService.rowChange$.subscribe(value => {
      const product = this.inventory?.products.data.find(e => e.uuid_product === value.id);
      if (!product) {
        return;
      }
      if (value?.key === 'comment') {
        product.comment = value.value;
      } else {
        product.product_quantity = +value?.value;
        product.quantity_difference = +product.expected_quantity - +product.product_quantity;
      }
      this.tableData.body.cellValue = this.inventoryService.createInventoryEditableRows(this.inventory);
      this.tableService.setTableValue(this.tableData);

      const existingModifiedProduct = this.modifiedProducts.find(e => e.product_uuid === product.uuid_product);
      if (existingModifiedProduct) {
        existingModifiedProduct.comment = product.comment;
        existingModifiedProduct.product_quantity = product.product_quantity;
        return;
      }
      this.modifiedProducts.push({
        product_uuid: product.uuid_product,
        product_quantity: product.product_quantity,
        quantity_difference: product.quantity_difference,
        comment: product.comment,
      });
    }));
  }

  private submitInventory(): void {
    this.subscription.add(
      this.inventoryService.submitInventory(this.inventory.inventory_uuid, this.modifiedProducts)
      .subscribe(response => {
        if (response.status < 300) {
          this.miscService.showToast({
            type: 'success',
            icon: 'check',
            title: 'Succès',
            desc: 'Inventaire enregistré'
          });
          this.router.navigate(['../../']);
          return;
        }
        this.miscService.showToast({
          type: 'danger',
          icon: 'alert',
          title: 'Erreur',
          desc: response.errors[0].message
        });
      })
    );
  }

  private saveInventory(): void {
    this.subscription.add(
      this.inventoryService.saveInventory(this.inventory.inventory_uuid, this.modifiedProducts)
      .subscribe(response => {
        if (response.status < 300) {
          this.miscService.showToast({
            type: 'success',
            icon: 'check',
            title: 'Succès',
            desc: 'Inventaire enregistré'
          });
          this.router.navigate([url.home]);
          return;
        }
        this.miscService.showToast({
          type: 'danger',
          icon: 'alert',
          title: 'Erreur',
          desc: response.errors[0].message
        });
      })
    );
  }

  onSaveInventoryBtnclick = (): void => this.saveInventory();

  onSubmitInventoryBtnClick = (): void => this.submitInventory();

  onShowMoreButtonClick = (): void => this.appendInventoryProduct();

  exportDocument() {
    this.subscription.add(
      this.inventoryService.exportDocument(this.inventoryUuid).subscribe((response: I_ApiResponse) => {
        const url = response.data.data.document
        window.open(url, '_blank');
      })
    )
  }

  getIconAction() {
    this.iconService.addIcon({
      id: 'inventory',
      icons: [
        {
          id: 'export',
          image: 'icn-icn-export',
          tooltip: 'Exporter',
          class: '_export-aff',
          action: () => {
            this.exportDocument()
          }
        }
      ]
    });
  }
}
