<aside class="sidebar">
  <div class="sidebar-content nav-box">
    <div class="nav-home" *ngFor="let menu of sidebarMenu">
      <div class="title" *ngIf="menu.groupName">{{menu.groupName}}</div>
      <nav class="navigation">
        <ul>
          <li  (click)="updateMenuItemStatus($event, menuItem)" class="active" [ngClass]="{active: menuItem.active}" *ngFor="let menuItem of menu.menuItem">
            <a>
              <span class="box-icon">
                <i class="icn-{{menuItem.icon}}"></i>
              </span>
              {{menuItem.label}}
              <i *ngIf="menuItem.subMenu?.length>0" class="arrowRight"></i>
            </a>

          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="submenu-container" #asideSubmenu>
    <div class="submenu" *ngIf="submenuStatus">
      <div class="submenu__wrapper">
        <div class="submenu__label">{{subMenuLabel}}</div>
        <div class="submenu__content">
          <ul class="submenu__list">
            <li class="submenu_elm" *ngFor="let subMenuItem of subMenu; let i=index;">
              <ng-container *ngIf="subMenuItem.groupName !== null else groupNameIsNull">
                <div class="submenu__item" appAccordion>
                  <span class="submenu_goup_name">{{subMenuItem.groupName}}</span>
                  <i class="arrow right"></i>
                </div>
                <ul class="submenu__list__child">
                  <li *ngFor="let item of subMenuItem.menuItem; let j=index;"
                    [ngClass]="{'active-child': item.active===true}" (click)="updateSubmenuItemStatus(i, j)">
                    <a>{{item.label}}</a>
                  </li>
                </ul>
              </ng-container>
              <ng-template #groupNameIsNull>
                <ul class="submenu__list__child__nogroup">
                  <li *ngFor="let item of subMenuItem.menuItem; let j=index;"
                    [ngClass]="{'active-child': item.active===true}" (click)="updateSubmenuItemStatus(i, j)">
                    <a>{{item.label}}</a>
                  </li>
                </ul>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</aside>
