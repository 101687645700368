import {Component, Input, OnInit} from '@angular/core';
import {I_CounterMulti, I_CounterMultiData} from "../../../models/counter-multi";

@Component({
  selector: 'app-counter-multi',
  templateUrl: './counter-multi.component.html',
  styleUrls: ['./counter-multi.component.scss']
})
export class CounterMultiComponent implements OnInit {
  @Input() counters!: I_CounterMulti;
  @Input() cible: string = '';
  @Input() date: string;

  constructor() { }

  ngOnInit(): void {
  }

}
