<div class="shop-list {{cible}}">
  <div class="shop-title">
    <h2>{{ counters.title |translate }}&nbsp;</h2>
  </div>

  <div class="block-flex-wrap-22">
    <ng-container *ngIf="!counters.type || counters.type === 'lite'">
      <div class="shop-list__counter" *ngFor="let counterData of counters.data" (click)="counterData?.action()">
        <div class="icon-bg" *ngIf="counterData.icon">
          <i class="{{ counterData.icon }}"></i>
        </div>
        <ng-container *ngIf="counterData.customIcon">
          <div class="block-counter-left">
            <div class="icon-bg" *ngFor="let icon of counterData.customIcon">
              <i class="{{ icon }}"></i>
              <!-- <i class="icn-icon_right_rrow"></i> -->
            </div>
          </div>

        </ng-container>
        <div class="counter-label">
          <span class="counter-label-desc">{{ counterData.description |translate }}</span>
          <span class="shop-number">{{ counterData.number | number }} <span class="font-initial">{{counterData.currency}}</span> </span>
        </div>
        <div class="icone-info">
          <i class="{{ counterData.info }}"></i>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="counters.type === 'full'">
      <div *ngFor="let counterData of counters.data" (click)="counterData?.action()" class="shop-list__counter shop-list__counter--full" [ngClass]="counterData.color">
        <div class="icon-bg" *ngIf="counterData.icon">
          <i class="{{ counterData.icon }}"></i>
        </div>
        <ng-container *ngIf="counterData.customIcon">
          <div class="block-counter-left">
            <div class="icon-bg" *ngFor="let icon of counterData.customIcon">
              <i class="{{ icon }}"></i>
              <!-- <i class="icn-icon_right_rrow"></i> -->
            </div>
          </div>

        </ng-container>
        <div class="counter-label">
          <span class="counter-label-desc">{{ counterData.description |translate }}</span>
          <span class="shop-number ">{{ counterData.number | number }} <span class="font-initial">{{counterData.currency}}</span> </span>
        </div>
        <div class="icone-info">
          <i class="icn-info-ellipse"></i>
        </div>
        <div *ngIf="date" class="dateKpi">
          {{date}}
        </div>
      </div>

    </ng-container>
  </div>
</div>
