import {Component, OnInit} from '@angular/core';
import {
  InternationalizationService
} from "./main/modules/shared/services/internationalization/internationalization.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pos-group-front';
}
