<div class="cntNotAllowed">
  <h3 class="cntNotAllowed_title">Connexion à POS Group réussi</h3>
  <div class="cntNotAllowed_img">

    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin:auto;background:transparent;display:block;" width="200px" height="200px" viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <defs>
        <mask id="ldio-htkdqgdoa5r-mask">
          <circle cx="50" cy="50" r="20" stroke="var(--white)" stroke-linecap="round"
            stroke-dasharray="94.24777960769379 31.41592653589793" stroke-width="9">
            <animateTransform attributeName="transform" type="rotate" values="0 50 50;360 50 50" times="0;1" dur="1s"
              repeatCount="indefinite"></animateTransform>
          </circle>
        </mask>
      </defs>
      <g mask="url(#ldio-htkdqgdoa5r-mask)" color="var(--primary)">
        <rect x="20.5" y="0" width="59" height="100" fill="var(--primary)">
          <animate attributeName="fill" values="var(--primary)" times="0" dur="1s" repeatCount="indefinite" begin="0s">
          </animate>
        </rect>
      </g>
    </svg>

    <p class="cntNotAllowed_descr">
      Vous allez être rediriger vers la page d'accueil dans un instant.
    </p>
  </div>