// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiCompanyService: 'http://10.163.13.191:301',
  apiShopService: 'http://10.163.13.191:302',
  apiStaticService: 'http://10.163.13.191:303',
  apiUserService: 'http://10.163.13.191:304',
  apiCatalogService: 'http://10.163.13.191:305',
  apiStockService: 'http://10.163.13.191:306',
  apiContractService: 'http://10.163.13.191:307',
  apiPaymentService: 'http://10.163.13.191:308',
  apiCartService: 'http://10.163.13.191:309',
  apiOrderService: 'http://10.163.13.191:310',
  apiBillingService: 'http://10.163.13.191:311',
  apiRightService: 'http://10.163.13.191:312',
  apiAuthService: "https://bfea-102-16-25-38.ngrok-free.app"
};

export const defaultLanguage = 'fr';
export const imgUrl = 'assets/img/'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
