<div class="shop-list shop-list-{{cible}}">
  <div class="shop-list__counter">
    <div class="icon-bg">
      <i class="{{ card.icon }}"></i>
    </div>
    <div class="counter-label">
      <span class="flex-bloc">
        <span class="counter-label-desc">{{ card.description |translate }}</span>
        <span *ngIf="cible!==''" class="left-flex-bloc">inactif</span>
      </span>
      <span class="shop-number">{{ card.data }}</span>
    </div>
    <span class="card__close" (click)="click()">+</span>
  </div>
</div>

<!--
<div class="shop-list {{cible}">
  <div class="shop-list__counter">
    <div class="icon-bg">
      <i class="{{ card.icon }}"></i>
    </div>
    <div class="counter-label">
      <span class="counter-label-desc">{{ card.description |translate }}</span>
      <span class="shop-number">{{ card.data  }}</span>
    </div>
  </div>
</div> -->
