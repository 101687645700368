import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAccordion]'
})
export class AccordionDirective {

  constructor(private el:ElementRef, private render: Renderer2) { }

  @HostListener('click') onClick() {
    let elemt = this.el.nativeElement;//this
    let parentNav = this.render.parentNode(elemt);//submenu__elm
    let listItem = elemt.closest('.submenu__list').querySelectorAll('.submenu__item'); //liste submenu item
    const hasClass = parentNav.classList.contains('submenu__active');
    if (this.render.nextSibling(elemt)){      
      let subMenuChild = this.render.nextSibling(elemt);//submenu__list__child du this
      for (const itemEl of listItem) {
        let noparentNav = this.render.parentNode(itemEl);//liste submenu_elm
        if (itemEl!==elemt){
          this.render.removeClass(noparentNav, 'submenu__active');
          if (this.render.nextSibling(itemEl)) { 
            let nosubMenuChild = this.render.nextSibling(itemEl);//liste submenu__list__child
            this.render.removeClass(nosubMenuChild, "activeSub");
            nosubMenuChild.style.maxHeight = null;
          }
        }           
      } 
      if(hasClass){
        this.render.removeClass(parentNav, 'submenu__active');
        this.render.removeClass(subMenuChild, 'activeSub');
        subMenuChild.style.maxHeight = null;
      }else{
        this.render.addClass(parentNav, 'submenu__active');
        this.render.addClass(subMenuChild, 'activeSub');
        subMenuChild.style.maxHeight = subMenuChild.scrollHeight + 'px';
      }
    }
    
  }

}
