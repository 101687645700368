import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { InventoryRoutingModule } from './inventory-routing.module';
import { CreateInventoryComponent } from './pages/create/create-inventory.component';
import { SharedModule } from '../shared/shared.module';
import { ListInventoryComponent } from './pages/list/list-inventory.component';


@NgModule({
  declarations: [
    CreateInventoryComponent,
    ListInventoryComponent
  ],
  imports: [
    CommonModule,
    InventoryRoutingModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InventoryModule { }
