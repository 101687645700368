import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {AddRemoveShop, AddRemoveShopList, AddRemoveShopNetwork} from "../../models/add-remove-shop/add-remove-shop.model";
import {I_ApiResponse} from "../../models/api-response";
import {ApiService} from "../api-service/api.service";
import {EnvService} from "../../../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class AddRemoveShopService {
  public selectedShops$: BehaviorSubject<AddRemoveShop> = new BehaviorSubject<AddRemoveShop>(null);
  public shops$: BehaviorSubject<any[]> = new BehaviorSubject<AddRemoveShop[]>([]);
  public runGetShop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saveSelectedShop$: BehaviorSubject<{ network: AddRemoveShopNetwork[], shop: AddRemoveShopList[] }>  = new BehaviorSubject<{ network: AddRemoveShopNetwork[], shop: AddRemoveShopList[] }>(null);

  constructor(
    private apiService: ApiService,
    private env: EnvService
  ) { }

  setSelectedShops(shops: AddRemoveShop) {
    this.selectedShops$.next(shops);
  }

  runGetShop(status: boolean) {
    this.runGetShop$.next(status);
  }

  getPointOfSales(companyUuid: string, shopName: string): Observable<I_ApiResponse> {
    const url = `${this.env.apiShopService}/company/${companyUuid}/get?group=network&shop=${shopName}`;
    return this.apiService.doGet(url);
  }

  getSaveSelectedShop(shop: { network: AddRemoveShopNetwork[], shop: AddRemoveShopList[] }) {
    this.saveSelectedShop$.next(shop)
  }
}
