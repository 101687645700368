import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {filter, Subscription} from "rxjs";
import {InputService} from "../../../services/input/input.service";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-input-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {
  @Input() id!: string;
  @Input() value: string = '';
  @Input() isDisabled: boolean = false;
  public error: boolean = false
  public textForm: FormGroup;
  private subscription = new Subscription()

  constructor(
    private formBuilder: FormBuilder,
    private inputService: InputService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.formValueChange();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.textForm = this.formBuilder.group({
      id: [this.id, Validators.required],
      text: [this.value, Validators.required],
      trigger: false
    })
  }

  triggerEvent() {
    this.textForm.get('trigger').setValue(true);
    this.textForm.updateValueAndValidity();
  }

  formValueChange() {
    this.subscription.add(
      this.textForm.valueChanges.pipe(
        debounceTime(500),
        filter(value => value.trigger)
      ).subscribe((value: any) => {
        this.error = !this.textForm.valid;
        const _value = {...value, ...{valid: this.textForm.valid}};
        this.inputService.setValue(_value);
      })
    );
  }
}
