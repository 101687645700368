import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InputService {
  private value$: Subject<any> = new Subject<any>();

  constructor() { }

  getValue() {
    return this.value$;
  }

  setValue(value: any) {
    this.value$.next(value);
  }
}
