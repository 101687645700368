import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ITableFilter} from "../../../../interfaces/table-filter";
import {Subscription} from "rxjs";
import {AddRemoveShopService} from "../../../../services/add-remove-shop/add-remove-shop.service";
import {responseStatus} from "../../../../config/constants";
import {storageKey} from "../../../../../../../config/storage";
import {DiscountService} from "../../../../../product/services/discount/discount.service";
import {LocalStorageService} from "../../../../services/local-storage/local-storage.service";
import {I_ApiResponse} from "../../../../models/api-response";
import {debounceTime, distinct, distinctUntilChanged, switchMap} from "rxjs/operators";
import {AddRemoveShop} from "../../../../models/add-remove-shop/add-remove-shop.model";

@Component({
  selector: 'app-add-shop',
  templateUrl: './add-shop.component.html',
  styleUrls: ['./add-shop.component.scss']
})
export class AddShopComponent implements OnInit, OnDestroy {

  public tabFilterParams: ITableFilter = {
    id: 'search-shop',
    title: '',
    fields: [{
      key: 'key',
      label: 'Mot clé',
      placeholder: 'Points de vente',
      type: 'input'
    }]
  };
  public networkTotal: number = 0;
  public shopTotal: number = 0;
  public selectedShop: AddRemoveShop = null;
  public shopForm!: FormGroup;
  private subscription = new Subscription();
  constructor(
    private formBuilder: FormBuilder,
    private addRemoveShopService: AddRemoveShopService,
    private discountService: DiscountService,
    private localStorageService: LocalStorageService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.listPointOfSales();
    this.formValues();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.shopForm = this.formBuilder.group({
      all_is_checked: false,
      shops: this.formBuilder.array([])
    })
  }

  shopGroups(): FormArray {
    return this.shopForm.get('shops') as FormArray
  }

  shopList(index: number): FormArray {
    return this.shopGroups().at(index).get('shop_list') as FormArray
  }

  shop(i: number, j: number) {
    return this.shopList(i).at(j)
  }

  get userDetail() {
    return JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData))
  }

  listPointOfSales(shopName: string = '') {
    this.subscription.add(
      this.addRemoveShopService.runGetShop$.pipe(
        switchMap((isRun: boolean) => {
          if (isRun) return this.addRemoveShopService.getPointOfSales(this.userDetail.company_uuid, shopName)
          else return []
        })
      ).subscribe((response: I_ApiResponse) => this.pointOfSaleResponse(response))
    )
  }

  pointOfSaleResponse(response: I_ApiResponse) {
    this.resetForm();
    this.networkTotal = 0;
    this.shopTotal = 0;
    switch (response.status) {
      case responseStatus.success:
        const networks = response.data.shops
        networks.forEach(network => {
          let fieldGroup = this.formBuilder.group({
            network_uuid: network.network_uuid,
            network_name: network.network_name,
            network_is_checked: false,
            shop_list: this.formBuilder.array([])
          })
          let group = fieldGroup.get('shop_list') as FormArray;
          this.networkTotal += network.shops.length;

          network.shops.forEach(shop => {
            this.shopTotal ++;
            let shopGroup = this.formBuilder.group({
              shop_uuid: shop.shop_uuid,
              shop_name: shop.name,
              shop_is_checked: false,
            })
            group.push(shopGroup);
          })
          this.shopGroups().push(fieldGroup)
        })
        break;
      case responseStatus.error:
        console.log(response.errors)
        break;
    }
  }

  resetForm() {
    this.shopForm.reset();
    this.shopGroups().clear();
    this.shopGroups().reset();
  }

  selectAllPos() {
    if (this.shopForm.get('all_is_checked').value == true) {
      this.shopGroups().controls.forEach((control, i) => {
        control.patchValue({network_is_checked: true})
        this.selectNetWork(i);
      })
      this.shopForm.updateValueAndValidity()
      this.formValues();
    } else {
      this.shopGroups().controls.forEach((control, i) => {
        control.patchValue({network_is_checked: false})
        this.selectNetWork(i);
      })
      this.shopForm.get('all_is_checked').setValue(false)
      this.shopForm.updateValueAndValidity()
      this.formValues();
    }
  }

  selectNetWork(i: number) {
    const network_is_checked = this.shopGroups().at(i).get('network_is_checked').value
    if (network_is_checked) {
      this.shopList(i).controls.forEach(control => {
        control.patchValue({shop_is_checked: true})
      });
      this.shopForm.updateValueAndValidity()
      this.formValues();
    } else {
      this.shopList(i).controls.forEach(control => {
        control.patchValue({shop_is_checked: false})
      });
      this.shopForm.get('all_is_checked').setValue(false)
      this.shopForm.updateValueAndValidity()
      this.formValues();
    }
  }

  selectShop(i: number, j: number) {
    const shop_is_checked = this.shop(i, j);
    if (!shop_is_checked.value.shop_is_checked) {
      this.shopForm.get('all_is_checked').setValue(false);
      this.shopGroups().at(i).get('network_is_checked').setValue(false)
    }
    this.shopForm.updateValueAndValidity()
    this.formValues();
  }

  formValues() {
    this.subscription.add(
      this.shopForm.valueChanges.subscribe(values => {
        if (values.shops.length > 0 && JSON.stringify(this.selectedShop) !=JSON.stringify(values)) {
          this.selectedShop = values;
          this.addRemoveShopService.setSelectedShops(this.selectedShop)
        }
      })
    );
  }
}
