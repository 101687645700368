<form [formGroup]="loginFormGroup">
  <div class="imgcontainer">
    <img src="{{logo}}" alt="Avatar" class="avatar">
  </div>

  <div class="container">
    <div class="alert" *ngIf="errorMessage">
      <strong>ERREUR!</strong> Nom d'utilisateur ou mots de passe incorrecte.
    </div>

    <label for="username"><b>Nom d'utilisateur</b></label>
    <input
      type="text"
      placeholder="Entrer votre nom d'utilisateur"
      [ngClass]="{ 'is-invalid': loginError && loginFormGroup.get('username').errors }"
      id="username"
      name="username"
      formControlName="username"
    >

    <label for="password"><b>Mot de passe</b></label>
    <input
      type="password"
      placeholder="Entrer votre mot de passe"
      [ngClass]="{ 'is-invalid': loginError && loginFormGroup.get('username').errors }"
      id="password"
      name="password"
      formControlName="password"
    >
    <button (click)="login()">Se connecter</button>
  </div>
</form>
