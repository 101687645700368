import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableService} from "../../services/table/dynamic/table.service";
import {Subscription} from "rxjs";
import {Table} from "../../models/table/table.model";
import {Line} from "../../models/table/body/row/line";
import { InputValue } from '../../models/table/validations/input-value';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() public id: string = '';
  @Input() public iconId: string = '';
  public table!: Table;
  private subscription = new Subscription();
  public expandedId: string = '';
  public expandedLines: Line[] = [];

  constructor(
    private tableService: TableService
  ) { }

  ngOnInit(): void {
    this.getTable();
    this.getExpandedLineValue();
    this.getRemovedLine();
    this.getColumnValue();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTable() {
    this.tableService.getTable().subscribe((value: Table) => {
      this.expandedId = '';
      this.expandedLines = []
      if (value?.id === this.id) {
        this.table = value;
      }
    })
  }

  setExpendLineId(lineId: string) {
    if (this.expandedId != lineId) {
      this.expandedId = lineId;
      this.tableService.setExpandedId(lineId);
    } else {
      this.expandedId = '';
    }
  }

  getExpandedLineValue() {
    this.subscription.add(
      this.tableService.getExpandedLineValues().subscribe((lines: Line[]) => {
        this.expandedLines = lines
      })
    )
  }

  action(id: string, action: ('delete' | 'edit' | 'view'), isExpand: boolean = false, parentId?: string): void {
    if (isExpand) {
      this.tableService.setAction({
        id,
        parentId,
        action
      });
      return;
    }
    this.tableService.setAction({id: id, action: action});
  }

  getInputValue(event: any, isExpand: boolean = false) {
    if (isExpand) {
      this.expandInputValue(event)
    } else {
      this.inputValue(event);
    }
  }

  inputValue(event: any): void {
    const ids = event.id.split('-');
    const value: InputValue = {
      id: this.table.body.line[ids[0]].lineId,
      value: event.value,
    };
    this.tableService.setTableInputValue(value);
  }

  expandInputValue(event: any) {
    const ids = event['id'].split('-');
    const value: InputValue = {
      id: this.expandedLines[ids[0]].lineId,
      value: event['value']
    }
    this.tableService.setTableInputValue(value);
  }

  getRemovedLine() {
    this.subscription.add(
      this.tableService.getRemovedLine().subscribe(value => {
        if (value.isParent) {

        } else {
          this.expandedLines = this.expandedLines.filter(line => line.lineId !== value.id);
        }
      })
    )
  }

  getColumnValue() {
    this.subscription.add(
      this.tableService.getColumnValue().subscribe(value => {
        if (value.isParent) {
          this.table.body.line.filter(line => line.lineId === value.id)[0].column[value.i] = value.column;
        } else {
          this.expandedLines.filter((line: Line) =>line.lineId === value.id)[0].column[value.i] = value.column
        }
      })
    );
  }

}
