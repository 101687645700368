<div class="block-article-unit block-unit">
  <div  class="article-unit align-center"  [formGroup]="serializationFormGroup">
    <ng-container>
      <div class="article-unit__item"  >
        <div class="article-unit__text">
          {{serialization?.product_serialization_key}}
        </div>
      </div>
      <div class="article-unit__item">
        <div>
          <input class="w-100"
            *ngIf="!autoCompleteField else autoComplete"
            type="text"
            formControlName="serial_number_start"
            (input)="triggerEvent()"
            class="pos-input"
          >
          <ng-template #autoComplete>
            <mat-form-field class="example-full-width" appearance="fill">

            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <div class="article-unit__item">
        <div class="article-unit-flex">
          <app-check-icon>
            <i class="icon" [ngClass]="serializationInvalid ? 'icn-alert': 'icn-check'"></i>
          </app-check-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>
