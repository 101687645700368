import { Injectable } from '@angular/core';
import {User} from "../../../shared/models/user.model";
import {ApiService} from "../../../shared/services/api-service/api.service";
import {LocalStorageService} from "../../../shared/services/local-storage/local-storage.service";
import {Router} from "@angular/router";
import { storageKey } from 'src/app/config/storage';
import { url } from 'src/app/config/url';
import {environment} from "../../../../../../environments/environment";
import {EnvService} from "../../../../../env.service";


@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private env: EnvService
  ) { }

  login(username: string, users: User[]) {
    let user = users.filter(_user => _user.first_name.toLocaleLowerCase() == username.toLocaleLowerCase())
    if (user.length == 0) {
      return false;
    } else {
      this.localStorageService.setLocalStorage(storageKey.user.userUuid, user[0].user_uuid.toString());
      this.localStorageService.setLocalStorage(storageKey.user.userData, JSON.stringify(user[0]));
      return true;
    }
  }

  fakeLogOut() {
    this.localStorageService.clearLocalStorage();
    this.router.navigate([ url.user.login.authenticationLDAP ]);
  }

  getCompany() {
    const url = `${this.env.apiCompanyService}/get/companies-user`
    return this.apiService.doGet(url);
  }
}
