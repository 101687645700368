import { NumberInputDirective } from './directives/number-input/number-input.directive';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CardComponent } from './components/miscellaneous/card/card.component';
import { PageTestComponent } from './pages/page-test/page-test.component';
import { QuickAccessComponent } from './components/miscellaneous/quick-access/quick-access.component';
import { QuickRepportComponent } from './components/miscellaneous/quick-repport/quick-repport.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InternationalizationService } from './services/internationalization/internationalization.service';
import { PageTitleComponent } from './components/miscellaneous/page-title/page-title.component';
import { LeftMenuComponent } from './components/miscellaneous/left-menu/left-menu.component';
import { TitleIntroComponent } from './components/miscellaneous/title-intro/title-intro.component';
import { DialogConfirmationComponent } from './components/miscellaneous/dialog-confirmation/dialog-confirmation.component';
import { ToastNotificationComponent } from './components/miscellaneous/toast-notification/toast-notification.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import {BreadCrumbComponent} from "./components/miscellaneous/bread-crumb/bread-crumb.component";
import {RouterModule} from "@angular/router";
import { ToDoComponent } from './components/miscellaneous/to-do/to-do.component';
import { ImportComponent } from './components/miscellaneous/import/import.component';
import { UploadCsvComponent } from './components/miscellaneous/upload-csv/upload-csv.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { DialogComponent } from './components/miscellaneous/dialog/dialog.component';
import { CounterComponent } from './components/miscellaneous/counter/counter.component';
import { TooltipInfoComponent } from './components/miscellaneous/tooltip-info/tooltip-info.component';
import {HeaderComponent} from "../../layouts/pos-layout/components/header/header.component";
import { NumericInputDirective } from './directives/numeric-input/numeric-input.directive';
import { InfiniteScrollTableComponent } from './components/miscellaneous/infinite-scroll-table/infinite-scroll-table.component';
import { PageInfiniteScrollTestComponent } from './pages/page-infinite-scroll-test/page-infinite-scroll-test.component';
import { ParagraphIntroComponent } from './components/miscellaneous/paragraph-intro/paragraph-intro.component';
import { TabComponent } from './components/miscellaneous/tab/tab.component';
import { TabsComponent } from './components/miscellaneous/tabs/tabs.component';
import { GenericFormComponent } from './components/miscellaneous/generic-form/generic-form.component';
import { PageTestFormulaireGeneriqueComponent } from './pages/page-test-formulaire-generique/page-test-formulaire-generique.component';
import { ContractCompliantFormComponent } from './components/miscellaneous/contract-compliant-form/contract-compliant-form.component';
import { TextareaInputDirective } from './directives/textarea-input/textarea-input.directive';
import { PageNotAllowedComponent } from './pages/page-not-allowed/page-not-allowed.component';
import { ExportComponent } from './components/miscellaneous/export/export.component';
import { HeightScreenDirective } from './directives/height-screen/height-screen.directive';
import { PageTestHeightComponent } from './pages/page-test-height/page-test-height.component';
import { CounterMultiComponent } from './components/miscellaneous/counter-multi/counter-multi.component';
import {TableFilterComponent} from "./components/miscellaneous/table-filter/table-filter.component";
import {NgSelectModule} from "@ng-select/ng-select";
import { PageTestFontIconComponent } from './pages/page-test-font-icon/page-test-font-icon.component';
import { CheckboxTreeComponent } from './components/miscellaneous/checkbox-tree/checkbox-tree.component';
import { ButtonComponent } from './components/miscellaneous/button/button.component';
import { TableFilterNewComponent } from './components/miscellaneous/table-filter-new/table-filter-new.component';
import { TableWithoutEditComponent } from './components/miscellaneous/table-without-edit/table-without-edit.component';
import { TableFormComponent } from './components/miscellaneous/table-form/table-form.component';
import { AddRemoveItemsComponent } from './components/miscellaneous/add-remove-items/add-remove-items.component';
import { ProductCardComponent } from './components/miscellaneous/add-remove-items/product-card/product-card.component';
import { PackCardItemComponent } from './components/miscellaneous/add-remove-items/pack-card-item/pack-card-item.component';
import { AddShopComponent } from './components/miscellaneous/add-remove-shop/add-shop/add-shop.component';
import { ListSelectedShopComponent } from './components/miscellaneous/add-remove-shop/list-selected-shop/list-selected-shop.component';
import { CheckboxComponent } from './components/miscellaneous/checkbox/checkbox.component';
import { ChipComponent } from './components/miscellaneous/chip/chip.component';
import { EmptyListComponent } from './components/miscellaneous/empty-list/empty-list.component';

import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ImageComponent } from './components/miscellaneous/image/image.component';
import { PageTestTabsComponent } from './pages/page-test-tabs/page-test-tabs.component';
import { ItemSelectionComponent } from './components/miscellaneous/item-selection/item-selection.component';
import { ItemCardComponent } from './components/miscellaneous/item-selection/item-card/item-card.component';
import { ItemSerializationComponent } from './components/miscellaneous/item-serialization/item-serialization.component';
import { PerUnitComponent } from './components/miscellaneous/item-serialization/per-unit/per-unit.component';
import { PerBatchComponent } from './components/miscellaneous/item-serialization/per-batch/per-batch.component';
import { ItemSerializationCartComponent } from './components/miscellaneous/item-serialization-cart/item-serialization-cart.component';
import { PerBatchCartComponent } from './components/miscellaneous/item-serialization-cart/per-batch-cart/per-batch-cart.component';
import { PerUnitCartComponent } from './components/miscellaneous/item-serialization-cart/per-unit-cart/per-unit-cart.component';
import { IconComponent } from './components/miscellaneous/icon/icon.component';
import { UserNotShopComponent } from './pages/user-not-shop/user-not-shop.component';
import { TableComponent } from './components/table/table.component';
import { NumberComponent } from './components/input/number/number.component';
import { SwitchComponent } from './components/input/switch/switch.component';
import { ButtonDefaultComponent } from './components/miscellaneous/button-default/button-default.component';
import { IconsComponent } from './pages/icons/icons.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent } from './components/stepper/step/step.component';
import { TitleComponent } from './components/stepper/title/title.component';
import { RequiredIndicatorComponent } from './components/required-indicator/required-indicator.component';

@NgModule({
  declarations: [
    HeaderComponent,
    PageNotFoundComponent,
    CardComponent,
    PageTestComponent,
    QuickAccessComponent,
    QuickRepportComponent,
    LeftMenuComponent,
    PageTitleComponent,
    TitleIntroComponent,
    DialogConfirmationComponent,
    ToastNotificationComponent,
    BreadCrumbComponent,
    ToDoComponent,
    ImportComponent,
    UploadCsvComponent,
    DialogComponent,
    TooltipInfoComponent,
    CounterComponent,
    NumericInputDirective,
    NumberInputDirective,
    InfiniteScrollTableComponent,
    PageInfiniteScrollTestComponent,
    ParagraphIntroComponent,
    TabComponent,
    TabsComponent,
    GenericFormComponent,
    PageTestFormulaireGeneriqueComponent,
    ContractCompliantFormComponent,
    TextareaInputDirective,
    PageNotAllowedComponent,
    ExportComponent,
    HeightScreenDirective,
    PageTestHeightComponent,
    CounterMultiComponent,
    TableFilterComponent,
    PageTestFontIconComponent,
    CounterMultiComponent,
    TableFilterComponent,
    PageTestFontIconComponent,
    CheckboxTreeComponent,
    ButtonComponent,
    TableFilterNewComponent,
    TableWithoutEditComponent,
    TableFormComponent,
    AddRemoveItemsComponent,
    ProductCardComponent,
    PackCardItemComponent,
    AddShopComponent,
    ListSelectedShopComponent,
    CheckboxComponent,
    ChipComponent,
    EmptyListComponent,
    ImageComponent,
    PageTestTabsComponent,
    ItemSelectionComponent,
    ItemCardComponent,
    ItemSerializationComponent,
    PerUnitComponent,
    PerBatchComponent,
    ItemSerializationCartComponent,
    PerBatchCartComponent,
    PerUnitCartComponent,
    IconComponent,
    UserNotShopComponent,
    TableComponent,
    NumberComponent,
    SwitchComponent,
    ButtonDefaultComponent,
    SwitchComponent,
    IconsComponent,
    StepperComponent,
    StepComponent,
    TitleComponent,
    RequiredIndicatorComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AutocompleteLibModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatDatepickerModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    LeftMenuComponent,
    TranslateModule,
    PageTitleComponent,
    DialogConfirmationComponent,
    ToastNotificationComponent,
    BreadCrumbComponent,
    ToDoComponent,
    ImportComponent,
    UploadCsvComponent,
    DialogComponent,
    FormsModule,
    CounterComponent,
    TooltipInfoComponent,
    QuickRepportComponent,
    HeaderComponent,
    NumericInputDirective,
    NumberInputDirective,
    InfiniteScrollTableComponent,
    AutocompleteLibModule,
    NgSelectModule,
    TabsComponent,
    ParagraphIntroComponent,
    TabComponent,
    ContractCompliantFormComponent,
    GenericFormComponent,
    AutocompleteLibModule,
    HeightScreenDirective,
    CardComponent,
    ParagraphIntroComponent,
    CounterMultiComponent,
    AutocompleteLibModule,
    PageTitleComponent,
    TitleIntroComponent,
    HeightScreenDirective,
    CardComponent,
    ParagraphIntroComponent,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ExportComponent,
    HeightScreenDirective,
    HeightScreenDirective,
    CounterMultiComponent,
    TableFilterComponent,
    ButtonComponent,
    TableFilterNewComponent,
    TableWithoutEditComponent,
    TableFormComponent,
    AddRemoveItemsComponent,
    ProductCardComponent,
    PackCardItemComponent,
    AddShopComponent,
    ListSelectedShopComponent,
    CheckboxComponent,
    CheckboxTreeComponent,
    ChipComponent,
    EmptyListComponent,
    IconsComponent,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatChipsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ImageComponent,
    ItemSelectionComponent,
    ItemSerializationComponent,
    PerUnitComponent,
    PerBatchComponent,
    ItemSerializationCartComponent,
    PerBatchCartComponent,
    PerUnitCartComponent,
    IconComponent,
    NumberComponent,
    SwitchComponent,
    ButtonDefaultComponent,
    TableComponent,
    StepperComponent,
    StepComponent,
    TitleComponent,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    InternationalizationService,
    CommonModule,
  ]
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './././assets/i18n/', '.json');
}
