<div>
  <div class="block-table-user pb-4">
    <div class="block-table-user-top">
      <div class="block-table-user-top-item">
        <!--<span>6 Remise(s) : </span> -->
      </div>
      <div class="block-table-user-top-item">
        <div class="block-table-user-top-item-right ">
          <app-icon *ngIf="iconId != ''" [id]="iconId"></app-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="client__list" appHeightScreen spaceHeight="183">
    <app-infinite-scroll-table>
      <table class="table">
        <thead>
          <th class="w-md" *ngFor="let header of tables?.header" [attr.colspan]="header?.colspan" scope="col">
            <div class="block-intern-header"
              [ngClass]="{'align-right': header.alignement==='right', 'align-left': header.alignement==='left', 'align-center': header.alignement==='center'}">
              {{ header?.value | translate }}
              <div *ngIf="header?.hasTooltip" class="icone-info" [attr.data-tooltip]="header?.tooltip"
                data-flow="bottom">
                <i class="icn-info-ellipse"></i>
              </div>
            </div>
          </th>
          <th class="w-sm" *ngIf="tables?.isEditable || tables?.isDeletable">
            Action
          </th>
        </thead>
        <tbody>
          <ng-container *ngFor="let body of tables?.body?.cellValue; let i = index">
            <tr style="height: 50px;" *ngIf="!body.is_expandable else expandable"
              [ngClass]="{'tr-disabled': body.is_disabled}">
              <td *ngFor="let row of body.rowValue">
                <div class="flx-align-center block-description-article"
                  [ngClass]="{'align-right': row?.align==='right', 'align-left':row?.align==='left', 'align-center': row?.align==='center'}">
                  <div class="block-left-description-article" *ngIf="row?.image">
                    <div class="block-left-int-description-article">
                      <img [src]="row?.image">
                    </div>
                  </div>

                  <div class="block-right-description-article">
                    <ng-container *ngIf="row?.subArray!==undefined else oneValueItem">
                      <p *ngFor="let subAarrayItem of row?.subArray" class="semibold">
                        {{subAarrayItem}}
                      </p>
                    </ng-container>
                    <ng-template #oneValueItem>
                      <ng-container *ngIf="getTypeOf(row?.value) != 'object' else objectValue">
                        <p *ngIf="row?.type === 'simple' else buttonType">
                          <span *ngIf="row?.badge?.status else notBadge" class="badge {{row?.badge?.bg}}">{{ row?.value
                            }}</span>
                          <ng-template #notBadge>{{ row?.value }}</ng-template>
                        </p>
                        <ng-template #buttonType>
                          <div [attr.data-tooltip]="row.label || row.value" data-flow="top" [ngClass]="{'tooltip-disabled':row?.disabled}">
                            <!-- add 'tooltip-disabled' tooltip disabled if button disabled -->
                              <button class="{{ row?.class }}" (click)="row?.action()" [disabled]="row?.disabled">
                                <span >{{ row?.label }}</span>
                              </button>
                          </div>
                        </ng-template>
                      </ng-container>
                      <ng-template #objectValue>
                        <p *ngIf="row?.type === 'simple' else buttonType">
                          <span *ngIf="row?.badge?.status else notBadge" class="badge {{row?.badge?.bg}}">{{
                            row?.value?.value}}</span>
                          <ng-template #notBadge>{{ row?.value?.value}}</ng-template>
                        </p>
                        <ng-template #buttonType>
                          <button class="{{ row?.class }}" (click)="row?.action()" [disabled]="row?.disabled">{{
                            row?.label }}</button>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                    <div *ngIf="row?.chip!==undefined && row?.chip!==null" class="chip-colored">
                      {{row?.chip}}
                    </div>
                  </div>

                </div>
              </td>
              <td *ngIf="tables?.isEditable || tables?.isDeletable">
                <div class="cell-content">
                  <a *ngIf="tables?.isDeletable" (click)="onClickDeleteButton(body.rowValue[0].id)"
                    class="link-edit-profile" data-tooltip="Supprimer" data-flow="top"><span
                      class="icn-delete"></span></a>
                  <a *ngIf="tables?.isEditable" (click)="editLine(body.rowValue[0].id)" class="link-edit-profile"
                    data-tooltip="Modifier" data-flow="top"><span class="icn-edit"></span></a>
                </div>
              </td>
            </tr>
            <ng-template #expandable>
              <tr [ngClass]="{'tr-disabled tr-disabled-expanded': body.is_disabled}">
                <td *ngFor="let row of body.rowValue"
                  [ngClass]="{'align-right': row?.align==='right', 'align-left':row?.align==='left', 'align-center': row?.align==='center'}">
                  <div class="column-flex" *ngIf="+row.expand else notExpandValue">
                    <div class="plus {{expanded === body.id ? 'expanded' : 'notexpanded'}}" (click)="expand(body.id )">
                      <span class="arrowRight"></span>
                    </div>
                    <div class="block-int-column-flex">
                      <div class="block-left-column-flex" *ngIf="row?.image">
                        <img *ngIf="row?.image" [src]="row?.image">
                      </div>
                      <div class="block-right-description-article lc-2">
                        <span class="bold" *ngIf="getTypeOf(row?.value) != 'object' else objectValue">{{ row?.value
                          }}</span>
                        <ng-template #objectValue>
                          <span class="bold">{{ row?.value?.value }}</span>
                        </ng-template>
                        <!-- <div class="chip-colored">iMPOSSIBLE D'AJOUTER CE PACK</div> -->
                        <div *ngIf="row?.chip!==undefined && row?.chip!==null" class="chip-colored">
                          {{row?.chip}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #notExpandValue>
                    <div class="flx-align-center ">
                      <img *ngIf="row?.image" [src]="row?.image">
                      <ng-container *ngIf="getTypeOf(row?.value) != 'object' else objectValue">
                        <span *ngIf="row?.badge?.status else notBadge" class="badge {{row?.badge?.bg}}">{{
                          row?.value}}</span>
                        <ng-template #notBadge>{{ row?.value}}</ng-template>
                      </ng-container>
                      <ng-template #objectValue>
                        <span *ngIf="row?.badge?.status else notBadge" class="badge {{row?.badge?.bg}}">{{
                          row?.value.value }}</span>
                        <ng-template #notBadge>{{ row?.value?.value }}</ng-template>
                      </ng-template>
                    </div>
                  </ng-template>
                </td>
                <td *ngIf="tables?.isEditable || tables?.isDeletable">
                  <div class="cell-content">
                    <a *ngIf="tables?.isDeletable" (click)="onClickDeleteButton(body.rowValue[0].id)"
                      class="link-edit-profile" data-tooltip="Supprimer" data-flow="top"><span
                        class="icn-delete"></span></a>
                    <a *ngIf="tables?.isEditable" (click)="editLine(body.rowValue[0].id)" class="link-edit-profile"
                      data-tooltip="Modifier" data-flow="top"><span class="icn-edit"></span></a>
                  </div>
                </td>
              </tr>
            </ng-template>
            <tr [ngClass]="expanded == body.id ? 'isExpand' : 'isNotExpand'"
              *ngFor="let expandValue of expandedValue?.cellValue; let i = index">
              <td *ngFor="let expand of expandValue.rowValue">
                <div class="flx-align-center"
                  [ngClass]="{'align-right': expand?.align==='right', 'align-left':expand?.align==='left', 'align-center': expand?.align==='center'}">
                  <img *ngIf="expand?.image" [src]="expand?.image">
                  <span *ngIf="expand?.badge?.status else notBadge" class="badge {{expand?.badge?.bg}}">{{ expand?.value
                    }}</span>
                  <ng-template #notBadge>{{ expand?.value }}</ng-template>
                </div>
              </td>

            </tr>
          </ng-container>
        </tbody>
      </table>
    </app-infinite-scroll-table>
  </div>
</div>
