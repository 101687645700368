import {Injectable} from '@angular/core';
import {ApiService} from "../../shared/services/api-service/api.service";
import {ImportService} from "../../shared/services/import/import.service";
import {map, Observable} from "rxjs";
import {I_ApiResponse} from "../../shared/models/api-response";
import {apiUrl} from "../config/apiUrl";
import {tableFilter} from "../../shared/config/constants";
import {ITableFilterFieldNewValue, ITableFilterNew} from "../../shared/interfaces/i-table-filter-new.interface";
import {HelperService} from "../../shared/services/helper/helper.service";
import {storageKey} from "../../../../config/storage";
import {LocalStorageService} from "../../shared/services/local-storage/local-storage.service";
import {EnvService} from "../../../../env.service";
import { HabilitationsService } from '../../shared/services/habilitations/habilitations.service';
import { abilities } from 'src/app/config/abilities';
import {Line} from "../../shared/models/table/body/row/line";
@Injectable({
  providedIn: 'root'
})
export class StockService {
  baseUrl: string =this.env.apiStockService;
  paymentUrl: string = this.env.apiPaymentService;
  constructor(
    private apiService:ApiService,
    private helperService: HelperService,
    private localStorageService: LocalStorageService,
    private env: EnvService,
    private habilitationsService: HabilitationsService,
  ) { }

  importStock(company_uuid: string, shop_uuid: string, data: string): Observable<any>{
    let url = `${this.baseUrl}/${apiUrl.importStock}/company/${company_uuid}/shop/${shop_uuid}`;
    return this.apiService.doPost(url, { file: data })
      .pipe(
        map( (response: I_ApiResponse)=> response )
      );
  }

  getCsvModel(): Observable<I_ApiResponse> {
    let url = `${this.baseUrl}/model/stock`;
    return this.apiService.doGet(url);
  }

  getSatistics(shop_uuid: string) {
    let url = `${this.baseUrl}/${apiUrl.statisticsStock}/shop/${shop_uuid}`
    return this.apiService.doGet(url);
  }

  addStockFilterValue(
    category: ITableFilterFieldNewValue[] = [],
    status: ITableFilterFieldNewValue[] = [],
    serializations: ITableFilterFieldNewValue[] = [],
    restock: ITableFilterFieldNewValue[] = []
  ) {
    let stockFilter: ITableFilterNew = {
      id: 'stock-list',
      title: "Liste des stocks",
      fields: [
        {
          key: tableFilter.key.keyword,
          label: "Mot clé",
          type: 'input',
          placeholder: 'Nom article'
        },
        {
          key: tableFilter.key.product_category,
          label: 'Catégorie',
          type: "select",
          value: category
        },
        {
          key: tableFilter.key.product_serialization,
          label: "Sérialisé",
          type: "select",
          value:serializations
        },
        {
          key: tableFilter.key.product_stock,
          label: "Statut",
          type: "select",
          value: status
        },
        {
          key: 'restock',
          label: "En réassort",
          type: "select",
          value: restock
        },
      ]
    };
    return stockFilter;
  }

  getProductCategories(company_uuid: string) {
    let url = `${this.env.apiCatalogService}/categories/company/${company_uuid}`;
    return this.apiService.doGet(url);
  }

  getAllStock(company_uuid: string, shop_uuid?: string, _params: Object = {}) {
    let params: Object = {}
    if (_params['category'] && _params['category'] != 'all' && _params['category'] != '') params['category_uuid'] = _params['category'];
    if (_params['search'] && _params['search'] != '') params['product'] = _params['search'];
    if (_params['p']  && _params['p'] != 0) params['p'] = _params['p'];
    if (_params['serialization'] == 'is-serializable') {
      params['is_serializable'] = 1
    } else if (_params['serialization'] == 'no-serializable') {
      params['is_serializable'] = 0
    }
    if (_params['stock'] == 'in-stock') {
      params['in_stock'] = 1
    } else if (_params['stock'] == 'out-stock') {
      params['in_stock'] = 0
    }
    if (shop_uuid) params['shop_uuid'] = shop_uuid;
    if (_params['shop'] && _params['shop'] != 'all') params['shop_uuid'] = _params['shop'];
    if (_params['restock'] && _params['restock'] != 'all') params['is_in_need_of_restocking'] = +_params['restock'];
    let url = `${this.env.apiStockService}/stocks/company/${company_uuid}`;
    return this.apiService.doGet(url, params);
  }

  getProductSerializationInStock(company_uuid: string, product_uuid: string, shop_uuid: string) {
    let params: Object = { };

    if (shop_uuid != '' && shop_uuid != 'all') params['shop_uuid'] = shop_uuid;

    let url = `${this.env.apiStockService}/stocks/company/${company_uuid}/product/${product_uuid}`;
    return this.apiService.doGet(url, params);
  }

  getMovementDirection(): Observable<I_ApiResponse> {
    let url = `${this.env.apiStockService}/stock/movement-direction`;
    return this.apiService.doGet(url,);
  }

  getMovementSource(): Observable<I_ApiResponse> {
    let url = `${this.env.apiStockService}/stock/movement-source`;
    return this.apiService.doGet(url,);
  }

  getStockMovemets(company_uuid: string, _params: Object = {}, shop_uuid?: string) {
    let params: Object = {}
    params['company_uuid'] = company_uuid;
    if (_params['p'] != 0) params['p'] = _params['p'];
    if (_params['search'] != '') params['q'] = _params['search'];
    if (_params['shop'] != 'all' && _params['shop'] != '') params['shop_uuid'] = _params['shop'];
    if (_params['direction'] != 'all' && _params['direction'] != '') params['direction'] = _params['direction'];
    if (_params['source'] != 'all' && _params['source'] != '') params['source'] = _params['source'];
    if (shop_uuid) params['shop_uuid'] = shop_uuid;

    let url = `${this.env.apiStockService}/movement/search`;
    return this.apiService.doGet(url, params);
  }

  getShop(company_uuid: string, search?: string): Observable<I_ApiResponse> {
    let params = {};
    if (search && search != '') params['search'] = search;
    const url = `${this.env.apiShopService}/company/${company_uuid}/get`;
    return this.apiService.doGet(url, params);
  }

  getType(search?: string):Observable<I_ApiResponse> {
    let params = {};
    if (search && search != '') params['search'] = search;
    const url = `${this.paymentUrl}/payment-method-provider-types`;
    return this.apiService.doGet(url, params);
  }

  shopFilter(values: any[]): ITableFilterFieldNewValue[] {
    const userData = JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
    if (this.habilitationsService.getKeyHabilitations(abilities.stock.element.allStock)) {
      return [
        { key: 'all', label: 'Tous', value: 'all', default: true },
        ...values.map(shop => {
          return {
           key: shop.shop_code.toLowerCase(),
           label: shop.name,
           value: shop.shop_uuid
          }
        })
      ];
    } else {
      const shop = userData.shop
      return [
        {
          key: shop.shop_code,
          label: shop.name,
          value: shop.shop_uuid
        }
      ];
    }
  }

  exportStock() {
    let url = `${this.env.apiStockService}/export`;
    return this.apiService.doGet(url);
  }

  getTableStock(stock: any, is_group: boolean = true): Line {
    let serialNumber = ''
    if (is_group) {
      serialNumber = stock["is_serializable"] && !stock["is_product_group"] && stock['serialization_key'] != '' ? stock["serialization_key"] + ' : ' + (
        stock["serial_number_start"] == stock["serial_number_end"] ? stock["serial_number_start"] : stock["serial_number_start"] + ' - ' + stock["serial_number_end"]
      ) : '';
    } else {
      serialNumber = stock["is_serializable"] && stock['serialization_key'] != '' ? stock["serialization_key"] + ' : ' + (
        stock["serial_number_start"] == stock["serial_number_end"] ? stock["serial_number_start"] : stock["serial_number_start"] + ' - ' + stock["serial_number_end"]
      ) : '';
    }
    return {
      lineId: stock['uuid_product'],
      column:
      [
        {
          content: [
            {
              type: "simple",
              key: "label",
              value: stock['label'],
              expandable: !is_group ? false : stock['quantity'] > 0 && stock["is_serializable"],
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "category",
              value: stock['category'],
              expandable: false,
              tooltip: {
                hasTooltip: true,
                text: stock['category'],
                flow: 'top'
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "serialNumber",
              value: serialNumber,
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "code",
              value: stock['code'],
              expandable: false,
              tooltip: {
                hasTooltip: true,
                text: stock['code'],
                flow: 'top'
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "badge",
              key: "status",
              value: stock['quantity'] > 0 ? "En stock" : 'En rupture',
              badge: {
                bg: stock['quantity'] > 0 ? "bg-success" : 'bg-danger'
              },
              tooltip: {
                hasTooltip: false,
                /*text: stock['code'],
                flow: 'top'*/
              }
            }
          ],
          style: {
            align: 'align-center',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "icon",
              key: "is_in_need_of_restocking",
              icon: is_group ? (stock['is_in_need_of_restocking'] ? 'icn-alert' : 'icn-check') : '',
              tooltip: { hasTooltip: false }
            }
          ],
          style: {
            align: 'align-center',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "restocking_threshold",
              expandable: false,
              value: is_group ? this.helperService.formatNumber(stock['restocking_threshold']) : '',
              tooltip: { hasTooltip: false }
            }
          ],
          style: {
            align: 'align-right',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "quantity",
              expandable: false,
              value: this.helperService.formatNumber(stock['quantity']),
              tooltip: { hasTooltip: false }
            }
          ],
          style: {
            align: 'align-right',
            flex: 'inline'
          }
        },
      ]
    };
  }

  getTableMovment(movment: any): Line {
    let serialization = '';
    if (movment['serializations'].length > 0) {
      serialization = `${movment['serializations'][0]['serialization_type_key']} : ${movment['serializations'][0]['serial_number_start']} - ${movment['serializations'][0]['serial_number_end']}`
    }
    let dateTime = this.helperService.convertDateTime(new Date(movment['create_date_utc']));
    return {
      lineId: movment['uuid_stock_movement'],
      column: [
        {
          content: [
            {
              type: "simple",
              key: "shop",
              value: movment['shop']['name'],
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "code",
              value: movment['products']['code'],
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "label",
              value: movment['products']['label'],
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "sn",
              value: serialization,
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "date",
              value: dateTime,
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "badge",
              key: "sens",
              value: movment['type_movement']['movement_direction']['movement_direction_label'],
              badge: {
                bg: movment['type_movement']['movement_direction']['movement_direction_key'] == 'IN' ? 'bg-success' : 'bg-danger'
              },
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-center',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "source",
              value: movment['type_movement']['description'],
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "user",
              value: movment['user']['first_name'] + ' ' + movment['user']['last_name'],
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-left',
            flex: 'inline'
          }
        },
        {
          content: [
            {
              type: "simple",
              key: "quantity",
              value: movment['quantity'],
              expandable: false,
              tooltip: {
                hasTooltip: false
              }
            }
          ],
          style: {
            align: 'align-right',
            flex: 'inline'
          }
        },
      ]
    }
  }
}
