import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss']
})
export class ToDoComponent implements OnInit {
  @Input() title:string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
