import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Observable, of, Subscription} from "rxjs";
import {LocalStorageService} from "../../../../services/local-storage/local-storage.service";
import {storageKey} from "../../../../../../../config/storage";
import {debounceTime, filter, switchMap} from "rxjs/operators";
import {ItemSerializationCartService} from "../../../../services/item-serialization-cart/item-serialization-cart.service";
import {I_ApiResponse} from "../../../../models/api-response";
import {responseStatus} from "../../../../config/constants";
import {MiscService} from "../../../../services/miscellaniscious/misc.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-per-unit-cart',
  templateUrl: './per-unit-cart.component.html',
  styleUrls: ['./per-unit-cart.component.scss']
})
export class PerUnitCartComponent implements OnInit, OnDestroy {
  @Input() id!: any;
  @Input() cartUuid!: string;
  @Input() serializationData!: any;
  @Input() productUuid: string = '';
  @Input() shopUuid!: string;
  @Output() public serializationInputValue: EventEmitter<any> = new EventEmitter();
  public serializationFormGroup: FormGroup;
  private userData!: any;
  public serialNumberPerUnit: Observable<any>;
  public serializationIsValid: boolean = false;
  public serializationIsNew: boolean = false;
  private readonly subscription = new Subscription();

  constructor(
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private itemSerializationCartService: ItemSerializationCartService,
    private miscService: MiscService,
    private translateService: TranslateService
  ) {
    this.getUserDetail()
  }

  ngOnInit(): void {
    this.createForm();
    this.getSerializationsByValue();
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.serializationFormGroup = this.formBuilder.group({
      id: this.id,
      productUuid: this.productUuid,
      product_serialization_key: [''],
      serial_number_start: [''],
      serial_number_end: [''],
      is_serialization_valid: [false],
      quantity: [1],
      trigger: false
    })
  }

  getUserDetail() {
    this.userData = JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
  }

  initializeForm() {
    this.serializationFormGroup.patchValue({
      product_serialization_key: this.serializationData?.serial_number_start != '' ? this.serializationData?.product_serialization_key : '',
      serial_number_start: this.serializationData?.serial_number_start || '',
      serial_number_end: this.serializationData?.serial_number_end || '',
      is_serialization_valid: this.serializationData?.is_serialization_valid || false
      // is_serialization_valid: this.serializationData?.is_serialization_valid || ''
    });
    this.serializationFormGroup.updateValueAndValidity();
  }

  triggerChange(trigger: boolean) {
    this.serializationFormGroup.patchValue({trigger: trigger});
    this.serializationFormGroup.updateValueAndValidity();
  }

  getSerializationsByValue() {
    this.subscription.add(
      this.serializationFormGroup.valueChanges.pipe(
        debounceTime(500),
        filter(value => {
          return value['productUuid'] == this.productUuid
        }),
        filter(value => {
          return value['serial_number_start'].length > 3
        }),
        filter(value => value['trigger']),
        switchMap(value => {
          return this.itemSerializationCartService.getSerialNumberByProduct(this.shopUuid, value['productUuid'], value['serial_number_start'])
        })
      ).subscribe((response: I_ApiResponse) => {
        this.triggerChange(false)
        this.serialNumberPerUnit = of(response.data);
      })
    )
  }

  selectValue(event: any) {
    this.triggerChange(false)
    const value = event.option.value;
    this.serializationFormGroup.patchValue(value);
    let newValue: any = {
      product_serialization_key: value.product_serialization_key,
      serial_number_start: value.serial_number_start,
      serial_number_end: value.serial_number_end,
      id: this.id
    };
    let previousValue: any = null;
    if (
      this.serializationData.product_serialization_key != '' &&
      this.serializationData.serial_number_start != '' &&
      this.serializationData.serial_number_end != ''
    ) {
      previousValue = {
        product_serialization_key: this.serializationData.product_serialization_key,
        serial_number_start: this.serializationData.serial_number_start,
        serial_number_end: this.serializationData.serial_number_end,
        id: this.id
      }
    }
    this.updateSerialiNumberAvailable(newValue, previousValue);
  }

  updateSerialiNumberAvailable(currentValue: any, previousValue: any) {
    this.subscription.add(
      this.itemSerializationCartService.updateSerialiNumberAvailable(
        this.cartUuid,
        this.shopUuid,
        this.productUuid,
        currentValue,
        previousValue
      ).subscribe((response: I_ApiResponse) => this.updateSerialiNumberAvailableResponse(response))
    )
  }

  updateSerialiNumberAvailableResponse(response: I_ApiResponse, isClear: boolean = false) {
    switch (response.status) {
      case responseStatus.success:
        this.serializationIsValid = true;
        this.serializationIsNew = true;
        const product = response.data.cart.items.filter(x => x.uuid_product == this.productUuid)[0];
        this.serializationInputValue.emit(product);
        this.serializationData = product.serialization.serializations[this.id].serializationData.filter(x => x.product_serialization_key == this.serializationFormGroup.value['product_serialization_key'])[0]
        this.initializeForm();
        this.itemSerializationCartService.setProducts(response.data.cart.items);
        if (isClear) {
          this.serialNumberPerUnit = of([]);
          this.serializationIsValid =false;
        }
        break;
      case responseStatus.error:
        this.showNotification('danger', 'alert', this.translateService.instant('notification.error.title'), response.errors[0].message);
        break;
    }
  }

  clearSerialNumber() {
    this.triggerChange(false);
    this.serialNumberPerUnit = of([]);
    const previousValue = {
      product_serialization_key: this.serializationData.product_serialization_key,
      serial_number_start: this.serializationData.serial_number_start,
      serial_number_end: this.serializationData.serial_number_end,
      id: this.id
    };
    this.itemSerializationCartService.updateSerialiNumberAvailable(
      this.cartUuid,
      this.shopUuid,
      this.productUuid,
      null,
      previousValue
    ).subscribe((response: I_ApiResponse) => this.updateSerialiNumberAvailableResponse(response, true))
  }

  showNotification(type, icon, title, description) {
    this.miscService.showToast({
      type: type,
      icon: icon,
      title: title,
      desc: description
    });
  }
}
