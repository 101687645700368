import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Title} from "../../../models/stepper/title";

@Component({
  selector: 'app-step-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() title: Title
  @Input() length: number = 1;
  @Output() next: EventEmitter<number>;
  @Input() counter: number = 1;
  constructor() { }

  ngOnInit(): void {
  }

  nextStep() {
    if (this.title.id < this.length) {
      this.counter ++;
      this.next.emit(this.counter);
    }
  }
}
