import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChangeFilterValue, ITableFilterNew, ITableFilterNewField } from "../../../interfaces/i-table-filter-new.interface";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { tableFilter } from '../../../config/constants';
import { TableFilterService } from "../../../services/table-filter/table-filter.service";
import { debounceTime, of, Subscription } from "rxjs";
import { filter, switchMap } from "rxjs/operators";

@Component({
  selector: 'app-table-filter-new',
  templateUrl: './table-filter-new.component.html',
  styleUrls: ['./table-filter-new.component.scss']
})
export class TableFilterNewComponent implements OnInit, OnDestroy {
  @Input() public id: string = '';
  @Input() public filterParams: ITableFilterNew;
  @Input() public size: string;
  public chips: string[] = [];
  public tableFilter = tableFilter
  public filterFormGroup: FormGroup;
  public selectValue: any = [];
  private subscription = new Subscription();
  allChipsAreAllCache: boolean | undefined;

  public stockFilter: ITableFilterNew = { id: 'table-filter-new', title: '', fields: [] };

  constructor(
    private formBuilder: FormBuilder,
    private tableFilterService: TableFilterService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.addFilter();
    this.sendFilterValue();
    this.getChangeFilterValue();
    this.getFilterColumn();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.filterFormGroup = this.formBuilder.group({
      field_list: this.formBuilder.array([]),
      triggerValueChange: [false],
      id: ''
    });
  }

  fieldGroup(): FormArray {
    return this.filterFormGroup.get('field_list') as FormArray;
  }

  addFilter() {
    this.subscription.add(
      this.tableFilterService.filterNew$.pipe(
        filter(value => {
          if (value?.id && value?.id == this.id) return true;
          else if (value?.id && value?.id != this.id) return true;
          return true
        })
      ).subscribe(filter => {
        this.chips = []
        this.filterFormGroup.reset();
        this.fieldGroup().reset()
        this.fieldGroup().clear();
        this.selectValue = [];
        this.filterParams = filter
        if (filter) this.add(filter)
      })
    )
  }

  sendFilterValue() {
    this.subscription.add(
      this.filterFormGroup.valueChanges.pipe(
        filter(data => {
          if (data.triggerValueChange) {
            return !(this.id != '' && data.id != this.id);
          }
          return data.triggerValueChange
        }),
        debounceTime(1000),
        switchMap(data => {
          this.filterFormGroup.patchValue({ triggerValueChange: false });
          let newData = { id: this.id };
          this.chips = [];
          data.field_list.forEach(value => {
            let search;
            if (value.type === this.tableFilter.tableFilterFieldType.swicth && value.form_field_value.toString() !== null) {
              search = value.form_field_value;
            } else if (value.type === this.tableFilter.tableFilterFieldType.range) {
              const dateStart = value.form_field_start ? new Date(value.form_field_start) : '';
              const dateEnd = value.form_field_end ? new Date(value.form_field_end) : '';
              search = (dateStart != '' && dateEnd != '') ? `${this.formatDate(dateStart)} ${this.formatDate(dateEnd)}` : ''
            } else {
              search = value.form_field_value === null ? '' : value.form_field_value;
            }
            if (search != '') this.chips.push(search)
            newData[value.key] = search
          });
          return of(newData);
        })
      ).subscribe(res => {
        this.tableFilterService.setFormValue(res)
      })
    )
  }

  private add(filter: ITableFilterNew) {
    this.filterFormGroup.patchValue({ id: filter?.id ?? '' });
    filter.fields.forEach((fields: ITableFilterNewField) => {
      let defaultValue = fields?.value?.filter(x => x.default)[0]
      let childFilterFormGroup = this.formBuilder.group({
        key: [fields.key],
        label: [fields.label],
        type: [fields.type],
        placeholder: [fields.placeholder ? fields.placeholder : ''],
      });
      if (fields.type === 'range') {
        childFilterFormGroup.addControl('form_field_start', new FormControl(null));
        childFilterFormGroup.addControl('form_field_end', new FormControl(null));
      } else {
        childFilterFormGroup.addControl('form_field_value', new FormControl(defaultValue?.default ? defaultValue.value : ''));
        if (defaultValue?.default) this.chips.push(defaultValue.value as string);
      }
      this.selectValue.push(fields.value ? fields.value : [])
      this.fieldGroup().push(childFilterFormGroup);
    })
  }

  private formatDate(date: Date): string {
    const dateValues = date.toLocaleDateString().split('/');
    return `${dateValues[2]}-${dateValues[1]}-${dateValues[0]}`;
  }

  removeFilter(key: string, value: string, index: number) {
    this.triggerValueChanges();
    this.chips = this.chips.filter(e => e != value)
    this.fieldGroup().at(index).patchValue({ form_field_value: '' });
  }

  removePeriodFilter(value: string, index: number) {
    this.triggerValueChanges();
    const dateString = this.formatDate(new Date(value));
    this.chips = this.chips.filter(e => !e.includes(dateString))
    this.fieldGroup().at(index).patchValue({
      form_field_start: '',
      form_field_end: '',
    });
  }

  resetFilter() {
    this.chips = [];
    for (let i = 0; i < this.fieldGroup().length; i++) {
      const value = this.fieldGroup().at(i).value;
      if (value['type'] === 'range') {
        this.fieldGroup().at(i).patchValue({
          'form_field_start': null,
          'form_field_end': null
        })
      } else if (value['type'] === 'select') {
        this.fieldGroup().at(i).patchValue({
          'form_field_value': 'all',
        })
      } else {
        this.fieldGroup().at(i).patchValue({
          'form_field_value': '',
        })
      }
    }
    this.triggerValueChanges();
  }

  getValueOfSelect(key: string, value: string, index: number) {
    let res = this.selectValue[index].filter(source => {
      return source.value === value;
    })
    return res[0].label
  }

  triggerValueChanges(): void {
    this.filterFormGroup.patchValue({ triggerValueChange: true });
    this.filterFormGroup.updateValueAndValidity();
  }

  getChangeFilterValue(): void {
    this.subscription.add(
      this.tableFilterService.getChangeFilterValue().subscribe((value: ChangeFilterValue) => {
        const fieldGroupValue = this.fieldGroup().value;
        const index = fieldGroupValue.findIndex(x => x['key'] == value.key);
        this.filterFormGroup.patchValue({ triggerValueChange: true });
        this.fieldGroup().at(index).get('form_field_value').setValue(value.value);
        this.fieldGroup().updateValueAndValidity();
      })
    )
  }

  getFilterColumn() {
    this.subscription.add(
      this.tableFilterService.getFilterColumn().pipe(
        filter(column => column['id'] === this.id)
      ).subscribe(column => {
        let childFilterFormGroup = this.formBuilder.group({
          key: column['filter'].key,
          label: column['filter'].label,
          type: column['filter'].type,
          placeholder: column['filter'].placeholder ? column['filter'].placeholder : ''
        });
        const defaultValue = column['filter'].value.filter(val => +val.default)[0];
        childFilterFormGroup.addControl('form_field_value', new FormControl(defaultValue ? defaultValue.value : ''));
        if (defaultValue?.default) this.chips.push(defaultValue.value as string);
        this.selectValue.push(column['filter'].value ? column['filter'].value : []);
        this.fieldGroup().push(childFilterFormGroup);
      })
    );
  }
  allChipsAreAll(): boolean {
    this.allChipsAreAllCache = this.chips.every(chip => chip === 'all');
    return this.allChipsAreAllCache;
  }
}
