<div class="wrapper p-sm block-batch" [formGroup]="serializationFormGroup">
  <div class="block-article-lot">
    <div class="serialization-key">
    </div>
    <div class="article-lot">
      <div class="article-lot__item">
        <div class="article-lot__container">
          <h5 class="article-lot__title">
            Debut ({{serialization?.fk_serialization_type_key}}):
          </h5>
          <div class="article-lot__wrapper">
            <div type="text">
              <input
                type="text"
                formControlName="serial_number_start"
                (input)="triggerEvent()"
                [ngClass]="{'input-error': showStartError}"
                class="pos-input"
              >
            </div>
            <div class="article-lot__icon">
              <div class="article-lot__icon">
                <app-check-icon ><i class="icon" [ngClass]="serializationInvalid ? 'icn-alert': 'icn-check'"></i></app-check-icon>
              </div>
          </div>
          <div class="error" *ngIf="showStartError">
            Numéro de série non valide
          </div>
        </div>
      </div>
      <div class="article-lot__item">
        <div class="article-lot__container">
          <h5 class="article-lot__title">
            Fin ({{serialization?.fk_serialization_type_key}}):
          </h5>
          <div class="article-lot__wrapper">
            <div type="text">
              <input
                type="text"
                formControlName="serial_number_end"
                (input)="triggerEvent()"
                [ngClass]="{'input-error': showEndError}"
                class="pos-input"
              >
            </div>
            <div class="article-lot__icon">
              <i class="icon" [ngClass]="serializationInvalid ? 'icn-alert': 'icn-check'"></i>
            </div>
          </div>
          <div class="error" *ngIf="showEndError">
            Numéro de série non valide
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
