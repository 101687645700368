<div class="section-login">
  <div class="block-login">
    <div class="item-login">
      <div class="block-login-left" style="background-image: url('assets/img/woman_check.png')">
        <div class="block-presentation fade-in">
          <div class="sub-block-presentation">
            <div class="block-logo">
              <figure>
                <img src="assets/img/logo_pos_lg.svg" alt="logo">
              </figure>
            </div>
            <div class="block-titre tracking-in-contract">{{'user.authentication.title'|translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-login">
      <div class="block-login-right">
        <div class="sub-block-login-right fade-in">
          <div class="title-login-page">{{'user.authentication.welcome'|translate}}</div>
          <app-authentication-form [companyList]="companyList"></app-authentication-form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="notification">
  <app-toast-notification *ngFor="let toast of toastDataList" [icon]="toast.icon" [type]="toast.type"
    [title]="toast.title" [desc]="toast.desc"></app-toast-notification>
</div>
