import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  ISerialization,
  ItemSerializationService
} from "../../../services/item-serialization/item-serialization.service";
import {filter, switchMap} from "rxjs/operators";
import {IItemSerialization} from "../../../models/i-item-serialization/i-item-serialization";
import {IButton} from "../../../models/i-button";
import {cancel, save} from "../../../config/constants";
import {LocalStorageService} from "../../../services/local-storage/local-storage.service";
import {DialogService} from "../../../services/dialog/dialog.service";

@Component({
  selector: 'app-item-serialization',
  templateUrl: './item-serialization.component.html',
  styleUrls: ['./item-serialization.component.scss']
})
export class ItemSerializationComponent implements OnInit, OnDestroy {
  @Input() id: string = '';
  @Input() selectProduct: boolean = false;
  @Input() autoCompleteField: boolean = false;
  private subscitpion = new Subscription();
  public itemSerialization: IItemSerialization = null;
  public serializationValue: any = [];
  public perBatch: IButton = {
    buttonText: 'Par lot',
    buttonIcon: [],
    variant: '',
    state: '',
  };
  public perUnit: IButton = {
    buttonText: 'Par unité',
    buttonIcon: [],
    variant: '',
    state: '',
  };
  public batchInputs: any[] = [];
  public isValid: boolean = true;

  constructor(
    private localStorageService: LocalStorageService,
    private itemSerializationService: ItemSerializationService
  ) { }

  ngOnInit(): void {
    this.getProduct();
    this.setButtonAction();
    this.saveSerialization();
  }

  ngOnDestroy() {
    this.subscitpion.unsubscribe();
  }

  getProduct() {
    this.subscitpion.add(
      this.itemSerializationService.getSerialization().pipe(
        switchMap(status => {
          if (status) return this.itemSerializationService.getProduct();
          else return [];
        })
      ).subscribe((itemSerialization => {
        this.batchInputs = [];
        this.itemSerialization = itemSerialization;
        this.lastSerialization = itemSerialization;
        this.serializationValue = itemSerialization?.serializationValue;
        this.itemSerializationService.setSerializationValue(this.serializationValue);
        if (this.serializationValue.length == 0) this.itemSerializationService.setAllSerializationInput(null);
        if (itemSerialization.product.is_batcheable) this.addDefaultInput(itemSerialization);
      }))
    );
  }

  getSerializationInputList(itemSerialization: IItemSerialization) {
    let inputs: any[] = [];
    for (let i = 0; i < itemSerialization?.quantity; i++) {
      inputs.push(itemSerialization?.serialization);
    }
    return inputs;
  }

  getSerializationValue(event: any) {
    const quantity = event['quantity']
    const serializationData = {
      fk_serialization_type_key: event['fk_serialization_type_key'],
      product_serialization_key: event['product_serialization_key'],
      quantity: quantity,
      serial_number_start: event['serial_number_start'],
      serial_number_end: event['serial_number_end'],
      is_serialization_valid: event['is_serialization_valid']
    }
    const value = {
      id: event['id'],
      totalItems: quantity,
      serializationData: [ serializationData ]
    }
    const serializationLength = this.itemSerialization.serialization.length ;

    if (value) {
      const ids = this.itemSerialization.serializationValue.map(((value1, index) => index))
      let isValid: boolean = false;
      if (!ids.includes(value['id'])) {
        this.itemSerialization.serializationValue.push(value);
        this.itemSerialization.serializationValue[value['id']]['serializationData'].map(_serializationData => {
          if (_serializationData.product_serialization_key == serializationData['product_serialization_key']) {
            isValid = serializationData['is_serialization_valid'];
            return _serializationData;
          }
        })
        const serializationDataLength = this.itemSerialization.serializationValue[value['id']]['serializationData'].length;
        if (serializationLength == serializationDataLength) this.itemSerialization.inputQuantity = this.itemSerialization.serializationValue.map(value => value.totalItems).reduce((a, b) => a + b, 0);
      } else {
        const keys = this.itemSerialization.serializationValue[value['id']]['serializationData'].map(x => x.product_serialization_key);
        const type_keys = this.itemSerialization.serializationValue[value['id']]['serializationData'].map(data => data['fk_serialization_type_key']).filter(key => key === serializationData['fk_serialization_type_key']);
        const serializationDataByKey = this.itemSerialization.serializationValue[value['id']]['serializationData'].filter(data => data['fk_serialization_type_key']  === serializationData['fk_serialization_type_key'])
        const serial_numbers_start = serializationDataByKey.map(data => data['serial_number_start'])
        const serial_numbers_end = serializationDataByKey.map(data => data['serial_number_end']);
        let defaultSerializationValidation: boolean = false;
        if (keys.includes(serializationData['product_serialization_key'])) {
          if (
            type_keys.length > 0 &&
            (
              serial_numbers_start.includes(serializationData['serial_number_start']) ||
              serial_numbers_end.includes(serializationData['serial_number_end'])
            ) &&
            !this.itemSerialization.product.is_batcheable
          ) {
            serializationData['is_serialization_valid'] = false
          }
          this.itemSerialization.serializationValue[value['id']]['serializationData'].map(_serializationData => {
            if (_serializationData.product_serialization_key == serializationData['product_serialization_key']) {
              isValid = serializationData['is_serialization_valid'];
              _serializationData.is_serialization_valid = isValid;
              _serializationData.serial_number_start = serializationData['serial_number_start'];
              _serializationData.serial_number_end = serializationData['serial_number_end'];
              return _serializationData;
            }
          });
          this.itemSerialization.serializationValue[value['id']]['totalItems'] = quantity
        } else {
          if (
            type_keys.length > 0 &&
            (
              serial_numbers_start.includes(serializationData['serial_number_start']) ||
              serial_numbers_end.includes(serializationData['serial_number_end'])
            ) &&
            !this.itemSerialization.product.is_batcheable
          ) {
            serializationData['is_serialization_valid'] = false
          }
          isValid = serializationData['is_serialization_valid'];
          this.itemSerialization.serializationValue[value['id']]['serializationData'].push(serializationData);
        }
      }
      const serializationDataLength = this.itemSerialization.serializationValue[value['id']]['serializationData'].length;
      if (serializationLength == serializationDataLength) {
        this.itemSerialization.inputQuantity = this.itemSerialization.serializationValue.map(value => value.totalItems).reduce((a, b) => a + b, 0);
      }
      this.isValid = this.itemSerialization['isValid'] = isValid;
      if (this.itemSerialization.inputQuantity > this.itemSerialization?.quantity) {
        this.isValid = this.itemSerialization['isValid'] = false;
      }
      this.setSerializationInputQuantity(isValid, event['id'], event['product_serialization_key']);
      this.itemSerializationService.setAllSerializationInput({
        id: event['id'],
        product_serialization_key: event['product_serialization_key'],
        serial_number_start: event['serial_number_start'],
        serial_number_end: event['serial_number_end'],
      });
    }
  }

  setSerializationInputQuantity(isValid: boolean, id: number, key) {
    this.itemSerializationService.setSerializationInputQuantity(
      {
        id: id,
        productUuid: this.itemSerialization.productUuid,
        isValid: isValid,
        quantity: this.itemSerialization?.quantity,
        inputQuantity: this.itemSerialization?.inputQuantity,
        product_serialization_key: key
      }
    )
  }

  private lastSerialization: any = null
  saveSerialization() {
    this.itemSerializationService.getSaveSerializationStatus().subscribe(status => {
      if (status == save) {
        this.itemSerializationService.setProduct(this.itemSerialization);
      } else if(status == cancel) {
        /*this.itemSerialization = this.lastSerialization = itemSerialization;
        this.serializationValue = itemSerialization?.serializationValue;
        this.itemSerializationService.setSerializationValue(this.serializationValue);
        if (this.serializationValue.length == 0) this.itemSerializationService.setAllSerializationInput(null);
        if (itemSerialization.product.is_batcheable) this.addDefaultInput(itemSerialization);*/
      }
    })
  }

  setButtonAction() {
    this.perBatch.action = this.addBacthInput;
    this.perUnit.action = this.addUnitInput;
  }

  addBacthInput = () => {
    this.isValid = false;
    const input = {
      isBatch: true,
      serialization: this.itemSerialization.serialization[0]
    }
    this.batchInputs.push(input)
  }

  addUnitInput = () => {
    this.isValid = false;
    const input = {
      isActive: true,
      isBatch: false,
      serialization: this.itemSerialization.serialization[0]
    }
    this.batchInputs.push(input)
  }

  addDefaultInput(itemSerialization:IItemSerialization) {
    itemSerialization?.serializationValue.forEach(serialization => {
      if (serialization.totalItems > 1) {
        this.addBacthInput();
      } else if (serialization.totalItems == 1) {
        this.addUnitInput();
      }
    })
  }

  removeInput(i: number) {
    this.batchInputs = this.batchInputs.filter((_batch, id) => id != i);
    this.isValid = true;
    this.itemSerialization.serializationValue = this.itemSerialization.serializationValue.filter((_value, id) => id != i);
    this.itemSerialization.inputQuantity = this.itemSerialization.serializationValue.map(value => value.totalItems).reduce((a, b) => a + b, 0);
    this.itemSerializationService.setSerializationValue(this.itemSerialization.serializationValue);
    const serializations: ISerialization[] = this.itemSerialization.serializationValue.map((x, i) => {
      const value = x['serializationData'][0];
      return {
        id: i,
        product_uuid: this.itemSerialization.productUuid,
        product_serialization_key: value['product_serialization_key'],
        serial_number_start: value['serial_number_start'],
        serial_number_end: value['serial_number_end']
      }
    })
    this.itemSerializationService.setInputSerializations(null, null, this.itemSerialization.productUuid, serializations);
  }

  getTotal(serializationData) {
    if (!serializationData || (serializationData.serial_number_start == '' || serializationData.serial_number_end == '')) {
      return 0
    } else {
      return ((+serializationData.serial_number_end) - (+serializationData.serial_number_start)) + 1;
    }
  }
}
