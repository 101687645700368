import { LocalStorageService } from 'src/app/main/modules/shared/services/local-storage/local-storage.service';
import { I_SidebarMenu } from './../../models/sidebar-menu';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { I_LeftMenu } from '../../models/left-menu';
import { storageKey } from 'src/app/config/storage';
import {Url} from "../../models/url/url";

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  menu$: BehaviorSubject<I_SidebarMenu|null> =new BehaviorSubject<I_SidebarMenu|null>(null);
  leftMenu$: BehaviorSubject<I_LeftMenu | null> = new BehaviorSubject<I_LeftMenu | null>(null);
  sidebarMenu$: BehaviorSubject<I_SidebarMenu[] | null> = new BehaviorSubject<I_SidebarMenu[] | null>(null);
  activateRoute$: BehaviorSubject<Url> = new BehaviorSubject<Url>(null)
  authorizedUrls$: BehaviorSubject<Url[]> = new BehaviorSubject<Url[]>([])

  // ------------------------------------------------------- Custom
  activeClass$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private localStorageService: LocalStorageService) {}

  updateLeftMenu(menu: I_LeftMenu) {
    this.leftMenu$.next(menu);
  }

  updateSidebarMenu(menu: I_SidebarMenu[]) {
    this.sidebarMenu$.next(menu);
  }

  updateAuthorizedUrls(urls: Url[]) {
    this.authorizedUrls$.next(urls);
  }


  setSelectedMenuIndex( selectedMenuIndex: number[] ){

    this.localStorageService.setLocalStorage(storageKey.selectedMenuIndex, JSON.stringify(selectedMenuIndex));
  }

  getSelectedMenuIndex(){
    return JSON.parse(this.localStorageService.getLocalStorage(storageKey.selectedMenuIndex));
  }

  updateActivateRoute(url: Url) {
    this.activateRoute$.next(url);
  }
}
