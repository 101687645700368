import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageList} from "../../models/language-list";

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {
  constructor(private translate: TranslateService) { }

  getlanguages() {
    return LanguageList;
  }

  switchLanguage(language: string) {
    localStorage.setItem('language', language)
    this.translate.use(language);
  }
}
