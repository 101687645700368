import { FormArray, FormControl } from '@angular/forms';
import { ICheckboxTreeItem } from '../../../models/checkbox-tree/checkbox-tree.model';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { filter, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, Renderer2, ElementRef } from '@angular/core';
import { MiscService } from '../../../services/miscellaniscious/misc.service';

@Component({
  selector: 'app-checkbox-tree',
  templateUrl: './checkbox-tree.component.html',
  styleUrls: ['./checkbox-tree.component.scss']
})
export class CheckboxTreeComponent implements OnInit, OnDestroy {

  @Input() uniqueId ='';
  @Input() checkAllLabel ='';
  @Input() idCheck: string;
  // @Input() sourceData: ICheckboxTreeItem[] =[];
  sourceData: ICheckboxTreeItem[] =[];

  source
  isOpen: boolean = true;

  formGroup: FormGroup;
  formGroupData: ICheckboxTreeItem[];
  subscription: Subscription =new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private miscService: MiscService,
    private renderer : Renderer2,
    private el: ElementRef
    ) {
    this.formGroup =this.formBuilder.group({
      checkAll: [false],
      items: this.formBuilder.array([]),
      triggerValueChange: [false]
    });
  }

  formGroupItems(): FormArray{
    return this.formGroup.get('items') as FormArray;
  }

  formGroupItemForm( index: number): FormArray{
    return this.formGroupItems().at(index).get('subItems') as FormArray;
  }

  ngOnInit(): void {

    this.subscription.add(
      this.miscService.checkBoxTreeItems$
      .pipe(
        filter(x=>x?.uniqueId ==this.uniqueId)
      )
      .subscribe(data=>{
        this.sourceData=data.checkboxTreeItems;
        this.buildForm();
      })
    );

    this.subscription.add(
      this.formGroup.valueChanges
        .pipe(
          filter(x=>x.triggerValueChange)
        )
        .subscribe(data=>{
          // traitement
          this.miscService.updateCheckBoxTreeData(this.uniqueId, data.items);
          // reset triggerValueChange
          this.formGroup.patchValue({triggerValueChange: false});

        })
    )

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClickCollapse(e:boolean): void {
    this.isOpen = !e;
  }

  onClickCollapseItem(id): void {
    if (this.el.nativeElement.querySelector(id).classList.contains('open')) {
      this.renderer.removeClass(this.el.nativeElement.querySelector(id), 'open')
    } else {
      this.renderer.addClass(this.el.nativeElement.querySelector(id), 'open')
    }
  }

  // TODO: Gérer les items sur plusieurs niveau
  buildForm(): void{
    this.formGroup.reset();
    this.formGroupItems().reset();
    this.formGroupItems().clear();

    this.sourceData.forEach(d=>{
      let form: FormGroup =this.formBuilder.group({
        label: [d.label],
        uuid: [d.uuid],
        isChecked: [d.isChecked],
        subItems: this.formBuilder.array([])
      });

      if(d.items && d.items.length>0){
        if (!d.uuid && !d.label) form.get('label').setValue('Rataché à aucun réseau')
        d.items.forEach(e=>{
          let formItems:FormArray =form.get('subItems') as FormArray;
          formItems.push(
            this.formBuilder.group({
              label: [e.label],
              uuid: [e.uuid],
              isChecked: [e.isChecked],
            })
          );
        })
      }

      this.formGroupItems().push(form);
    });
  }

  triggerValueChanges(): void{
    this.formGroup.patchValue({triggerValueChange: true});
    this.formGroup.updateValueAndValidity();
  }

  chekAllSubItems(index){
    let data =this.formGroupItems().at(index).value;
    let inputIsChecked: boolean =data.isChecked;//this.formGroup.value.items[index].subItems
    this.formGroup.value.items[index].subItems.forEach((d, i)=>{
      this.formGroupItemForm(index).at(i).patchValue({isChecked: inputIsChecked});
    });
    if (!inputIsChecked) this.formGroup.patchValue({checkAll: false})
    this.formGroup.patchValue({triggerValueChange: true});
    this.formGroup.updateValueAndValidity();
  }

  chekSubItem(itemIndex, subItemIndex){
    let data =this.formGroupItemForm(itemIndex).at(subItemIndex).value;
    if( !data.isChecked ){
      this.formGroupItems().at(itemIndex).patchValue({isChecked: false});
      this.formGroup.patchValue({checkAll: false})
    }
    this.formGroup.patchValue({triggerValueChange: true});
    this.formGroup.updateValueAndValidity();
  }

  checkAll(){
    let checkAllIsChecked =this.formGroup.value.checkAll;
    this.formGroup.value.items.forEach((d, i) => {
      this.formGroupItems().at(i).patchValue({isChecked: checkAllIsChecked});
      d.subItems.forEach((e, j)=>{
        this.formGroupItemForm(i).at(j).patchValue({isChecked: checkAllIsChecked});
      });
    });
    this.formGroup.patchValue({triggerValueChange: true});
    this.formGroup.updateValueAndValidity();
  }

}
