export const storageKey = {
  session: {
    sessionKey: 'session_key',
    jwtKey: 'jwt',
  },

  user:{
    userData: "user_data",
    userUuid: "user_uuid"
  },

  ability: {
    url: 'url'
  },
  cashRegister: {
    opened: 'cashRegisterOpened'
  },
  selectedMenuIndex: 'selectedMenuIndex',
  isVaultOpen: 'isVaultOpen',
};
