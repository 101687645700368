<form [formGroup]="formGroup">
  <div class="block-on-off" [attr.data-tooltip]="tooltip.hasTooltip ? tooltip.text : null" [attr.data-flow]="tooltip.hasTooltip ? tooltip.flow : null">
    <div class="block-switch lg-switch" [ngClass]="{ 'disabled': isDisabled }"> <!-- lg-switch ou sm-switch -->
      <label class="switch switch-primary">
        <input type="checkbox" class="switch-input" [name]="'value'" [formControlName]="'value'" (change)="triggerValue()" [readOnly]="">
        <span class="switch-label" data-on="Actif" data-off="Inactif"></span>
        <span class="switch-handle"></span>
      </label>
    </div>
  </div>
</form>
