<div class="block-list card-chip-article" [ngClass]="{'rockerState': selected, 'card-disabled': !product}" >
  <div class="item-list">
    <img class="no-image" src='../../../../../../../../assets/img/no-img.svg'>
  </div>
  <div class="item-list">
    <div class="block-intern-item-list">
      <div class="block-intern-top-item-list">
        <span class="semibold lc-2 title-intern">{{ product.label }}</span>
      </div>
      <div class="block-intern-bottom-item-list">
        <div class="block-flex-intern lc-2 title-intern">
          {{ product.code }}
        </div>
        <div class="block-flex-intern price-intern" *ngIf="showPrice && product.ttc_price">
          <span class="bold">{{ product.ttc_price }}</span> XOF
        </div>

      </div>
    </div>
  </div>
</div>
