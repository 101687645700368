<div class="">
  <div class="modal-article-content__item">
    <div class="container-header">
      <div class="container-header__item">
        <h3 class="container-header__title">
          {{ product?.label}}
        </h3>
      </div>
      <div class="container-header__item">
        <div class="container-header__content">
          <div class="container-header__stat">
            {{ 'serialization-item-selling.serial-field.serial-number-information' | translate }} :
            <span class="container-header__stat&#45;&#45;number">{{product?.serialization?.quantity_selected }}
              / {{ product?.quantity }}</span>
          </div>
          <div class="container-header__stat">
            {{ 'serialization-item-selling.serial-field.serial-number-remaining' | translate }} :
            <span
              class="container-header__stat&#45;&#45;number">{{ product?.serialization?.quantity_remaining }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-content"><mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>
      <div class="article-content custom-view"><mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>
        <ng-container *ngIf="!product?.serialization?.is_batcheable else batcheable">
          <app-per-unit-cart
            *ngFor="let serialization of product?.serialization.serializations; let i = index;"
            [serializationData]="serialization.serializationData[0]"
            [productUuid]="product.uuid_product"
            [cartUuid]="cartUuid"
            [id]="i"
            [shopUuid]="shopUuid"
            (serializationInputValue)="getProductInput($event)"
          >
          </app-per-unit-cart>
        </ng-container>
        <ng-template #batcheable>
          <ng-container *ngFor="let input of inputs; let i = index">
            <div class="article-content">
              <div class="block-inline-flx">
                <div class="item-inline-flx">
                  <div class="block-int-item-inline-flx">
                    <app-per-batch-cart
                      *ngIf="input['isBatch'] else unit"
                      [serializationData]="input.serializationData[0]"
                      [productUuid]="product.uuid_product"
                      [cartUuid]="cartUuid"
                      [quantityNeeded]="product['serialization'].quantity_needed"
                      [quantityRemaining]="product['serialization'].quantity_remaining"
                      [id]="i"
                      [shopUuid]="shopUuid"
                      (serializationInputValue)="getProductInput($event)"
                    >
                    </app-per-batch-cart>
                    <ng-template #unit>
                      <app-per-unit-cart
                        [serializationData]="input.serializationData[0]"
                        [productUuid]="product.uuid_product"
                        [cartUuid]="cartUuid"
                        [id]="i"
                        [shopUuid]="shopUuid"
                        (serializationInputValue)="getProductInput($event)"
                      >
                      </app-per-unit-cart>
                    </ng-template>
                  </div>
                </div>
                <div class="item-inline-flx">
                  <div class="article-lot__item">
                    <div class="article-lot__trash" data-tooltip="Supprimer" data-flow="left" (click)="remove(i)">
                      <i class="icn-delete"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="article-total inline-flx-right">
                <p class="article-total__text">Total:
                  <span class="article-total__stat">
                    {{ input.totaItems }}
                  </span>
                </p>
              </div>
            </div>

          </ng-container>
          <div class="wrapper" *ngIf="product?.serialization.quantity_remaining > 0 && (inputs.length == 0 || inputs[inputs.length - 1].serializationData[0].is_serialization_valid)">
            <div class="batchable">
              <div
                class="batchable__item"
                *ngIf="product?.serialization.quantity_remaining > 1 "
              >
                <app-button [config]="perBatch"></app-button>
              </div>
              <div class="batchable__item">
                <app-button [config]="perUnit"></app-button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
