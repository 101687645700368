<div class="block-table-filtre" [formGroup]="filterFormGroup">
  <h3 class="title-table-filtre">{{ filterParams.title }}</h3>
  <div class="block-filtre">
    <div class="block-content-filtre" formArrayName="field_list">
      <div class="item-content-filtre" *ngFor="let field of fieldGroup().controls; let i = index;">
          <div class="block-flex" [formGroupName]="i">
            <label>{{ field.value.label }} :</label>
            <input type="text" name="form_field_value" [placeholder]="field.value.placeholder" formControlName="form_field_value" *ngIf="field.value.type === tableFilter.tableFilterFieldType.input">

            <ng-container *ngIf="field.value.type === tableFilter.tableFilterFieldType.select">
              <div class="box-select">
                <ng-select
                  [items]="selectSourceData[field.value.key]"
                  [bindLabel]="field.value.source_value_key"
                  [bindValue]="field.value.source_key"
                  formControlName="form_field_value"
                >
                </ng-select>
                <span class="arrow"></span>
              </div>
            </ng-container>
          </div>
      </div>
      <!--<div class="item-content-filtre">
        <div class="block-flex">
          <label for="_profil">Profil :</label>
          <select name="_profil">
            <option value="Adm"> Administrateur </option>
          </select>
        </div>
      </div>
      <div class="item-content-filtre">
        <div class="block-flex">
          <label for="shop">SHOP DE RATTACHEMENT :</label>
          <input type="text" name="shop" placeholder="Saisissez un shop de rattachement">
        </div>
      </div>-->
    </div>
  </div>
  <div class="result-filtre" *ngIf="chips.length > 0">
    <div class="result-filtre-titre">Critères d'affichage :</div>
    <div class="block-word">
      <div class="item-word" *ngFor="let chip of this.fieldGroup().value; let i = index;" >
        <div class="item-content-word" *ngIf="chip.form_field_value">
          <span *ngIf="chip.type === tableFilter.tableFilterFieldType.input">{{ chip.form_field_value }}</span>
          <ng-container *ngIf="chip.type === tableFilter.tableFilterFieldType.select">
            <span>{{ getValueOfSelect(chip.key, chip.form_field_value, chip.source_key, chip.source_value_key) }}</span>
          </ng-container>
          <span class="icon-close" (click)="removeFilter(chip.key, chip.form_field_value, i)">+</span>
        </div>
      </div>
<!--      <div class="item-word">
        <div class="item-content-word">
          <span>User</span><span class="icon-close">+</span>
        </div>
      </div>-->
    </div>
    <div class="block-delete-all">
      <div class="block-delete-all-content" (click)="resetFilter()">
        <span class="icn-refersh"></span><span>Supprimer les critères</span>
      </div>
    </div>
  </div>
</div>
