import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Observable, of, pairwise, Subscription} from "rxjs";
import {LocalStorageService} from "../../../../services/local-storage/local-storage.service";
import {ItemSerializationCartService} from "../../../../services/item-serialization-cart/item-serialization-cart.service";
import {storageKey} from "../../../../../../../config/storage";
import {I_ApiResponse} from "../../../../models/api-response";
import {debounceTime, distinct, filter, startWith, switchMap} from "rxjs/operators";
import {responseStatus} from "../../../../config/constants";
import {MiscService} from "../../../../services/miscellaniscious/misc.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-per-batch-cart',
  templateUrl: './per-batch-cart.component.html',
  styleUrls: ['./per-batch-cart.component.scss']
})
export class PerBatchCartComponent implements OnInit, OnDestroy {
  @Input() id!: any;
  @Input() cartUuid!: string;
  @Input() serializationData!: any;
  @Input() productUuid: string = '';
  @Input() quantityNeeded!: number;
  @Input() quantityRemaining!: number;
  @Input() shopUuid!: string;
  @Output() public serializationInputValue: EventEmitter<any> = new EventEmitter();
  public serializationFormGroup: FormGroup;
  private userData!: any;
  public serialNumberPerBacth: Observable<any>;
  public showStartError: boolean = false;
  public showEndError: boolean = false;
  public serializationIsValid: boolean = false;
  public serializationIsNew: boolean = false;

  private readonly subscription = new Subscription();

  constructor(
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private itemSerializationCartService: ItemSerializationCartService,
    private miscService: MiscService,
    private translateService: TranslateService
  ) {
    this.getUserDetail()
  }

  ngOnInit(): void {
    this.createForm();
    this.initializeForm();
    this.getStartValue();
    this.getEndValue();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.serializationFormGroup = this.formBuilder.group({
      id: this.id,
      productUuid: this.productUuid,
      product_serialization_key: [''],
      serial_number_start: [''],
      serial_number_end: [''],
      is_serialization_valid: [false],
      quantity: 1,
      trigger: false
    })
  }

  initializeForm() {
    const serializationQuantity = (+this.serializationData?.serial_number_end) - (+this.serializationData?.serial_number_start )
    const quantity = (this.serializationData?.serial_number_end != '' && this.serializationData?.serial_number_start != '') ? serializationQuantity : this.quantityRemaining;
    this.serializationFormGroup.patchValue({
      product_serialization_key: this.serializationData?.serial_number_start != '' ? this.serializationData?.product_serialization_key : '',
      serial_number_start: this.serializationData?.serial_number_start,
      serial_number_end: this.serializationData?.serial_number_end,
      is_serialization_valid: this.serializationData?.is_serialization_valid,
      quantity: quantity
    });
    this.showStartError = this.serializationData?.serial_number_start == '';
    this.showEndError = this.serializationData?.serial_number_end == '';
    this.serializationFormGroup.updateValueAndValidity();
  }

  getUserDetail() {
    this.userData = JSON.parse(this.localStorageService.getLocalStorage(storageKey.user.userData));
  }

  triggerChange(status: boolean = true) {
    this.serializationFormGroup.patchValue({ trigger: status });
    this.serializationFormGroup.updateValueAndValidity();
  }

  getStartValue() {
    this.subscription.add(
      this.serializationFormGroup.get('serial_number_start').valueChanges.pipe(
        debounceTime(500),
        filter(value => {
          return value.length > 3
        }),
        filter(value => this.serializationFormGroup.get('trigger').value),
        distinct(),
        switchMap(value => {
          const product = this.productUuid;
          const quantity = this.quantityRemaining == this.quantityNeeded ? this.serializationData.quantity : this.quantityRemaining
          return this.itemSerializationCartService.getSerialNumberByProduct(this.shopUuid, product, value, quantity)
        })
      ).subscribe((response: I_ApiResponse) => {
        this.triggerChange(false);
        this.serialNumberPerBacth = of(response.data);
      })
    )
  }

  getEndValue() {
    this.subscription.add(
      this.serializationFormGroup.get('serial_number_end').valueChanges.pipe(
        debounceTime(500),
        filter(value => {
          return value.length > 3
        }),
        filter(value => this.serializationFormGroup.get('trigger').value),
        distinct(),
        switchMap(value => {
          const product = this.productUuid;
          const key = this.serializationFormGroup.get('product_serialization_key').value;
          const start = this.serializationFormGroup.get('serial_number_start').value;
          const end = this.serializationFormGroup.get('serial_number_end').value;
          const quantity = this.serializationFormGroup.get('quantity').value;
          const param = {
            search: value,
            quantity: quantity
          }
          return this.itemSerializationCartService.getEndSerialNumber(this.shopUuid, this.cartUuid,product, key, start, param);
        })
      ).subscribe((response: I_ApiResponse) => {
        this.triggerChange(false);
        this.serialNumberPerBacth = of(response.data);
      })
    )
  }

  selectValue(event: any) {
    this.triggerChange(false);
    const value = event.option.value;
    this.serializationFormGroup.patchValue(value);
    let newValue: any = {
      product_serialization_key: value.product_serialization_key,
      serial_number_start: value.serial_number_start,
      serial_number_end: value.serial_number_end,
      id: this.id
    }
    let previousValue: any = null;
    if (
      this.serializationData.product_serialization_key != '' &&
      this.serializationData.serial_number_start != '' &&
      this.serializationData.serial_number_end != ''
    ) {
      previousValue = {
        product_serialization_key: this.serializationData.product_serialization_key,
        serial_number_start: this.serializationData.serial_number_start,
        serial_number_end: this.serializationData.serial_number_end,
        id: this.id
      }
    }
    this.updateSerialiNumberAvailable(newValue, previousValue);
  }

  updateSerialiNumberAvailable(currentValue: any, previousValue: any) {
    this.subscription.add(
      this.itemSerializationCartService.updateSerialiNumberAvailable(
        this.cartUuid,
        this.shopUuid,
        this.productUuid,
        currentValue,
        previousValue
      ).subscribe((response: I_ApiResponse) => this.updateSerialiNumberAvailableResponse(response))
    )
  }

  updateSerialiNumberAvailableResponse(response: I_ApiResponse, isClear: boolean = false) {
    switch (response.status) {
      case responseStatus.success:
        this.serializationIsValid = true;
        this.serializationIsNew = true;
        const product = response.data.cart.items.filter(x => x.uuid_product == this.productUuid)[0];
        this.serializationInputValue.emit(product);
        this.serializationData = product.serialization.serializations[this.id].serializationData.filter(x => x.product_serialization_key == this.serializationFormGroup.value['product_serialization_key'])[0]
        this.initializeForm();
        this.itemSerializationCartService.setProducts(response.data.cart.items);
        if (isClear) {
          this.serialNumberPerBacth = of([]);
          this.serializationIsValid =false;
        }
        break;
      case responseStatus.error:
        this.showNotification('danger', 'alert', this.translateService.instant('notification.error.title'), response.errors[0].message);
        break;
    }
  }

  clearStartValue() {
    this.serializationFormGroup.patchValue({
      serial_number_start: '',
      is_serialization_valid: false
    });
    this.showStartError = true;
    this.serialNumberPerBacth = of([]);
    this.serializationIsValid =false;
  }

  clearEndValue() {
    const previous = {
      product_serialization_key: this.serializationFormGroup.value.product_serialization_key,
      serial_number_start: this.serializationFormGroup.value.serial_number_start,
      serial_number_end: this.serializationFormGroup.value.serial_number_end,
      id: this.id
    }
    this.subscription.add(
      this.itemSerializationCartService.updateSerialiNumberAvailable(
        this.cartUuid,
        this.shopUuid,
        this.productUuid,
        null,
        previous
      ).subscribe((response: I_ApiResponse) => {
        this.serializationFormGroup.patchValue({
          serial_number_end: '',
          is_serialization_valid: false
        });
        this.showEndError = true;
        this.serialNumberPerBacth = of([]);
        this.serializationIsValid =false;
      })
    )
  }

  showNotification(type, icon, title, description) {
    this.miscService.showToast({
      type: type,
      icon: icon,
      title: title,
      desc: description
    });
  }
}
