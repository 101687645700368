import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableService} from "../../../services/table/table.service";
import {of, Subscription} from "rxjs";
import { ITableWithoutEdit } from '../../../interfaces/tables/without-edit/i-table-without-edit';
import { ICellWithoutEdit } from '../../../interfaces/tables/without-edit/i-cell-without-edit';
import {filter} from "rxjs/operators";
import { TranslatePipe } from '@ngx-translate/core';
import { StockService } from 'src/app/main/modules/stock/services/stock.service';
import { I_ApiResponse } from '../../../models/api-response';

@Component({
  selector: 'app-table-without-edit',
  templateUrl: './table-without-edit.component.html',
  styleUrls: ['./table-without-edit.component.scss'],
})
export class TableWithoutEditComponent implements OnInit, OnDestroy {
  @Input() public id: string = '';
  @Input() public hasActionButton: boolean = false;
  @Input() public iconId: string = '';
  private subscription = new Subscription();
  public tables: ITableWithoutEdit = null;
  public expanded: string = '';
  public expandedValue: ICellWithoutEdit = null;

  constructor(
    private tableService: TableService,
    private stockService: StockService,
  ) { }

  ngOnInit(): void {
    this.getTableValue();
    this.getExpandedValue();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTableValue() {
    this.subscription.add(
      this.tableService.tableWithoutEditValue$.pipe(
        filter(value => {
          if(value?.id && value?.id == this.id) return true;
          else if (value?.id && value?.id != this.id) return false;
          return true;
        })
      ).subscribe(value => {
        if (value) {
          this.tables = value;
        }
      })
    );
  }

  expand(id: string) {
    if (this.expanded != id)  {
      this.expanded = id;
      this.tableService.setExpand(this.expanded);
    } else {
      this.expanded = '';
    }
  }

  getExpandedValue() {
    this.subscription.add(
      this.tableService.tableExpandedValue$.subscribe(value => {
        this.expandedValue = null;
        if (value) {
          this.expandedValue = value;
        }
      })
    );
  }

  onClickDeleteButton(uuid: string): void {
    this.tables.body.cellValue = this.tables.body.cellValue.filter(item => item.id !== uuid);
    this.tableService.removeAction$.next(uuid);
  }

  editLine(id: string) {
    //this.tableService.setLineEdited(id);
  }

  getTypeOf(value: any) {
    return typeof value;
  }

  isNumber(value): boolean {
    return !isNaN(value);
  }

  exportStock() {
    this.subscription.add(
      this.stockService.exportStock().subscribe((response: I_ApiResponse) => {
        const url = response.data.data.document
        window.open(url, '_blank');
      })
    );
  }
}
