import { tableFilter } from '../../../config/constants';
import { ITableFilter, ITableFilterField } from '../../../interfaces/table-filter';
import { Component, Input, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {debounceTime, forkJoin, map} from "rxjs";
import {ApiService} from "../../../services/api-service/api.service";
import {TableFilterService} from "../../../services/table-filter/table-filter.service";

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit {
  @Input() public filterParams: ITableFilter;
  public chips: string[]=[];
  public tableFilter = tableFilter
  public filterFormGroup: FormGroup;
  private selectSource: any;
  public selectSourceData: any = {};

  public stockFilter: ITableFilter = {id: 'table-filter', title: '', fields: []};


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private tableFilterService: TableFilterService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.addFilter()

    this.filterFormGroup.get('field_list').valueChanges.pipe(
      debounceTime(tableFilter.debounceTime),
      map((data: any) => {
        let newData = {};
        this.chips = []
        data.forEach(value => {
          let search = value.form_field_value === null ? '': value.form_field_value;
          if (search != '') this.chips.push(search);
          newData[value.key] = search;
        })
        return newData;
      })
    ).subscribe(res => {
      this.tableFilterService.setFormValue(res);
    })
  }

  createForm() {
    this.filterFormGroup = this.formBuilder.group({
      field_list: this.formBuilder.array([])
    });
  }

  addFilter() {
    this.filterParams?.fields.forEach((filterField: ITableFilterField)=>{
      this.selectSource = {};
      if( filterField.type == tableFilter.tableFilterFieldType.select){
        let childFilterFormGroup = this.formBuilder.group({
          key: [filterField.key],
          label: [filterField.label],
          type: [filterField.type],
          source_form_field_id: [filterField.source.form_field_source_id],
          source_key: [filterField.source.source_key],
          source_url: [filterField.source.source_url],
          source_value_key: [filterField.source.source_value_key],
          form_field_value: ['']
        });
        this.fieldGroup().push(childFilterFormGroup);
        this.selectSource[filterField.key] = this.apiService
          .doGet(filterField.source.source_url)
          .pipe(map( (response)=> response.data.roles ));

        this.fetchSelectSourceData();
      } else {
        let childFilterFormGroup = this.formBuilder.group({
          key: [filterField.key],
          label: [filterField.label],
          placeholder: [filterField.placeholder ? filterField.placeholder : ''],
          type: [filterField.type],
          form_field_value: ['']
        });
        this.fieldGroup().push(childFilterFormGroup);
      }
    });
  }

  fieldGroup(): FormArray {
    return this.filterFormGroup.get('field_list') as FormArray;
  }

  fetchSelectSourceData() {
    forkJoin(this.selectSource).subscribe((data: any) => {
      this.selectSourceData = data;
    });
  }

  loadFilterSource(){

  }

  removeFilter(key: string, value: string, index: number){
    this.chips = this.chips.filter( e => e != value )
    this.fieldGroup().at(index).patchValue({form_field_value: ''});
  }

  resetFilter(){
    this.chips = [];
    this.fieldGroup().clear();
    this.addFilter();
  }

  getValueOfSelect(key: string, value: string, source_key: string, source_value_key: string) {
    let res = this.selectSourceData[key].filter( source => source[source_key] === value )
    return res[0][source_value_key]
  }
}
