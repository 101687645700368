import { Subscription } from 'rxjs';
import { LoaderService } from '../../../../shared/services/loader/loader.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/main/modules/shared/services/api-service/api.service';
import { itemInArrayValidator } from 'src/app/main/modules/shared/validators/item-in-array/item-in-array.validator';
import { apiUrl } from 'src/app/config/api';
import { config } from 'src/app/config/config';
import { constant } from 'src/app/config/constants';
import { responseStatus } from 'src/app/main/modules/shared/config/constants';
import { MiscService } from 'src/app/main/modules/shared/services/miscellaniscious/misc.service';
import {EnvService} from "../../../../../../env.service";
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-authentication-form',
  templateUrl: './authentication-form.component.html',
  styleUrls: ['./authentication-form.component.scss']
})
export class AuthenticationFormComponent implements OnInit {
  public loading$ = this.loaderService.loading$;
  private subscription: Subscription = new Subscription();
  formulaire: FormGroup;
  @Input()
  companyList:any[] =[];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private miscService: MiscService,
    private loaderService: LoaderService,
    private env: EnvService,
    private translateService: TranslateService
  ) {
    this.formulaire =this.fb.group({
      'company': ['', [itemInArrayValidator(this.companyList, 'company_key')]] //
    });
  }

  ngOnInit(): void {

  }

  connect(){
    let data =this.formulaire.value;
    if (data.company == '') {
      this.miscService.showToast({
        icon: 'alert',
        type: 'danger',
        title: this.translateService.instant('notification.error.title'),
        desc: "Veuillez renseigner la compagnie!"
      });
      return;
    }

    let companyKey =data.company.company_key;

    let url =`${this.env.apiAuthService}/${apiUrl.authApi.login}/${companyKey}/${config.appName}`;
    // let url =`${this.env.apiAuthService}/${apiUrl.authApi.login}/pulse/${config.appName}`;
    this.loaderService.show();
    this.apiService.doGet(url, {}, {
      'X-Key': constant.authentication.xKey,
      'Frame-Target': constant.authentication.fragmentTarget,
      "ngrok-skip-browser-warning": "any value"
    }).subscribe((response)=>{
      this.loaderService.hide();
      switch( response.status ){
        case responseStatus.success:
          window.location.href =response.data.redirection_url;
          break;
        case responseStatus.error:
          this.miscService.showToast({
            icon: 'alert',
            type: 'danger',
            title: this.translateService.instant('notification.error.title'),
            desc: "Une erreur est survenue sur le serveur lors de l'authentification"
          });
      }
    },
    (error)=>{
      this.miscService.showToast({
        icon: 'alert',
        type: 'danger',
        title: this.translateService.instant('notification.error.title'),
        desc: "Une erreur est survenue sur le serveur lors de l'authentification"
      });
    })
  }

}
