import { I_FieldData } from './../../../models/field-data';
import { MiscService } from 'src/app/main/modules/shared/services/miscellaniscious/misc.service';
import { ApiService } from 'src/app/main/modules/shared/services/api-service/api.service';
import { I_FormContractField, I_FormContractFieldRegex } from './../../../models/formContract.model';
import { forkJoin, map } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { I_FormContract } from '../../../models/formContract.model';
import { Subscription, debounceTime } from 'rxjs';
import { contractFormFieldType } from '../../../config/constants';
import { GenericFormService } from '../../../services/generic-form/generic-form.service';
import { config } from 'src/app/config/config';
import { I_ContractCompliantFormData } from '../../../models/contractCompliantFormData.model';
import { HelperService } from '../../../services/helper/helper.service';

@Component({
  selector: 'app-contract-compliant-form',
  templateUrl: './contract-compliant-form.component.html',
  styleUrls: ['./contract-compliant-form.component.scss'],
})
export class ContractCompliantFormComponent implements OnInit {
  displayError: boolean =false;
  fieldTypText: string = contractFormFieldType.TEXT;
  fieldTypSelect: string = contractFormFieldType.SELECT;
  fieldTypDateTime: string = contractFormFieldType.DATETIME;
  fieldTypDate: string = contractFormFieldType.DATE;
  fieldTypPhone: string = contractFormFieldType.PHONE;
  fieldTypTextArea: string = contractFormFieldType.TEXT_AREA;
  fieldTypNumber: string = contractFormFieldType.NUMBER;

  formContract: I_FormContract;

  @Input()
  cible:string;

  @Input()
  formTitle: string;

  @Input()
  formClass: string;

  @Input()
  uniqueId: string;

  subscription: Subscription = new Subscription();

  formulaire: FormGroup;

  selectSource: any;
  selectSourceData: any = {};

  regexRulesMap: string[][] =[];

  constructor(
    private formBuilder: FormBuilder,
    private genericFormService: GenericFormService,
    private apiService: ApiService,
    public helperService: HelperService,
    private miscService: MiscService,
  ) {
    this.formulaire = this.formBuilder.group({
      contract_uuid: [''],
      field_group: this.formBuilder.array([]),
    });
  }

  fieldGroup(): FormArray {
    return this.formulaire.get('field_group') as FormArray;
  }

  fieldList(index: number): FormArray {
    return this.fieldGroup().at(index).get('field_list') as FormArray;
  }

  ngOnInit() {
    this.subscription.add(
      this.genericFormService.displayError$.subscribe( (data: any)=>{
        if (data?.uniqueId == this.uniqueId) {
          this.displayError =data.displayError;
        }
      } )
    );

    this.subscription.add(
      this.genericFormService.resetContractCompliantForm$.subscribe( (data: any)=>{
        if (data?.uniqueId == this.uniqueId) {
          this.genericFormService.hideFormError(this.uniqueId);
          this.fieldGroup().controls.forEach((d, i) => {
            this.fieldList(i).controls.forEach( (e)  =>{
              e.patchValue({form_field_value: ''});
            })
          });
        }
      } )
    );

    this.subscription.add(
      this.genericFormService.genericFormSchema$.subscribe((data: any) => {
        if (data?.uniqueId == this.uniqueId) {
          this.formContract = data.formSchema;
          this.applyFormContract();
        }
      })
    );

    this.subscription.add(
      this.formulaire.valueChanges
        .pipe(
          debounceTime(config.formValueChangeDebounceTime),
          map((data: any) => {
            let fields: any = {};
            data.field_group.forEach((d: any) => {
              d.field_list.forEach((e) => {
                fields[e.form_field_key] = e.form_field_value;
              });
            });
            return {
              form_contract_uuid: data.contract_uuid,
              fields: fields,
              categories: [],
              is_valid :this.formulaire.valid
            } as I_ContractCompliantFormData;
          })
        )
        .subscribe((data: I_ContractCompliantFormData) => {
          this.genericFormService.updateContractCompliantFormData(
            data,
            this.uniqueId
          );
        })
    );

    this.subscription.add(
      this.miscService.fieldData$.subscribe( (data:I_FieldData)=>{
        if( data!=null && data?.uniqueId == this.formContract?.contract_uuid ){
          let indexes =data.fieldName.split('_');
          this.fieldList(+indexes[0]).at(+indexes[1]).patchValue({ form_field_value:data.fieldValue });
          this.formulaire.updateValueAndValidity();
        }
      })
    );
  }

  fetchSelectSourceData() {
    forkJoin(this.selectSource).subscribe((data: any) => {
      this.selectSourceData = data;
    });
  }

  applyFormContract() {
    this.formulaire.patchValue({
      contract_uuid: this.formContract?.contract_uuid,
    });

    this.fieldGroup().clear();

    // regrouper les champs par leur group_key
    let fields = this.formContract?.fields?.reduce((acc, obj) => {
      const key = obj['form_field_group_key'];
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(obj);
      return acc;
    }, {});

    this.selectSource = {};

    // créer un form group avec un field_list
    Object.keys(fields)?.forEach((key) => {
      let fieldGroup = this.formBuilder.group({
        field_group_name: [key],
        field_list: this.formBuilder.array([]),
      });

      let group = fieldGroup.get('field_list') as FormArray;
      fields[key].forEach((d: I_FormContractField) => {
        let regexRules: string[] =[];
        if (+d.is_active === 1) {
          let inputFormControl: FormControl = new FormControl('');

          let item = this.formBuilder.group({
            label: [d.form_field_label],
            type: [d.form_field_type.form_field_type_key],
            form_field_key: [d.form_field_key],
            source_url: [d.form_field_source?.source_url],
            source_key: [d.form_field_source?.source_key],
            source_value_key: [d.form_field_source?.source_value_key],
            is_mandatory: [+d.is_mandatory],

          });

          if (+d.is_mandatory) {
            inputFormControl.addValidators(Validators.required);
          }

          if( d.form_field_regexes!=null ){
            d.form_field_regexes.forEach( (e: I_FormContractFieldRegex)=>{
              regexRules.push(e.form_field_regex.form_field_regex_value);
              inputFormControl.addValidators(Validators.pattern(e.form_field_regex.form_field_regex_value));
            } );
          }

          item.addControl('form_field_value', inputFormControl);

          if (
            d.form_field_type.form_field_type_key == this.fieldTypSelect &&
            d.form_field_source.source_url != null
          ) {
            this.selectSource[d.form_field_key] = this.apiService
              .doGet(d.form_field_source.source_url)
              // TODO Modifier static service api pour ne plus devoir passer par .country ou .city => Le formulaire doit être générique
              .pipe(map((x) => x.data?.country || x.data?.city||[]));
          }

          group.push(item);
          this.regexRulesMap.push(regexRules);
        }
      });
      this.fieldGroup().push(fieldGroup);
    });

    // récupération des données
    this.fetchSelectSourceData();
  }
}
