<nav class="nav-menu">
  <ul class="nav-menu__content bg-white">
    <li class="nav-menu__item"
      [ngClass]="{'active': menu_item.active, 'disabled': disabled}"
      *ngFor="let menu_item of menu?.menu_item"
    >
      <a (click)="goTo(menu_item.url)">
        <span>{{menu_item.label}}</span>
        <span *ngIf="menu_item?.actif || menu_item?.total">({{ menu_item?.actif }}/{{ menu_item?.total }})</span>
      </a>
    </li>
  </ul>
</nav>
