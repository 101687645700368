<form class="action__list-item" [formGroup]="importFormGroup">
  <label for="file">
    <figure>
      <img src="{{importConfig.icon}}" alt="icone"/>
      
    </figure>
    <!--    <input type="file" id="file" class="import" (change)="sendNotification()"/>-->
    <!-- <input type="file" id="file" class="import" formControlName="file"  encoding="UTF8"/> -->
    <input type="file" id="file" class="import" (change)="onFilechange($event)" (click)="resetForm($event)" [accept]="importConfig.accept"/>
    {{importConfig.label}}</label>
</form>


