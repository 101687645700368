<div class="block-list-select-deselect">
  <div class="block-sub-list-select-deselect">
    <div class="item-sub-list-select-deselect">
      <div class="block-int-first">
        <div class="block-list-popin-top">
          <app-table-filter-new [filterParams]="itemFilter" [id]="'item-selection'" [size]="'column-filter'" >
          </app-table-filter-new>
        </div>
        <div class="block-list-popin-bottom">
          <div class="block-scroll-list" >
            <app-item-card
              *ngFor="let product of products; let i = index"
              [product]="product"
              [showPrice]="showPrice"
              [selected]="isSelectedProduct(product.uuid_product)"
              (click)="selectProduct(product)"
            >
            </app-item-card>
          </div>
        </div>
      </div>
    </div>
    <div class="item-sub-list-select-deselect">
      <div class="block-int-middle">
        <button class="btn btn-add" (click)="addProductSelected()">
          AJOUTER <em _ngcontent-gxr-c81="" class="arrow right"></em>
        </button>
        <button class="btn btn-unadd" (click)="removeProductSelected()">
          <em _ngcontent-gxr-c81="" class="arrow right"></em>RETIRER
        </button>
      </div>
    </div>
    <div class="item-sub-list-select-deselect">
      <div class="block-int-last">
        <h3 class="title-selected"><span>{{ addedProduct.length }} </span>{{ title }}</h3>
        <div class="block-scroll-list">
          <app-item-card
            *ngFor="let product of addedProduct; let i = index"
            [product]="product"
            [showPrice]="showPrice"
            [selected]="isRemovedProduct(product.uuid_product)"
            (click)="selectProductToRemove(product)"
          >
          </app-item-card>
        </div>
      </div>
    </div>
  </div>
</div>
