import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ITableFilterNew} from "../../../interfaces/i-table-filter-new.interface";
import {save, tableFilter} from "../../../config/constants";
import {Subscription} from "rxjs";
import {ItemSelectionService} from "../../../services/item-selection/item-selection.service";
import {Product} from "../../../../product/models/product/product.model";
import {TableFilterService} from "../../../services/table-filter/table-filter.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {debounceTime, distinctUntilChanged, filter, switchMap} from "rxjs/operators";

@Component({
  selector: 'app-item-selection',
  templateUrl: './item-selection.component.html',
  styleUrls: ['./item-selection.component.scss']
})
export class ItemSelectionComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() title: string = '';
  @Input() showPrice: boolean = true;
  public itemFilter: ITableFilterNew = {
    id: 'item-selection',
    title: '',
    fields: [{
      label: 'MOT CLÉ',
      placeholder:'Nom de l\'article',
      type: 'input',
      key: tableFilter.key.keyword
    }]
  };
  private subscription = new Subscription();
  public products: Product[] = [];
  private selectedProduct: Product[] = [];
  public addedProduct: Product[] = [];
  private productToRemove: Product[] = [];

  constructor(
    private itemSelectionService: ItemSelectionService,
    private tableFilterService: TableFilterService
  ) { }

  ngOnInit(): void {
    this.resetProduct();
    this.getAllProducts();
    this.getDialogIsOpened();
    this.productToRemove = [];
    this.getRemoveAll();

    this.itemSelectionService.getProductIsAdded().subscribe(status => {
      if (status) this.itemSelectionService.setAddedProducts(this.addedProduct);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  resetProduct() {
    this.selectedProduct = [];
    this.addedProduct = [];
    this.productToRemove = [];
  }

  getAllProducts() {
    this.subscription.add(
      this.itemSelectionService.getAllProducts().subscribe(products => {
        this.products = products;
      })
    )
  }

  selectProduct(product: Product) {
    const selectedIds = this.selectedProduct.map((x: Product)=>x.uuid_product);
    const addedIds = this.addedProduct.map((x: Product)=>x.uuid_product);
    if (!product.is_stock && !selectedIds.includes(product.uuid_product)) {
      this.selectedProduct = [...this.selectedProduct, product]
    } else if (!product.is_stock && selectedIds.includes(product.uuid_product) && !addedIds.includes(product.uuid_product)) {
      this.selectedProduct = this.selectedProduct.filter(x => x.uuid_product != product.uuid_product)
    }
  }

  isSelectedProduct(uuid_product: string) {
    const ids = this.selectedProduct.map((x: Product)=>x.uuid_product);
    return ids.includes(uuid_product);
  }

  selectProductToRemove(product: Product) {
    const selectedIds = this.productToRemove.map((x: Product)=>x.uuid_product);
    if (!selectedIds.includes(product.uuid_product)) {
      this.productToRemove = [...this.productToRemove, product]
    } else if (selectedIds.includes(product.uuid_product)) {
      this.productToRemove = this.productToRemove.filter(x => x.uuid_product != product.uuid_product)
    }
  }

  isRemovedProduct(uuid_product: string) {
    const ids = this.productToRemove.map((x: Product)=>x.uuid_product);
    return ids.includes(uuid_product);
  }

  addProductSelected() {
    this.addedProduct = this.selectedProduct;
  }

  removeProductSelected() {
    this.selectedProduct = this.selectedProduct.filter(selected => {
      return selected.uuid_product != this.productToRemove.map(remove => remove.uuid_product)[0]
    });
    this.productToRemove = [];
    this.addProductSelected();
  }

  getDialogIsOpened() {
    this.subscription.add(
      this.itemSelectionService.getProductIsAdded().pipe(
        debounceTime(150),
        filter(value => !value),
        switchMap(value => {
          this.tableFilterService.setFilterNew(this.itemFilter);
          return this.itemSelectionService.getProductIn()
        })
      ).subscribe((addedProducts: { id: string, product: Product[] }) => {
        if (addedProducts && addedProducts.id == this.id) {
          this.selectedProduct = addedProducts.product;
          this.addedProduct = addedProducts.product;
          this.productToRemove = [];
        }
      })
    );
  }

  getRemoveAll() {
    this.subscription.add(
      this.itemSelectionService.getRemoveAll().subscribe(value => {
        if (value && value.id == this.id && value.status) this.resetProduct();
      })
    )
  }
}
