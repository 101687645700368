<nav class="ds-nav">
  <div class="ds-nav--content">
    <img src="assets/img/logo.svg" alt="" class="logo">
    <span>Design System</span>
  </div>
</nav>
<main class="ds-main">
  <div class="ds-sidemenu">
    <ul class="ds-list-menu">
      <li class="ds-item">
        <div class="ds-item--content ds-has-children">
          <a class="link-item" href="#">Couleur</a>
          <span class="state-item"></span>
        </div>
        <ul class="ds-list-menu ds-children">
          <li class="ds-item">
            <div class="ds-item--content">
              <a class="link-item" href="#">Couleur 1</a>
              <span class="state-item"></span>
            </div>
          </li>
          <li class="ds-item">
            <div class="ds-item--content">
              <a class="link-item" href="#">Couleur 2</a>
              <span class="state-item"></span>
            </div>
          </li>
        </ul>
      </li>
      <li class="ds-item">
        <div class="ds-item--content">
          <a class="link-item" href="#">Typographie</a>
          <span class="state-item"></span>
        </div>
      </li>
      <li class="ds-item">
        <div class="ds-item--content">
          <a class="link-item" href="#">Iconographies</a>
          <span class="state-item"></span>
        </div>
      </li>
    </ul>
  </div>

  <div class="content-page-design-system">

    <!-- COLOR -->
    <div class="main main-color">
      <strong>Color</strong><br>
      <br>
      <strong>Primary Color</strong><br>

      <div class="parent w-100px">
        <div class="div1">
          <span class="color color-1">--primary</span>
        </div>
      </div>
      <br>
      <strong>Secondary Color</strong><br>

      <div class="parent w-100px">
        <div class="div2">
          <span class="color color-2">--secondary-1</span>
        </div>
        <div class="div3">
          <span class="color color-3">--secondary-2</span>
        </div>
      </div>
      <br>
      <strong>Informatif Color</strong><br>

      <div class="parent w-100px">
        <div class="div4">
          <span class="color color--state-info">--info</span>
        </div>
        <div class="div5">
          <span class="color color--state-success">--success</span>
        </div>
        <div class="div6">
          <span class="color color--state-warning">--warning</span>
        </div>
        <div class="div7">
          <span class="color color--state-danger">--danger</span>
        </div>
      </div>

      <br>
      <strong>Custom Color</strong><br>

      <div class="parent w-100px">
        <div class="div8">
          <span class="color color--custom-catalog">--custom-catalog</span>
        </div>
        <div class="div9">
          <span class="color color--custom-customer">--custom-customer</span>
        </div>
        <div class="div10">
          <span class="color color--custom-document">--custom-document</span>
        </div>
        <div class="div11">
          <span class="color color--custom-bg-space-sale">--custom-bg-space-sale</span>
        </div>
        <div class="div9">
          <span class="color color--custom-asset">--custom-asset</span>
        </div>
        <div class="div10">
          <span class="color color--custom-ticket">--custom-ticket</span>
        </div>
        <div class="div11">
          <span class="color color--custom-invoice">--custom-invoice</span>
        </div>
      </div>


      <br>
      <strong>neutral Color</strong><br>

      <div class="parent w-100px">
        <div class="div8">
          <span class="color color--black">--black</span>
        </div>
        <div class="div9">
          <span class="color color--white">--white</span>
        </div>
      </div>
      <div class="parent w-100px">
        <div class="div10">
          <span class="color color--neutral-700">--neutral-700</span>
        </div>
        <div class="div11">
          <span class="color color--neutral-500">--neutral-500</span>
        </div>
        <div class="div12">
          <span class="color color--neutral-300">--neutral-300</span>
        </div>
        <div class="div13">
          <span class="color color--neutral-200">--neutral-200</span>
        </div>
        <div class="div14">
          <span class="color color--neutral-100">--neutral-100</span>
        </div>
      </div>


      <!-- table -->
      <div class="block-table block-table-result-input-key-word">

        <table class="table-profil-utilisateur">
          <thead>
            <tr>
              <th>
                <div class="block-th">
                  <span>Profils</span>
                  <span class="tri-table">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6.341" viewBox="0 0 12 6.341">
                      <path id="icon_arrow"
                        d="M.822,6,6.241.58A.34.34,0,0,0,5.761.1L.1,5.761a.338.338,0,0,0,0,.48L5.761,11.9A.342.342,0,0,0,6,12a.331.331,0,0,0,.239-.1.338.338,0,0,0,0-.48Z"
                        transform="translate(0 6.341) rotate(-90)" fill="var(--neutral-700)" />
                    </svg>
                  </span>
                </div>

              </th>
              <th>
                <div class="block-th">
                  <span>Nombre d'utilisateur</span>
                  <span class="tri-table">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6.341" viewBox="0 0 12 6.341">
                      <path id="icon_arrow"
                        d="M.822,6,6.241.58A.34.34,0,0,0,5.761.1L.1,5.761a.338.338,0,0,0,0,.48L5.761,11.9A.342.342,0,0,0,6,12a.331.331,0,0,0,.239-.1.338.338,0,0,0,0-.48Z"
                        transform="translate(0 6.341) rotate(-90)" fill="var(--neutral-700)" />
                    </svg>
                  </span>
                </div>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="cell-table">
                  <label class="container">Administrateur
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </td>
              <td class="type-number"><span>5</span></td>
            </tr>
            <tr>
              <td>
                <div class="cell-table">
                  <label class="container">Responsable d'agence
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </td>
              <td class="type-number"><span>5</span></td>
            </tr>
            <tr>
              <td>
                <div class="cell-table">
                  <label class="container">Administrateur des ventes
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </td>
              <td class="type-number"><span>5</span></td>
            </tr>
            <tr>
              <td>
                <div class="cell-table">
                  <label class="container">Vendeur
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </td>
              <td><span>Lomé</span></td>
            </tr>

            <tr>
              <td>
                <div class="cell-table">
                  <div class="radio-buttons">
                    <div class="form-group">
                      <input type="radio" id="shop" name="category" checked />
                      <label for="shop">Responsable d'agence 1</label>
                    </div>
                  </div>
                </div>
              </td>
              <td><span>Lomé</span></td>
            </tr>
            <tr>
              <td>
                <div class="cell-table">
                  <div class="radio-buttons">
                    <div class="form-group">
                      <input type="radio" id="autres" name="category" />
                      <label for="autres">Responsable d'agences autres</label>
                    </div>
                  </div>
                </div>
              </td>
              <td><span>Lomé</span></td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
    <div footer>
      <button class="btn btn-lite" (click)="manaoZavatra()"> Annuler</button>
      <button class="btn btn-yellow" (click)="closeAndRedirect()"> Ajouter (4)</button>
    </div>
    
  <!-- <hr> -->

      <br>
      <strong>Profile Color</strong><br>

      <div class="parent w-200px">
        <div class="div8">
          <small class="color color--profile-administrator">--profile-administrator</small>
        </div>
        <div class="div9">
          <small class="color color--profile-agency-manager">--profile-agency-manager</small>
        </div>
        <div class="div10">
          <small class="color color--profile-control-cell">--profile-control-cell</small>
        </div>
        <div class="div11">
          <small class="color color--profile-sales-administrator">--profile-sales-administrator</small>
        </div>
        <div class="div12">
          <small class="color color--profile-seller">--profile-seller</small>
        </div>
        <div class="div13">
          <small class="color color--profile-single-user">--profile-single-user</small>
        </div>
      </div>
      <div class="parent w-200px">
        <div class="div14">
          <small class="color color--profile-other-1">--profile-other-1</small>
        </div>
        <div class="div8">
          <small class="color color--profile-other-2">--profile-other-2</small>
        </div>
        <div class="div9">
          <small class="color color--profile-other-3">--profile-other-3</small>
        </div>
        <div class="div10">
          <small class="color color--profile-other-4">--profile-other-4</small>
        </div>
        <div class="div11">
          <small class="color color--profile-other-5">--profile-other-5</small>
        </div>
        <div class="div12">
          <small class="color color--profile-other-6">--profile-other-6</small>
        </div>
        <div class="div13">
          <small class="color color--profile-other-7">--profile-other-7</small>
        </div>
        <div class="div14">
          <small class="color color--profile-other-8">--profile-other-8</small>
        </div>
        <div class="div12">
          <small class="color color--profile-other-9">--profile-other-9</small>
        </div>
        <div class="div13">
          <small class="color color--profile-other-10">--profile-other-10</small>
        </div>
      </div>


    </div>

    <!-- TYPO -->
    <div class="main main-typo">
      <strong>Typography</strong><br><br>

      <table>
        <tr>
          <th><strong>Heading</strong></th>
          <th><strong>Example</strong></th>
        </tr>
        <tr>
          <td>
            <pre>&lt;h1&gt;/&lt;h1&gt;</pre>
            <pre>.title1</pre>
          </td>
          <td><span class="title1">Lorem ipsum of heading</span></td>
        </tr>
        <tr>
          <td>
            <pre>&lt;h2&gt;/&lt;h2&gt;</pre>
            <pre>.title2</pre>
          </td>
          <td><span class="title2">Lorem ipsum of heading</span></td>
        </tr>
        <tr>
          <td>
            <pre>&lt;h3&gt;/&lt;h3&gt;</pre>
            <pre>.title3</pre>
          </td>
          <td><span class="title3">Lorem ipsum of heading</span></td>
        </tr>
        <tr>
          <td>
            <pre>&lt;h4&gt;/&lt;h4&gt;</pre>
            <pre>.title4</pre>
          </td>
          <td><span class="title4">Lorem ipsum of heading</span></td>
        </tr>
        <tr>
          <td>
            <pre>&lt;h5&gt;/&lt;h5&gt;</pre>
            <pre>.title5</pre>
          </td>
          <td><span class="title5">Lorem ipsum of heading</span></td>
        </tr>
        <tr>
          <td>
            <pre>&lt;h6&gt;/&lt;h6&gt;</pre>
            <pre>.title6</pre>
          </td>
          <td><span class="title6">Lorem ipsum of heading</span></td>
        </tr>
      </table>

      <br><br>

      <table>
        <tr>
          <th><strong>Body</strong></th>
          <th><strong>Example</strong></th>
        </tr>
        <tr>
          <td>
            <pre>.bold</pre>
            <pre>.semibold</pre>
            <pre>.regular</pre>
          </td>
          <td>
            <p class="bold"> Lorem ipsum of paragraph</p>
            <p class="semibold"> Lorem ipsum of paragraph</p>
            <p class="regular"> Lorem ipsum of paragraph</p>
          </td>
        </tr>
        <tr>
          <td>
            <pre>.size-18</pre>
            <pre>.size-17</pre>
            <pre>.size-16</pre>
            <pre>.size-15</pre>
            <pre>.size-14</pre>
            <pre>.size-13</pre>
            <pre>.size-12</pre>
            <pre>.size-11</pre>
          </td>
          <td>
            <div class="size-18">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="size-17">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="size-16">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="size-15">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="size-14">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="size-13">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="size-12">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="size-11">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
          </td>
        </tr>
      </table>


      <br><br>

      <table>
        <tr>
          <th><strong>Text Color</strong></th>
          <th><strong>Example</strong></th>
        </tr>
        <tr>
          <td>
            <pre>.text-primary</pre><br>
            <pre>.text-secondary-1</pre>
            <pre>.text-secondary-2</pre><br>
            <pre>.text-info</pre>
            <pre>.text-success</pre>
            <pre>.text-warning</pre>
            <pre>.text-danger</pre><br>
            <pre>.text-dark</pre>
            <pre>.text-light</pre>
          </td>
          <td>
            <div class="text-primary">Lorem ipsum dolores Lorem ipsum dolor sit.</div><br>
            <div class="text-secondary-1">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="text-secondary-2">Lorem ipsum dolores Lorem ipsum dolor sit.</div><br>
            <div class="text-info">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="text-success">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="text-warning">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="text-danger">Lorem ipsum dolores Lorem ipsum dolor sit.</div><br>
            <div class="text-dark">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
            <div class="text-light">Lorem ipsum dolores Lorem ipsum dolor sit.</div>
          </td>
        </tr>
      </table>

      <!-- <br><br>

    <table>
      <tr>
        <th><strong>Badge Color</strong></th>
        <th><strong>Example</strong></th>
        <th></th>
        <th><strong>Badge Rounded</strong></th>
        <th><strong>Example</strong></th>
        <th></th>
        <th><strong>Badge Square</strong></th>
        <th><strong>Example</strong></th>
      </tr>
      <tr>
        <td>
          <pre>.badge-simple--sm</pre><br>
          <pre>.badge-simple--lg</pre>
        </td>
        <td>
          <div class="badge-simple badge-simple--sm"><span>9+</span></div><br>
          <div class="badge-simple badge-simple--lg"><span>25%</span></div>
        </td>
        <td>

        </td>
        <td>
          <pre>.badge-rounded--sm</pre><br>
          <pre>.badge-rounded--lg</pre>
        </td>
        <td>
          <div class="badge-rounded badge-rounded--sm"><span>9+</span></div><br>
          <div class="badge-rounded badge-rounded--lg"><span>25%</span></div>
        </td>
        <td>

        </td>
        <td>
          <pre>.badge-square--sm</pre><br>
          <pre>.badge-square--lg</pre>
        </td>
        <td>
          <div class="badge-square badge-square--sm"><span>Oui</span></div><br>
          <div class="badge-square badge-square--lg"><span>$Oui</span></div>
        </td>
      </tr>
      <tr>
        <td>
          <pre>.badge-simple--primary</pre>
          <pre>.badge-simple--secondary-1</pre>
          <pre>.badge-simple--secondary-2</pre>
          <pre>.badge-simple--info</pre>
          <pre>.badge-simple--success</pre>
          <pre>.badge-simple--warning</pre>
          <pre>.badge-simple--danger</pre>
          <pre>.badge-simple--black</pre>
          <pre>.badge-simple--light</pre><br>
        </td>
        <td>
          <div class="badge-simple badge-simple--primary"><span>9+</span></div>
          <div class="badge-simple badge-simple--secondary-1"><i class="icn-check"></i></div>
          <div class="badge-simple badge-simple--secondary-2"><i class="icn-alert"></i></div>
          <div class="badge-simple badge-simple--info"><span>2-</span></div>
          <div class="badge-simple badge-simple--success"><span>9+</span></div>
          <div class="badge-simple badge-simple--warning"><span>9+</span></div>
          <div class="badge-simple badge-simple--danger"><span>9+</span></div>
          <div class="badge-simple badge-simple--black"><span>9+</span></div>
          <div class="badge-simple badge-simple--light"><span>9+</span></div><br>
        </td>
        <td>

        </td>
        <td>
          <pre>.badge-rounded--primary</pre>
          <pre>.badge-rounded--secondary-1</pre>
          <pre>.badge-rounded--secondary-2</pre>
          <pre>.badge-rounded--info</pre>
          <pre>.badge-rounded--success</pre>
          <pre>.badge-rounded--warning</pre>
          <pre>.badge-rounded--danger</pre>
          <pre>.badge-rounded--black</pre>
          <pre>.badge-rounded--light</pre><br>
        </td>
        <td>
          <div class="badge-rounded badge-rounded--primary"><span>9+</span></div>
          <div class="badge-rounded badge-rounded--secondary-1"><i class="icn-check"></i></div>
          <div class="badge-rounded badge-rounded--secondary-2"><i class="icn-alert"></i></div>
          <div class="badge-rounded badge-rounded--info"><span>2-</span></div>
          <div class="badge-rounded badge-rounded--success"><span>9+</span></div>
          <div class="badge-rounded badge-rounded--warning"><span>9+</span></div>
          <div class="badge-rounded badge-rounded--danger"><span>9+</span></div>
          <div class="badge-rounded badge-rounded--black"><span>9+</span></div>
          <div class="badge-rounded badge-rounded--light"><span>9+</span></div><br>
        </td>
        <td>

        </td>
        <td>
          <pre>.badge-square--primary</pre>
          <pre>.badge-square--secondary-1</pre>
          <pre>.badge-square--secondary-2</pre>
          <pre>.badge-square--info</pre>
          <pre>.badge-square--success</pre>
          <pre>.badge-square--warning</pre>
          <pre>.badge-square--danger</pre>
          <pre>.badge-square--black</pre>
          <pre>.badge-square--light</pre><br>
        </td>
        <td>
          <div class="badge-square badge-square--primary"><span>9+</span></div>
          <div class="badge-square badge-square--secondary-1"><i class="icn-check"></i></div>
          <div class="badge-square badge-square--secondary-2"><i class="icn-alert"></i></div>
          <div class="badge-square badge-square--info"><span>2-</span></div>
          <div class="badge-square badge-square--success"><span>9+</span></div>
          <div class="badge-square badge-square--warning"><span>9+</span></div>
          <div class="badge-square badge-square--danger"><span>9+</span></div>
          <div class="badge-square badge-square--black"><span>9+</span></div>
          <div class="badge-square badge-square--light"><span>9+</span></div><br>
        </td>
      </tr>
    </table> -->
    </div>

    <!-- BUTTON -->
    <div class="main main-button">
      <strong>Button type</strong><br><br>
      <strong>Default type</strong><br>
      <div class="parent">
        <div class="div1">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="primary"></app-button-default>
          <pre><div>color=" "</div><div>color="primary"</div></pre>
        </div>
        <div class="div2">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="secondary"></app-button-default>
          <pre>color="secondary"</pre>
        </div>
        <div class="div3">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary"></app-button-default>
          <pre>color="tertiary"</pre>
        </div>
        <div class="div4">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary"
            [disabled]="true"></app-button-default>
          <pre>[disabled]="true"</pre>
        </div>
      </div>
      <br>
      <strong>Outlined Button</strong><br>
      <div class="parent">
        <div class="div2">
          <app-button-default label="outlined" view="outlined"></app-button-default>
          <pre><div>view="outlined"</div><div>label="outlined"</div></pre>
        </div>
        <div class="div2">
          <app-button-default icon="icn-ico_deconnexion" view="outlined"></app-button-default>
          <pre><div>view="outlined"</div><div>icon="icn-ico_deconnexion"</div></pre>
        </div>
        <div class="div3">
          <app-button-default label="outlined" view="outlined" [disabled]="true"></app-button-default>
          <pre><div>view="outlined"</div><div>label="outlined"</div><div>[disabled]="true"</div></pre>
        </div>
      </div>
      <br>
      <strong>Flat Button</strong><br>
      <div class="parent">
        <div class="div1">
          <app-button-default label="flat" view="flat"></app-button-default>
          <pre><div>view="flat"</div><div>label="flat"</div></pre>
        </div>
        <div class="div2">
          <app-button-default icon="icn-ico_deconnexion" view="flat"></app-button-default>
          <pre><div>view="flat"</div><div>icon="icn-ico_deconnexion"</div></pre>
        </div>
        <div class="div3">
          <app-button-default label="flat" view="flat" [disabled]="true"></app-button-default>
          <pre><div>view="flat"</div><div>icon="icn-ico_deconnexion"</div><div>[disabled]="true"</div></pre>
          <!-- <pre>{{appbutton}}</pre> -->
        </div>
      </div>


      <br><strong>Sizes</strong><br>
      <strong>Large Button type</strong><br>
      <div class="parent">
        <div class="div1">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="lg" icon="icn-profil" color="primary"
            (actionButton)="testAction()" [rightIcon]="true">
          </app-button-default>
          <pre><div>color="primary"</div><div>icon="icn-profil"</div><div>size="lg"</div></pre>
        </div>
        <div class="div2">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="lg" icon="icn-profil" color="secondary"
            (actionButton)="testAction()" [rightIcon]="true">
          </app-button-default>
          <pre><div>color="secondary"</div><div>icon="icn-profil"</div><div>size="lg"</div></pre>
        </div>
        <div class="div3">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="lg" icon="icn-profil" color="tertiary"
            (actionButton)="testAction()" [rightIcon]="true">
          </app-button-default>
          <pre><div>color="tertiary"</div><div>icon="icn-profil"</div><div>size="lg"</div></pre>
        </div>
        <div class="div4">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary" size="lg" icon="icn-profil"
            [disabled]="true" (actionButton)="testAction()">
          </app-button-default>
          <pre><div>color=" "</div><div>icon="icn-profil"</div><div>size="lg"</div><div>[disabled]="true"</div></pre>
        </div>
      </div>

      <strong>Medium type</strong><br>
      <div class="parent">
        <div class="div1">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="md" color="primary"></app-button-default>
          <pre><div>color="primary"</div><div>size="md"</div></pre>
        </div>
        <div class="div2">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="md" color="secondary"></app-button-default>
          <pre><div>color="secondary"</div><div>size="md"</div></pre>
        </div>
        <div class="div3">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="md" color="tertiary"></app-button-default>
          <pre><div>color="tertiary"</div><div>size="md"</div></pre>
        </div>
        <div class="div4">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="md" color="tertiary"
            [disabled]="true"></app-button-default>
          <pre><div>color=" "</div><div>size="md"</div><div>[disabled]="true"</div></pre>
        </div>
      </div>

      <strong>Small Button type</strong>
      <div class="parent">
        <div class="div1">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="sm" icon="icn-profil" color="primary"
            (actionButton)="testAction()" [rightIcon]="true">
          </app-button-default>
          <pre><div>color="primary"</div><div>icon="icn-profil"</div><div>size="sm"</div></pre>
        </div>
        <div class="div2">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="sm" icon="icn-profil" color="secondary"
            (actionButton)="testAction()" [rightIcon]="true">
          </app-button-default>
          <pre><div>color="secondary"</div><div>icon="icn-profil"</div><div>size="sm"</div></pre>
        </div>
        <div class="div3">
          <app-button-default label="{{ 'home.logOut' | translate }}" size="sm" icon="icn-profil" color="tertiary"
            (actionButton)="testAction()" [rightIcon]="true">
          </app-button-default>
          <pre><div>color="tertiary"</div><div>icon="icn-profil"</div><div>size="sm"</div></pre>
        </div>
        <div class="div4">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary" size="sm" icon="icn-profil"
            [disabled]="true" (actionButton)="testAction()">
          </app-button-default>
          <pre><div>color=" "</div><div>icon="icn-profil"</div><div>size="sm"</div><div>[disabled]="true"</div></pre>
        </div>
      </div>
      <br>
      <strong>With Icon</strong><br><br>
      <strong>Button Icon Left</strong><br>
      <div class="parent">
        <div class="div1">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="primary" icon="icn-profil">
          </app-button-default>
        </div>
        <div class="div2">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="secondary" icon="icn-profil">
          </app-button-default>
        </div>
        <div class="div3">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary" icon="icn-profil">
          </app-button-default>
        </div>
        <div class="div4">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary" [disabled]="true"
            icon="icn-profil">
          </app-button-default>
        </div>
      </div>

      <strong>Button Icon Right</strong><br>
      <div class="parent">
        <div class="div4">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="primary" icon="icn-profil"
            [rightIcon]="true">
          </app-button-default>
        </div>
        <div class="div5">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="secondary" icon="icn-profil"
            [rightIcon]="true">
          </app-button-default>
        </div>
        <div class="div6">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary" icon="icn-profil"
            [rightIcon]="true">
          </app-button-default>
        </div>
        <div class="div6">
          <app-button-default label="{{ 'home.logOut' | translate }}" color="tertiary" icon="icn-profil"
            [rightIcon]="true" [disabled]="true">
          </app-button-default>
        </div>
      </div>

      <br>
      <strong>Icon only</strong><br>
      <div class="parent">
        <div class="div1">
          <app-button-default size="lg" icon="icn-profil">
          </app-button-default>
        </div>
        <div class="div2">
          <app-button-default size="lg" icon="icn-ico_deconnexion" color="secondary">
          </app-button-default>
        </div>
        <div class="div3">
          <app-button-default size="lg" icon="icn-ico_deconnexion" color="tertiary">
          </app-button-default>
        </div>
        <div class="div4">
          <app-button-default size="lg" icon="icn-ico_deconnexion" color="tertiary" [disabled]="true">
          </app-button-default>
        </div>
      </div>
    </div>

    <!-- ICON -->
    <div class="main main-icon">
      <strong>All icons</strong><br>
      <br>
      <app-demo-icons></app-demo-icons>
    </div>

    <!-- LINK -->
    <div class="main main-link">
      <strong>Link type</strong><br>
      <div class="parent">
        <div class="div1">
          <a class="link">
            <span class="link--intern">
              <span class="icn-return"></span>
              <span class="return-text"> Texte lien</span>
            </span>
          </a>
        </div>
      </div>
    </div>

</main>
