import { Injectable } from '@angular/core';
import {ApiService} from "../../../shared/services/api-service/api.service";
import {BehaviorSubject, Observable } from "rxjs";
import {I_ApiResponse} from "../../../shared/models/api-response";
import {User} from "../../model/user/user.model";
import {ITableFilter} from "../../../shared/interfaces/table-filter";
import {tableFilter} from "../../../shared/config/constants";
import {AbilityGroup} from "../../model/ability/ability.model";
import {Role} from "../../model/role/role.model";
import {EnvService} from "../../../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public abilites$: BehaviorSubject<AbilityGroup[]> = new BehaviorSubject<AbilityGroup[]>([]);
  public users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  public user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public userError$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public imgDimension$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor(
    private apiService: ApiService,
    private env: EnvService
  ) { }

  getUsers(param: string): Observable<I_ApiResponse> {
    // /search?company=a7b7ba91-73f9-4b52-aa0b-14e0fb37f0c8&categories[]=862360ed-7682-4abe-a4e1-eae6793d6eff&roles[]=togocom-pos-group-admIN&shop=akorondrano
    let url = `${this.env.apiUserService}/${param}`;
    return this.apiService.doGet(url);
  }

  getUserByKey(userKey: string, companyUuid: string): Observable<I_ApiResponse> {

    let url = `${this.env.apiUserService}/get`;
    return this.apiService.doGet(url, {user_key: userKey, company: companyUuid});
  }

  getUser(uuid: string) {
    let url = `${this.env.apiUserService}/get/${uuid}`;
    return this.apiService.doGet(url);
  }

  setUsers(users: User[]) {
    this.users$.next(users);
  }

  setUser(user: User) {
    this.user$.next(user);
  }

  getRoles(application: string, companyKey: string): Observable<I_ApiResponse> {
    return this.apiService.doGet(`${this.env.apiRightService}/roles/${application}/${companyKey}`);
  }

  getPOSUserCategory(){
    let url: string = `${this.env.apiUserService}/category/get`;
    return this.apiService.doGet(url);
  }

  getAbilities(role: string) {
    let url = `${this.env.apiRightService}/abilities/full?${role}`;
    return this.apiService.doGet(url);
  }

  setAbilities(abilities: AbilityGroup[]) {
    this.abilites$.next(abilities);
  }

  setUserError(error: Object) {
    this.userError$.next(error);
  }

  getRoleParam(roles: Role[]) {
    let role: string = ''
    if(roles && roles.length > 0 && roles[0] != null)
      roles.forEach( (value: Role, index: number) => {
        role += index == 0 ? `roles[]=${value.role_uuid}` : `&roles[]=${value.role_uuid}`
      })
    return role;
  }

  getAvatarDimention(image: File) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    let isDimension: boolean = false;
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        // isDimension = (height <= 145 && width <= 145);
        this.imgDimension$.next((height <= 145 && width <= 145));
      };
    };
  }


  getRoleByMultiUuid(param: string): Observable<I_ApiResponse> {
    let url = `${this.env.apiRightService}/roles?${param}`;
    return this.apiService.doGet(url);
  }

  getShops(company_uuid: string) {
    let url = `${this.env.apiShopService}/company/${company_uuid}/get`;
    return this.apiService.doGet(url);
  }

  getShop(uuid: string) {
    let url = `${this.env.apiShopService}/${uuid}`;
    return this.apiService.doGet(url);
  }
}
