import { MiscService } from 'src/app/main/modules/shared/services/miscellaniscious/misc.service';
import { regexValidation } from './../../config/constants';
import { Directive, ElementRef, Input } from '@angular/core';
import { I_FieldData } from '../../models/field-data';
import { Observable, fromEvent, debounceTime } from 'rxjs';
import { config } from 'src/app/config/config';

@Directive({
  selector: '[numericInput]',
})
export class NumericInputDirective {

  @Input()
  fieldData?: I_FieldData;

  @Input()
  maxAllowedValue?: number;

  @Input()
  minAllowedValue?: number;

  InputRegexStr = regexValidation.allowDigitOnly;
  AllowedRegexStr = regexValidation.allowNumberOnly;
  currentValue: number|string = '';

  valueRegex: RegExp;

  private change: Observable<any> =fromEvent(this._elementRef.nativeElement, 'keyup');
  inputPreviousValue: number =0;

  constructor(private _elementRef: ElementRef, private miscService:MiscService) {

    this.valueRegex = new RegExp(this.AllowedRegexStr);

    this.change.pipe(
      debounceTime(config.autocompleteFilterDebounceTime)
    )
    .subscribe((eventInput: any)=>{

      // check de la valeur de l'input
      const currentValue =eventInput.target.value;

      if( !this.valueRegex.test( currentValue ) && currentValue!='' ){
        this._elementRef.nativeElement.value =this.inputPreviousValue;
      }else{
        this.inputPreviousValue =currentValue;
      }

      this.updateFieldData({...this.fieldData, ...{ fieldValue: this.inputPreviousValue }});
      //

    })

  }

  updateFieldData(fieldData: any){
    if( this.fieldData!=null ){
      this.fieldData ={...this.fieldData, ...fieldData};

      this.miscService.updateFieldData(this.fieldData);
    }

  }
}
