import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Icon} from "../../models/icon/icon";

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private icon$: BehaviorSubject<Icon> = new BehaviorSubject<Icon>(null)
  constructor() { }

  getIcon(): Observable<Icon> {
    return this.icon$
  }

  addIcon(icon: Icon) {
    this.icon$.next(icon);
  }
}
