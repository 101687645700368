import {HelperService} from 'src/app/main/modules/shared/services/helper/helper.service';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../shared/services/local-storage/local-storage.service';
import {storageKey} from 'src/app/config/storage';
import {url} from 'src/app/config/url';
import {SidebarService} from 'src/app/main/layouts/pos-layout/services/sidebar/sidebar.service';
import {UserService} from '../../services/user/user.service';
import {abilities} from "../../../../../config/abilities";

@Component({
  selector: 'app-ssoRedirection',
  templateUrl: './ssoRedirection.component.html',
  styleUrls: ['./ssoRedirection.component.scss']
})
export class SsoRedirectionComponent implements OnInit {

  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private sideBarService: SidebarService,
    private userService: UserService
  ) {

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let token = params.get('token');
      this.localStorageService.setLocalStorage(storageKey.session.jwtKey, token);
      let token_data = this.helperService.decodeJwtToken(token);

      this.userService.getUserByKey(token_data.sub?.user_key, token_data.sub?.users?.company_uuid).subscribe({
        next: (data) => {
          const user = data.data.users[0];
          const user_data: any = {
            company_uuid: user.company_uuid,
            first_name: user.first_name,
            last_name: user.last_name,
            fk_shop_uuid: user.fk_shop_uuid,
            shop: user.shop,
            user_uuid: user.user_uuid,
            company: user.company,
            avatar: user?.avatar
          };

          this.localStorageService.setLocalStorage(storageKey.user.userData, JSON.stringify(user_data));
          this.localStorageService.setLocalStorage(storageKey.user.userUuid, user.user_uuid);
          this.sideBarService.updateUserAbilities(token_data.sub.abilities);
          if (!user.shop && !token_data.sub.abilities.includes(abilities.user_access_not_shop)) this.router.navigateByUrl('/error/user-no-shop');
          else this.router.navigateByUrl(url.home);
        },
        error: _ => {
          console.log('une erreur est survenue : gérer les erreurs ici');
        }
      });
    })
  }

}
