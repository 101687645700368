import { filter } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';

import { Subscription } from "rxjs";
import { I_DialogData, I_DialogStatus } from "../../../models/dialog";
import { DialogService } from '../../../services/dialog/dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  @ViewChild('contentForm') contentForm: ElementRef;
  @Input()
  dialogId!: string;


  @Input()
  size: string;

  @Input()
  cible?: string;
  @Input()
  dialogData: I_DialogData | null = null;

  @Input() test?: string;

  dialogStatus: I_DialogStatus | null = null;
  subscription: Subscription = new Subscription();

  @Input() width: number;

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {

    this.subscription.add(
      this.dialogService.dialogVisible$
        .pipe(
          filter(x => x != null && x?.dialogId === this.dialogId),
        )
        .subscribe((status) => {
          this.dialogStatus = status;

        })
    );

    this.subscription.add(
      this.dialogService.dialogData$
        .subscribe((data) => {
          if (data != null) {
            this.dialogData = data;
          }
        })
    );

    this.subscription.add(
      this.dialogService.dialogCible$
        .pipe(
          filter(x => x != null && x?.dialogId == this.dialogId),
        )
        .subscribe((data: any) => {
          this.cible = data.cible;
        })
    );

    this.subscription.add(
      this.dialogService.dialogSize$
        .pipe(
          filter(x => x != null && x?.dialogId == this.dialogId),
        ).subscribe((data: any) => {
          this.size = data.size;
        })
    );
  }


  closeModal() {
    if (this.test === '' || this.test === undefined) {
      this.dialogService.setDialogIsClosed(true);
      this.dialogService.hideDialog(this.dialogId);
    } else {
      this.dialogService.hideDialog(this.dialogId);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  dialogWidth(): string {
    return this.width && this.width > 0 ? this.width + 'px' : '';
  }
}
