export const constant = {
  authentication: {
    xKey: '989FA50C-C1FE-47D3-8CF1-0380F3AACC34',
    fragmentTarget: 'in',
  },

  apiResponseStatus: {
    error: 500,
    empty: 204,
    success: 200,
  },
};


export const userCategoryKey = {
  customer: 'CUSTOMER',
  particular: 'PARTICULAR',
  professionnal: 'PROFESSIONNAL'
}
