<app-page-title [text]="pageTitle"></app-page-title>

<div class="block-column-20">
  <div class="content-product">
    <div class="left-cl">

      <div class="enterprise inventaire">
        <form>
          <div class="block-inventaire-form">
            <div class="enterprise__form">
              <div class="form-group">
                <label for="">{{ 'inventory.create.form.reference' | translate }} <span class="text-danger">*</span> :</label>
                <div class="box-inputs">
                  <label for=""><span class="bold">{{ inventory?.inventory_reference }}</span></label>
                </div>
              </div>
              <div class="form-group">
                <label for="">{{ 'inventory.create.form.site' | translate }} <span class="text-danger">*</span> :</label>
                <div class="box-inputs">
                  <label for=""><span class="bold">TOGOCOM SHOP 1</span></label>
                </div>
              </div>
              <div class="form-group">
                <label for="">{{ 'inventory.create.form.type' | translate }} <span class="text-danger">*</span> :</label>
                <div class="box-inputs">
                  <label for=""><span class="bold">{{ inventory?.inventory_type.inventory_type_label }}</span></label>
                </div>
              </div>
              <div class="form-group">
                <label for="">{{ 'inventory.create.form.status' | translate }} <span class="text-danger">*</span> :</label>
                <div class="box-inputs">
                  <label for=""><span class="bold">{{ inventory?.inventory_status.inventory_status_label }}</span></label>
                </div>
              </div>
              <div class="form-group">
                <label for="">{{ 'inventory.create.form.startDate' | translate }} <span class="text-danger">*</span> :</label>
                <div class="box-inputs">
                  <label for=""><span class="bold">{{ inventory?.create_date_utc | date }}</span></label>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
    <div class="right-cl">
      <app-quick-repport [quickRepports]="quickReportDatas"></app-quick-repport>
    </div>
  </div>

  <div class="right-cl">
    <div class="block-column-20">
      <app-table-filter-new [id]="tableFilterArticleListId" [filterParams]="tableFilter"></app-table-filter-new>
      <app-table-form [id]="tableFormId" [iconId]="'inventory'"></app-table-form>
      <div class="block-center" *ngIf="hasNextPage">
        <app-button [config]="showMoreButton"></app-button>
      </div>
    </div>
  </div>
</div>

<div class="align-center space-top" *ngIf="inventoryStatus?.inventory_status_key !== 'FINISHED'">
  <app-button style="margin-top: 25px" [config]="saveButton"></app-button>
  <app-button style="margin-top: 25px" [config]="finishButton"></app-button>
</div>
