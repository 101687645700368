<div class="wrapper block-batch" [formGroup]="serializationFormGroup">
  <div class="block-article-lot">
    <div class="serialization-key">
    </div>
    <div class="article-lot">
      <div class="article-lot__item">
        <div class="article-lot__container">
          <h5 class="article-lot__title">
            Debut {{ serializationFormGroup.value['product_serialization_key'] }}:
          </h5>
          <div class="article-lot__wrapper">
            <div type="text">
              <mat-form-field class="example-full-width" appearance="fill">
                <input
                  type="text"
                  formControlName="serial_number_start"
                  matInput
                  (input)="triggerChange(true)"
                  [matAutocomplete]="start"
                >
                <button class="grey-icon" *ngIf="serializationFormGroup.value['serial_number_start']" matSuffix mat-icon-button aria-label="Clear" (click)="clearStartValue()">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-autocomplete autoActiveFirstOption #start="matAutocomplete" (optionSelected)="selectValue($event)">
                  <mat-option *ngFor="let serial of serialNumberPerBacth | async" [value]="serial">
                    {{serial.product_serialization_key}} {{serial.serial_number_start}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <!-- <div class="article-lot__icon"> -->
              <div class="article-lot__icon">
                <i [ngClass]="serializationFormGroup.value['is_serialization_valid'] ? 'icn-check' : 'icn-alert'"></i>
              </div>
            <!-- </div> -->
          </div>
          <div class="error" *ngIf="showStartError">
            Numéro de série non valide
          </div>
        </div>
      </div>
      <div class="article-lot__item">
        <div class="article-lot__container">
          <h5 class="article-lot__title">
            Fin {{ serializationFormGroup.value['product_serialization_key'] }}:
          </h5>
          <div class="article-lot__wrapper">
            <div type="text">
              <mat-form-field class="example-full-width" appearance="fill">
                <input
                  type="text"
                  formControlName="serial_number_end"
                  matInput
                  (input)="triggerChange()"
                  [matAutocomplete]="end"
                >
                <button class="grey-icon" *ngIf="serializationFormGroup.value['serial_number_end']" matSuffix mat-icon-button aria-label="Clear" (click)="clearEndValue()">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-autocomplete autoActiveFirstOption #end="matAutocomplete" (optionSelected)="selectValue($event)">
                  <mat-option *ngFor="let serial of serialNumberPerBacth | async" [value]="serial">
                    {{serial.product_serialization_key}} {{serial.serial_number_end}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="article-lot__icon">
              <i [ngClass]="serializationFormGroup.value['is_serialization_valid'] ? 'icn-check' : 'icn-alert'"></i>
            </div>
          </div>
          <div class="error" *ngIf="showEndError">
            Numéro de série non valide
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
